import './_styles.scss';

interface Props{
    title:string,
    text?:string,
}

export const Message = (props:Props) => {
    return (
        <p className="warning">
            {props.title ?
                <span>
                    {props.title}<br/>
                </span>
                :
                null
            }
            {props.text ?
                <span className="faint">{props.text}</span>
                :
                null
            }
        </p>
    )
}