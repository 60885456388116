// import _ from 'lodash'

import _ from 'lodash';
//@ts-ignore
import lunr from 'elasticlunr';
// Note: Would be nice to support gaelic-specific searching with https://www.npmjs.com/package/lunr-languages
// but it doesn't support Gaelic yet.

import Content from './Content';
import type {ContentHome} from './Content';
import Config from '../config/Config'
import {LangId} from '../tools/Lang';
import Tools from '../tools/Tools';
import {AudienceId} from '../tools/Audience';
import lunrTools from '../tools/elasticlunr/ElasticLunrTools'
import type {ContentData, LangString, ContentStory, AnyContentItem, ContentMaterial, RawContent} from './Content'
import type {SearchResultsItemData} from '../components/ModalSearch/SearchResultsItem';
import {MediaType} from '../data/Topic';

type LunrSearchResultItem = {
	ref:string,
	score:number,
}

/** We delay startup up the search engine. May help performance, and people take a few secs to begin searching. */
const STARTUP_DELAY_SECS = 2

export default class Searcher {

	content:Content
	data:ContentData
	indexes:{[key:string]: lunr} = { }

	langs = ["en", "ga"]

	initComplete = false

	settings = {
		story: {
			fields: [
				{id: "title",			isLangField: true, weight: 1},
				{id: "description",		isLangField: true, weight: 0.5},
			],
		}		
	}
	constructor(content:Content, data:ContentData) {
		this.content = content
		this.data = data
		this._init()
	}


	async _init() {
		this.initComplete = false

		await Tools.later(STARTUP_DELAY_SECS)
		console.log(`Creating search indexes...`)
		const startTime = Date.now()

		this._initLunr()
		this.indexes[LangId.en] = await this._createSearchIndex(LangId.en);
		this.indexes[LangId.ga] = await this._createSearchIndex(LangId.ga);
		
		const duration = Date.now() - startTime
		console.log(`Search indexes took ${(duration/1000).toFixed(2)} secs to create.`)
		
		this.initComplete = true
	}

	async _ensureInitComplete() {
		await Tools.time.poll(() => this.initComplete, 0.1)
	}
	

	_initLunr() {
		lunr.Pipeline.registerFunction(lunrTools.pipeline.removeAccents, "removeAccents")
		lunr.tokenizer.setSeperator(lunrTools.separators.default)
		lunrTools.extendFunctionality.getDoc()
	}

	async _createSearchIndex(lang:string) {

		const index = lunr();
		if (Config.settings.langs[lang]?.stopWords) {
			lunr.clearStopWords()
			lunr.addStopWords(Config.settings.langs[lang]?.stopWords)
		}
		else {
			lunr.resetStopWords()
		}

		index.pipeline.before(lunr.trimmer, lunrTools.pipeline.removeAccents)

		index.setRef('id');
		index.addField('type')
		index.addField(`title`)
		index.addField(`surtitle`)
		index.addField(`description`)
		index.addField(`keywords`)
		index.addField(`places`)
		index.addField(`characters`)

		await this._addStoriesToIndex(this.data.stories, 	 index, lang)
		await this._addMaterialsToIndex(this.data.materials, index, lang)
		await this._addGroupsToIndex(this.content, 			 index, lang)

		return index;
	}

	async _addStoriesToIndex(stories:ContentStory[], index:lunr, lang:string) {		
		// Filter out any stories that have no title or no description
		stories = stories.filter(
			(story:ContentStory) => Content.isLangStringPopulated(story.title) && Content.isLangStringPopulated(story.description)
		)
		for (var i in stories) {

			// Wait a tick every batch of items so we don't block the UI
			if (Number(i) % 20 === 0) await Tools.time.tick()

			const story = stories[i]
			const placenames:LangString|null = this.content.getStoryFullPlaceNames(story.places)
			const characters:LangString|null = this.content.getStoryCharacterNames(story.characters)

			// console.log(`${story.id}:`, story.audiences)

			const doc:any = {
				id: 					`topic:${story.id}`,
				type: 					"topic",
				title: 					story.title?.[lang] || "",
				description: 			story.description?.[lang] || "",
				places: 				placenames?.[lang],
				characters: 			characters?.[lang],
				hiddenOnTouchDevice: 	story.hiddenOnTouchDevice == true
			}
			if (doc.characters) {
				// console.log(doc.characters)
			}

			// _.forOwn(story.audiences, (isSet:boolean, audienceId:string) => {
			// 	doc[audienceId] = isSet
			// })
			index.addDoc(doc)

		}
	}

	async _addMaterialsToIndex(materials:ContentMaterial[], index:lunr, lang:string) {

		// Filter out any materials that have no title, just in case
		materials = materials.filter(
			(material:ContentMaterial) => Content.isLangStringPopulated(material.title)
		);

		for (let i in materials) {
			const material = materials[i]

			// Wait a tick every batch of items so we don't block the UI
			if (Number(i) % 20 === 0) await Tools.time.tick()

			const story:ContentStory|undefined = this.content.getStoryByRawId(material.story) 
			//@BUG material_id should be an itemId (e.g. "black_dog_318")
			const niceId = Content.getNiceId(material.id, material._id)
			const materialHidden = this.content.isMediaItemHiddenOnTouchDevice(story, niceId)
			const storyHidden = story?.hiddenOnTouchDevice == true
			const hiddenOnTouchDevice = materialHidden || storyHidden


			const placenames:LangString|null = story ? this.content.getStoryFullPlaceNames(story.places) : null
			const characters:LangString|null = story ? this.content.getStoryCharacterNames(story.characters) : null


			const doc:any = {
				id: 			`material:${material._id}`,
				type: 			material.type,
				title: 			material.title?.[lang] || "",
				surtitle:		story?.title[lang] || "",
				description:	material.description?.[lang] || "",
				keywords:		material.keywords?.[lang] || "",
				places: 				placenames?.[lang],
				characters: 			characters?.[lang],
                hiddenOnTouchDevice,
			}
			index.addDoc(doc)
			if (doc?.keywords) {
				// console.log(doc.keywords)
				if (story?._id=="29") {
					// console.log(`HALT!`)
				}
				// console.log(this.content.getStoryCharacterNames(story.characters))
			}
			// if (material._id==="90") console.log(`Added search doc material:`, material, `story:`, story);
		}

	}
	




	async _addGroupsToIndex(content:Content, index:lunr, lang:string) {
		const imagePath = Config.getImagePath()

		// For now we're only doing learners, since they're the only ones 
		// with group pages that we can navigate to.
		const audienceId:AudienceId = AudienceId.learners

		let homeData:ContentHome = content.getAudienceData(audienceId).home;
		for(let sectionData of homeData.sections){
			for (let rowData of sectionData.rows) {
				const url = `group/${sectionData.id}/${rowData.id}`
				const pic = `${imagePath}/shortcuts/${audienceId}/${sectionData.id}.jpg`

				const doc:any = {
					id: 			`group:${sectionData.id}:${rowData.id}`,
					type: 			"group",
					title: 			 rowData.title?.[lang]|| "",
					description:	"",
				}

				index.addDoc(doc)
				// console.log(`Added doc ${doc.id} to index`, doc)
			}
		}
	}





	//--------------------------------------------------------------------------------------------
	// SEARCH






	async search ({lang="en", searchValue, searchForPartOfWords=true, audiences=[]}:{lang:string, searchValue:string, searchForPartOfWords?:boolean, audiences:(AudienceId|undefined)[]}):Promise<SearchResultsItemData[]> {
		// console.log("Searcher.search()")
        if (!this.data) throw Error(`No data found.`)
		// let items:Array<SearchResultsItemData> =  []

		searchValue = searchValue.trim()
		if (searchValue.length===0) return [];
		
		console.log(`Search results for "${searchValue}" in audience ${audiences}:`)
		
		
		let items:SearchResultsItemData[] = []
		const searchOptions = {expand: searchForPartOfWords===true}

		// Search through all language indexes, not just the currently selected lang.
		// We merge the results and sort them properly. 
		// E.g. Searching for "Cu" will find "Black Dog" items, since "Cù Dubh" is the gaelic.
		const allLangs = Object.keys(LangId)

		// allLangs.forEach(lang => {
		// 	const itemsForLang:SearchResultsItemData[] = this.indexes[lang].search(searchValue, searchOptions)
		// 	.map(
		// 		(searchResultItem:LunrSearchResultItem) => this._convertLunrItemToResultItem(searchResultItem, lang, audience)
		// 	)
		// 	.filter((item:SearchResultsItemData|undefined)=>item) as SearchResultsItemData[]
	
		// 	items = items.concat(itemsForLang)
		// })


		if (audiences.length===0) {
			audiences = [AudienceId.learners, AudienceId.kids, AudienceId.toddlers]
		}
	
		
		this._ensureInitComplete()

		allLangs.forEach(lang => {
			const itemsForLang:LunrSearchResultItem[] = this.indexes[lang].search(searchValue, searchOptions)
			
			audiences.forEach((audience?:AudienceId)=> {
				const itemsForAudience:SearchResultsItemData[] = itemsForLang
				.slice()
				.map(
					(searchResultItem:LunrSearchResultItem) => this._convertLunrItemToResultItem(searchResultItem, lang, audience)
				)
				.filter((item:SearchResultsItemData|undefined)=>item) as SearchResultsItemData[]
		
				items = items.concat(itemsForAudience)
			})
		})

		items = _.uniqBy(items, (item:SearchResultsItemData) => item.ref)
		.sort((item1,item2) => item2.weight - item1.weight)

		this._gatherUpTopicResultItems(items)

		return items;
	}



	_convertLunrItemToResultItem(searchResultItem:LunrSearchResultItem, lang:string, audience?:AudienceId):SearchResultsItemData|undefined {
		// console.log(searchResultItem)
		
		let doc = this.indexes[lang].getDoc(searchResultItem.ref)
		// console.log(doc)

		// Currently the only way to filter is by doing it after the lunr search has run
		const [type] = searchResultItem.ref.split(`:`)

		let result:SearchResultsItemData = {
			type,
			weight: searchResultItem.score,
			ref: searchResultItem.ref,
			audience,
		}

		const isTouchDevice = Tools.isTouchDevice;

		let content:{item:AnyContentItem, parent:AnyContentItem}
		let topic:ContentStory|undefined
		let parentTopic:ContentStory|undefined
		let material:ContentMaterial|undefined
		let isItemForAudience:boolean
		let isItemForDevice:boolean

		switch(type) {
			case "topic":
				var [typetmp, id] = searchResultItem.ref.split(`:`)
				content = this.content.getContentById({type, id})

				topic = content.item as ContentStory
				parentTopic = content.parent as ContentStory
				material = content.item as ContentMaterial
				isItemForAudience = this._isResultItemForAudience({audience, type, topic, material, parentTopic})
				isItemForDevice = this._isResultItemForDevice({isTouchDevice, type, topic, material, parentTopic})
				if (!isItemForAudience || !isItemForDevice) return;
				
				result = {
					... result,
					topicId: 	id,
					text: 		topic.description,
					topicTitle:	topic.title,
				}
				break	
			case "material":
				var [typetmp, id] = searchResultItem.ref.split(`:`)
				content = this.content.getContentById({type, id})

				topic = content.item as ContentStory
				parentTopic = content.parent as ContentStory
				material = content.item as ContentMaterial
				isItemForAudience = this._isResultItemForAudience({audience, type, topic, material, parentTopic})
				isItemForDevice = this._isResultItemForDevice({isTouchDevice, type, topic, material, parentTopic})
				// if (material._id==="90") console.log(`Search result material:`, material)
				
				if (!isItemForAudience || !isItemForDevice) return;
				const topicTitle = _.isEqual(material.title, parentTopic?.title) ? undefined : parentTopic.title
				result = {
					... result,
					type: 		material.type,
					itemId: 	id,
					topicId: 	parentTopic?.id,
					topicTitle,
					text: 		material.description,
					itemTitle: 	material.title,
					... this._getMediaExtraData(material)
				}
				break
				
			case "group":
				// Example result IDs for a group...
				//
				// "group:specialCollections:places.indian_tribes" 					accesses: `raw.places.indian_tribes`
				// "group:characters:characters.heroes_and_heroines"				accesses: `raw.characters.heroes_and_heroines`
				// "group:characters:characters.wizards_witches"
				// "group:storyTypes:storyTypes.supernatural_stories"
				// "group:places:places.highland_scotland.argyll_and_the_islands"	accesses: `raw.places.highland_scotland.argyll_and_the_islands`

				var [typetmp, sectionId, fullGroupId] = searchResultItem.ref.split(`:`)
				const groupIdParts = fullGroupId.split(".")
				const endIndex = groupIdParts.length - 1
				let parentGroupId = groupIdParts[endIndex-1]
				let groupId:string|undefined = groupIdParts[endIndex]

				isItemForAudience = this._isResultItemForAudience({audience, type})
				if (!isItemForAudience) return;

				content = this.content.getContentById({type: sectionId, id: groupId, parent: parentGroupId})
				// console.log(`CONTENT:`, content)
				result = {
					... result,
					sectionId: 		sectionId,
					groupId: 		fullGroupId,
					parentGroupId: 	parentGroupId,
					sectionTitle:	this.content.getSectionTitle(sectionId),
					groupTitle: 	content.item?.title,
					parentGroupTitle:	content.parent?.title,	
				}

				break	
		}

		// console.log(result)
		return result;
	}


	itemToString(item:SearchResultsItemData) {
		return `${item.type} topicId: ${item.topicId}, itemId: ${item.itemTitle?.en||""} SCORE: ${item.weight}`;
	}

	_gatherUpTopicResultItems(items:SearchResultsItemData[]) {
		// console.log(`ITEMS:`, items.map(item=> this.itemToString))

		const topicItems = items.filter(item=>item.type==="topic")

		for (let iTopic in topicItems) {
			const topicItem:SearchResultsItemData = topicItems[iTopic]
			// console.log(`GATHER UP TOPIC ${topicItem.topicId}`)
			this._gatherUpTopic(topicItem, items, Config.settings.search.gatherItems)

			// Do the topic-gathering again. The algorithm doesn't quite work perfectly.
			// If topic item was originally too far away from a topic, it will stay in the same place.
			// Doing it twice catches most of these stragglers.
			this._gatherUpTopic(topicItem, items, Config.settings.search.gatherItems)
		}
	}


	/** For a given topic result, try to move nearby topic-items to just below it. */
	_gatherUpTopic(
		topicItem:SearchResultsItemData, items:SearchResultsItemData[], 
		{scoreProximity, positionProximity}:{scoreProximity:number, positionProximity:number}
	) {
		let iTopicItem = items.indexOf(topicItem)
		// console.log(`_gatherUpTopic(${topicItem.topicId}) at index ${iTopicItem} `)
	
		let i = 0

		// We want to build up this list. We don't change `items` until the end.
		const itemsToMove:SearchResultsItemData[] = []

		let item:SearchResultsItemData

		while (true) {
			const item:SearchResultsItemData = items[i]
			if (!item) break;

			// Is this item a child of the topic?
			const isTopicItem = item.type!=="topic" && topicItem.topicId===item.topicId
			if (isTopicItem) {

				// Similar score?
				const scoreDifference = Math.abs(topicItem.weight - item.weight)
				const hasSimilarScore = scoreDifference <= scoreProximity

				// Nearby?
				const numToMove = itemsToMove.length
				const isTopNearby = Math.abs(iTopicItem - i) <= positionProximity
				const isBottomNearby = Math.abs((iTopicItem + numToMove) - i) <= positionProximity
				const isNearby = isTopNearby || isBottomNearby

				const iDestination = iTopicItem + 1 + numToMove
				// const isMoving = i !== iDestination
				if (hasSimilarScore || isNearby) {
					// console.log(`SHIFTING item at index ${items.indexOf(item)}: ${this.itemToString(item)}`)
					// console.log(`item:`, items[items.indexOf(item)])
					// console.log(`items map:`, items.map(this.itemToString))
					itemsToMove.push(item)
				}
			}
			i++;
		}


		if (itemsToMove.length>0) {
			// Move the topic to the top index
			items.splice(iTopicItem, 1)
			const firstItemIndex = items.indexOf(itemsToMove[0])
			items.splice(firstItemIndex, 0, topicItem)
			// console.log(`Hoisted topic ${topicItem.topicId} from index ${iTopicItem} -> ${firstItemIndex}`)
			iTopicItem = firstItemIndex

			// Move all the ones to be moved to just below the topic
			// console.log(`Moving ${itemsToMove.length} items to position ${iTopicItem+1}`)
			// console.log(`items at indexes:`, itemsToMove.map(item => items.indexOf(item)))
			// console.log(`items:`); items.forEach((item, index) => console.log(`[${index}]:`, item))
			Tools.array.moveElementsToPosition(items, itemsToMove, iTopicItem+1)
		}
	}



	_getMediaExtraData(material:ContentMaterial):object {
		// console.log(`${material.id}:`, material)
		switch(material.type) {
			case MediaType.video:
				// @ts-ignore
				// return {director: [{en: "Horse Darveyquinn", ga: "Pelturainn E'Doneqguach"}]};
				// return {director: material.director};
				break
		}
		return {};
	}
	


	/**
	 * 
	 *	Search results are filtered depending on the audience.
	 *
	 *	It's done in three tests, in this order of precedence...
	 *
	 *	1) A material can be tagged with an audience. Or it can be tagged to be hidden from an audience.
	 *	2) The blacklist: Audiences do not show certain types of result, e.g. kids get no groups, topics or archive materials.
	 *	3) A topic can be tagged with an audience (or tagged to be hidden from an audience)
	 */
	_isResultItemForAudience(
		{type, audience, topic, material, parentTopic}
		:{audience?:AudienceId, type:string, topic?:ContentStory, material?:ContentMaterial, parentTopic?:ContentStory}
	):boolean
	{
		if (!audience) return true;
		switch (type) {	
			case 'topic':
				// Topics may be blacklisted for this audience, e.g. the kids side doesn't ever don't show topics.
				const isBlacklisted:boolean = this._isResutlItemTypeBlacklistedForAudience(audience, type)
				if (isBlacklisted) {
					return false;
				}
				
				const storyIsForAudience:boolean|undefined = Content.isStoryForAudience(audience, topic)
				if (storyIsForAudience!=undefined) {
					return storyIsForAudience;
				}		
				break

			case "material":
				// If a material explicitly mentions the audience, return what it says.
				const materialIsForAudience:boolean|undefined = Content.isMaterialForAudience(audience, material)
				if (materialIsForAudience!=undefined) {
					return materialIsForAudience;
				}

				// The material may be blacklisted for this audience, 
				// e.g. comics for learners (this can be overidden in the material or story)
				const isMaterialTypeBlacklisted:boolean = this._isResutlItemTypeBlacklistedForAudience(audience, type, material?.type)
				if (isMaterialTypeBlacklisted) {
					return false;
				}

				// If a parent story explicitly mentions the audience, return what it says.
				const parentStoryIsForAudience:boolean|undefined = Content.isStoryForAudience(audience, parentTopic)
				if (parentStoryIsForAudience!=undefined) {
					return parentStoryIsForAudience;
				}
				break

			case "group":
				// Currently only leaners can see groups
				if (audience===AudienceId.learners) {
					return true;
				}
				break
		}

		
		// We default to saying no if we haven't found anywhere explicitly saying yes or no
		return false;
	}


	_isResutlItemTypeBlacklistedForAudience(audience?:string, type?:string, materialType?:string) {
		switch(type) {
			 case "material": 
				return Content.isMaterialBlacklistedForAudience(audience, materialType)

			case "topic":
				if (audience) {
					const blacklist:string[]|undefined = Config.settings.search.audiences[audience]?.resultItemTypeBlacklist
					return blacklist?.includes(type);
				}
				break
		}
		return false;
	}

	_isResultItemForDevice({isTouchDevice, type, topic, material, parentTopic}:{isTouchDevice?:boolean, type:string, topic?:ContentStory, material?:ContentMaterial, parentTopic?:ContentStory}):boolean {
		if (!isTouchDevice) return true;
		if (type==='topic'    && topic?.hiddenOnTouchDevice) return false;
		if (type==='material' && (material?.hiddenOnTouchDevice || parentTopic?.hiddenOnTouchDevice)) return false;
		return true;
	}


	_normalise(str:string):string {
		return str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
	}
}