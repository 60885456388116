import './_styles.scss';
import React from 'react';

import Nav, {NavTheme} from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer';
import Lang, {LangId} from '../../tools/Lang';
import Tools from '../../tools/Tools';
import {Message} from '../../components/Message/Message';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

interface Props{
    langId?:LangId,
    navProps?:any,
}

export default class ErrorScreen extends React.Component<Props>{
    constructor(props:Props){
        super(props);

        log("ErrorScreen()")

        Lang.setTitle("title", "home.description", undefined, Tools.OPENGRAPH_TYPE_ARTICLE, undefined, false)
    }


    render(){
        return(

            <div id="error-screen">
                <Nav
                    theme={NavTheme.dark}
                    showLogo={true}
                />

                <div className="main-column">
                    <div className="main-column-content content">
                        <Message 
                            title={Lang.t("error.loadingPage")} 
                        />
                        
                    </div>
                </div>

                <Footer/>
            </div>
        )
    }


}