import './_styles.scss';

import React from 'react';
import { ReactSVG } from 'react-svg'

type Props = {
    className?:string
}
export default class Spinner extends React.Component<Props>{
    className:string;

    CLASS_NAME = "spinner-container";

    constructor(props:Props){
        super(props);
        
        if(this.props.className){
            this.className = this.props.className + " " + this.CLASS_NAME;
        }else{
            this.className = this.CLASS_NAME;
        }
    }

    render(){
        
        return(

            <div className={this.className}>
                {/* <img src='/images/ui/spinner.gif' width='100%' height='100%'/> */}
                <ReactSVG src="/images/ui/spinner.svg" />
            </div>
        )
    }
}