import {Component, ReactElement} from 'react';

import Lang, {LangId} from '../../tools/Lang';
import Tools from '../../tools/Tools';
import Router from '../../router/Router'
import Content, {LangString} from '../../data/Content';
import type {ContentMaterial} from '../../data/Content';
import {TopicTabId} from '../TopicTabs/TopicTabs';
import {MediaTypes, MediaType} from '../../data/Topic';
import Audience, {AudienceId} from '../../tools/Audience';
import Config from '../../config/Config';
// import Router from '../../router/Router';
import Link from '../Link/Link';


import './_styles_SearchResultsItem.scss';


// -------------------
// TYPE DEFINITIONS OF THE ITEM DATA OBJECTS
export interface SearchResultsItemData   {
    
    // All possible properties

	/** The lunr document id */
	ref:string,

    type:string,
	
	/** The lunr search score */
	weight:number,

    text?:LangString,
    topicId?:string,
    topicTitle?:LangString,
    itemId?:string,
    itemTitle?:LangString,
    composer?:LangString[],
    director?:LangString[],
    writer?:LangString[],
    fieldworker?:string,
    informant?:string,
    publication?:string,
    sectionId?:string,
    sectionTitle?:LangString,
    groupId?:string,
    groupTitle?:LangString,
	parentGroupId?:string,
    parentGroupTitle?:LangString,

	/** When this result was found, which audienceId were we filtering for? */
	audience?:AudienceId,
}

export interface SearchResultsItemTopicData extends SearchResultsItemData  {
    type:string,
    text?:LangString,
    topicId?:string,
    topicTitle?:LangString,
}

export interface SearchResultsItemAudioData extends SearchResultsItemData  {
    type:string,
    text?:LangString,
    topicId?:string,
    topicTitle?:LangString,
    itemId?:string,
    itemTitle?:LangString,
    composer?:LangString[],
}
export interface SearchResultsItemVideoData extends SearchResultsItemData  {
    type:string,
    text?:LangString,
    topicId?:string,
    topicTitle?:LangString,
    itemId?:string,
    itemTitle?:LangString,
    director?:LangString[];
}
export interface SearchResultsItemGameData extends SearchResultsItemData  {
    type:string,
    text?:LangString,
}
export interface SearchResultsItemComicData extends SearchResultsItemData  {
    type:string,
    text?:LangString,
}
export interface SearchResultsItemScriptData extends SearchResultsItemData  {
    type:string,
    text?:LangString,
    topicId?:string,
    topicTitle?:LangString,
    itemId?:string,
    itemTitle?:LangString,
    writer?:LangString[],
}

export interface SearchResultsItemArchiveSoundData extends SearchResultsItemData  {
    type:string,
    text?:LangString,fieldworker?:string,
    topicId?:string,
    topicTitle?:LangString,
    itemId?:string,
    itemTitle?:LangString,
    informant?:string,
}
export interface SearchResultsItemArchiveTextData extends SearchResultsItemData {   
    type:string,
    text?:LangString,
    topicId?:string,
    topicTitle?:LangString,
    itemId?:string,
    itemTitle?:LangString,
    publication:string,
}

export interface SearchResultsItemResearchData extends SearchResultsItemData  {
    type:string,
    text?:LangString,
    topicId?:string,
    topicTitle?:LangString,
}

export interface SearchResultsItemBibliographyData extends SearchResultsItemData  {
    type:string,
    text?:LangString,
    topicId?:string,
    topicTitle?:LangString,
}

export interface SearchResultsItemGroupData extends SearchResultsItemData  {
    sectionId?:string,
    sectionTitle?:LangString,
    groupId?:string,
    groupTitle?:LangString,
}

// -------------------







// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}


interface Props {
    index:number,
    data:SearchResultsItemData,
    onClick:Function,
}

interface State {
    imageLoaded:boolean,
    revealed:boolean,
}


export default class SearchResultItem extends Component<Props, State>{
    
    isUnmounted:boolean = false;

    // Item 'type' property can be any of these values
    TYPE_GROUP:string = "group";
    TYPE_TOPIC:string = "topic";
    TYPE_AUDIO:string = MediaType.audio;
    TYPE_VIDEO:string = MediaType.video;
    TYPE_GAME:string = MediaType.game;
    TYPE_COMIC:string = MediaType.comic;
    TYPE_SCRIPT:string = MediaType.script;
    TYPE_ARCHIVE_TEXT:string = MediaType.archiveText
    TYPE_ARCHIVE_SOUND:string = MediaType.archiveSound;
    TYPE_RESEARCH:string = TopicTabId.research;
    TYPE_BIBLIOGRAPHY:string = TopicTabId.bibliography;

    // Valid types. If the 'type' property is something else then nothing is rendered
    validTypes:string[] = [
        this.TYPE_ARCHIVE_SOUND,
        this.TYPE_ARCHIVE_TEXT,
        this.TYPE_AUDIO,
        this.TYPE_BIBLIOGRAPHY,
        this.TYPE_COMIC,
        this.TYPE_GAME,
        this.TYPE_GROUP,
        this.TYPE_RESEARCH,
        this.TYPE_SCRIPT,
        this.TYPE_TOPIC,
        this.TYPE_VIDEO,
    ]
    

    // Map the type property to specific tabIds
    typeToTabIdsMap:any = {
        [this.TYPE_ARCHIVE_TEXT]: TopicTabId.library,
        [this.TYPE_ARCHIVE_SOUND]:  TopicTabId.sounds,
        [this.TYPE_RESEARCH]: TopicTabId.research, 
        [this.TYPE_BIBLIOGRAPHY]: TopicTabId.bibliography, 
        
    }

    imagePath:string = Config.getImagePath();

    constructor (props:Props){
        super(props)
		// console.log(props)

		const hasNoThumb = this.getThumbUrl()===""
		// console.log(`HAS NO THUMB: ${hasNoThumb}`)
		
        this.state = {
            imageLoaded:false,
            revealed:hasNoThumb,
        }
    }

    componentWillUnmount(){
        this.isUnmounted = true;
    }
    


    // --------------------------------------------------------------
    // GETTERS

    get isTypeTopic():boolean{
        return this.props.data.type === this.TYPE_TOPIC;
    }


    get isTypeGroup():boolean{
        return this.props.data.type === this.TYPE_GROUP;
    }

    get isTypeMedia():boolean{
        return MediaTypes.includes(this.props.data.type as MediaType)
    }

    get isTypeTopicTab():boolean{
        return this.typeToTabIdsMap[this.props.data.type] !== undefined;
    }

    // --------------------------------------------------------------
    // METHODS
    getUrl():string{
		const result:SearchResultsItemData = this.props.data
        // console.log(`${result.type} ${result.itemId} ${result.itemTitle?.en || ""} audience ${result.audience}`)
        const type = result.type;
        const topicId = result.topicId;
        const itemId = result.itemId;
        const groupId = result.groupId;
        const sectionId = result.sectionId;
        // const audienceStr = Audience.id === AudienceId.learners ? "" : "/a/" + Audience.id
        const audienceStr = result.audience === AudienceId.learners ? "" : `/a/${result.audience}`
        
        // Navigate to topic overview
        if(this.isTypeTopic){
            return audienceStr + "/topic/" + topicId
        }

        // Navigate to topic tab (includes navigating to tabChild too)
        else if(this.isTypeTopicTab){
            if(itemId){
				// E.g.
				// http://localhost:3000/en/topic/black_dog/library/macphie_s_black_dog_631
				const material = Content.getMaterialByRawId(itemId)
				const itemPrettyId = Content.getNiceId(material?.id, material?._id)
				return Router.getMediaItemPath(itemPrettyId)
					// return audienceStr + "/topic/" + topicId + "/" + this.typeToTabIdsMap[type] + "/" + itemId;
            }else{
                return audienceStr + "/topic/" + topicId + "/" + this.typeToTabIdsMap[type];
            }
        }

        // Navigate to media item
        else if(this.isTypeMedia){
			const material = Content.getMaterialByRawId(itemId)
			// console.log(`RESULT MATERIAL:`, material)
			const itemPrettyId = Content.getNiceId(material?.id, material?._id)
			return Router.getMediaItemPath(itemPrettyId)
            // return audienceStr + `/topic/${topicId}/item/${itemPrettyId}_${itemId}`
        }

        // Navigate to group
        else if(this.isTypeGroup && sectionId && groupId){
            return "/group/" + sectionId + "/" + groupId;
        }
        
        return "";
    }
    
    reveal(){
        this.setState({
            revealed:true
        })
    }
    // --------------------------------------------------------------
    // EVENTS

    handleTitleClick = () => {
        if(this.props.onClick){
            this.props.onClick(this.props.data, this.getUrl());
        }
    }

    handleImageLoad = (evt:any) => {
        // console.log("SearchResultsItem.handleImageLoad()")
        // console.log(" - this.props.id = " + this.props.data.topicId);
        // console.log(" - evt = ", evt)
        if(this.isUnmounted) return;
        this.setState({
            imageLoaded:true,
        })
        this.reveal();
    }


    handleImageError = (evt:any) => {
        // console.log("SearchResultsItem.handleImageError()")
        // console.log(" - this.props.id = " + this.props.data.topicId);
        // console.log(" - evt = ", evt)
        this.reveal();
    }
    

    // --------------------------------------------------------------
    // RENDER

    renderSurtitle():any{
        log("SearchResultsItem.renderSurtitle()")
        
        const isSurtitleRequired:boolean = 
            this.props.data.type !== this.TYPE_TOPIC
        if(!isSurtitleRequired) return null;

        let els:any[] = [];

        // ------------
        // Topic title
        if(this.props.data.topicTitle){
            els.push(
                <span key={"surtitle_topic_title_" + this.props.index}>{Lang.tc(this.props.data.topicTitle)}</span>
            )
        }
        
        // ------------
        // Tab title
        let type:string = this.props.data.type;
        let tabId:string = this.typeToTabIdsMap[type] ? this.typeToTabIdsMap[type] : "";
        if(tabId){

            
            // Separator
            if(els.length > 0){
                els.push((<span  key={"surtitle_tab_title_separator_" + this.props.index} className="separator">|</span>))} 
            
            // Tab title
            els.push((
                <span key={"surtitle_tab_title_" + this.props.index}>{Lang.t("topic." + tabId)}</span>
            ))
        }

        // ------------
        // Media type title
        else if(MediaTypes.includes(type as MediaType)){

            // Separator
            if(els.length > 0){els.push((<span key={"surtitle_media_title_separator_" + this.props.index} className="separator">|</span>))} 
            
            // Tab title
            els.push((
                <span key={"surtitle_media_title_" + this.props.index}>{Lang.t("media." + type)}</span>
            ))
        }

        // ------------
        // Media type title
        else if(type === this.TYPE_GROUP) {
			
			if (this.props.data.parentGroupTitle) {
				els.push((
					<span key={"surtitle_group_parent_title_" + this.props.index}>{Lang.tc(this.props.data.parentGroupTitle)}</span>
				))
				els.push((<span key={"surtitle_media_title_separator_" + this.props.index} className="separator">|</span>))
			}
			els.push(
				<span key={"surtitle_section_title_" + this.props.index}>{Lang.tc(this.props.data.sectionTitle)}</span>
			)
        }

       

        // ------------
        // Render the surtitle element
        if(els.length > 0){
            return (
                <p className="surtitle smallcaps">{els}</p>
            )
        }

        return null;
        
    }


    renderTitle():any{
        let title:LangString|undefined;
        
        // Media item / archive text or archive sound title
        if(this.props.data.itemTitle) title = this.props.data.itemTitle;

        // Group title
        else if(this.props.data.groupTitle) title = this.props.data.groupTitle;
        
        // Topic title
        else if(this.props.data.topicTitle)  title = this.props.data.topicTitle

        // Append tab name to title sometimes
        const type:string = this.props.data.type;
        if(title && (type === this.TYPE_BIBLIOGRAPHY || type === this.TYPE_RESEARCH)){
            const tabId = this.typeToTabIdsMap[type];
            title = {...title};
            title.en += " – " + Lang.t("topic." + tabId, LangId.en);
            title.ga += " – " + Lang.t("topic." + tabId, LangId.ga);
        }

        if(!title) return null;

		return (
            <div
                className="title-but" 
                // onClick={this.handleTitleClick}
            >
                <h3 className="title">{Lang.tc(title)}</h3>
            </div>
        )
    }


    renderSubtitle():any{
        // log("SearchResultsItem.renderSubtitle()")
        
        let str = "";
        switch(this.props.data.type){
            case this.TYPE_TOPIC:
                if(this.props.data.topicId){
                    str = Content.getStoryLocation(this.props.data.topicId)
                    if (!str) return null
                    return (
                        <p className="subtitle">
                            {/* <span className="location-from">{Lang.t("locationFrom")}</span> */}
                            <span className="location-place">{str}</span>
                        </p>
                        
                    );
                }
                break
            case this.TYPE_AUDIO:
                if(this.props.data.composer){
                   
                    for(let langString of this.props.data.composer){
                        if(str) str += ", ";
                        str += Lang.tc(langString)
                    }
                    return (
                        <p className="subtitle">{Lang.t("credits.composer") + ": " + str}</p>
                    )
                }
                break
            case this.TYPE_VIDEO:
                if(this.props.data.director){
                    let str = "";
                    for(let langString of this.props.data.director){
                        if(str) str += ", ";
                        str += Lang.tc(langString)
                    }
                    return (
                        <p className="subtitle">{Lang.t("credits.director") + ": " + str}</p>
                    )
                }
                break
            case this.TYPE_SCRIPT:
                if(this.props.data.writer){
                    let str = "";
                    for(let langString of this.props.data.writer){
                        if(str) str += ", ";
                        str += Lang.tc(langString)
                    }
                    return (
                        <p className="subtitle">{Lang.t("credits.writer") + ": " + str}</p>
                    )
                }
                break
            case this.TYPE_ARCHIVE_SOUND:
                let els:any[] = [];
                if(this.props.data.informant){
                    els.push((
                        <span key={"subtitle_informant_" + this.props.index}>{Lang.t("topic.sounds.informant") + ": " + this.props.data.informant}</span>
                    ))
                }

                if(this.props.data.fieldworker){
                    // Separator
                    if(els.length > 0){els.push((<span key={"subtitle_fieldworker_separator" + this.props.index}>, </span>))} 
                    
                    els.push((
                        <span key={"subtitle_fieldworker_" + this.props.index}>{Lang.t("topic.sounds.fieldworker") + ": " + this.props.data.fieldworker}</span>
                    ))
                }
                if(els.length > 0){
                    return (
                        <p className="subtitle">{els}</p>
                    )
                }
                break;
                
            case this.TYPE_ARCHIVE_TEXT:
                if(this.props.data.publication){
                    return (
                        <p className="subtitle">{this.props.data.publication}</p>
                    )
                }
                break
                
        }

        return null;
    }

	getThumbUrl():string {
        const audienceFolder:string = Audience.isAudienceAnyKids ? AudienceId.kids : AudienceId.learners;
		let src = ""
        switch(this.getThumbType()){
            case "topic":
                src= this.imagePath + "thumbs/" + audienceFolder + "/" + this.props.data.topicId + ".jpg"
				break
			case "media":
				src= this.imagePath + "thumbs/" + audienceFolder + "/" + this.props.data.topicId + ".jpg"
				break
				
			case "group":
				// https://cms.bocan.tv/images/content/group_banners/places.highland_scotland.western_isles.png"
				src= `${this.imagePath}group_banners/${this.props.data.groupId}.png`
				break
					
		}
		// console.log(`getThumbUtrl(): ${src}`)
		return src;
	}

	getThumbType():string {
		let thumbType = "none"

		const type = this.props.data.type
		if (MediaTypes.includes(type as MediaType)) {
			thumbType = "media"
		}
		if (type===this.TYPE_TOPIC) {
			thumbType = "topic"
		}
		if (type===this.TYPE_GROUP) {
			thumbType = "group";
		}
		return thumbType;
	}

    renderThumb():any{

        let className:string = "thumb-block" 
        if (this.state.imageLoaded ) className += " loaded"

		const thumbType = this.getThumbType()
        switch(thumbType){
            case "topic":
            case "media":
            case "group":
                return (
                    <div
                        className={className}
                        onClick={this.handleTitleClick}
                    >
						{thumbType==="media" && 
		                    <div className={"media-item-icon media-item-icon--" + Tools.string.normaliseForCss(this.props.data.type)}/>                    
						}
                            <img src={this.getThumbUrl()} onLoad={this.handleImageLoad} onError={this.handleImageError}  alt=""/>
                        
                    </div>
                );
        }

        return null;
    }
    

	renderTags(tags:string[]|undefined, glue=", "):ReactElement|undefined {
		if (tags==undefined) return;

		tags = tags.filter(tag => Lang.hasTerm(`oral.controls.filter.type.${tag}`) )
		if (tags.length===0) return;

		const numTags = tags.length

		return <p className='smallcaps faint tags'> {
				tags.map((tag:string, i:number) => <span 
							className='tag'
							key={`tag_${tag}_${i}`}
						>
							{Lang.t(`oral.controls.filter.type.${tag}`)}
							{ 
								(i < numTags - 1) && (glue || "")
							}
						</span>
				)
			}
		</p>;
	}



    render(){
        
        // No data
        if(!this.props.data) return null;

        // Not a valid type
        if(!this.validTypes.includes(this.props.data.type)) return null;

        let className = "search-results-item";
        if(this.state.revealed){
            className += " revealed";
        }
		const material:ContentMaterial|undefined = this.isTypeMedia ? Content.getMaterialByRawId(this.props.data.itemId) : undefined

        return(
            
            <Link
                to={this.getUrl()} 
                key={"search_results_item_" + this.props.index} 
                className={className}
                onClick={this.handleTitleClick}
            >
                <div className="search-results-item-inner">
                    
                    {this.renderThumb()}

                    <div className="text-column">

                        {this.renderSurtitle()}
                        
                        {this.renderTitle()}

                        {this.renderSubtitle()}
                        

                        {this.props.data.text && 
                            <p className="text"
                                dangerouslySetInnerHTML={
                                    {__html: Lang.tc(this.props.data.text)}
                                }   
                            />
                        }

						{this.renderTags(material?.tags)}
                    </div>
                </div>
            </Link>
        )
    }
}