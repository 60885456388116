import React from 'react';
import Snack from './Snack';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

export interface SnackProps {
    message:string,
    type?:SnackType,
    secs?:number,
    butLabel?:string,
    onButPress?:Function,
}

export enum SnackType {
    error = "error",
    success = "success",
    warning = "warning",
}

type Props = {}

type State = {
    data:SnackProps|null
    
}

let instance:Snacks;

export default class Snacks extends React.Component<Props, State>{

    constructor(props:Props){
        super(props);

        this.state = {
            data:null
            
        }

        instance = this;
    }

    static add = (data:SnackProps) => {
        instance.add(data);
        
    }

    add = (data:SnackProps) => {
        log("Snacks.add()");
        this.setState({
            data:data
        })
        
    }

    handleClose = () => {
        this.setState({
            data:null,
        })
    }

    
    render(){

        // const message = this.state.data.message;
        // log("Snacks.render() message = " + message)
        
        

        if(this.state.data !== null){
            return(

                <Snack
                    {...this.state.data}
                    onClose={this.handleClose}
                />
            );
        }

        return null;
        
        
        
    }
}