import './_styles.scss';
import React from 'react';
import Lang from '../../tools/Lang';
import {TopicData, MediaItemData} from '../../data/Topic';
import {AudienceId} from '../../tools/Audience';
// import { MediaType } from '../../data/Topic';
import Config from '../../config/Config';
import Link from '../Link/Link';
import Router from '../../router/Router';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

interface Props {
    itemData:MediaItemData,
    topicData:TopicData,
    onClick:Function,
    isActive?:boolean
}
interface State {
    // imageError:boolean,
    loadingState:LoadingState,
}

enum LoadingState {
    loading="loading",
    loaded="loaded",
    error="error"
}
export default class MediaItem extends React.Component<Props, State>{

    isUnmounted:boolean = false;

    imagePath:string = Config.getImagePath();
    
    constructor(props:Props){
        super(props);

        this.state = {
            // imageError:false,
            loadingState:LoadingState.loading
        }

        

        log("MediaItem()")
    }

    componentWillUnmount(){
        this.isUnmounted = true;
    }

    getUrl(){
        // return "/test"
        return Router.getMediaItemPath(this.props.itemData.id);
        // const langFrag = Lang.langId === LangId.en ? LangId.en + "/": "";
        // const audienceFrag = Audience.isAudienceAnyKids ? "a/" + Audience.id + "/": "";
        // let sectionFrag = Audience.isAudienceToddlers ?  "/section/" + this.props.sectionId : "";
        
        // return "/" + langFrag + audienceFrag + "topic/" + this.props.data.id + sectionFrag;
    }

    handleClick = (event:any) => {
        this.props.onClick(this.props.itemData, this.props.topicData.topicId)
    }

    handleImageError = (evt:any) =>{
        if(this.isUnmounted) return;
        this.setState({
            loadingState: LoadingState.error,    
        })

    }

    handleImageLoad = (even:any) => {
        if(this.isUnmounted) return;
        this.setState({
            loadingState: LoadingState.loaded,    
        })
    }
   
    render(){
        log("MediaItem.render()")
        const type = this.props.itemData.type;
        
        // Image path
        const audienceFolder = AudienceId.kids;
        const imageId:string = this.state.loadingState === LoadingState.error ? "default" : this.props.topicData.topicId
        let imagePath = this.imagePath + "thumbs/" + audienceFolder + "/" + imageId + ".jpg";
          

        

        let containerClass = "media-item";
        if(this.props.isActive) containerClass += " active";
        if(this.state.loadingState === LoadingState.loading) containerClass += " loading";
        return (
            <Link to={this.getUrl()} className={containerClass} onClick={this.handleClick}>
                <div className="media-item-content">
                    <div className={"media-item-icon media-item-icon--" + type}/>
                    <div className='media-item-image-container'>
                        <img 
                            src={imagePath} 
                            onLoad={this.handleImageLoad} 
                            onError={this.handleImageError} 
                            className="media-item-image" 
                            alt=""
                        />
                    </div>
                        
                    <div className="media-item-text">
                        <h3 className="grid-item">{Lang.tc(this.props.itemData.title)}</h3>
                        <p className="description">{Lang.t("media." + type)}</p>
                    </div>
                </div>
                <div className='border'></div>
            </Link>
        )
    }
}