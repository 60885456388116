import {Theme, makeStyles } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
// import Typography from '@material-ui/core/Typography';

const useStylesBootstrap = makeStyles((theme: Theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
      fontSize: 14,
      fontWeight: 'normal'
    },
  }));

export function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
  
    return <Tooltip arrow classes={classes} {...props} />;
}
