import './_styles.scss';
import React, {Component} from 'react';

// import App from '../../App';
import Intersector,{RatioDirection} from '../Intersector/Intersector';
import ShortcutsItem from '../ShortcutsItem/ShortcutsItem';
import Audience from '../../tools/Audience';
// import Config from '../../config/Config';
import Content from '../../data/Content';
import type {ContentShortcuts, ContentShortcutsItem} from '../../data/Content';
import {ContentSectionRowAspect} from '../../data/Content';
// import Tools from '../../tools/Tools';
import { BootstrapTooltip } from '../BootstrapTooltip/BootstrapTooltip';
import Lang from '../../tools/Lang';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

interface Props{
    onItemClick?:Function,
    scrollY:number,
}
interface State{
    isStuck:boolean,
    isTransitionInstant:boolean
}


export default class Shortcuts extends Component<Props, State>{
    
    
    scroller:any = null;

    
    SHOW_TOP_BUT:boolean = false;

    
    isUnmounted:boolean = false;
    constructor(props:Props){
        super(props);
        this.state = {
            isStuck:false,
            isTransitionInstant:false,
        }
    }

    componentDidMount(): void {
        // console.log("Shortcuts.componentDidMount()")
        // console.log(" - scrollY = " + this.props.scrollY)
        
        // If offscreen then set to 'stuck' 
        const el = document.getElementById("shortcuts-sentinel--top")
        if(el){
            const rect = el.getBoundingClientRect();
            const isOffscreen = rect.top < this.props.scrollY;
            if(isOffscreen){
                this.setStuck(true, true)
            }
        }
        
        // Scroll to start of shortcuts element (in the x dimension)
        this.scroller = document.getElementById("shortcuts");
        if(!this.scroller) return;
        this.scroller.scrollTop = 0;
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        // console.log("Shortcuts.componentDidUpdate()")
        // console.log(" - scrollY = " + this.props.scrollY)
    }
 
    componentWillUnmount(){
        // console.log("Shortcuts.componentWillUnmount()")
        this.isUnmounted = true;
    }

  
    
    /* -------------------------------------------------
    * GETTERS & SETTERS
    */
    


    /* -------------------------------------------------
    * METHODS
    */
    
    
    /**
     * Set the state to isStuck or not
     * @param isStuck 
     * @param isTransitionInstant - Turn transitions off (sets the css instantly, rather than using usual transition duration)
     * @returns 
     */
    async setStuck(isStuck:boolean, isTransitionInstant:boolean = false){
        // console.log("Shortcuts.setToStuck()")
        if(this.isUnmounted) return;
        
        this.setState({isStuck, isTransitionInstant},()=>{
            
            // Turn transitions back on once rendered
            if(isTransitionInstant) this.setState({isTransitionInstant: false})
            
        });

        
    }

     /* -------------------------------------------------
    * HANDLERS
    */
    
   

    handleItemClick = (itemId:string, event:any) => {
        log(" ")
        log("-------")
        log("Shortcuts.handleItemClick() itemId = " + itemId)
        if(event) event.preventDefault();    

       
        // log(" - sectionId = " + this.props.data.sectionId)
        // log(" - data.path = " + this.props.data.path)
        // log(" - data.id = " + this.props.data.id)
        
        if(this.props.onItemClick) this.props.onItemClick();
    
        

        
    }

    handleIntersect = (element:Element, ratio:number, ratioDirection:RatioDirection, rect:any) => {
        // console.log("Shortcuts.handleIntersect() " + new Date().getTime())
        if(this.isUnmounted || !rect) return;
        // console.log(" ")
        // console.log("-----")
        // console.log(" ratio = " + ratio)
        // console.log(" ratioDirection = " + ratioDirection)
        // console.log(" rect.y = " + rect.y)
        const isStuck = (ratio < 1 && rect.y < 0);
        // this.setState({isStuck})
        this.setStuck(isStuck)
    }

    handleTopButClick = () => {
        // console.log("Shortcuts.handleTopButClick()")
        const el = document.querySelector('html');
        if(!el) return;
        const langTop = document.getElementById("lang")?.getBoundingClientRect().top || 0;
        const navHeight = document.getElementById("nav")?.getBoundingClientRect().height || 0;
        const scrollTop = el?.scrollTop;
        const offsetY = 10 // offset by this amount to ensure perfect alignment with nav bar
        let targetY = scrollTop + langTop - navHeight + offsetY;

        let promoHeight = document.getElementById("home-promo")?.getBoundingClientRect().height || 0;
        if(promoHeight){
            targetY += promoHeight;
        }
        
        // console.log(" - langBottom = " + langBottom)
        // console.log(" - scrollTop = " + scrollTop)
        // console.log(" - targetY = " + targetY)
        el.scrollTo({top:targetY, behavior:"smooth"});
    }
    

    /* -------------------------------------------------
    * RENDER
    */

    
    _data:ContentShortcuts|null = null;
    get data():ContentShortcuts|null{
        if(this._data) return this._data;
        return Content.getShortcuts();
    }


    renderItems():any{
        // log("SectionRow.renderItems()")

        if(!this.data) return null;
        return this.data.items.map((item:ContentShortcutsItem, index:number) => 
            <ShortcutsItem
                key={"shortcuts_row_item__" + item.id + "__" + index}
                data={item}
                onClick={this.handleItemClick}
                aspect={ContentSectionRowAspect.portrait}
                index={index}
            />
        );
    }


   
    render(){
        let shortcutsClass = "shortcuts";
        if(this.state.isStuck) shortcutsClass += " stuck";
        
        // Is the theme light or dark?
        const isThemeDark = (Audience.isAudienceLearners);
        
        // Container class
        let shortcutsContainerClass = isThemeDark ? "dark" : "";
        if(this.state.isStuck) shortcutsContainerClass += " stuck";
        if(this.state.isTransitionInstant) shortcutsContainerClass += " instant";
        

        // Icon-top path
        let iconTopPath = isThemeDark ? "/images/ui/icon-top-dark.png" : "/images/ui/icon-top.png";
        
            

        return(
            <div id="shortcuts-container" className={shortcutsContainerClass}>
                <Intersector
                    onIntersect={this.handleIntersect}
                >
                    <div id="shortcuts-sentinel--top"/>
                </Intersector>
                
                <div id="shortcuts" className={shortcutsClass}> 
                    
                    <div className="shortcuts-items-container">
                        
                        {/* TOP BUTTON */}
                        {this.SHOW_TOP_BUT &&
                        <BootstrapTooltip
                            title={Lang.t("shortcuts.topBut")}
                        >
                            <button 
                                onClick={this.handleTopButClick} 
                                className="top-but"  aria-label="Back to top"
                                disabled={!this.state.isStuck}
                            >
                                
                                    <div className="top-but-icon">
                                        <img src={iconTopPath} alt=""/>
                                    </div>
                                
                            </button>
                        </BootstrapTooltip>
                        }

                        {/* ITEMS */}
                        <div className="shortcuts-items">                            
                            {this.renderItems()}

                        </div>
                    </div>
                   
                </div>
            </div>
            
        )
    }
}