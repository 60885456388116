import _ from 'lodash'
import {useState} from 'react';
import {LangString} from '../data/Content';
// import Lang, {LangId} from '../tools/Lang'


enum EnvironmentId {
    dev = "dev",
    prod = "prod"
}

/**
 * Use async state in React hooks.
 * Usage:   const [myStateProperty, setMyStateProperty] = useAsyncState(myInitialValue)
 *          await setMyStateProperty(myNewValue)
 * @see https://stackoverflow.com/questions/53898810/executing-async-code-on-update-of-state-with-react-hooks 
 * @param initialState 
 * @returns The standard two functions for reading and writing state
 */
export const useAsyncState = (initialState:any) => {
    const [state, setState] = useState(initialState);
  
    const asyncSetState = (value:any) => {
      return new Promise(resolve => {
        setState(value);
        setState((current:any) => {
          resolve(current);
          return current;
        });
      });
    };
  
    return [state, asyncSetState];
};

export default class Tools{

    
    static OPENGRAPH_TYPE_ARTICLE:string = "article";
    static OPENGRAPH_TYPE_WEBSITE:string = "website";

    static get isDev():boolean{return this.environment === EnvironmentId.dev}
    static get isProd():boolean{return this.environment === EnvironmentId.prod}
    
    /**
     * We consider ONLY a local server on port 3000 to be dev.
     */
    static get environment():EnvironmentId{
        const isDev = this.isLocal && (window.location.port === "3000" || window.location.port === "5000");
        return isDev ? EnvironmentId.dev : EnvironmentId.prod;
    }

    /**
     * Is the site deployed on remote server, e.g. production site at https://bocan.tv
     */
    static get isDeployedProduction():boolean{
        return  (this.isProd && !this.isLocal)
    }
    /**
     * Either production or dev server running on localhost or LAN
     */
    static get isLocal():boolean{
        return (
            window.location.href.indexOf("localhost") >= 0 || 
            window.location.href.indexOf("192.168") >= 0
        );
    }

    static get isSafari() {
        let isSafari = false;
        try{
            isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
        }catch(err){}
        return isSafari;
      
      }
    /**
     * Is the user accessing the site on a LAN? 
     */
    static get isLAN():boolean{
        return window.location.href.indexOf("192.168") >= 0
    }

	static misc = {
		asyncSetState(el:React.Component, state:object) {
			return new Promise((resolve)=> {
				const callback = resolve as () => void
				el.setState(state, callback)	
			})
		},

		isValidEnum(value:string, enumType:Object):boolean {
			return Object.keys(enumType).includes(value);
		}
	}

    static object = {

        /**
         * Get the number of keys in an Object
         * @param obj
         * @returns Total number of keys
         */
        numKeys(obj:Object) {
            let num:number = 0;
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) num++;
            }
            return num;
         },

		 createMap: (oldMap:{[key:string]: any}, keyname:string="id") => {
			const map:{[key:string]:any} = {}
			if (!oldMap) return map;
			
			for (let key in oldMap) {
				const item = oldMap[key]
				if (item) {
					map[item[keyname]] = item
				}
			}
			return map;
		}



    }
    
     static array = {

        /**
         * Sort array by key
         * @param {Array} arr
         * @param {string} key 
         * @param {boolean} [desc]
         */
         sortByKey(arr:any[], key:string, desc:boolean = false):any[]{
            arr.sort(function(a, b){
                if(a[key] < b[key]){
                    return desc ? 1 : -1;
                    // return -1;
                }else if(a[key] > b[key]){
                    return desc ? -1 : 1;
                    // return 1;
                }
                return 0;
            });
            return arr;
        },

        /**
         * Sort array alphabetically, in place
         * @param arr 
         */
        sortAlphabetically(arr:string[]){
            arr.sort((a:string,b:string) => {
                if(a>b) return 1
                if(a<b) return -1
                return 0;
            });
            // return arr;
        },

        /**
         * Convert an array into an object
         * @param arr 
         * @param key 
         * @returns 
         */
        toObject(arr:any[], key:string = "id"):any{
            return arr.reduce((acc, cur)=>{return {...acc, [cur[key]]: cur}}, {})
        },

		/**
		 * Turn an array into a map, e.g.
		 * 
		 * 		toMap(["horse", "grass"])
		 * 		>>> 
		 * 		{horse: true, grass: true}
		 * Or
		 * 		toMap([{id: "horse", description: "friendly"}, {id: "grass", description: "green"}], "id")
		 * 		>>>
		 * 		{
		 * 			horse: {id: "horse", description: "friendly"},
		 * 			grass: {id: "grass", description: "green"},
		 *		}
		 * Or
	 	 * 		toMap([{id: "horse", description: "friendly"}, {id: "grass", description: "green"}], "id", "description")
		 * 		>>>
		 * 		{
		 * 			horse: "friendly",
		 * 			grass: "green",
		 *		}
		 */
		toMap(arr:any[], keyField?:string, valueField?:string):Record<string, any> {
			let map:Record<string, any> = {}
			if (!Array.isArray(arr)) return map;

			arr.forEach((el:any) => {
				let value:any = true
				let key:any = el
				if (keyField!=null) {
					key = el[keyField]
					if (valueField!=null) {
						value = el[valueField]
					}
				}
				if (key!=null && typeof key==='string') {
					map[key] = value
				}
			})
			return map;
		},

		/**
		 * Does an array have all of these values?
		 * 
		 * @see https://lodash.com/docs/#difference
		 * 
		 * Note: The lodash docs do not explain the `difference` function at all! Here's my explanation...
		 * @see https://codesandbox.io/s/lodash-playground-forked-z7w6wc?file=/src/index.js
		 */
		hasAllValues(arr:any[], values:any[]):boolean {
			const valuesNotInArray = _.difference(values, arr)
			return valuesNotInArray.length === 0;
		},


		/**
		 	We might want to move a bunch of elements to a new position in an array, e.g.
			if we've selected a few items from a list and try to drag them to a particular index.
			For example...
				const arr = [0,1,2,3,4,5,6,7,8,9,10]
				moveElementsToPosition(arr, [1, 2, 7, 10, 0, 8], 5)
				// `arr` will change from this to this...
				// [0,1,2,3,4,5,6,7,8,9,10]
				// [3,4,1,2,7,10,0,8,5,6,9]

			Note that the elements param is a list of actual elements, not indexes.
			I'm not sure what would happen if you have duplicate elements, it's untested.
		*/
		moveElementsToPosition: (arr:any[], elements:any[], position:number) => {
			if (!Array.isArray(arr) || !Array.isArray(elements)) return;
			if (position < 0) position = 0
			if (position > arr.length) position = arr.length

			// console.log(`About to moveElementsToPosition with array:`); arr.forEach(item=>console.log(item))
			let numMoved = 0
			elements.forEach(element => {
        		// console.log(`element:`, element); console.log(`arr bef:`);  arr.forEach(item=>console.log(item))
				let i = arr.indexOf(element)
				if (i===-1) return;

				const movedFromBeforePosition = i < position

				// Delete the element
				arr.splice(i, 1)

				// Has the position shifted left because of the deletion?
				if (movedFromBeforePosition) {
					position--
				}

				// Insert the element at the correct position
				arr.splice(position + numMoved, 0, element)

				numMoved++
				// console.log(`arr aft:`);  arr.forEach(item=>console.log(item))
			})

		},
    }

    static fillTags(template:string, values:any, openChar:string="{", closeChar:string="}") {
		for (let key in values) {
			let value = values[key]
			// Replace "{key}" with "value"
			var reg = new RegExp(`${openChar}[\\s]*${key}[\\s]*${closeChar}`, "gi")
			template = template.replace(reg, value)
		}
		return template;
	}

	/**
	 * Populate any tags in a template string with the one value.
	 * 
	 * @param {string} template 
	 * @param {any} value 
	 */
	static fillTagsWithValue(template:string, value:any) {
		template = template.replace(/{\s*(.*?)\s*}/gi, value)
		return template;
	}


    /**
     * Truncate text string. This method leaves words intact.
     * Inspired by https://stackoverflow.com/questions/4700226/i-want-to-truncate-a-text-or-line-with-ellipsis-using-javascript
     * @param str 
     * @param limit 
     * @returns
     */
    static trunc (str:string, limit:number):string {
        const symbol:string = '…';
        if (str.length > limit){
            for (let i = limit; i > 0; i--){
                if(str.charAt(i) === ' ' && (str.charAt(i-1) !== ','||str.charAt(i-1) !== '.'||str.charAt(i-1) !== ';')) {
                    return str.substring(0, i) + symbol;
                }
            }
             return str.substring(0, limit) + symbol;
        }
        
        return str;
    };

    static truncLangString = (langStr?:LangString, limit?:number):LangString => {
        if(!langStr) return {en:"", ga:""}
        if(limit === undefined) limit = 120;
        return {
            en: Tools.trunc(langStr.en, limit),
            ga: Tools.trunc(langStr.ga, limit)
        }
    }


    /**
     * Does a basic detection to find out if user is on touch device
     * @returns {boolean}
     */
     static get isTouchDevice():boolean{
        
        // METHOD 1
        // https://code-examples.net/en/q/3ca6ab
        // @ts-ignore
        // return true === ("ontouchstart" in window || (window.DocumentTouch && document instanceof DocumentTouch));

        // METHOD 2
        // https://stackoverflow.com/questions/15221680/javascript-detect-touch-devices
        // @ts-ignore
        // return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) ||(navigator.msMaxTouchPoints > 0));

        // METHOD 3
        // Uses media queries. window.matchMedia is well supported at 97.64% (https://caniuse.com/?search=matchmedia)
        // https://css-tricks.com/touch-devices-not-judged-size/
        const isPointerCoarse:boolean = window.matchMedia("(pointer: coarse)").matches;
        const isHover:boolean = window.matchMedia("(hover: hover)").matches;
        const isTouchDevice:boolean = isPointerCoarse && !isHover;
        // console.log(" - isPointerCoarse = " + isPointerCoarse)
        // console.log(" - isHover = " + isHover)
        // console.log(" - isToucDevice = " + isTouchDevice)
        
        return isTouchDevice;
    }

    /**
     * Is device capable of hover interaction, e.g. mouse.
     * This can be used to distinguish between mobile and desktop devices.
     */
    static get isHoverDevice():boolean{
        // Uses media queries. window.matchMedia is well supported at 97.64% (https://caniuse.com/?search=matchmedia)
        // https://css-tricks.com/touch-devices-not-judged-size/
        const isHover:boolean = window.matchMedia("(hover: hover)").matches;
        return isHover;
    }


	static time = {
		tick: ():Promise<void> => {
			const p = new Promise<void>(resolve => setTimeout(resolve, 1))
			return p;
		},

		/**
		 * 
		 * @param func 			A function to ascertain if the polling should complete successfully. 
		 * 						Run every interval.
		 * @param intervalSecs 
		 * @param timeoutSecs 
		 * @returns {boolean} 	Success? Will be `false` if it timed out.
		 */
		poll: (func:() => boolean, intervalSecs:number=1, timeoutSecs:number=-1):Promise<boolean> => {
			return new Promise((resolve)=> {
				let timeout:NodeJS.Timeout|undefined = undefined;

				const finish = (success=true) => {
					clearInterval(timeout)
					clearInterval(interval)
					resolve(success)
				}

				const test = () => {
					let isComplete = func()
					if (isComplete) {
						finish()
					}
				}
				
				if (timeoutSecs >= 0) {
					timeout = setTimeout(()=> {
						finish(false)
					}, timeoutSecs*1000)
				}

				const interval = setInterval(test, intervalSecs*1000)
				
				// Run the test immediately
				test()
			});
		},
	}


	static string = {

		normaliseForCss(text:string):string {
			const _normalisePattern = /[^a-z0-9]/gi
			return text.toLowerCase().replace(_normalisePattern, '-');
		},

		isEmpty(text?:string):boolean {
			if (text==null)				return true;
			if (typeof text!=="string") return true;
			if (text==="") 				return true;
			if (text.trim()==="") 		return true;
			return false;
		},

		isPopulated(text?:string):boolean {
			if (text==null)				return false;
			if (typeof text!=="string") return false;
			if (text==="") 				return false;
			if (text.trim()==="") 		return false;
			return true;
		},

		removeTrailingSlash(str:string=""):string {
			if (typeof str !== "string") return str;
			str = str.replace(/\/+$/, "")
			return str;
		},
	}


    /**
     * Split a string into an array of strings based on line-breaks. Used for splitting a long screed into paragraphs
     * @param str 
     * @param trimWhitespace 
     * @param removeEmpty 
     * @returns Array of strings
     */
    static stringToArray(str:string, trimWhitespace:boolean = true, removeEmpty:boolean = true):string[]{
        if(!str) return [];

        // Split string by line breaks
        let arr:string[] = str.split(/\r?\n/g);
        
        for(let i = arr.length-1; i >= 0; i--){
            str = arr[i];

            // Trim
            if(trimWhitespace) arr[i] = arr[i].trim();
            
            // Remove if empty
            if(removeEmpty && !arr[i]) arr.splice(i, 1)
        }
        if(!arr) arr = [];
        return arr;
    }

    /**
     * Trims the junk off of the end of a string, e.g. "," "." ";"
     * https://stackoverflow.com/questions/661305/how-can-i-trim-the-leading-and-trailing-comma-in-javascript
     * @param str 
     * @returns string
     */
    static stringTrimTrailingJunk(str:string):string{
        // return str.replace(/(^,)|(,$)|(.$)/g, "");
        str = str.replace(/(^[,\s]+)|([,\s]+$)/g, '');
        str = str.replace(/(^[.\s]+)|([.\s]+$)/g, '');
        str = str.replace(/(^[;\s]+)|([;\s]+$)/g, '');
        return str;
    }

    static stringCapitalizeFirstLetter(str:string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }

    static later(secs:number):Promise<void> {
		return new Promise((resolve:Function) => setTimeout(resolve, secs*1000));
	}

    static arrayRandomElement(arr:Array<any>):any {
		if (!arr || arr.length === 0) return undefined;
		let i = Math.floor(Math.random() * arr.length)
		let el = arr[i]
		return el;
	}

    static stringClean(str:string):string{
        str = str.replace(/<\s*\/?div\s*>/gi, "");
        str = str.replace(/<\s*br\s*\/?\s*>/gi, "\n");
        str = str.replace(/&amp;/gi, "&");
        str = str.replace(/&nbsp;/gi, " ");
        str = str.replace(/&lt;/gi, "<");
        str = str.replace(/&gt;/gi, ">");
        
        // console.log("cleaned string = ", str)
        return str;
    }

    static langStringClean(obj?:LangString):LangString|undefined{
        if(!obj) return undefined;
        if(obj.en) obj.en = Tools.stringClean(obj.en);
        if(obj.ga) obj.ga = Tools.stringClean(obj.ga);
        return obj;
    }

    static addClassToHTML(str:string){
        const list = document.getElementsByTagName('html')[0].classList;
        // log("list = ", list);
        list.add(str);
    }

    static removeClassFromHTML(str:string){
        const list = document.getElementsByTagName('html')[0].classList;
        // log("list = ", list);
        list.remove(str);
    }

    /**
     * Replace diacritics with latin version of string.
     * @param str 
     * @param removeInitialThe
     * @returns {string}
     */
    static stringNormalize(str:string, removeInitialThe:boolean = true):string{

        // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/
        str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        // Discard the word "The " if it appears at the start
        if(removeInitialThe){
            str = this.stringRemoveInitialThe(str);
        }

        // This removes spaces as well as diacritics
        // https://stackoverflow.com/questions/863800/replacing-diacritics-in-javascript
        // str = str.normalize('NFKD').replace(/[^\w]/g, '');
        return str;
    }
    
    
    static stringRemoveInitialThe(str:string):string{
        if(str.search("The ") === 0) str = str.substring(4)
        return str;
    }
    



    


    
}



