import './_styles.scss';

//@ts-ignore
import _ from 'lodash'

import React from 'react';
// import Lang from '../../tools/Lang';
import Tools from '../../tools/Tools'
import {TopicData} from '../../data/Topic';
import TabPage from '../TabPage/TabPage';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

interface Props  {
    topicData:TopicData,
    setTitle:Function,
}

type ItemData = {
    source:string,
    author?:string,
    title?:string,
    publisher?:string,
    
}

export default class TopicBibliography extends React.Component<Props>{
    
    topicData:TopicData|null;

    itemsData:ItemData[] = [];

    constructor(props:Props){
        
        super(props);
        log("TopicBibliography()")

        this.topicData = this.cleanData(_.cloneDeep(props.topicData));

        this.itemsData = this.getItemsData()
    }


    componentDidMount(){
        this.props.setTitle();
    }
    
     // ----------------------------------------------------------------------
    // METHODS

    cleanData(propData:TopicData):TopicData|null{
        log("TopicBibliography.cleanData()")
        if(!propData) return null;

        let data:TopicData = {...propData}

        if(data.bibliography){
            data.bibliography.source = Tools.stringClean(data.bibliography.source)
        }
        return data;
    }

    getItemsData():ItemData[]{
        log("TopicBibliography.getItemsData()")
        if(!this.topicData || !this.topicData.bibliography) return [];

        let itemsData:ItemData[] = [];
        let itemsSource:string[] = Tools.stringToArray(this.topicData.bibliography.source)
        
        for(let itemSource of itemsSource){
            let itemData:ItemData = this.getItemDataFromSource(itemSource);
            itemsData.push(itemData);
        }

        return itemsData;
    }

    getItemDataFromSource(source:string):ItemData{
        log("-------------")
        log("TopicBibliography.getItemDataFromSource()")
        let itemData:ItemData = {source:source};
        log(" - source = " + source);

        source = source.trim();
        
        // Use <em> or <i>
        let tag:string = "em";
        if(source.indexOf("<i>") > -1) tag = "i";
        const tagOpen = "<" + tag + ">";
        const tagClose = "</" + tag + ">";

        // Get the author
        if(source.indexOf(tagOpen) !== -1 && source.indexOf(tagOpen) !== 0){
            let arr = source.split(tagOpen);
            let str = arr[0];
            str=str.trim();
            str = Tools.stringClean(str);
            str = Tools.stringTrimTrailingJunk(str);
            itemData.author = str;
            log(" - author = " + str);
        }

        // Get the publisher
        if(source.indexOf(tagClose) !== -1){
            let arr = source.split(tagClose);
            log(" - publisher arr = ", arr);
            let str = arr[arr.length-1];
            str = Tools.stringClean(str);
            str = Tools.stringTrimTrailingJunk(str);
            
            str = Tools.stringCapitalizeFirstLetter(str);
            
            itemData.publisher = str;
            log(" - publisher = " + str);
        }
        
        // Get the title
        if(source.indexOf(tagOpen) !== -1){
            let arr = source.split(tagOpen);
            let str = "";
            if(source.indexOf(tagOpen) === 0){
                log("arr = " , arr)
                str = arr[1].split(tagClose)[0];
            }else{
                log("arr = " , arr)
                str = arr[1].split(tagClose)[0];
            }
            itemData.title = Tools.stringTrimTrailingJunk(str);
            log(" - title = " + str);
        }

        return itemData;
    }
    
    
    renderItems():any{
        if(!this.itemsData) return null;

        let items:any[] = [];
        let i = 0;
        for(let itemData of this.itemsData){
            let item = (
                <div className="bibliography-item" key={"bibliography_item__" + i}>
                    <h3 className="list-title"><i>{itemData.title}</i></h3>
                    <p className="smallcaps">{itemData.author}</p>
                    <p className="faint publisher small">{itemData.publisher}</p>

                    {/* <p className="faint">{itemData.text}</p> */}
                </div>
            )
            items.push(item);
            i++;
        }
        
        return (
            <div className="bibliography-items">
                {items}

            </div>
        )

    }
    

    render(){
       

        return(
            <TabPage>
                <div id="topic-bibliography" className="topic-panel">
                    <div className="main-column main-column--narrow">
                        <div className="main-column-content">
                            <div className="text-column">
                                <div className="topic-subheader"/>
                                {this.renderItems()}

                            </div>
                        </div>
                    </div>
                    
                </div>
            </TabPage>
        )
    }
}