import './_styles.scss';

import React, {useState, useEffect, useRef} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Lang from '../../tools/Lang';
import TopicOverview from '../TopicOverview/TopicOverview';
import TopicResearch from '../TopicResearch/TopicResearch';
import TopicBibliography from '../TopicBibliography/TopicBibliography';
import TopicLinks from '../TopicLinks/TopicLinks';
import TopicLibrary from '../TopicLibrary/TopicLibrary';
import TopicSounds from '../TopicSounds/TopicSounds';
import {TopicData} from '../../data/Topic';
import Router from '../../router/Router';


// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

/**
NOTES
This component is based on the examples found in https://material-ui.com/components/tabs/
 */


interface Props {
    tabIds:Array<TopicTabId>,
    topicData:TopicData,
    activeId?:string,
    childId?:string,
    itemId?:string,
    setTitle:Function,
    
}


export enum TopicTabId  {
    overview = "overview",
    research = "research",
    library = "library",
    bibliography = "bibliography",
    sounds = "sounds",
    links = "links",
}

export default function TopicTabs(props:Props) {
    
    
    
    const activeValue:number = Math.max(0, props.tabIds.indexOf(props.activeId as TopicTabId));
    const [value, setValue] = useState(activeValue);
    const mounted = useRef();


    useEffect(() => {
        log("TopicTabs.useEffect()")
        
        if (!mounted.current) {
          // do componentDidMount logic
		  //@ts-ignore
          mounted.current = true;
          log("TopicTabs.useEffect() didMount")
        } else {
          // do componentDidUpdate logic
          log("TopicTabs.useEffect() didUpdate")
          log(" - value = " + value);
          const activeValue = Math.max(0, props.tabIds.indexOf(props.activeId as TopicTabId));
          if(value !== activeValue){
              setValue(activeValue)
          }
        
        }
      },[value, props]);

    
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        log("TopicTabs.handleChange() newValue = " + newValue)
        setValue(newValue);
        Router.navigateToTopicTab(props.tabIds[newValue])
    };

    const renderTabItem = (tabId:TopicTabId, index:number) => {

        return (
            <Tab 
                label={Lang.t("topic." + tabId)}
                // style={{color:'red'}}
                className="topic-tab"
                {...a11yProps(index)} 
                key={"TopicTabs__tabItem__" + index + + "__" + tabId}
            />
        )
    }
    const renderTabItems = () => {
        let itemIndex = 0;
        let items:Array<any> = [];
        for(let tabId of props.tabIds){

            let item = renderTabItem(tabId, itemIndex)
            itemIndex ++;
            items.push(item);
        }
        return items;
        
    }

    const renderTabPanelContent = (tabId:TopicTabId) => {
        log("renderTabPanelContent()")
        log(" - props = ", props)
        let content:any = null;
        switch (tabId){
            case TopicTabId.overview:
                content = (
                    <TopicOverview
                        topicData={props.topicData}
                        mediaItemId={props.itemId}
                        setTitle={props.setTitle}
                    />
                    
                )       
            break
            
            case TopicTabId.research:
                content = (
                    <TopicResearch
                        topicData={props.topicData}
                        setTitle={props.setTitle}
                    />
                    
                )  
            break
            case TopicTabId.library:
                content = (
                    <TopicLibrary
                        topicData={props.topicData}
                        childId={props.childId}
                        setTitle={props.setTitle}
                    />
                    
                )       
            break
            case TopicTabId.bibliography:
                content = (
                    <TopicBibliography
                        topicData={props.topicData}
                        setTitle={props.setTitle}
                    />
                    
                )      
            break
            case TopicTabId.sounds:
                content = (
                    <TopicSounds
                        topicData={props.topicData}
                        childId={props.childId}
                        setTitle={props.setTitle}
                    />
                    
                )      
            break
            
            case TopicTabId.links:
                content = (
                    <TopicLinks
                        topicData={props.topicData}
                        setTitle={props.setTitle}
                    />
                    
                )         
            break
            default:
                content = (
                    <div className="main-column">
                        <div className="main-column-content topic-header">
                            <h1>{Lang.t("topic." + tabId)}</h1>
                        </div>
                    </div>
                )
        }

        return content
    }

    const renderTabPanels = () => {
        let index = 0;        
        let panels:Array<any> = [];
        for(let tabId of props.tabIds){
            let panel = (
                <TabPanel 
                    value={value} 
                    index={index}
                    key={"TopicTabs__TabPanel__" + index + "__" + tabId}
                >
                    {renderTabPanelContent(tabId)}
                </TabPanel>
            )
            panels.push(panel);
            index ++;
        }
        return panels;
    }

    

    return (
        <div id="tabs-inner">
            <div className="main-column main-column--narrow">
                <div className="main-column-content">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="Tabs for this topic"
                    >
                        {renderTabItems()}    
                    </Tabs>
                </div>
            </div>

            {renderTabPanels()}
            
        </div>
    );
}


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }
  
  function TabPanel(props: TabPanelProps) {
      const { children, value, index, ...other } = props;
  
      return (
          <div
              role="tabpanel"
              hidden={value !== index}
              id={`scrollable-auto-tabpanel-${index}`}
              aria-labelledby={`scrollable-auto-tab-${index}`}
              {...other}
          >
              {value === index && (
              <Box>
                  {children}
              </Box>
              )}
          </div>
      );
  }
  
  function a11yProps(index: any) {
      return {
          id: `scrollable-auto-tab-${index}`,
          'aria-controls': `scrollable-auto-tabpanel-${index}`,
      };
  }
  