import './_styles.scss';

import React from 'react';

import ReactPlayer from "react-player/vimeo"
import {VideoLang, VideoService, VideoSource} from '../../data/Topic';
import Lang from '../../tools/Lang';
import Config from '../../config/Config';
import Cookies from '../../tools/Cookies';
import { LangId } from '../../tools/Lang';


// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

type Props = {
    videoSource:VideoSource,
}

type State = {
    videoLang:VideoLang
}

export default class ModalMediaVideo extends React.Component<Props,State>{
    
    // All video language options
    allVideoLangs:VideoLang[] = [VideoLang.ga, VideoLang.en_gaSubs, VideoLang.ga_enSubs, VideoLang.en, VideoLang.et, VideoLang.et_enSubs, VideoLang.hi, VideoLang.hi_enSubs];

    // Default video language preferences for users who have chosen a Lang.langId
    preferredVideoLangs:{[langId:string]:VideoLang[]} = {

        // Users who have chose English prefer English with gaelic subs
        en:[VideoLang.ga_enSubs, VideoLang.en, VideoLang.ga, VideoLang.en_gaSubs, VideoLang.et, VideoLang.et_enSubs, VideoLang.hi, VideoLang.hi_enSubs],

        // Users who have chosen Gaelic
        ga:[VideoLang.ga_enSubs, VideoLang.ga, VideoLang.en, VideoLang.en_gaSubs, VideoLang.et, VideoLang.et_enSubs, VideoLang.hi, VideoLang.hi_enSubs],

    }

    constructor(props:Props){
        super(props);

        log("ModalMediaVideo()")
        log(" props = ", props)

        // Default lang is Gaelic with English subs
        let videoLang:VideoLang = VideoLang.en_gaSubs;

        // If user has set language to ga or en
        if(Lang.langId){
            
            let preferredVideoLang:VideoLang | null = null;

            // Preferred videoLang stored in cookie
            const cookieId:string = this.getVideoLangCookieId();
            const cookieLang:VideoLang|undefined = cookieId ? Cookies.getCookie(cookieId): undefined;
            if(cookieLang && this.props.videoSource[cookieLang]){
                preferredVideoLang = cookieLang;
            }

            // No cookie, just work out the preferred video lang
            else{
                preferredVideoLang = this.getPreferredVideoLang(Lang.langId);
            }
            
            // We have a preferred video lang
            if(preferredVideoLang) videoLang = preferredVideoLang;
        }

        this.state = {
            videoLang:videoLang
        };
    }

    /**
     * Get the cookie id for video lang
     * @returns Id of the cookie
     */
    getVideoLangCookieId():string{
        return "videoLang";
    }

    /**
     * Get the preferred video language for the given Lang.langId value
     */
    getPreferredVideoLang(langId:LangId):VideoLang|null{
        log("ModalMediaVideo.getPreferredVideoLang()")
        if(!langId) return null;

        const preferredVideoLangs:VideoLang[] = this.preferredVideoLangs[langId]
        
        if(!preferredVideoLangs) return null;

        // Check each of the prefered video languages in order
        for(let videoLang of preferredVideoLangs){
            
            // This language is available, use it 
            if(this.props.videoSource[videoLang]){
                return videoLang;
            }
        }

        // Preferred video language not found.
        // How would this happen? Only if all of the languages in the this.preferredVideoLangs array are not present in the videoSource object.
        return null;
    }

    handleItemClick = (lang:VideoLang) =>{
        log("ModalMediaVideo.handleItemClick() lang = " + lang);
        this.setState({
            videoLang: lang
        });
        const cookieId:string|null = this.getVideoLangCookieId();
        if(cookieId){
            Cookies.setCookie(cookieId, lang);
        }
        
    }

    renderVideoLangs = ():any =>{
        log("ModalMediaVideo.renderVideoLangs()");
        let items:any[] = [];
        
        for(let lang of this.allVideoLangs){
            
            // const url = this.props.urls[key];
            let url = this.props.videoSource[lang];
            if(url === undefined) continue;

            let className:string = this.state.videoLang === lang ? "active" : "";
            className += " lang-but-" + lang;
            let item = (
                <li key={"video_lang__" + lang} className={className}>
                    <button onClick={(event)=>{this.handleItemClick(lang)}}>
                        <span className="smallcaps bold">{Lang.t("video.lang." + lang)}</span>
                    </button>
                </li>        
            )
            items.push(item);
        }
        
        return(
            <div className="video-langs">
                
                {/* <div className="video-langs-icon"/> */}
                
                <ul>
                    {items}
                    
                </ul>
            
            </div>
        )
    }

    render(){
        log("ModalMediaVideo.render()");
        
        if(!this.props.videoSource.service) return null;
        const videoId:string|undefined = this.props.videoSource[this.state.videoLang];
        const serviceId:VideoService = this.props.videoSource.service;
        const url = Config.getServiceUrl(serviceId, {id: videoId})
        log(" - url = " + url)
        if(!url) return null;
        
        
        return(
            <>
                <div className="media">
                    
                    <ReactPlayer
                        url={url}
                        controls={true}
                        width={'unset'}
                        height={'unset'}
                        
                    config={{
                            //@ts-ignore
                            vimeo:{
                                playerOptions:{
                                    responsive:true,
                                    // width:100,
                                    // muted:true,
                                    // color:"ffff00",
                                }
                            }
                        }}
                        
                    />

                    
                    
                    
                </div>
            {this.renderVideoLangs()}
            </>
        

        )
    }
}