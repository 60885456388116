import React from 'react';

import ModalScreen from '../ModalScreen/ModalScreen';
import Lang from '../../tools/Lang';
import {Close } from '@material-ui/icons';
// import Tools from '../../tools/Tools';


import './_styles.scss';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

type Props = {
    isOpen:boolean,
    onClose:any,
}




export default class ModalThanks extends React.Component<Props>{
    
    NUM_LISTS:{[key:string]:number} =  {
        default: 2,
        production: 1,
        animators: 2,
        musicians: 2,
        gaelicWriters: 2,
        englishWriters: 2,
        gaelicUnits: 2,
        programmers: 1,
        schools: 2,
      
    }

    static MODAL_ID:string = "credits";

    close = () => {
        log("ModalThanks.close()")
        this.props.onClose();
    }
    handleCloseClick = () => {
        this.close();
    }


    
	/** A name can have a link at the end, e.g. 
	 * 		
	 * 		Piotrek Chuchla https://thenounproject.com/icon/books-21511
	 * 
	 * Return {name, link}
	 */
	parseName(text?:string):{name:string, link:string|undefined} {
		let result:{name:string, link:string|undefined}  = {name: text || "", link: undefined}
		if (!text) return result;

		// Get the string-index of a link. It's -1 if not there.
		const iLink = text.search(/\s+https?:\/\//gi)

		const hasLink = iLink >= 0
		if (hasLink) {
			result = {
				name: text.substring(0, iLink).trim(), 
				link: text.substring(iLink, text.length).trim()
			}
		}
		return result;
	}
	

    renderNamesList(id:string):any{
        log(" ")
        log("--------------------------------------------------------")
        log("ModalThanks.renderNamesList()")
        log(" - id = " + id)

        let el:any = null;

        let lists:any[] = [];

        let items:any[] = [];
        const nameStr:string = Lang.t("thanks." + id + ".names");
        const names:string[] = nameStr.split(" | ");

        const titlesId:string = "thanks." + id + ".titles";
        const titleStr:string = Lang.t(titlesId) === titlesId ? "" :  Lang.t(titlesId);
        const titles:string[] = titleStr ? titleStr.split(" | ") : [];

        const numCols:number  = this.NUM_LISTS[id] === undefined ? this.NUM_LISTS.default :  this.NUM_LISTS[id];
        const listItemsMax = Math.ceil(names.length / numCols);

        let listIndex = 0;
        let itemIndex = 0;
        let totItemsRendered = 0;
        
        for(let name of names){
            const title:string|undefined = titles[itemIndex];
			const {name: nameText, link} = this.parseName(name)

            let item = (
                <li
                    className="item"
                    key={"thanks_" + id + "_list" + listIndex + "_item" + itemIndex}
                >
                    <span className="item-name">
						{   link ? <a href={link} target="_blank" rel="noreferrer">{nameText}</a> : nameText   }
					</span>
                    {title && 
                        <span className="item-title"><i>{title}</i></span>
                    }
                </li>
            )
            items.push(item)
            
            itemIndex ++;
            totItemsRendered ++;

            // Reached max items in list, create <UL> list element
            const shouldCreateList:boolean = 
                itemIndex === listItemsMax
                || totItemsRendered === names.length

            
            if(shouldCreateList){
                let list = (
                    <ul 
                        className="names-list"
                        key={"thanks_" + id + "_list" + listIndex}
                    >
                        {items}
                    </ul>
                )
                lists.push(list);
                listIndex ++;
                itemIndex = 0;
                items = [];
            }

            

            
        }
        el = (
            <div className="names-lists">
                {lists}
            </div>
        )
        return el;
    }

    render(){
        log("ModalThanks.render()")
        return(

            <ModalScreen
                open={this.props.isOpen}
                onClose={this.props.onClose}
                // itemId={this.state.mediaItemId}
            >
                <div className="modal" id="modal-thanks">
                    <div className="modal-header">
                        <div className="modal-header-text">
                        <h3>{Lang.t("thanks.title")}</h3>
                        </div>
                        <div className="buts">
                            <button 
                                aria-label={Lang.t("but.close")} 
                                className="but close-but" 
                                onClick={this.handleCloseClick}
                            >
                                <Close className="but-icon" />
                            </button>
                        </div>

                    </div>
                    <div className="modal-body">
                        <div className="modal-content">
                            <div className="text-column">
                                <div className="intro">
                                    <h2>
                                        <i>{Lang.t("thanks.intro")}</i>
                                    </h2>
                                    <p>{Lang.t("thanks.text")}</p>
                                </div>

                                {/* PRODUCTION TEAM */}
                                <div className="section production">
                                    <h3>{Lang.t("thanks.production")}</h3>
                                    <div className='underline'/>
                                    {this.renderNamesList("production")}
                                </div>

                                {/* PIC */}
                                <div className='pic frog'>
                                    <div className="image-container">
                                        <img src="/images/ui/credits_frog.png" alt=""/>
                                    </div>
                                </div>

                                {/* ANIMATORS */}
                                <div className="section animators">
                                    <h3>{Lang.t("thanks.animators")}</h3>
                                    <div className='underline'/>
                                    {this.renderNamesList("animators")}
                                </div>

                                {/* PIC */}
                                <div className='pic piper'>
                                    <div className="image-container">
                                        <img src="/images/ui/credits_piper.png" alt=""/>
                                    </div>
                                </div>


                                {/* MUSICIANS */}
                                <div className="section musicians">
                                    <h3>{Lang.t("thanks.musicians")}</h3>
                                    <div className='underline'/>
                                    {this.renderNamesList("musicians")}
                                </div>

                                {/* PIC */}
                                <div className='pic wise-fool'>
                                    <div className="image-container">
                                        <img src="/images/ui/credits_wise_fool.png" alt=""/>
                                    </div>
                                </div>

                                {/* GAELIC WRITERS */}
                                <div className="section gaelicWriters">
                                    <h3>{Lang.t("thanks.gaelicWriters")}</h3>
                                    <div className='underline'/>
                                    {this.renderNamesList("gaelicWriters")}
                                </div>


                                {/* PIC */}
                                <div className='pic bagpipe'>
                                    <div className="image-container">
                                        <img src="/images/ui/credits_bagpipe.png" alt=""/>
                                    </div>
                                </div>

                                {/* ENGLISH WRITERS */}
                                <div className="section englishWriters">
                                    <h3>{Lang.t("thanks.englishWriters")}</h3>
                                    <div className='underline'/>
                                    {this.renderNamesList("englishWriters")}
                                </div>

                                {/* PIC */}
                                <div className='pic sheep'>
                                    <div className="image-container">
                                        <img src="/images/ui/credits_sheep.png" alt=""/>
                                    </div>
                                </div>

                                {/* GAELIC UNIT */}
                                <div className="section gaelicUnits">
                                    <h3>{Lang.t("thanks.gaelicUnits")}</h3>
                                    <div className='underline'/>
                                    {this.renderNamesList("gaelicUnits")}
                                </div>


                                {/* PIC */}
                                <div className='pic fairy'>
                                    <div className="image-container">
                                        <img src="/images/ui/credits_fairy.png" alt=""/>
                                    </div>
                                </div>


                                {/* PROGRAMMERS */}
                                <div className="section programmers">
                                    <h3>{Lang.t("thanks.programmers")}</h3>
                                    <div className='underline'/>
                                    {this.renderNamesList("programmers")}
                                </div>

                                {/* PIC */}
                                <div className='pic school'>
                                    <div className="image-container">
                                        <img src="/images/ui/credits_school.png" alt=""/>
                                    </div>
                                </div>

                                {/* SCHOOLS */}
                                <div className="section schools">
                                    <h3>{Lang.t("thanks.schools")}</h3>
                                    <div className='underline'/>
                                    {this.renderNamesList("schools")}
                                </div>


                                {/* ALSO */}
                                <div className="section also">
                                    <h3>{Lang.t("thanks.also")}</h3>
                                    <div className='underline'/>
                                    {this.renderNamesList("also")}
                                </div>


                                {/* FUNDERS */}
                                <div className="section funders">
                                    <h3>{Lang.t("thanks.funders")}</h3>
                                    <div className='underline'/>
                                    <div className='logos'>
                                        <img src="/images/ui/logo-wha.png" alt="West Highland Animation" className='logo logo-wha'/>
                                        <img src="/images/ui/logo-hie.png" alt="Highlands &amp; Islands Enterprise" className='logo logo-hie'/>
                                        <img src="/images/ui/logo-cnag.png" alt="CNAG" className='logo logo-cnag'/>
                                        <img src="/images/ui/logo-gms.png" alt="Gaelic Media Service" className='logo logo-gms'/>
                                        <img src="/images/ui/logo-leader.png" alt="Leader" className='logo logo-leader'/>
                                    </div>
                                    
                                </div>

                                <div className='bottom-underline'/>

                                {/* PIC */}
                                <div className='pic otter'>
                                    <div className="image-container">
                                        <img src="/images/ui/credits_otter.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                        
                        
                    </div>
                    
                    
                </div>
            </ModalScreen>
        )
    }
}