import {Component} from 'react';

type Props = {

    // The id of the animation must be the same as the folder name containing the lottie data.json
    // i.e. /lottie/{id}/data.json
    id:string,

    loop?:boolean,

    autoplay?:boolean,

    // Defaults to 'svg'
    // @see https://airbnb.io/lottie/#/web
    renderer?:string,
    
    // Called when all data is loaded
    onLoaded?:Function,

    // Called when animation is complete
    onComplete?:Function,

    // Called at the end of every loop
    onLoopComplete?:Function,

    // Called on every frame
    onEnterFrame?:Function,
}

/**
 * Renders a Lottie animation.
 * For this to work there needs to be:
 * - a Lottie data file at "/lottie/{id}/data.js"
 * - any required images or other assets inside the "/lottie/{id}/" folder
 */
export default class Lottie extends Component<Props>{
    
    static anims:any = {};

   
    // --------------------------------------------------------------------
    // LIFECYCLE

    componentDidMount(){
        this.load(this.props.id, this.props.loop, this.props.autoplay, this.props.renderer)
    }

    componentWillUnmount(){
        this.destroy();
        
    }

    
    // --------------------------------------------------------------------
    // METHODS

    /**
   * Load in a Lottie animation
   * @param {string} id 
   * @param {boolean} loop
   * @param {boolean} autoplay
   * @param {string} renderer
   */
    load(id:string, loop:boolean = false, autoplay:boolean = true, renderer:string = 'svg'){
        // console.log("Lottie.load()")
        //@ts-ignore
        if(!window.lottie){
            console.log("Lottie.load()")
            console.log( - "Error: cannot load Lottie animation, could not find lottie library");
            return;
        }
        
        //@ts-ignore
        Lottie.anims[id] = window.lottie.loadAnimation({
            container: document.getElementById(id),
            renderer: renderer,
            loop: loop,
            autoplay: autoplay,
            path: '/lottie/' + id + '/data.json'
        })

        var anim = Lottie.anims[id];

        // Set up handlers
        if(this.props.onLoaded) anim.addEventListener("data_ready", this.handleDataReady);

        if(this.props.onComplete) anim.onComplete = this.handleComplete;
        
        if(this.props.onLoopComplete) anim.onLoopComplete = this.handleLoopComplete;

        if(this.props.onEnterFrame) anim.onEnterFrame = this.handleEnterFrame;
        
    }

    destroy(){
        if(Lottie.anims[this.props.id]){
            Lottie.anims[this.props.id].destroy();
            delete Lottie.anims[this.props.id]
        }
    }


    // --------------------------------------------------------------------
    // EVENT HANDLERS

    handleDataReady = () => {
        if(!this.props.onLoaded) return
        this.props.onLoaded(this.props.id)    
    }

    handleComplete = () => {
        if(!this.props.onComplete) return
        this.props.onComplete(this.props.id)    
    }

    handleLoopComplete = () => {
        if(!this.props.onLoopComplete) return
        this.props.onLoopComplete(this.props.id)    
    }

    handleEnterFrame = () => {
        if(!this.props.onEnterFrame) return
        this.props.onEnterFrame(this.props.id)    
    }

    // --------------------------------------------------------------------
    // RENDER

    render(){
        return(
            <div id={this.props.id}/>
        )
    }
}