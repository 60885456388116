import Config from '../config/Config'
import Lang from '../tools/Lang'


const littleSettings:Record<string, any> = {

	validTobarLangs: 	["gd", "en"],

	validTypesAsLabels:	["tobar"],
}


export enum ExternalLinkType {
	vimeo = "vimeo",
	tobar = "tobar",
}


export type ExternalLinkData = {
	url:string,

	/** Instead of a url the link may be just an ID, and the URL is constructed on the fly, e.g. for vimeo. */
	type?:ExternalLinkType,

	/** E.g. "tobar" */
	label?:string,

	/** The main languages of the destination. Our two-letter language code, e.g. en, ga, hi, etc.  */
	langs?:string[],

	/** If the destination has written transcriptions in other languages */
	langSubtitles?:string[],
}



export default class ExternalLink {
	
	link:ExternalLinkData;

	constructor(link:ExternalLinkData) {
		this.link = link
	}



	static pickLinkForLang(links:ExternalLinkData[]|undefined, lang:string):ExternalLink|undefined {
		// console.log(`pickLinkForLang ${lang}:`, links)
		if (!links) return;

		const linksWithLang = links.filter(link => link.langs?.includes(lang))
		let link = linksWithLang[0]
		if (!link) {

			// const linksWithNoLang = links.filter(link => !link.langs)
			link = linksWithLang[0]
			if (!link) {
				link = links[0]
			}
		}

		if (!link) return;

		return new ExternalLink(link);
	}


	get url():string {
		return this.getUrl();
	}


	getUrl(params?:Record<string, any>) {
		switch (this.link.type) {
			case "vimeo":
				return Config.getServiceUrl(this.link.type, {id: this.link.url} );

			case "tobar":
				let lang:string = Lang.isoLangId
	
				if (!littleSettings.validTobarLangs.includes(lang)) {
					lang = littleSettings.validTobarLangs[0]
				}
				return Config.getServiceUrl(this.link.type, {id: this.link.url, lang});
			
			default:
				return this.link.url;
		}
	}

	get label():string {
		if (this.link.label!=null) {
			return this.link.label;
		}
		if (this.link.type) {
			
			if (littleSettings.validTypesAsLabels.includes(this.link.type)) {
				return this.link.type;
			}
		}
		return this.link.label || "";
	}

	get labelAlt():string {
		if (this.label==="") {
			return "";
		}

		const termId = `externalLinks.${this.label}.alt`

		return Lang.hasTerm(termId) ? Lang.t(termId) : "";
	}

	hasLang(lang:string):boolean {
		if (this.link.langs) {
			
			for (let linkLang of this.link.langs) {
				if (linkLang===lang) {
					return true;
				}
			}

		}
		return false;
	}

	hasSubtitlesLang(lang:string):boolean {
		if (this.link.langSubtitles) {
			
			for (let linkLang of this.link.langSubtitles) {
				if (linkLang===lang) {
					return true;
				}
			}

		}
		return false;
	}
	
}