import './_styles.scss';
import React from 'react';
import Lang from '../../tools/Lang';
import Tween, {TweenObject} from '../../tools/Tween';
import Tools from '../../tools/Tools';
import Config from '../../config/Config';
import Lottie from '../Lottie/Lottie';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

enum PreloadIds  {
    image = "image",
    logo = "logo",
    logoLottie = "hero-learners-logo",
}
interface Props{
    scrollY?:number
    
}

interface State {
    top:number,
    opacity:number,
    imageOpacity:number,
}

export default class HeroLearners extends React.Component<Props, State>{
    
    imageId:string;

    opacityTween:TweenObject | null = null;
    imageOpacityTween:TweenObject | null = null;

    isUnmounted:boolean = false;

    imagePath:string = Config.getImagePath();

    preloadIdsCompleted:any = {
        [PreloadIds.image]: false,
        [PreloadIds.logoLottie]: false,
    }

    logoLottieId:string = "hero-learners-logo";
    isRevealed:boolean = false;

    constructor(props:Props){
        super(props)

        this.state = {
            top:0,
            opacity:0,
            imageOpacity:0,
        }

        //window.addEventListener('scroll', this.handleScroll);

       
        //@ts-ignore
        // if(!window.lottieDataReadyHandlers) window.lottieDataReadyHandlers = {};
        //@ts-ignore
        // window.lottieDataReadyHandlers[PreloadIds.logoLottie] = this.handleLottieDataReady;

        // //@ts-ignore
        // if(!window.react) window.react = {};
        
        // //@ts-ignore
        // if(!window.react.lottieHandlers) window.react.lottieHandlers = {};
        
        // //@ts-ignore
        // window.react.lottieHandlers[PreloadIds.logoLottie] = {};
        
        // //@ts-ignore
        // window.react.lottieHandlers[PreloadIds.logoLottie].handleDataReady = this.handleLottieDataReady

        // Set image Id
        this.imageId = Tools.arrayRandomElement(Config.settings.home.hero.imageIds);
    }

    

    // ---------------------------------------------------------------------------------
    // LIFECYCLE

    componentWillUnmount(){
        this.isUnmounted = true;
        // window.removeEventListener('scroll', this.handleScroll);
        Tween.kill(this.opacityTween);
        Tween.kill(this.imageOpacityTween);
    }



    // ---------------------------------------------------------------------------------
    // METHODS

    async reveal(){
        log("HeroLearners.reveal()");
        if(this.isRevealed) return;
        await Tools.later(0.25)
        if(this.isUnmounted) return;
        this.isRevealed = true;
        let durationSecs = 2;
        this.opacityTween = Tween.to(this, {opacity:1, duration:durationSecs});
        this.imageOpacityTween = Tween.to(this, {imageOpacity:1, duration:durationSecs*2});
    }

    isPreloadComplete = ():boolean => {
        for(let key in this.preloadIdsCompleted){
            if(!this.preloadIdsCompleted[key]) return false;
        }
        log("HeroLearners.isPreloadComplete()");
        log(" - preload complete = true")
        return true;
    }



    // ---------------------------------------------------------------------------------
    // EVENTS

    // handleScroll = (event:any) => {
    //     // log("Hero.handleScroll() event = ", event)

    //     let top  = window.pageYOffset || document.documentElement.scrollTop;
    //     // log(" - top = " + top)
    //     if(top<500){
    //         this.setState({top:top})
    //     }
    // }


    handleImageLoad = async(id:PreloadIds) => {
        log("HeroLearners.handleImageLoad()")
        this.preloadIdsCompleted[id] = true;
        if(this.isPreloadComplete()){
            
            this.reveal();
        }
        
    }

    handleLottieLoaded = async(id:string) => {
        // console.log("HeroLearners.handleLottieDataReady()")
        // console.log(" - id = " + id)
        if(id === PreloadIds.logoLottie) {
            this.preloadIdsCompleted[id] = true;
            if(this.isPreloadComplete()){
                
                this.reveal();
            }
        }
       
        
    }

    handleLottieComplete = (id:string) => {
        // console.log("HeroLearners.handleLottieComplete()")
        // console.log(" - id = " + id)
    }
    

    


    // ---------------------------------------------------------------------------------
    // RENDER

    
    

    render(){
        log("HeroLearners.render()");
        log(" - this.state.imageOpacity = " + this.state.imageOpacity);
        
        return(
            
            <div id="hero" className="audience-learners">
                <div 
                    className="main-column" 
                    style={{
                        opacity:this.state.opacity
                    }}
                >
                    
                    <div id="hero-foreground">
                        <div 
                            id="hero-title-container"
                            className="rellax" 
                            data-rellax-speed="2" 
                            // data-rellax-percentage="0.5"
                        >
                            <div id="hero-title">
                                
                               
                                <Lottie
                                    id={PreloadIds.logoLottie}
                                    // loop={true}
                                    // autoplay={false}
                                    onLoaded={this.handleLottieLoaded}
                                    onComplete={this.handleLottieComplete}
                                    // onLoopComplete={this.handleLottieLoopComplete}
                                    // onEnterFrame={this.handleLottieEnterFrame}
                                />

                                
                                {/* <img onLoad={(event) => this.handleImageLoad(PreloadIds.logo)} src="/images/ui/logo-light-l.png" id="hero-title-logo" alt=""/> */}
                                
                                <h2>{Lang.t("home.subtitle")}</h2>
                                <h3>{Lang.t("home.description")}</h3>
                                {/* <LangBar
                                    location="hero-learners"
                                /> */}
                            </div>
                        </div>
                    </div>
                    <div id="hero-background" style={{
                        //top:this.state.top/3
                    }}>
                        <div id="hero-overlay">
                            <div id="hero-overlay-scrim"></div>
                            {/* <div id="hero-overlay-gradient-vertical"></div> */}
                            <div id="hero-overlay-gradient-horizontal"></div>
                        </div>
                        
                        <div id="hero-image" 
                            className="rellax" 
                            // data-rellax-speed="-2" 
                            // data-rellax-percentage="0.5"
                            // data-rellax-zindex="1"
                            
                        >
                            <img 
                                style={{
                                    opacity:this.state.imageOpacity
                                }}
                                onLoad={(event) => this.handleImageLoad(PreloadIds.image)} src={this.imagePath + "l/" + this.imageId + ".jpg"} alt=""
                            />
                        </div>
                    </div>
                    
                </div>
                
                <div className="hero-base">
                    <img src="/images/ui/hero-base.png" alt=""/>
                </div>
            
            </div>
        
        )
    }
}



