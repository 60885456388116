import './_styles.scss';
import React from 'react';

import Nav, {NavTheme} from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer';
import Lang, {LangId} from '../../tools/Lang';
import Tools from '../../tools/Tools';
import Content from '../../data/Content';
import { ContentAudience, ContentSection} from '../../data/Content';
import {AudienceId} from '../../tools/Audience';
import Config from '../../config/Config';
import Spinner from '../../components/Spinner/Spinner';
import {MediaItemData, MediaType, TopicData} from '../../data/Topic';
import Topic from '../../data/Topic';


// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

enum LoadState {
    loading = "loading",
    loaded = "loaded",
    error = "error"
}

type LoadTopicRes = {
    topic?:TopicData,
    error?:Error
}

type VideoItem = {
    topicId:string, 
    titleEn:string,
    titleGa:string,
    descriptionEn:string,
    descriptionGa:string
}

type GameItem = {
    topicId:string, 
    titleEn:string,
    titleGa:string,
    descriptionEn:string,
    descriptionGa:string
}
type ComicItem = {
    topicId:string, 
    titleEn:string,
    titleGa:string,
    descriptionEn:string,
    descriptionGa:string
}

type AudioItem = {
    topicId:string, 
    titleEn:string,
    titleGa:string,
    descriptionEn:string,
    descriptionGa:string
}
type ArchiveTextItem = {
    topicId:string, 
    titleEn:string,
    titleGa:string,
    descriptionEn:string,
    descriptionGa:string
}
type ScriptItem = {
    topicId:string, 
    titleEn:string,
    titleGa:string,
    descriptionEn:string,
    descriptionGa:string
}

type ArchiveSoundItem = {
    topicId:string, 
    titleEn:string,
    titleGa:string,
    descriptionEn:string,
    descriptionGa:string
}


interface Props{
    langId?:LangId,
    navProps?:any,
}

interface State {
    loadState:LoadState,
    
    topics:{[key:string]:TopicData},

    // The sitemap string
    sitemap:string,

    // Total number of stories
    totStories:number,
    
    // Total number of links in the sitemap
    totLinks:number,
    
    // Object containing movie items
    videoItems:{[key:string]:VideoItem},

    // Object containing game items
    gameItems:{[key:string]:GameItem},

    // Object containing comic items
    comicItems:{[key:string]:ComicItem},

    // Object containing archive items
    archiveTextItems:{[key:string]:ArchiveTextItem},

    // Object containing archive sound items
    archiveSoundItems:{[key:string]:ArchiveSoundItem},

    // Object containing music items
    audioItems:{[key:string]:AudioItem},

    // Object containing music items
    scriptItems:{[key:string]:ScriptItem},
}

export default class SitemapScreen extends React.Component<Props, State>{
    

    // Set to 0 to do all stories
    MAX_STORIES = 0;

    // Seconds between each fetch to get a topic's data
    DELAY_BETWEEN_TOPIC_DATA_FETCHES = 5;
    
    constructor(props:Props){
        super(props);


        

        log("SitemapScreen()")

        this.state = {
            loadState: LoadState.loading,
            sitemap: '',
            topics:{},
            totStories:0,
            totLinks:0,
            

            videoItems:{},
            gameItems: {},
            comicItems: {},
            archiveTextItems:{},
            archiveSoundItems: {},
            audioItems:{},
            scriptItems:{}
        }

        Lang.setTitle("title", "home.description", undefined, Tools.OPENGRAPH_TYPE_ARTICLE, undefined, false)
    }

    componentDidMount(){

        this.setState({
            sitemap: this.getSitemapStart()
        })
        this.loadData();
    }


  
    loadData = async() => {
        log("SitemapScreen.loadData()")
        const stories = Content.data?.stories;
        
        if(stories){
            this.setState({totStories:stories.length})

            const startIndex = 0;
            // const startIndex = 7;
            const endIndex = this.MAX_STORIES || stories.length;
            // const endIndex = 8;
    
            for(let i=startIndex; i < endIndex; i++){
                
                const story = stories[i];

                // Load topic
                let res:LoadTopicRes = await this.loadTopic(story.id, story._id, i);
                const isError = !res || !res.topic || res.error;
                
                // Error
                if(isError){
                    this.setState({loadState:LoadState.error})
                    break;
                }
                
                // Loaded topic
                else{
                   
					res.topic = Content.cleanTopicData(res.topic)
                    if(res.topic) {
						this.parseNewTopic(res.topic);
					}
                    
                    
                    // Finished
                    if(i === endIndex - 1){
                        let sitemap = this.state.sitemap;
                        sitemap += this.getSitemapEnd();

                        this.setState({
                            loadState:LoadState.loaded,
                            sitemap
                        })
                        break;
                    }

                    // Wait
                    await Tools.later(this.DELAY_BETWEEN_TOPIC_DATA_FETCHES);
                    
                }        
            }
        }
        
    }

    loadTopic = async(id:string, _id:string, index:number):Promise<LoadTopicRes> => {
        log(" ");
        log("SitemapScreen.loadTopic()")
        log(index + ") - id = " + id + "_" + _id);
        if(!id || !_id) return {error: new Error("Error loading data")};
        
        // Get path
        const path:string  = Config.getTopicPath(id, _id);
        // console.log(" - path = " + path);
        
        // Fetch the file / send request
        try{
            let res:any = await fetch(path);
            const topic:TopicData = await res.json();
			// console.log(topic)
            if(!topic) throw new Error("No topic loaded")
            return {topic: topic};
        }catch(err){
            return {error: new Error("Error loading data")};
        }
        
    }

    getItemUrls(gaUrl:string, enUrl:string):string{
        if(!gaUrl || !enUrl) return ``;
        
        // this.totLinksNew ++;
        this.setState(state=>({
            totLinks: state.totLinks + 1
        }))

        return `
    <url>
        <loc>${gaUrl}</loc>
        <xhtml:link rel="alternate" hreflang="en" href="${enUrl}" />
        <xhtml:link rel="alternate" hreflang="gd-gb" href="${gaUrl}" />
    </url>
    <url>
        <loc>${enUrl}</loc>
        <xhtml:link rel="alternate" hreflang="en" href="${enUrl}" />
        <xhtml:link rel="alternate" hreflang="gd-gb" href="${gaUrl}" />
    </url>`
        
    }

    

    addVideoItem(topic:TopicData, item:MediaItemData){
        // console.log("addVideoItem()")
        // console.log(" - item = ", item);
        let newItem:VideoItem = {

            topicId: topic.topicId, 
            //@ts-ignore
            titleEn: item.title?.en,
            //@ts-ignore
            titleGa: item.title?.ga,
            //@ts-ignore
            descriptionEn: item.description?.en,
            //@ts-ignore
            descriptionGa: item.description?.ga,
        }
        this.setState(state => {
            let items = {...state.videoItems}
            items[item.id] = newItem;
            return {videoItems: items}
        });
        
    }

    addGameItem(topic:TopicData, item:MediaItemData){
        log("addGameItem()")
        log(" - item = ", item);
        let newItem:GameItem = {
            topicId: topic.topicId, 
            //@ts-ignore
            titleEn: item.title?.en,
            //@ts-ignore
            titleGa: item.title?.ga,
            //@ts-ignore
            descriptionEn: item.description?.en,
            //@ts-ignore
            descriptionGa: item.description?.ga,
        }
        this.setState(state => {
            let items = {...state.gameItems}
            items[item.id] = newItem;
            return {gameItems: items}
        });
        
    }

    addComicItem(topic:TopicData, item:MediaItemData){
        log("addComicItem()")
        log(" - item = ", item);
        let newItem:ComicItem = {
            topicId: topic.topicId, 
            //@ts-ignore
            titleEn: item.title?.en,
            //@ts-ignore
            titleGa: item.title?.ga,
            //@ts-ignore
            descriptionEn: item.description?.en,
            //@ts-ignore
            descriptionGa: item.description?.ga,
        }
        this.setState(state => {
            let items = {...state.comicItems}
            items[item.id] = newItem;
            return {comicItems: items}
        });
    }


    addArchiveTextItem(topic:TopicData, item:MediaItemData){
        log("addArchiveTextItem()")
        log(" - item = ", item);
        let newItem:ArchiveTextItem = {
            topicId: topic.topicId, 
            //@ts-ignore
            titleEn: item.title?.en,
            //@ts-ignore
            titleGa: item.title?.ga,
            //@ts-ignore
            descriptionEn: item.description?.en,
            //@ts-ignore
            descriptionGa: item.description?.ga,
        }

        this.setState(state => {
            let items = {...state.archiveTextItems}
            items[item.id] = newItem;
            return {archiveTextItems: items}
        });
    }


    addAudioItem(topic:TopicData, item:MediaItemData){
        log("addAudioItem()")
        log(" - item = ", item);
        let newItem:AudioItem = {
            topicId: topic.topicId, 
            //@ts-ignore
            titleEn: item.title?.en,
            //@ts-ignore
            titleGa: item.title?.ga,
            //@ts-ignore
            descriptionEn: item.description?.en,
            //@ts-ignore
            descriptionGa: item.description?.ga,
        }

        this.setState(state => {
            let items = {...state.audioItems}
            items[item.id] = newItem;
            return {audioItems: items}
        });
    }

    addArchiveSoundItem(topic:TopicData, item:MediaItemData){
        log("addArchiveSoundItem()")
        log(" - item = ", item);
        let newItem:AudioItem = {
            topicId: topic.topicId, 
            //@ts-ignore
            titleEn: item.title?.en,
            //@ts-ignore
            titleGa: item.title?.ga,
            //@ts-ignore
            descriptionEn: item.description?.en,
            //@ts-ignore
            descriptionGa: item.description?.ga,
        }

        this.setState(state => {
            let items = {...state.archiveSoundItems}
            items[item.id] = newItem;
            return {archiveSoundItems: items}
        });
    }

    addScriptItem(topic:TopicData, item:MediaItemData){
        log("addScriptItem()")
        log(" - item = ", item);
        let newItem:AudioItem = {
            topicId: topic.topicId, 
            //@ts-ignore
            titleEn: item.title?.en,
            //@ts-ignore
            titleGa: item.title?.ga,
            //@ts-ignore
            descriptionEn: item.description?.en,
            //@ts-ignore
            descriptionGa: item.description?.ga,
        }

        this.setState(state => {
            let items = {...state.scriptItems}
            items[item.id] = newItem;
            return {scriptItems: items}
        });
    }

    parseNewTopic(topic:TopicData):string{
        // console.log(" ")
        // console.log("--------------------------------------")
        // console.log("SitemapScreen.parseNewTopic()")
        // console.log(" - topicId = " + topic.topicId)

        this.setState(state => {
            let topics = {...state.topics};
            topics[topic.topicId] = topic;
            return {topics}
        })
        
        const story = Content.getStory(topic.topicId);
        if(!story) return "";
        // log(" - story = " + story.id)
        let str = ``;



        // ---------------------------------------------
        // LEARNERS
        
        if(Content.isStoryAudienceLearners(story)){

            // OVERVIEW
            let fragBeforeLang = `https://bocan.tv/`;
            let fragAfterLang = `topic/${topic.topicId}`;
            let gaUrl = `${fragBeforeLang}${fragAfterLang}`;
            let enUrl = `${fragBeforeLang}en/${fragAfterLang}`;
            str += this.getItemUrls(gaUrl, enUrl)

            // BIBLIOGRAPHY
            if(Topic.topicHasBibliography(topic)){
                
                fragAfterLang = `topic/${topic.topicId}/bibliography`;
                gaUrl = `${fragBeforeLang}${fragAfterLang}`;
                enUrl = `${fragBeforeLang}en/${fragAfterLang}`;
                str += this.getItemUrls(gaUrl, enUrl);
            }

            // LINKS
            if(Topic.topicHasLinks(topic)){
                
                fragAfterLang = `topic/${topic.topicId}/links`;
                gaUrl = `${fragBeforeLang}${fragAfterLang}`;
                enUrl = `${fragBeforeLang}en/${fragAfterLang}`;
                str += this.getItemUrls(gaUrl, enUrl);
            }

            // RESEARCH
            if(Topic.topicHasResearch(topic)){
                
                fragAfterLang = `topic/${topic.topicId}/research`;
                gaUrl = `${fragBeforeLang}${fragAfterLang}`;
                enUrl = `${fragBeforeLang}en/${fragAfterLang}`;
                str += this.getItemUrls(gaUrl, enUrl);
            }

            // SOUNDS
            if(Topic.topicHasArchiveSounds(topic)){
                
                fragAfterLang = `topic/${topic.topicId}/sounds`;
                gaUrl = `${fragBeforeLang}${fragAfterLang}`;
                enUrl = `${fragBeforeLang}en/${fragAfterLang}`;
                str += this.getItemUrls(gaUrl, enUrl);
            }

            // LIBRARY
            if(Topic.topicHasLibrary(topic)){
                
                fragAfterLang = `topic/${topic.topicId}/library`;
                gaUrl = `${fragBeforeLang}${fragAfterLang}`;
                enUrl = `${fragBeforeLang}en/${fragAfterLang}`;
                str += this.getItemUrls(gaUrl, enUrl);
            }

            // MEDIA ITEMS
            if(topic.mediaItems){
                for(let mediaItem of topic.mediaItems){
					log(mediaItem, story)
                    // log(" ")
                    
                    // log(" - mediaItem.id = " + mediaItem.id + "_" + mediaItem._id)
                    // log(" - mediaItem.type = " + mediaItem.type)
                    let isTypeOK = true;
                    
                    // Ignore media items with no type, or 'archive' type
                    if(!mediaItem.type || mediaItem.type === MediaType.archive) isTypeOK = false;

                    // Ignore games + comics
                    if(mediaItem.type === MediaType.game || mediaItem.type === MediaType.comic)  isTypeOK = false;

                    // Sometimes we manually override, e.g. for certain comics
                    if(!isTypeOK && Content.isMediaItemAudienceLearners(mediaItem, story)) {
						isTypeOK = true;
					}
                    // log(" - mediaItem.type = " + mediaItem.type)
                    // log(" - isTypeOK = " + isTypeOK)

                    // Add game to list
                    if(mediaItem.type === MediaType.game){
                        this.addGameItem(topic, mediaItem)
                    }

                    // Add to list
                    else if(mediaItem.type === MediaType.comic){
                        this.addComicItem(topic, mediaItem)
                    }

                    // Add to list
                    else if(mediaItem.type === MediaType.archiveText){
                        this.addArchiveTextItem(topic, mediaItem)
                    }

                    // Add to list
                    else if(mediaItem.type === MediaType.audio){
                        this.addAudioItem(topic, mediaItem)
                    }

                    // Add to list
                    else if(mediaItem.type === MediaType.script){
                        this.addScriptItem(topic, mediaItem)
                    }

                    // Add to list
                    else if(mediaItem.type === MediaType.archiveSound){
                        this.addArchiveSoundItem(topic, mediaItem)
                    }

                    if(!isTypeOK) continue;

                    // log(" - mediaItem = ", mediaItem)
                    let tab = "item";                    
                    if(mediaItem.type === MediaType.archiveSound) tab = "sounds";
                    else if(mediaItem.type === MediaType.archiveText) tab = "library";

                    if(mediaItem.type === MediaType.video){
                        this.addVideoItem(topic, mediaItem)
                    }

                            

                    // fragAfterLang = `topic/${topic.topicId}/${tab}/${mediaItem.id}`
                    fragAfterLang = `topic/${topic.topicId}/${tab}/${mediaItem.id}_${mediaItem._id}`;
                    gaUrl = `${fragBeforeLang}${fragAfterLang}`;
                    enUrl = `${fragBeforeLang}en/${fragAfterLang}`;
                    str += this.getItemUrls(gaUrl, enUrl);
                            
                }       
            }

            this.setState(state => {
                return {
                    sitemap: state.sitemap + str,
                }
            })
            
            

            
        }

        

        // ---------------------------------------------
        // KIDS
        
        if(Content.isStoryAudienceKids(story)){
            // MEDIA ITEMS
            if(topic.mediaItems){
                for(let mediaItem of topic.mediaItems){

                    let isTypeOK = true;
                    
                    // Ignore media items with no type, or 'archive' type
                    if(!mediaItem.type || mediaItem.type === MediaType.archive) isTypeOK = false;

                    // Ignore archive text, archive sounds
                    if(Topic.MEDIA_GRID_SORT_ORDER_BY_AUDIENCE.kids.indexOf(mediaItem.type as MediaType) === -1 )  isTypeOK = false;

                    
                    

                    if(!isTypeOK) continue;

                    // Add movie to list
                    if(mediaItem.type === MediaType.video){
                        this.addVideoItem(topic, mediaItem)
                    }
                    // Add game to list
                    else if(mediaItem.type === MediaType.game){
                        this.addGameItem(topic, mediaItem)
                    }

                    // Add comic to list
                    else if(mediaItem.type === MediaType.comic){
                        this.addComicItem(topic, mediaItem)
                    }

                    // Add comic to list
                    else if(mediaItem.type === MediaType.audio){
                        this.addAudioItem(topic, mediaItem)
                    }
                    

                    const fragBeforeLang = `https://bocan.tv/`;
                    const fragAfterLang = `a/kids/topic/${topic.topicId}/item/${mediaItem.id}_${mediaItem._id}`;
                    const gaUrl = `${fragBeforeLang}${fragAfterLang}`;
                    const enUrl = `${fragBeforeLang}en/${fragAfterLang}`;
                    str += this.getItemUrls(gaUrl, enUrl);

                    

                            
                }   
            }

            
        }


        return str;
    }


    getGroup(rowId:string, sectionId:string):string{

        // Home (Learners)
        const fragBeforeLang = `https://bocan.tv/`;
        const fragAfterLang = `group/${sectionId}/${rowId}`;
        const gaUrl = `${fragBeforeLang}${fragAfterLang}`;
        const enUrl = `${fragBeforeLang}en/${fragAfterLang}`;
        const str = this.getItemUrls(gaUrl, enUrl);
        return str;
    }

    getVideoItems():string{
        let str = '';
        for(let key in this.state.videoItems){
            const item = this.state.videoItems[key];
            str += `${item.topicId}\t${key}\t${item.titleEn}\t${item.titleGa}\t${item.descriptionEn}\t${item.descriptionGa}\n`
        }
        return str;
    }

    getGameItems():string{
        let str = '';
        for(let key in this.state.gameItems){
            const item = this.state.gameItems[key];
            str += `${item.topicId}\t${key}\t${item.titleEn}\t${item.titleGa}\t${item.descriptionEn}\t${item.descriptionGa}\n`
        }
        return str;
    }

    getComicItems():string{
        let str = '';
        for(let key in this.state.comicItems){
            const item = this.state.comicItems[key];
            str += `${item.topicId}\t${key}\t${item.titleEn}\t${item.titleGa}\t${item.descriptionEn}\t${item.descriptionGa}\n`
        }
        return str;
    }

    getAudioItems():string{
        let str = '';
        for(let key in this.state.audioItems){
            const item = this.state.audioItems[key];
            str += `${item.topicId}\t${key}\t${item.titleEn}\t${item.titleGa}\t${item.descriptionEn}\t${item.descriptionGa}\n`
        }
        return str;
    }

    getArchiveTextItems():string{
        let str = '';
        for(let key in this.state.archiveTextItems){
            const item = this.state.archiveTextItems[key];
            str += `${item.topicId}\t${key}\t${item.titleEn}\t${item.titleGa}\t${item.descriptionEn}\t${item.descriptionGa}\n`
        }
        return str;
    }

    getArchiveSoundItems():string{
        let str = '';
        for(let key in this.state.archiveSoundItems){
            const item = this.state.archiveSoundItems[key];
            str += `${item.topicId}\t${key}\t${item.titleEn}\t${item.titleGa}\t${item.descriptionEn}\t${item.descriptionGa}\n`
        }
        return str;
    }

    getScriptItems():string{
        let str = '';
        for(let key in this.state.scriptItems){
            const item = this.state.scriptItems[key];
            str += `${item.topicId}\t${key}\t${item.titleEn}\t${item.titleGa}\t${item.descriptionEn}\t${item.descriptionGa}\n`
        }
        return str;
    }

    getSitemapStart():string{
        // log(" - content = ", Content.data?.stories)
        const data = Content.data;
        if(!data) return '';
    
        
        let str = 
`<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xhtml="http://www.w3.org/1999/xhtml">
`

        // Home (Learners)
        const fragBeforeLang = `https://bocan.tv/`;
        let fragAfterLang = ``;
        let gaUrl = `${fragBeforeLang}`;
        let enUrl = `${fragBeforeLang}en`;
        str += this.getItemUrls(gaUrl, enUrl);

        // Home (Kids)
        fragAfterLang = `a/kids`;
        gaUrl = `${fragBeforeLang}${fragAfterLang}`;
        enUrl = `${fragBeforeLang}en/${fragAfterLang}`;
        str += this.getItemUrls(gaUrl, enUrl);

        // Home (Toddlers)
        fragAfterLang = `a/toddlers`;
        gaUrl = `${fragBeforeLang}${fragAfterLang}`;
        enUrl = `${fragBeforeLang}en/${fragAfterLang}`;
        str += this.getItemUrls(gaUrl, enUrl);

        // Home (Learners credits)
        fragAfterLang = `#credits`;
        gaUrl = `${fragBeforeLang}`;
        enUrl = `${fragBeforeLang}en`;
        str += this.getItemUrls(gaUrl, enUrl);


        // Groups
        const audiences:ContentAudience[] = data.audiences;
        if(audiences){
            for(let audience of audiences){
                if(audience.id === AudienceId.learners){
                    const sections:ContentSection[] = audience.home.sections;
                    for(let section of sections){
                        if(section.rows){
                            for(let row of section.rows){
                                const sectionId = row.uiSectionId;
                                const rowId = row.id
                                str += this.getGroup(rowId, sectionId)
                            }
                        }
                    }
                }
                
            }
        }
        return str;
    }

       

    getSitemapEnd():string{
        // Close XML
        return `</urlset>`;
    }
    

    render(){

        
      

        return(

            <div id="sitemap-screen">
                <Nav
                    theme={NavTheme.dark}
                    showLogo={true}
                />

                <div className="main-column">
                    <div className="main-column-content content">

                        
                        <div className='status'>
                            {this.state.loadState === LoadState.loading &&
                                <Spinner
                                    className='spinner'
                                />
                            }

                            <p>Topics loaded: {Object.keys(this.state.topics).length} / {this.state.totStories}</p>
                            <p>Links: {this.state.totLinks}</p>
                            <p>Films: {Object.keys(this.state.videoItems).length}</p>
                            <p>Games: {Object.keys(this.state.gameItems).length}</p>
                            <p>Comics: {Object.keys(this.state.comicItems).length}</p>
                            <p>Audio: {Object.keys(this.state.audioItems).length}</p>
                            <p>Archive texts: {Object.keys(this.state.archiveTextItems).length}</p>
                            <p>Archive sounds: {Object.keys(this.state.archiveSoundItems).length}</p>
                            <p>Scripts: {Object.keys(this.state.scriptItems).length}</p>
                            
                            {this.state.loadState === LoadState.error &&
                                <p>Error loading data</p>
                            }
                        </div>

                        
                        

                        
                        <form className='sitemap-form'>
                            <h3 className="smallcaps">Sitemap.xml</h3>
                            <textarea readOnly={true} value={this.state.sitemap}/> 
                        </form>

                        <form className='sitemap-form movies'>
                            <h3 className="smallcaps">Video items (TSV)</h3>
                            <textarea readOnly={true} value={this.getVideoItems()}/> 
                        </form>

                        <form className='sitemap-form games'>
                            <h3 className="smallcaps">Game items (TSV)</h3>
                            <textarea readOnly={true} value={this.getGameItems()}/> 
                        </form>

                        <form className='sitemap-form comics'>
                            <h3 className="smallcaps">Comic items (TSV)</h3>
                            <textarea readOnly={true} value={this.getComicItems()}/> 
                        </form>

                        <form className='sitemap-form audio-items'>
                            <h3 className="smallcaps">Audio items (TSV)</h3>
                            <textarea readOnly={true} value={this.getAudioItems()}/> 
                        </form>

                        <form className='sitemap-form archive-text-items'>
                            <h3 className="smallcaps">Archive text items (TSV)</h3>
                            <textarea readOnly={true} value={this.getArchiveTextItems()}/> 
                        </form>

                        <form className='sitemap-form archive-sound-items'>
                            <h3 className="smallcaps">Archive sound items (TSV)</h3>
                            <textarea readOnly={true} value={this.getArchiveSoundItems()}/> 
                        </form>

                        <form className='sitemap-form script-items'>
                            <h3 className="smallcaps">Script items (TSV)</h3>
                            <textarea readOnly={true} value={this.getScriptItems()}/> 
                        </form>
                        
                    </div>
                </div>

                <Footer/>
            </div>
        )
    }


}