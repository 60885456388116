import './_styles.scss';
import React from 'react';
// import MediaItem from "../MediaItem/MediaItem";
import ModalMedia from './ModalMedia';
import {TopicData, MediaItemData} from '../../data/Topic';
import Router from '../../router/Router';
// import Audience from '../../tools/Audience';
// import Tools from '../../tools/Tools';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

type Props = {
    topicData:TopicData,
    itemId?:string,
    
    // This is used to differentiate between two scenarios:
    // 1) When user opens an item manually
    // 2) When an item is opened automatically using the url
    // The router uses this information to choose to goBack (when manually opened) or not (when automatic) when the user closes the modal
    goBackOnModalClose?:boolean
}

type State = {
    isModalOpen:boolean,
    itemData?:MediaItemData,
}

export default class ModalMediaController extends React.Component<Props, State>{

    

   
   
    constructor(props:Props){
        super(props);

        log("ModalMediaController()")

       
        let itemData:MediaItemData|undefined = this.props.itemId ? this.getItemData(this.props.itemId) : undefined
        log(" - this.props.itemId = " + this.props.itemId)
        log(" - itemData = " + itemData)

        this.state = {
            isModalOpen: itemData ? true : false,
            itemData: itemData
            
        }
        
    }

    getItemData(itemId:string):MediaItemData|undefined{
        log("ModalMediaController.getItemData()");
        if(!this.props.topicData.mediaItems) return undefined;
        for(let itemData of this.props.topicData.mediaItems){
            if(itemData.id === itemId) return itemData
        }
        return undefined
    }

    componentDidMount(){
        log("ModalMediaController.componentDidMount()");
    }

    componentDidUpdate(){
        log("ModalMediaController.componentDidUpdate()");
        log(" - itemId = " + this.props.itemId);
        
        if(this.props.itemId){
            const shouldOpenModal:boolean = !this.state.itemData || (this.state.itemData && this.state.itemData.id !== this.props.itemId);
            if(shouldOpenModal){
                this.openModal(this.props.itemId); 
            }
            
        }
        
        else{
            if(this.state.isModalOpen){
                this.closeModal();
            }
        }
    }


    openModal(itemId:string){
        log("ModalMediaController.openModal()")
        let itemData = this.getItemData(itemId); 
        if(itemData){
            this.setState({
                itemData: itemData,
                isModalOpen: true
            })
        }
    }

    closeModal(){
        log("ModalMediaController.closeModal()")
        this.setState({
            isModalOpen:false,
            itemData:undefined
        })
    }

  

    handleModalClose = async () => {
        log("ModalMediaController.handleModalClose()")
        log(" - goBackOnModalClose = " + this.props.goBackOnModalClose)
        Router.navigateBackFromMediaItem(this.props.goBackOnModalClose)
        
        // this.goBackOnModalClose = false;
    }



    render(){
        log("ModalMediaController.render()")
        if(this.state.itemData){
        
            return (
                <ModalMedia
                    isOpen={this.state.isModalOpen}
                    onClose={this.handleModalClose}
                    topicData={this.props.topicData}
                    mediaItemData={this.state.itemData}
                />
            )
        }

        return null;
        
    }
}