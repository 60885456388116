// CSS
import './_styles.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {NavigateNext } from '@material-ui/icons';

// IMPORTS
import React from 'react';

import Slider from "react-slick";
import App from '../../App';
import Router from '../../router/Router';
import Lang from '../../tools/Lang';
import SectionItem from '../SectionItem/SectionItem';
import type {ContentSectionRow, ContentStory, ContentSectionRowStrip} from '../../data/Content';
import Audience from '../../tools/Audience';
import Config from '../../config/Config';



interface Props{
    data:ContentSectionRow;
    onItemClick:Function;
    index:number;
}

interface State {
    image1Loaded:boolean;
    image2Loaded:boolean;
}


let settings = {
    swipe:true,
    // swipeToSlide:true,
    slidesToShow: 6,
    slidesToScroll: 6,
    infinite: false,
    arrows:true,
    // className:'row-inner',
    // dots: true,


    // Breakpoints should correspond to those in the breakpoints.scss file
    // I.e. the $<breakpointId>-upper-boundary values
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                // swipe:true,
                // swipeToSlide:true,
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: false,
                arrows:true,
            }
        },
        {
            breakpoint: 1280,
            settings: {
                // swipe:true,
                // swipeToSlide:true,
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: false,
                arrows:true,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                // swipe:true,
                // swipeToSlide:true,
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: false,
                arrows:true,
            }
            },
            {
                breakpoint: 640,
                
                settings: {
                    // swipe:true,
                    // swipeToSlide:true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    arrows:false,  
                }
            },

            
            {
                breakpoint: 420,
                
                settings: {
                    // swipe:true,
                    // swipeToSlide:true,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    arrows:false,  
                }
            },
            

            {
                breakpoint: 360,
                
                settings: {
                    // swipe:true,
                    // swipeToSlide:true,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    arrows:false,  
                }
            },
            {
                breakpoint: 300,
                
                settings: {
                    // swipe:true,
                    // swipeToSlide:true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows:false,  
                }
            }
    ]
    
}

type SliderInfo = {
	ref:Slider|null,
	initialSlide:number,
}


export default class SectionRow extends React.Component<Props, State>{

    isDragging:boolean = false;
    
    isLoggingEnabled:boolean = false;
    
    sliders:SliderInfo[] = [];

    hasSetInitiaDraggingValue:boolean = false;

    imagePath:string = Config.getImagePath();

    isUnmounted:boolean = false;

    constructor(props:Props){
        super(props);
        this.state = {
            image1Loaded:false,
            image2Loaded:false
        }
        
    }
    log(...args:any){if(this.isLoggingEnabled) console.log(...args);}


    componentDidMount(){
        // this.log(" ")
        // this.log("-------")
        // this.log("SectionRow.componentDidMount()")
        // this.log(" id = " + this.props.data.id)
        // this.log(" - this.slideNum = " + this.slideNum)
        // this.log(" - this.isDragging = " + this.isDragging)
        // Set the initial slide on mount.
        // This is required on top of settings.initialSide due to weirdness on remounting when coming back to the page.
        // See known issue: https://github.com/akiran/react-slick/issues/484
		if (this.sliders.length===0) {
			this.sliders = this.props.data.strips?.map(
				(strip, stripIndex) => (
					{
						ref: null,
						initialSlide: this.getSlideNum(stripIndex)
					}
				))
				|| []
		}
        this.sliders?.forEach(sliderInfo => sliderInfo.ref?.slickGoTo(sliderInfo.initialSlide, true))
        this.isDragging = false;
    }


    componentWillUnmount(){
        this.isUnmounted = true;
    }

    

    /* -------------------------------------------------
    * METHODS
    */
    saveSlideNum(stripIndex:number, num:number){
        // this.log("SectionRow.saveSlideNum()")
        // this.log(" - num = " + num)
        // this.log(" - this.carouselId = " + this.carouselId)
        // let carousels = {...App.state.carousels};
        // carousels[this.carouselId] = num;
        // this.setState({slideNum:num})
        // App.setState({
            // carousels: carousels
        // })
		this.setSlideNum(stripIndex, num)
    }


    getImageSource(num:number){
        const path:string = this.imagePath + "rows/" +  this.props.data.id + "." + num + ".png"
        // log(" - path = " + path)
        return path;
    }


    /* -------------------------------------------------
    * GETTERS & SETTERS
    */
    
    getCarouselId(stripIndex:number):string{
        // return Router.audienceIdFromRoute + "_" + this.props.data.id;
        return `${Audience.id}_${this.props.data.id}__strip${stripIndex}`;
    }

    getSlideNum(stripIndex:number):number{
        return App.state.carousels[this.getCarouselId(stripIndex)] || 0;
    }

    setSlideNum(stripIndex:number, num:number){
		this.log(`SectionRow.setSlideNum(${stripIndex}, ${num}) carouselId ${this.getCarouselId(stripIndex)}`)

        let carousels = {...App.state.carousels};
        carousels[this.getCarouselId(stripIndex)] = num;
        App.setState({
            carousels: carousels
        });
    }

    get shouldShowSeeAllButton():boolean{
        // log("SectionRow.shouldShowSeeAllButton()")
        // log(" this.props.data.items.length =" + this.props.data.items.length)
        // log(" this.props.data.allItems.length =" + this.props.data.allItems.length)
        return true;
        // let should = false;
        // if(this.props.data.items.length < this.props.data.allItems.length){
        //     should = true;

        // }
        // return should;
    }


    /* -------------------------------------------------
    * HANDLERS
    */
    
    /*
    * Bug in Slick where dragging a row fires a click event
    * https://github.com/akiran/react-slick/pull/1483
    */
    handleBeforeChange = (current:number, next:number) => {
        this.log(" ")
        this.log("-------")
        this.log("SectionRow.handleBeforeChange()");
        this.log(" id = " + this.props.data.id)
        // this.log(" - current = " + current)
        // this.log(" - next = " + next)
        this.log(" - setting isDragging to true")
        this.isDragging = true;
        
        // Bug in Slick were afterChange is not always called.
        // Specically when navigating back, then using the method slickGoTo(num) to set the initial slide.
        // The hacky solution is set the isDragging value when slickGoTo() calls beforeChange()
        // setTimeout(()=>{
        //     this.isDragging = false
        // }, 1000)
        if(!this.hasSetInitiaDraggingValue){
            this.isDragging = false;
            this.hasSetInitiaDraggingValue = true
            // this.log(" - setting isDragging to false")
        }
    }

    handleAfterChange = (stripIndex:number, currentSlide:number) => {
        this.log(" ")
        this.log("-------")
        this.log("SectionRow.handleAfterChange()")
        this.log(" id = " + this.props.data.id)
        // log(" - currentSlide = " + currentSlide)
        
        this.log(" - setting isDragging to false")
        this.isDragging = false;
        

        this.saveSlideNum(stripIndex, currentSlide);
        
    }
    
    handleItemClick = (itemData:ContentStory, item:SectionItem, event:any, mediaItemId?:string) => {
        this.log(" ")
        this.log("-------")
        this.log("SectionRow.handleItemClick() itemId = " + itemData.id)
        this.log(" id = " + this.props.data.id)
        this.log(" - isDragging = " + this.isDragging)
        if(event) event.preventDefault();    

        if(this.isDragging){ 
            this.log(" - isDragging = true")
            
        }else{
            // this.log(" - sectionId = " + this.props.data.sectionId)
            // this.log(" - data.path = " + this.props.data.path)
            // this.log(" - data.id = " + this.props.data.id)
            this.isDragging = false;
            this.props.onItemClick(itemData, item, this.props.data, event);
        }
        this.isDragging = false;

        
    }

    handleTitleClick = (event:any) => {
        // log("SectionRow.handleTitleClick()")
        // log(" - sectionId = " + this.props.data.sectionId)
        event.preventDefault();  
        
        Router.navigateToGroup(this.props.data.uiSectionId, this.props.data.id);
    }

    
    
    /* -------------------------------------------------
    * RENDER
    */

	renderStrip = (strip:ContentSectionRowStrip, stripIndex:number):any => {
		let sliderInfo = this.sliders[stripIndex]
		if (!sliderInfo) {
			sliderInfo = this.sliders[stripIndex] = {ref:null, initialSlide: 0}
		}
       // Set initial slide
	   sliderInfo.initialSlide = this.getSlideNum(stripIndex)

		this.log(`renderStrip() row ${this.props.data.id} strip ${stripIndex} initialSlide=${sliderInfo.initialSlide}`, strip)
		const stripTitle = strip.title ? Lang.t(strip.title) : ""
		

	//    this.log(`row ${this.props.data.id} slider ${stripIndex}:`, this.sliders, `index: ${stripIndex}`)


		return <div className="rowStrip" key={`row${this.props.data.id}__strip${stripIndex}__container`}>
			{stripTitle &&
				<h5>{stripTitle}</h5>
			}
			<Slider 
				ref={(ref)=>{ this.sliders[stripIndex].ref = ref} }
				beforeChange={this.handleBeforeChange}
				afterChange={(currentSlide) => this.handleAfterChange(stripIndex, currentSlide)}
				{...settings }
				initialSlide = {sliderInfo.initialSlide}
				key={`row${this.props.data.id}__strip${stripIndex}__slider`}
			>
				{this.renderItems(strip.items, stripIndex)}
			</Slider>
		</div>
	}

    renderItems(items:ContentStory[], stripIndex=0):any{
        // this.log("SectionRow.renderItems()")
        // this.log(" - id = " + this.props.data.id)
        // this.log(" - groupId = " + this.props.data.groupId)
        // this.log(" - uiSectionId = " + this.props.data.uiSectionId)
        // this.log(" - sectionId = " + this.props.data.sectionId)
        // this.log(" - aspect = " + this.props.data.aspect)
        return items.map((item, index) => 
            <SectionItem
                key={"section_row_item__" + this.props.data.id + "__" + stripIndex + "__ " + item.id + "__" + index}
                data={item}
                groupId={this.props.data.groupId}
                sectionId={this.props.data.sectionId}
                onClick={this.handleItemClick}
                rowTitle={this.props.data.title}
                switchTitle={this.props.data.switchTitle}
                aspect={this.props.data.aspect}
                mediaItemId={item.mediaItemId}
                // mediaType={item.mediaType}
            />
        );
    }

    handleImage1Load = () => {
        if(this.isUnmounted) return;
        this.setState({image1Loaded:true})
    }

    handleImage2Load = () => {
        if(this.isUnmounted) return;
        this.setState({image2Loaded:true})

    }

    renderKidsHeader():any{
        

        // const langFrag = Lang.getUrlLangFrag();

        let image1Class = "row-header-image row-header-image-1";
        if(!this.state.image1Loaded) image1Class += " unrevealed";

        let image2Class = "row-header-image row-header-image-2";
        if(!this.state.image2Loaded) image2Class += " unrevealed";

        return (
            <div className="row-header" key={"section_row_" + this.props.data.id}>
                {this.props.data.images && this.props.data.images.left &&
                    <img 
                        src={this.getImageSource(1)} 
                        className={image1Class}
                        alt=""
                        onLoad={this.handleImage1Load}
                    />
                }
                
                {this.props.data.images && this.props.data.images.right &&
                    <img 
                        src={this.getImageSource(2)} 
                        className={image2Class}
                        alt=""
                        onLoad={this.handleImage2Load}
                    />
                }
                    
                {/* <a href={langFrag + "group/" +this.props.sectionId + "/" + this.props.data.id} onClick={this.handleTitleClick}> */}
                    <h2 className="row-title"  id={this.props.data.id}>
                        {Lang.tc(this.props.data.title)}
                       
                    </h2>
                    
                {/* </a> */}

            </div>
        )
    }

    render(){
        // log(" - id = " + this.props.data.id)
        // log(" - groupId = " + this.props.data.groupId)
        // this.log(" ")
        // this.log("------------------------")
        // this.log("SectionRow.render()");
        // log("langFrag = " + langFrag)
        // this.log(" - this.props.data.id = ", this.props.data.id)
        // log(" - Audience.id = " + Audience.id)
        // log(" - App.state.audienceId = " + App.state.audienceId)
        // Router.logPath();

        // this.log(" - this.carouselId = " + this.carouselId)
        // this.log(" - this.slideNum = " + this.slideNum)
        
        const langFrag = Lang.getUrlLangFrag();
        
        let containerClass = "row-container";
        if(this.props.index === 0) containerClass += " row-container-first";

        return(
            
            <div className={containerClass}>
        
                {/* ADULTS HEADER */}
                {Audience.isAudienceLearners &&

                    <div className="row-header">
						<div className="row-anchor"><a id={this.props.data.id}></a></div>
                        <a href={langFrag + "group/" +this.props.data.sectionId + "/" + this.props.data.id} onClick={this.handleTitleClick}>
                            <h2 className="row-title" id={this.props.data.id}>{Lang.tc(this.props.data.title)}</h2>
                            {this.shouldShowSeeAllButton &&
                                <div className="but type2 more-but more-but-right">
                                    {/* <span>{Lang.t("section.seeAll")}</span> */}
                                    <NavigateNext className="icon"/>
                                </div>
                            }
                        </a>
                        

                    </div>
                }

                {/* KIDS HEADER */}
                {Audience.isAudienceKids &&
                    this.renderKidsHeader()
                }

                {/* ITEMS */}
                <div className="row">
                    <div className="row-inner">

						{this.props.data.strips?.map(this.renderStrip)}
                    </div>
                </div>
            </div>
        
        )
    }
}



