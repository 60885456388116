import './_styles.scss';
import React from 'react';
// import App from '../../App';
// import Config from '../../config/Config'
import Lang from '../../tools/Lang';
import {LangString} from '../../data/Content';
// import Section from '../Section/Section'
// import SectionRow from '../SectionRow/SectionRow';
// import SectionItems from '../SectionItems/SectionItems';
// import {ExpandMore } from '@material-ui/icons';
// import Expander from '../Expander/Expander';
// import type {ContentSection} from '../../data/Content';
// import Audience from '../../tools/Audience';
// import Flash, {LangMode} from '../Flash/Flash';
// import Router from '../../router/Router';
// import Tools from '../../tools/Tools';
// import App from '../../App';
// import type {FlashHistory} from '../../App';
// import {ContentSectionId} from '../../data/Content';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}


type Props = {
    sectionId:string,
    title:LangString,
    subtitle?:LangString,
}



export default class SectionHeaderToddlers extends React.Component<Props>{
    
    data:any = {
        toddlersFilms:{
            images:{left:false, center:true, right:true}
        },
        toddlersMusic:{
            images:{left:true, center:false, right:true}
        },
        toddlersComics:{
            images:{left:true, center:false, right:true}
        }
        
    }
    constructor(props:Props){
        super(props);

        log("SectionHeaderToddlers()");
        log(" - sectionId = ", this.props.sectionId);
    }
    
  

    render(){
        log("SectionHeaderToddlers.render()");
        const sectionId = this.props.sectionId;
        log(" - sectionId = " + sectionId)

        let data = this.data[this.props.sectionId];
        if(!data) return null;
      
        const imageFolder:string = "/images/ui/section-banners/toddlers/" + this.props.sectionId + "/";
        
        return(
        
            <div className={"section-header"} id={"section-header-" + this.props.sectionId}>
                
                
                
                <img src={imageFolder + "bg.png"} alt="" className="bg"/>
                
                
                <div className="images">
                    {data.images.left &&
                        <div className="image image-left">
                            <img src={imageFolder + "left.png"} alt=""/>
                        </div>
                    }
                    {data.images.right &&
                        <div className="image image-right">
                            <img src={imageFolder + "right.png"} alt=""/>
                        </div>
                    }
                    {data.images.center &&
                        
                        <div className="image image-center-container">
                            <div className="image image-center">
                                <img src={imageFolder + "center.png"} alt=""/>
                            </div>
                        </div>
                    }
                </div>

                <div className="section-header-text">
                    <h1 className="section-title" id={"section-title-" + this.props.sectionId}>
                        <span>{Lang.tc(this.props.title)}</span>
                    </h1>
                    {this.props.subtitle &&
                        <h4 className="section-subtitle"><i>{Lang.tc(this.props.subtitle)}</i></h4>
                    }
                </div>
                
            </div>
        )
    
    }
}





