/*
	Provide the data from the CMS server.

	So far we just have getTopic()


*/
import type {TopicData} from './Topic'
import Config from '../config/Config';
import Tools from '../tools/Tools';


//....................................
// EXAMPLE DATA 
// Only available in development environment

const USE_EXAMPLE_DATA_IN_DEV = true

const exampleData = {topics:[]}

tryLoadingExampleData()
//....................................



export default {

	/**
	 * 
	 * @throws Error	Could be a network, server, or data corruption errror.
	 * 					If the topic isn't found, we return null with no error thrown.
	 */
	async getTopic(topicIdNumber:string):Promise<TopicData|null> {

		const url:string  = Config.getTopicPath(topicIdNumber, topicIdNumber);
		// console.log(`DataProvider.getTopic(${topicIdNumber}) from ${url}`);

		// Try to use example data if it's available (only in dev)
		const exampleTopic:TopicData|undefined = exampleData.topics.find(  (topic:any) => topic._id === topicIdNumber)
		if (exampleTopic) {
			console.log(`DataProvider.getTopic(${topicIdNumber}): Used example topic`)
			return exampleTopic;
		}



		let response:Response = await fetch(url);
		if (response.ok) {
			try {
				const topicData = await response.json() as TopicData | null;
				return topicData;
			}
			catch(err) {
				console.log(`DataProvider.getTopic JSON decode error:`, err) 
				throw Error("Data error")
			}
		}
		else {
			console.log(`DataProvider.getTopic: Bad response (${response.status}) for url ${url}`)
			throw Error(`Server error ${response.status}`)
		}
	}
}



async function tryLoadingExampleData() {
	const allowed = Tools.isDev && USE_EXAMPLE_DATA_IN_DEV
	if (!allowed) return;

	try {
		const topics = await require('./example-topics.json')
		if (topics) {
			exampleData.topics = topics
			console.log(`DataProvider.loadExampleData: Loaded ${topics.length} example topic(s)`)
		}
	}
	catch(err) {
		console.log(err)
	}
}