import './_styles.scss';
import {Component} from 'react';

interface Props{
    name:string,
    classes:string,
    placeholder?:string,
    id:string,
    focussed:boolean,
    onChange?:Function,
    initialValue?:string,
    selectOnFocus?:boolean,
}


// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

export default class TextInput extends Component<Props>{
    
    fieldRef:HTMLInputElement|null = null;

    initialValue:string|undefined;
    
    constructor(props:Props){
        super(props);
        
        this.initialValue = this.props.initialValue;
    }

    get value():string{
        return this.fieldRef ? this.fieldRef.value : '';
    }
    set value(val:string){
        if(this.fieldRef) this.fieldRef.value = val;
    }

    select(){
        log("TextInput.select()")
        if(this.fieldRef && this.fieldRef.select) this.fieldRef.select()
        
    }

    handleChange = (evt:any)=>{
        log("TextInput.handleChange()")

        // Populate the fieldRef
        if(!this.fieldRef && evt.target) this.fieldRef = evt.target;

        // Send back the input value
        if(this.props.onChange){
            if(!evt || !evt.nativeEvent || !evt.nativeEvent.target) return;
            this.props.onChange(evt.nativeEvent.target.value, evt)
        }
    }

    handleFocus = (evt:any) => {
        log("TextInput.handleFocus()")

        // Populate the fieldRef
        if(!this.fieldRef && evt.target) this.fieldRef = evt.target;

        // Select now?
        if(this.props.selectOnFocus){
            this.select()
        }
    }

    render(){
        return(
            <input 
                name={this.props.name}
                type="text"
                className={this.props.classes}
                placeholder={this.props.placeholder ? this.props.placeholder : undefined}
                id={this.props.id}
                autoFocus={this.props.focussed}
                onKeyUp={this.handleChange}
                defaultValue={this.initialValue}
                ref={(ref)=>{if(ref) this.fieldRef = ref}}
                onFocus={this.handleFocus}
            />
        )
    }
}