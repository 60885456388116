import React from "react";
//@ts-ignore
import $ from "jquery";

// Logging
const isLoggingEnabled = false;
//@ts-ignore
function log(...args){if(isLoggingEnabled) console.log(...args);}

type Props = {
    options:any,
    className?:string,
    style?:any,
    children?:any,
}
export default class Turn extends React.Component<Props>{

    

    ref:any;


    componentDidMount(){
        log("Turn.componentDidMount()")
        if (!this.ref || !$(this.ref).turn){
            console.log (" no turn() function");
            return;
        }
        
        // log( $(ref).turn)
        $(this.ref).turn({...this.props.options});
    }
   

    render(){
        log("Turn.render()")
        return (
            <div
                className={this.props.className}
                id="turn"
                style={Object.assign({}, this.props.style)}
                ref={el => {this.ref = el;}}
            >
                {this.props.children}
            </div>
        );
    }
}
