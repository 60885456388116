

import React from 'react';

// @ts-ignore
import $ from "jquery";
import "turn.js";
import _ from 'lodash';

import Turn from "./Turn";
import type {MediaItemData} from '../../data/Topic';
import type {ContentManualMediaItem, ContentManualMediaItemLangItem} from '../../data/Content';
import Lang from '../../tools/Lang';
import Tools from '../../tools/Tools';
import type {LangId} from '../../tools/Lang';
import Snacks from '../Snacks/Snacks';
import Config from '../../config/Config';
import {BootstrapTooltip} from '../BootstrapTooltip/BootstrapTooltip';
import './_styles.scss';


// Logging
const isLoggingEnabled = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

type Props = {
    mediaItem?:MediaItemData,
    manualMediaItem?:ContentManualMediaItem,
    isMax:boolean,
    onMaximizePress?:Function,
    onMinimizePress?:Function
}

type State = {
    isSizeSet:boolean,
    isOnFrontCover:boolean,
    displayMode:string,
    isDestroyed:boolean,
    page:number,

}

enum DisplayMode {
    single = "single",
    double = "double"

}
export default class ModalMediaComic extends React.Component<Props, State>{
    
    pages:string[] = [];

    pagesInitial:string[] = [];

    isUnmounted:boolean = false;
    
    NUM_PAGES_PRELOADED:number = 3;

    isOpened:boolean = false;
    
    options = {
        width: 800,
        height: 600,
        autoCenter: true,
        // display: String(DisplayMode.double),
        display: String(DisplayMode.single),
        duration: 600,
        acceleration: true,
        elevation: 50,
        gradients: !$.isTouch,
        page:1,
        // cornerSize:300,
        
        when: {
            
            start: ()=>{
                this.handleStart()
            },
            turned: (e:any, page:any) => {
                // log("Current view: ", $(this).turn("view"));
                this.handleTurned(page)
          },
          
        }
    };

    
    // Duration of resize animation
    // Should be same as in styles.magazine{transition: width 0.1s}
    RESIZE_DURATION_SECS:number = 0.1;
    

    data:ContentManualMediaItemLangItem|undefined;
    langId:LangId = Lang.langId;

    constructor(props:Props){
        
        super(props);

        log("ModalMediaComic()");
        
        this.state = {
            isSizeSet:false,
            isOnFrontCover:false,
            displayMode:this.options.display,
            isDestroyed:false,
            page:this.options.page,
        }
        

        // -----------------
        // Set the data and langId we'll be using
        let langId:LangId = Lang.langId;
        let data = this.props.manualMediaItem?.comic?.langs[langId];
        if(!data){
            langId = Lang.langIdInactive;
            data = this.props.manualMediaItem?.comic?.langs[langId];
            Snacks.add({
                message: Lang.t("error." + Lang.langId + "NotAvailable." + Lang.langId),
                // type: SnackType.success,
                secs:5,
            })
        }
        this.langId = langId;
        this.data = data;

        // -----------------

        this.populatePages();
        
    }
  
    componentDidMount(){
        log("ModalMediaComic.componentDidMount()");
        window.addEventListener("resize", this.handleResize);
        this.setSize(false);
        
        
    }

    componentDidUpdate(prevProps:Props, prevState:State){
        log("ModalMediaComic.componentDidUpdate()")
        
        if(prevProps.isMax !== this.props.isMax){
            this.setSize(true);
        }

        if(!prevState.isOnFrontCover && this.state.isOnFrontCover){
            log(" - now on front cover")
        }
    }

    componentWillUnmount(){
        this.isUnmounted = true;
        window.removeEventListener("resize", this.handleResize);
    }


    // -------------------------------------------------------------------------------------------------
    // GETTERS / SETTERS

    get totalPages():number{
        if(!this.data || !this.data.totalPages) return 0;
        return this.data.totalPages;
    }
    get isOnFrontCover():boolean{
        if(!$("#turn")) return false;
        const page:number = $("#turn").turn('page');
        return page === 1;
    }

    get isOnBackCover():boolean{
        if(!$("#turn")) return false;
        const page:number = $("#turn").turn('page');
        return page === this.totalPages;
    }
    get isOnPageAfterCover():boolean{
        return $("#turn").turn('page') === 2;
    }


    get isSingle():boolean{
        return (this.state.displayMode === DisplayMode.single);
    }

    get isDouble():boolean{
        return (this.state.displayMode === DisplayMode.double);
    }



    // -------------------------------------------------------------------------------------------------
    // METHODS

    isPageFrontCover(page:number):boolean{
        return page === 1;
    }

    isPageBackCover(page:number):boolean{
        return page === this.totalPages;
    }

    /**
     * Set size of the comic. This unreveals, then resizes, then reveals.
     * @param resizeTurnInstance - force the Turn instance to resize
     * @returns 
     */
     setSize = async (resizeTurnInstance:boolean = true, fade:boolean = true) => {
        log("ModalMediaComic.setSize()");
        if(this.isUnmounted) return;
        if(!this.data || this.data.width === undefined|| this.data.height === undefined) return;
        
        try{
            let container = document.querySelector(".media");
            
            let comicWidth = 0;
            let comicHeight = 0;
            let pageWidth = 0;
            if(container){
                // @ts-ignore
                comicWidth = container.offsetWidth - 40;
                pageWidth = this.isSingle ?  comicWidth: comicWidth / 2;
                const scale = pageWidth / this.data.width;
                comicHeight = this.data.height * scale
            }
            
            if(this.options.width === comicWidth && this.options.height === comicHeight){
                log(" - same size");
                this.setState({isSizeSet: true});
                return;
            }

            this.options.width = comicWidth;
            this.options.height = comicHeight;

            // Hide
            if(fade) this.fadeOut();
            this.setState({
                isSizeSet: true,
            });

             // Upate the viewer
            if(resizeTurnInstance){
                if($("#turn").turn){

                    // log(" - display = " + $("#turn").turn('display'));

                    $("#turn").turn('size', this.options.width, this.options.height);
                }
            }
          
            // Reveal
            await Tools.later(this.RESIZE_DURATION_SECS);
            if(fade) this.fadeIn();
        }
        catch(err){
            log("Error setting comic size: ", err)
        }
                
    }
    hide(){
        log(" ")
        log("-------------------")
        log("ModalMediaComic.hide()")
        $('.media').addClass("hide")
        log("-------------------")
        log(" ")
    }
    unhide(){
        log(" ")
        log("-------------------")
        log("ModalMediaComic.unhide()")
        $('.media').removeClass("hide")
        log("-------------------")
        log(" ")
    }
    fadeOut(){
        $('.media-comic-container').addClass("resizing")
    }

    fadeIn(){
        
        $('.media-comic-container').removeClass("resizing")
        
    }

    setFrontCover(){
        $('.media-comic-container').addClass("front-cover");
    }

    setBackCover(){
        $('.media-comic-container').addClass("back-cover")
        
    }

    setNotCover(){
        $('.media-comic-container').removeClass("front-cover");
        $('.media-comic-container').removeClass("back-cover");
    }

    

    populatePages(){
        log("ModalMediaComic.populatePages()")
  
        log(" - this.data = ", this.data)

        if(!this.props.mediaItem) return;
        if(!this.data) return;
        if(!this.langId) return;

        // No pages
        if(!this.totalPages) return;

        // No id
        const mediaItemId:string = this.props.mediaItem.id ? this.props.mediaItem.id : "";
        if(!mediaItemId) return;


        
        // Populate
        this.pagesInitial = [];

        const totInitialPages = this.state.page === undefined ? this.NUM_PAGES_PRELOADED : Math.max(this.state.page,this.NUM_PAGES_PRELOADED) ;
        log(" - totInitialPages = " + totInitialPages)

        for(let i = 1; i <= this.totalPages; i++){
            
            const url = Config.getComicPagePath(mediaItemId, this.langId, i);
            
            this.pages.push(url)

            if(i <= totInitialPages){
                log(" - adding page = " + i)
                this.pagesInitial.push(url)
            }
        }
    }
    
    async prevPage () {
        log("ModalMediaComic.prevPage()");
        // $("#turn").turn('previous');

        if(this.isOnBackCover){
            log(" - is on cover");
            this.setNotCover();
            await Tools.later(this.RESIZE_DURATION_SECS + 0.1)
        
            $("#turn").turn('previous');
        }else{
            $("#turn").turn('previous');
        }
    }

    async nextPage(){
        log("ModalMediaComic.nextPage()");
        // $("#turn").turn('next');
        

        if(this.isOnFrontCover){

            if(this.isUnmounted) return;
            this.setState({isOnFrontCover:false})
            this.setNotCover();
            await Tools.later(this.RESIZE_DURATION_SECS + 0.1)
            $("#turn").turn('next');
        }else{
            $("#turn").turn('next');
        }
    }

    goToPage = async  (num:number, delay:number = 0) => {
        log("ModalMediaComic.goToPage()");
        log(" - num = " + num);
        
        
        if(delay) await Tools.later(delay);
        
        if(this.isUnmounted) return;


        if(this.isPageFrontCover(num)){
            log(" - isPageFrontCover = true");
            this.setState({isOnFrontCover:true})
            this.setFrontCover();
        }

        else if(this.isPageBackCover(num)){
            log(" - isPageBackCover = true");
            this.setState({isOnFrontCover:false})
            this.setBackCover();
            
        }
        else{
            log(" - is inner page = true");
            this.setState({isOnFrontCover:false})
            this.setNotCover();
        }
        

        if(num > 0){
            $("#turn").turn('page', num);
        }

        this.setSize(true);

        await Tools.later(this.options.duration / 1000);
        this.fadeOut();
        await Tools.later(0.3);
        this.unhide();
        this.fadeIn();

        // this.setState({isHidden:false})
        
    }

    tryPreloadPages(pageCurrent:number) {
        log("ModalMediaComic.tryPreloadPages()");
        // log(" - pageCurrent = " + pageCurrent)
        let book =  $("#turn");
        if(!book || !book.turn || pageCurrent === undefined) return;

        const pageMin:number = pageCurrent + 1;
        const pageMax:number = Math.min(pageCurrent + this.NUM_PAGES_PRELOADED, this.totalPages);
        for (let page = pageMin; page <= pageMax; page++){
            if (!book.turn('hasPage', page)) {
                const url:string = this.pages[page-1];
                const element = $('<div class="page"/>').html('<img src="' + url + '" alt=""/>')
                
                // Add the page
                book.turn('addPage', element, page);
            }
        }
    }

    
    changeDisplay = async (mode:DisplayMode) => {
        log("ModalMediaComic.changeDisplay()");
        this.fadeOut()
        await Tools.later(0.31);
        
        this.hide();
        
        // Set initial page
        log(" - this.state.page = " + this.state.page)
        let initialPage = this.state.page === undefined ? 1 : this.state.page;
        
        // Force initialPage to be even if double mode
        if(!this.isSingle && initialPage > 1 && initialPage % 2 !== 0){
            log(" - initialPage -- ")
            initialPage = initialPage - 1;
        }
        
    
        log(" - initialPage = " + initialPage)
        this.setState({
            page: initialPage,
            displayMode: mode,
        },()=>{
            
            this.reinstantiate();
            this.goToPage(this.state.page, 0.5)

        })
    }

    reinstantiate(){
        if(this.isUnmounted) return;

        this.setState({isDestroyed: true}, () => {
            this.populatePages();
            this.setState({isDestroyed: false});
            
        });
    
        
    }

    // --------------------------------------------------------------------------------
    // EVENTS

    handlePrevPress = (evt:any) => {
        // log("ModalMediaComic.handlePrevPress()");
        // log(" - evt = ", evt);
        this.prevPage();
    }

    
    handleNextPress = async (evt:any) => {
        // log("ModalMediaComic.handleNextPress()");
        // log(" - evt = ", evt);
        this.nextPage();
        
    }


    handleStart = () => {
        log("ModalMediaComic.handleStart()")
    }

    handleCoverTap = () => {
        log("ModalMediaComic.handleCoverTap()")
    }

    
    handleTurned = (pageCurent:number)=>{
        log(" ");
        log("------------");
        log("ModalMediaComic.handleTurned()")
        log("pageCurent = " + pageCurent)
        
        if(this.isUnmounted) return;

        this.setState({
            page:pageCurent
        })

        // Add pages if needed
        this.tryPreloadPages(pageCurent);
        
        if(this.isOnFrontCover){
            this.setFrontCover()
            this.setState({isOnFrontCover:true})

            
        }
        else{

            this.setState({isOnFrontCover:false})

            if(this.isOnBackCover){
                this.setBackCover()
            }
            else{
                this.setNotCover();
            }

        }
        
    }

    

    handleResizeDebounced = _.debounce(_.bind(this.setSize, this), 500)
    handleResize = (event:any) => {
        this.handleResizeDebounced();
    }

    handleOpenButClick = () => {
        this.nextPage();
    }

    handleMaximizePress = () => {
        log("ModalMediaComic.handleMaximizePress()")
        if(this.props.isMax) return;
        if(this.props.onMaximizePress) this.props.onMaximizePress();
    }
    handleMinimizePress = () => {
        log("ModalMediaComic.handleMinimizePress()");
        if(!this.props.isMax) return;
        if(this.props.onMinimizePress) this.props.onMinimizePress();
    }

    handleSinglePress = () => {
        log("ModalMediaComic.handleSinglePress()")
        if(this.isSingle) return;
        this.changeDisplay(DisplayMode.single);
    }
    handleDoublePress = () => {
        log("ModalMediaComic.handleDoublePress()")
        if(this.isDouble) return;
        this.changeDisplay(DisplayMode.double);
    }

    // -----------------------------------------------------------
    // RENDER
   

    render(){
        log("ModalMediaComic.render()")
        if(!this.state.isSizeSet) return null;

        if(this.state.isDestroyed) return null;

        const display:string = this.state.displayMode;
        this.options.display = display;
        let containerClass:string = "media-comic-container " + display;
        // if(this.state.isHidden) containerClass += " hidden";
        
        const butSingleClass = this.isSingle ? "but active layout-single" : "but layout-single";
        const butDoubleClass = this.isDouble ? "but active layout-double" : "but layout-double";
        
        return (
            <div className={containerClass}>
                <div className='turn-container'>
                    <div className='turn-controls'>
                        
                        <div className='turn-controls-paging'>
                            <button aria-label={Lang.t("but.prevPage")} className='turn-controls-but-prev' onClick={this.handlePrevPress}/>
                            <button aria-label={Lang.t("but.nextPage")}className='turn-controls-but-next' onClick={this.handleNextPress}/>
                        </div>
                        {this.state.isOnFrontCover &&
                            <OpenBut
                                onPress={this.handleOpenButClick}
                            />
                        }
                        <div className='turn-controls-buts'>
                            <div className='but-group'>
                                {!this.props.isMax &&
                                    <BootstrapTooltip
                                        title={Lang.t("but.maximize")}
                                    >
                                        <button aria-label={Lang.t("but.maximize")} className="but" onClick={this.handleMaximizePress}>
                                            <img src="/images/ui/icon-maximize-dark.png" className="but-icon" alt=""/>
                                        </button>
                                    </BootstrapTooltip>
                                }

                                {this.props.isMax &&
                                    <BootstrapTooltip
                                        title={Lang.t("but.minimize")}
                                    >
                                        <button aria-label={Lang.t("but.minimize")}  className="but" onClick={this.handleMinimizePress}>
                                            <img src="/images/ui/icon-minimize-dark.png" className="but-icon" alt=""/>
                                        </button>
                                    </BootstrapTooltip>
                                }
                               
                                <BootstrapTooltip
                                    title={Lang.t("but.comicSingle")}
                                >
                                    <button aria-label={Lang.t("but.comicSingle")} className={butSingleClass} onClick={this.handleSinglePress}>
                                        <img src="/images/ui/icon-comic-single-dark2.png" className="but-icon" alt=""/>
                                    </button>
                                </BootstrapTooltip>
                                
                                <BootstrapTooltip
                                    title={Lang.t("but.comicDouble")}
                                >
                                    <button aria-label={Lang.t("but.comicDouble")} className={butDoubleClass} onClick={this.handleDoublePress}>
                                        <img src="/images/ui/icon-comic-double-dark.png" className="but-icon" alt=""/>
                                    </button>
                                </BootstrapTooltip>
                                   
                                </div>
                        </div>
                        
                    </div>
                    <Turn 
                        options={this.options}
                        className="magazine"
                    >
                        {this.pagesInitial.map((url, index) => (
                            <div key={index} className="page">
                                <img src={url} alt=""/>
                            </div>  
                        ))}
                    </Turn>
                    
                </div>
            </div>
        )
    }
}




// -----------------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------
// OPEN BUTTON

type OpenButProps = {
    // style:any,
    onPress:Function
}


type OpenButState = {
    isUnrevealed:boolean
}



class OpenBut extends React.Component <OpenButProps, OpenButState>{

    isUnmounted:boolean = false;

    constructor(props:OpenButProps){
        super(props);
        this.state = {
            isUnrevealed:true
        }
    }
    componentDidMount(){
        this.reveal();
    }

    componentWillUnmount(){
        this.isUnmounted = true;
    }

    async reveal(){
        await Tools.later(0.3);
        if(this.isUnmounted) return;
        this.setState({isUnrevealed:false})
    }
    handlePress = (evt:any) => {
        if(this.props.onPress) this.props.onPress();
    }
    render(){

        let butClass:string = "turn-controls-but-open";
        if(this.state.isUnrevealed) butClass += " unrevealed";
        return(
            <div className='turn-controls-but-open-container'>
                <div className={butClass} onClick={this.handlePress}>
                    <img src="/images/ui/icon-comic-open.png" alt=""/>
                    <span>{Lang.t("but.openComic")}</span>
                </div>
            </div>
        )
    }

}