// import Lang from "../../tools/Lang";
import './_styles.scss';
// import { SyntheticEvent } from "react";

type Props = {
   id:string,
   children:any,
   className?:string
}
export const Form = (props:Props) => {
   
    // Class
    let className = "form";
    if(props.className) className += " " + props.className;
    
    return (
        <form className={className} id={props.id}>
            {props.children}
        </form>
    )
}