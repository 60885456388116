import './_styles.scss';


import React, {ReactNode} from 'react';
import {ArrowBackIos} from '@material-ui/icons';

import Lang from '../../tools/Lang';
import Tools from '../../tools/Tools'
import Router from '../../router/Router';
import {TopicTabId} from '../TopicTabs/TopicTabs';
import Tween from '../../tools/Tween';
import {MediaItemData} from '../../data/Topic';
import {Message} from '../Message/Message';
import {LangString} from '../../data/Content';
import ExternalLink from '../../tools/ExternalLink'
import Link from '../Link/Link';


// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

type Props = {
    itemData:MediaItemData,
    setTitle:Function,
}

type State = {
    opacity:number,
    y:number,
}

export default class TopicSoundsItemPage extends React.Component<Props, State>{

    itemData:MediaItemData;

    isUnmounted:boolean = false;

    tween:any;

    DEV_FORCE_EXTERNAL:boolean = true;

    constructor(props:Props){
        super(props);

        log("TopicSoundsItemPage()")
        log(" data = ", this.props.itemData)

        this.state={
            opacity:0,
            y:0
        } 
        
        this.itemData = this.cleanData(props.itemData);
    }

    // -------------------------------------------------------
    // LIFECYCLE


    componentDidMount(){
       

        this.tween = Tween.to(this, {
            opacity:1, 
            y:-5, 
            duration:0.8, 
            ease:"power4.out",
        })

        this.setTitle();
        
    }

    

    componentWillUnmount(){
        this.isUnmounted = true;
        Tween.kill(this.tween);
    }

    // -------------------------------------------------------
    // METHODS
    
    setTitle(){
        log("TopicSoundsItemPage.setTitle()")
        log(" - this.itemData = ", this.itemData)
        let description:LangString|undefined = undefined;
        let descriptionStr = "";
        
        // if(this.itemData.informant){ 
        //     descriptionStr = this.itemData.informant
        // }
        // if(this.itemData.author){
        //     descriptionStr += " | " + this.itemData.author
        // }

        // if(this.itemData.publisher){
        //     descriptionStr += " | " + this.itemData.publisher
        // }

        let descriptionStrEn = descriptionStr;
        let descriptionStrGa = descriptionStr;
        if(this.itemData.intro){
            if(descriptionStr){
                descriptionStrEn += " | ";
                descriptionStrGa += " | ";
            }
            
            descriptionStrEn += this.itemData.intro.en
            descriptionStrGa += this.itemData.intro.ga
        }

        if(descriptionStrEn || descriptionStrGa){
            description = {
                en: descriptionStrEn,
                ga: descriptionStrGa
            }
        }
        this.props.setTitle(this.itemData.title, description)
    }
    cleanData(propsItemData:MediaItemData):MediaItemData{
        let itemData = {...propsItemData};
        Tools.langStringClean(itemData.intro);
        Tools.langStringClean(itemData.text);
        return itemData;
    }

	getUrl(itemData:MediaItemData):string{
		const link:ExternalLink|undefined = ExternalLink.pickLinkForLang(itemData.externalLinks, Lang.langId)

        return link?.url || "";
    }


    // -------------------------------------------------------
    // EVENTS


    handleBackClick = () => {
        Router.navigateToTopicTab(TopicTabId.sounds)
    }

	handleItemClick = (itemData:MediaItemData) => {
        if(!itemData?.id) return;
        
        // External url
        if(itemData.externalLinks){
			const link:ExternalLink|undefined = ExternalLink.pickLinkForLang(itemData.externalLinks, Lang.langId)
			if (link?.url) {
				// console.log(link.url)
	            Router.navigateToPath(link.url, null, false, false, false, true, true)    ;
	            return;
			}
        }
    }




    // -------------------------------------------------------
    // RENDER

    renderText(itemData:MediaItemData):any{
        let paras:string[] = Tools.stringToArray(Lang.tc(itemData.text))
        let warning:any = null;
        if(paras.length === 0){
            paras = Tools.stringToArray(Lang.tc(itemData.text, true))
            warning = 
                <Message 
                    title={Lang.t("error." + Lang.langId + "NotAvailable.ga")} 
                    text={Lang.t("error." + Lang.langId + "NotAvailable.en")}
                    key="message_no_lang"
                />
            
        }


        let items:any[] = [];
        let i = 0;
        for(let para of paras){
            let item =  <p key={"sounds_text_para__" + i}>{para}</p>
            items.push(item);
            i++;
        }
        
        return (
            <div className="text">
                {warning}
                {items}
                <span className="topic-sounds-text-underline"></span>
            </div>
        )
    }
    
    
	renderTags(tags:string[]=[], glue:string=""):ReactNode[] {
		tags = tags.filter(tag => Lang.hasTerm(`oral.controls.filter.type.${tag}`) )

		return tags.map((tag:string, i:number) => 
		
            <span 
                className='tag'
                key={"tag_" + tag + "_" + i}
            >
                {Lang.t(`oral.controls.filter.type.${tag}`)}
                { 
                    (i < tags.length-1) && (glue || "")
                }
            </span>
				

		)
	}

    render(){
        const itemData = this.itemData;
        
        if(!itemData) return null;

		const externalLink = ExternalLink.pickLinkForLang(itemData.externalLinks, Lang.langId)


        // let itemData:ItemData = this.getItemDataFromSource()
        return (
            <div className="topic-sounds-item text-column"
                style={{
                    opacity:this.state.opacity,
                    transform:"translateY(" + this.state.y + "px)"
                }}
            >
                <div className="topic-subheader">

                    {/* BACK BUTTON */}
                    <button className="but type2" onClick={this.handleBackClick}><ArrowBackIos className="icon"/>{Lang.t("topic.back-to-list")}</button>
                    
					<Link 
						to={this.getUrl(itemData)}
						onClick={()=>{this.handleItemClick(itemData)}} 
						className="topic-sounds-list-item" 
					>
						<div className="item-content">

							<div className="main-block">
								<div className="item-header">
									<div className="item-header-text">
										<h3 className="list-title">
											{Lang.tc(itemData.title)}
										</h3>

										
									</div>
									{externalLink?.label &&
										<div className="item-header-icon">
											<img src={`/images/ui/logo-${externalLink.label}.png`} alt={externalLink?.labelAlt}/>
										</div>
									}
								</div>

								{/* AUTHOR */}
								{itemData.informant || itemData.fieldworker ? 
									<p className="smallcaps author">
										
											{itemData.informant ?
												<span>
													<span className="role">{Lang.t("topic.sounds.informant") + ": "}</span>
													{itemData.informant}
												</span>
												: null
											}

											{itemData.fieldworker && itemData.informant ?
												<span>
													<span className="divider">|</span>
													<span className="new-line"><br/></span>
												</span>
												:
												null
											}

											{itemData.fieldworker ?
												<span>
													<span className="role">{Lang.t("topic.sounds.fieldworker") + ": "}</span>
													{itemData.fieldworker}
												</span>
												: null
											}

										
									</p>
									: null
								}
								

								{/* DESCRIPTION */}
								{itemData.description && Lang.tc(itemData.description) &&
									<p className="description">
										{Lang.tc(itemData.description)}
									</p>
								}


								
								
								{/* PUBLISHER */}
								<p className="faint publisher">
									{itemData.catname}{itemData.catnumber && (", " + itemData.catnumber)}
								</p>

								{/* FILTER TAGS */}
								{itemData && itemData.tags && itemData.tags.length>0 && 
									<p className='smallcaps faint tags'>{this.renderTags(itemData.tags, ", ")}</p>
								}

								

								


								{externalLink &&
									<div className="external">
										<img src="/images/ui/icon-new-window.png" alt=""/>
										<span>{Lang.t("but.newWindow")}</span>
									</div>
								}
							</div>
							
						</div>

						{/* <p className="faint">{itemData.text}</p> */}
					</Link>
				</div>
            </div>
        )
    }

    


}