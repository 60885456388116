import React from 'react';

type Props = {
    to:string,
    // href:string,
    onClick?:Function,
    className?:string,
    children?:any,
    ariaLabel?:string
}
export default function Link(props:Props) {
    
    const onClick = (e:any) => {
        // console.log("Link._onClick()")
        
        
        if (props.onClick) {
            e.preventDefault()
            // console.log(" - this.props.onClick = " + this.props.onClick)
            props.onClick(e)
            
            return false;

        }
        

        
        //@ts-ignore
        // window.location(this.props.to);
    }
  
   
    // var {onClick, ...others} = this.props
    const className = props.className || "";
    return (
        <a 
            href={props.to}
            // {...others} 
            className={className}
            onClick={onClick}
            aria-label={props.ariaLabel || ""}
        >
            {props.children}
        </a>
    )
}


  
