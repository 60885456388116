import './_styles.scss';

import {Component} from 'react';
import Audience from '../../tools/Audience';
import Config from '../../config/Config';
import Link from '../Link/Link';
import Lang from '../../tools/Lang';
// import Tools from '../../tools/Tools';
import Router from '../../router/Router';
import type {ContentShortcutsItem} from '../../data/Content';
import {ContentSectionRowAspect} from '../../data/Content';

interface Props{
    onClick:Function,
    data:ContentShortcutsItem,
    aspect?:ContentSectionRowAspect,
    index:number,
}

interface State{
    isLoadingImage:boolean
}
export default class ShortcutsItem extends Component<Props, State>{
    isUnmounted:boolean = false;

    imagePath:string = Config.getImagePath();

    url:string = '';

    TARGET_PADDING_DEFAULT:number = 50;

    constructor(props:Props){
        super(props);
        this.state = {
            isLoadingImage:true
        }

        this.url = Router.pathname + "#" + this.props.data.targetId
        
    }

    componentWillUnmount(): void {
        this.isUnmounted = true;
    }

    revealImage () {

        this.setState(
            {
                isLoadingImage:false,
            }
        );

    }
    
  

    handleClick = async (event:any)=> {
        if(event) event.preventDefault();
        // console.log("ShortcutsItem.handleClick()")
        // console.log(" - id = " + this.props.data.id)
        // await Tools.later(3)
        const el:Element|null = document.getElementById(this.props.data.targetId)
        if(!el) return;

        // el.scrollIntoView({behavior: "smooth"});

        // ---------------------
        // Scroll to section
        const elTop = el.getBoundingClientRect().top;

        const scroller = document.querySelector('html');
        if(!scroller) return;
    
        const shortcutsHeight = document.getElementById("shortcuts-container")?.getBoundingClientRect()?.height || 0;
        const navHeight = document.getElementById("nav")?.getBoundingClientRect()?.height || 0;
        const scrollTop = scroller.scrollTop;
        const padding = this.props.data.targetPadding || this.TARGET_PADDING_DEFAULT ; // Space between shortcuts bottom and the selected section top
        const targetY = scrollTop + elTop - navHeight - shortcutsHeight - padding;

        scroller.scrollTo({top: targetY, behavior:"smooth"})


    }

    handleImageLoad = async() => {
        if(this.isUnmounted) return;
        // await Tools.later(2 + (Math.random() * 5));
        this.revealImage();
    }


    render(){

        // Image path
        let imagePath = '';
        let folder = this.imagePath + "shortcuts/" + Audience.id + "/";
        let filename = this.props.data.id;
        imagePath = folder + filename + ".jpg";

        // Main link class
        let linkClass = "shortcuts-row-item";
        
        // Profile aspect
        if(this.props.aspect) linkClass += " aspect-" + this.props.aspect; 
        
        // Loading
        if(this.state.isLoadingImage) linkClass += " loading"

        return (
            
            <Link 
                to={this.url}
                onClick={this.handleClick}
                className={linkClass}
            >
                {/* IMAGE WRAPPER */}
                {/* This animates up when stuck, making images float upwards */}
                <div className="shortcuts-row-item-image-wrapper">
                    
                    {/* IMAGE CONTAINER */}
                    {/* This shrinks the height of the images and fades them out when stuck */}
                    <div className="shortcuts-row-item-image-container">
                        
                        {/* IMAGE */}
                        <img 
                            onLoad={this.handleImageLoad} 
                            src={imagePath} 
                            className="shortcuts-row-item-image"
                            alt=""
                        />
                        
                        <div className="shortcuts-row-item-image-border"/>
                    </div>
                </div>
            
                <div className="shortcuts-row-item-text">
                    <h3  className="shortcuts-row-item-title grid-item">{Lang.tc(this.props.data.title)}</h3>
                    {/* <h3  className="shortcuts-row-item-title grid-item">Text</h3> */}
                
                    <p className="shortcuts-row-item-subtitle">
                        {Lang.tc(this.props.data.subtitle)}
                        {/* Example subtitle text */}
                        </p>
                </div>
            </Link>
            
        )
    }
}