
import {LangId} from '../tools/Lang'
import {AudienceId} from '../tools/Audience';
// import {VideoService} from '../data/Topic';
import Tools from '../tools/Tools';
import {MediaType} from '../data/Topic';


type ConfigSettings = {
    cdn:string,
    corsUnblockPath:string,
    imagePath:ConfigPath,
    soundsPath:ConfigPath,
    genericFlashPath:ConfigPath,
    flashComicPath:ConfigPath,
    flashGamePath:ConfigPath,
    comicPagePath:ConfigPath,
    topicPath:ConfigPath,
    storiesDataPath:ConfigPath,
	defaultLangId:LangId,
	defaultAudienceId:AudienceId,
    home:ConfigHome,
    services:Record<string, ConfigService>,
	langs: {[key:string]: ConfigLang},
	search:ConfigSearch,
    
}

type ConfigPath = {
    dev:string,
    prod:string,
}
type ConfigHome = {
    hero: ConfigHero,
    promo: ConfigPromo,
}

type ConfigHero = {
    imageIds:string[]
}

type ConfigPromo = {
    id:string
}


type ConfigService = {
    url:string
}
type ConfigLang = {
	stopWords:string[]|undefined,
}

type ConfigSearch = {
	audiences: {
		[key:string]: {
			resultItemTypeBlacklist:string[],
			tabs:string[],
		}
	},

	/** We gather search result topic-items into their parent topic */
	gatherItems: {

		/** Up to a certain distance (search-score wise) we will gather up a topic-item into its topic */
		scoreProximity:number,

		/** Up to a certain distance (search result list position wise) we will gather up a topic-item into its topic */
		positionProximity:number,
	}
}


export default class Config {


    static settings:ConfigSettings = {

        cdn: "https://cms.bocan.tv",

        // ---------
        // corsUnblockPath
        // 
        // This IS used only in following circumstance:
        // - Local dev or production build on LAN: this allows the site to be viewed on devices wih no CORS unblocker extension, i.e. iOS devices
        // 
        // IS NOT used:
        // - Local dev or production build on localhost: you still need a CORS unblocker in your browser.
        // - Deployed production build
        // 
        // If not working:
        // cors-anywhere is enabled only temporarily. Visit the corsUnblockPath url to enable it again.
        // corsUnblockPath:"https://cors-anywhere.herokuapp.com/",
        corsUnblockPath:"",

        // soundsPath: "{cdn}/M/{filename}",

        // cdn: "",
        imagePath:{
            dev: "{cdn}/images/content/",
            prod: "{cdn}/images/content/",
        },
        soundsPath: {
            // dev: "/audio/m{_id}.mp3",
            dev: "{corsUnblockPath}{cdn}/M/m{_id}.mp3",
            prod: "{corsUnblockPath}{cdn}/M/m{_id}.mp3"
        },
        genericFlashPath:{
            dev: "{cdn}/flash/{_id}.swf",
            prod: "{cdn}/flash/{_id}.swf"
        },
        flashComicPath: {
            dev: "{cdn}/C/c{_id}.swf",
            prod: "{cdn}/C/c{_id}.swf"
        },
        flashGamePath: {
            dev: "{cdn}/G/g{_id}.swf",
            prod: "{cdn}/G/g{_id}.swf"
        },
        comicPagePath: {
            dev: "{cdn}/images/content/comics/{id}.{langId}/{page}.jpg",
            prod: "{cdn}/images/content/comics/{id}.{langId}/{page}.jpg"
        },
        topicPath:{
            // dev: "/data/topics/{topicId}.json",
            // dev: "/api/topic/{_id}",
            dev: "{corsUnblockPath}https://cms.bocan.tv/tests/get-content-dev.php?story={_id}",
            prod: "{corsUnblockPath}https://cms.bocan.tv/tests/get-content.php?story={_id}"
            // prod: "https://bocan-site.appspot.com/api/topic/{_id}"

        },

        storiesDataPath: {
            dev: "/data/get-content.php.json",
            // dev: "{corsUnblockPath}https://cms.bocan.tv/tests/get-content-dev.php",
            prod: "{corsUnblockPath}https://cms.bocan.tv/tests/get-content.php"
        },

		defaultLangId: LangId.ga,
		defaultAudienceId: AudienceId.learners,

        home:{
            hero:{
                // imageIds:["beltane", "fraoch", "lady_of_lawers", "black_murdo_of_the_cave", "mull_witch", "neishes_and_macnabs_2"]
                imageIds:["sea_poetry", "ossian", "cuchullain", "fraoch", "lady_of_lawers", "neishes_and_macnabs_2"]
            },
            promo:{
                id:"kidsAndOral",
                // id:"kids"
            }
        },


		services: {
			tobar: {url: "https://www.tobarandualchais.co.uk/track/{id}?l={lang}"},
			vimeo: {url: "https://vimeo.com/{id}"},
		},

		langs: {
			en: {
				stopWords: undefined
			},
			ga: {
				stopWords: ["a'", "an", "na", "agus", "ach", "mo", "do", "ar", "e", "i", "bha", "bidh", "iad", "bu", "dh'", "bhiodh", "cha"]
			}
		},

		search: {
			audiences: {
				toddlers: {
					// In the search results we only want to show certain item types, depending on the audience.
					// E.g. we don't really want to see any comics in the learners side.
					// However, it's only topics that are tagged with specific audiences.
					resultItemTypeBlacklist: ["topic", "archive.text", "archive.sound", "group" ],
					tabs: [],
				},
				kids: {
					resultItemTypeBlacklist: ["topic", "archive.text", "archive.sound", "group" ],
					tabs: [MediaType.game, MediaType.video, MediaType.comic, MediaType.audio, MediaType.script],
				},
				learners: {
					resultItemTypeBlacklist: ["game", "comic"],
					tabs: [MediaType.video, MediaType.audio, MediaType.archiveSound, MediaType.archiveText],
				}
			},
			gatherItems: {
				positionProximity: 4,
				scoreProximity: 0.2,
			},
		}
    }


    
    /**
     * Do we need CORS anywhere inserted in front of the url? 
     * All LAN devices and Safari on localhost get it.
     */
    static get needsCorsAnywhere():boolean{
        return Tools.isLAN || (Tools.isLocal && Tools.isSafari);
    }

    static getTopicPath(topicId?:string, _id?:string):string{
        if(!_id || !topicId) return "";
        let str:string = this.settings.topicPath[Tools.environment];

        // If accessing on a LAN then use a CORS unblocker
        let corsUnblockPath:string = this.needsCorsAnywhere ?  this.settings.corsUnblockPath : "";
        str = Tools.fillTags(str, {_id:_id, topicId: topicId, corsUnblockPath: corsUnblockPath})
        return str;
    }

   

    static getStoriesDataPath():string{
        let str:string = this.settings.storiesDataPath[Tools.environment];
        
        // If accessing on a LAN then use a CORS unblocker
        let corsUnblockPath:string = this.needsCorsAnywhere ?  this.settings.corsUnblockPath : "";
        str = Tools.fillTags(str, {corsUnblockPath: corsUnblockPath})
        return str;
    }

    static getSoundPath(_id?:string):string{
        if(!_id) return "";
        let str:string = this.settings.soundsPath[Tools.environment];
        
        // If accessing on a LAN then use a CORS unblocker
        let corsUnblockPath:string = this.needsCorsAnywhere ?  this.settings.corsUnblockPath : "";
        str = Tools.fillTags(str, {_id:_id, cdn: this.settings.cdn, corsUnblockPath: corsUnblockPath})
        return str;
    }

	static getServiceUrl(serviceId:string, params:object):string {
        if(!params || !serviceId) return "";
        let url = "";
        const service:ConfigService|undefined = this.settings.services[serviceId];
        if(service){
            return Tools.fillTags(service.url, params);
        }
        return url;
    }

	

    /**
     * Get the path of content image
     * @returns 
     */
     static getImagePath():string{
        let str:string = this.settings.imagePath[Tools.environment];
        str = Tools.fillTags(str, {cdn: this.settings.cdn})
        return str;
    }

    static getGenericFlashPath(_id:string):string{
        if(!_id) return "";
        let str:string = this.settings.genericFlashPath[Tools.environment];
        str = Tools.fillTags(str, {_id:_id, cdn:this.settings.cdn})
        return str;
    }
    /**
     * Get the path of a comic swf e.g. the Battle of Caranish comic has media item number "42", path is "https://cms.bocan.tv/C/c42.swf"
     * @param _id - Numeric id of the media item 
     * @returns 
     */
     static getFlashComicPath(_id:string):string{
        if(!_id) return "";
        let str:string = this.settings.flashComicPath[Tools.environment];
        str = Tools.fillTags(str, {_id:_id, cdn:this.settings.cdn})
        return str;
    }

    /**
     * Get the path of a game swf e.g. the Bac o' "Count te heep" game has media item number "668", path is "https://cms.bocan.tv/G/g668.swf"
     * @param _id - Numeric id of the media item 
     * @returns 
     */
     static getFlashGamePath(_id:string):string{
        if(!_id) return "";
        let str:string = this.settings.flashGamePath[Tools.environment];
        str = Tools.fillTags(str, {_id:_id, cdn:this.settings.cdn})
        return str;
    }

    /**
     * Get the path for a page of a jpg comic, e.g. "https://cms.bocan.tv/images/content/comics/the_titmouses_hero_706/1.jpg"
     * @param id - Full id of the media item, e.g. "the_titmouses_hero_706"
     * @param langId - Either "en" or "ga"
     * @param page - Page number
     * @returns 
     */
    static getComicPagePath(id:string, langId:LangId, page:number):string{
        if(!id || !langId || page === undefined) return "";
        let str:string = this.settings.comicPagePath[Tools.environment];
        str = Tools.fillTags(str, {id:id, cdn:this.settings.cdn, langId: langId, page: page})
        return str;
    }
}