// CSS
import './_styles.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//@ts-ignore
import _ from "lodash";

// IMPORTS
import React from 'react';

import App from '../../App';
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer';
import LangBar from '../../components/LangBar/LangBar';
import Section from '../../components/Section/Section';
import Hero from '../../components/Hero/Hero';
import Lang, {LangId} from '../../tools/Lang';
import Tools from '../../tools/Tools';
import Router from '../../router/Router';
import Content, {ContentHome, ContentSectionRow, ContentStory, LangString} from '../../data/Content';
import Audience, {AudienceId} from '../../tools/Audience';
import ModalMediaController from '../../components/ModalMedia/ModalMediaController';
import type {MediaItemData} from '../../data/Topic';
import Config from '../../config/Config';
import Topic, {TopicData} from '../../data/Topic';
import SectionItem from '../../components/SectionItem/SectionItem'
import Snacks from '../../components/Snacks/Snacks';
import {MediaType} from '../../data/Topic';
import Shortcuts from '../../components/Shortcuts/Shortcuts';
// import HomePromoKids from '../../components/HomePromoKids/HomePromoKids';
import HomePromoKidsAndOral from '../../components/HomePromoKidsAndOral/HomePromoKidsAndOral';
import DataProvider from '../../data/DataProvider'



// import {MEDIA_GRID_SORT_ORDER} from '../../data/Topic';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}


enum LoadingTopicState {
    unset = "unset",
    loading = "loading",
    loaded = "loaded",
    error = "error"
}



interface Props{
    content:Content,
    langId?:string,
    audienceId?:AudienceId,
    topicId?:string,
    itemId:string,
    sectionId?:string,
    navProps:any,
}

interface State{
    mediaItemId?:string|undefined,
    scrollY:number,
    isRevealed:boolean,
    showCarousels:boolean,
}



export default class HomeScreen  extends React.Component<Props, State>{
    content:Content;

    topicData:TopicData | null  = null;

    topicsData:{ [topicId: string]: TopicData } = {};

    // This is used to differentiate between two scenarios:
    // 1) When user opens an item manually
    // 2) When an item is opened automatically using the url
    // The router uses this information to choose to goBack (when manually opened) or not (when automatic) when the user closes the modal
    goBackOnModalClose:boolean= false;

    loadingError:string = "";

    isUnmounted:boolean = false;

    langId:LangId|null = null;
    audienceId:AudienceId|null = null;

    scroller:any;

    throttledScrollHandler:Function|any;

    loadingTopicDataState:LoadingTopicState = LoadingTopicState.unset;

    HOME_PROMO_KIDS:string = "kids";
    HOME_PROMO_KIDS_AND_ORAL:string = "kidsAndOral";

    constructor(props:Props){
        super(props);
        this.content = props.content;
        log("HomeScreen()")
        log("- props = ", props);
        
        Router.detectAndNavigateToError(props);

        // console.log("HomeScreen()")
        this.setTitle();

        // this.langId = Lang.langId;
        // this.audienceId = Audience.id;

       
        

        this.state = {
            scrollY:App.state.homeScrollY || 0,
            isRevealed:false,
            showCarousels:true,
        }


        

    }

    componentDidMount(){
        log("HomeScreen.componentDidMount()")

        
        
        // console.log("x = ", navigator)
        // document.body.style.backgroundColor="pink";  

        this.init();

        // Open Thanks modal
        if(this.props.navProps.location.hash === "#credits") App.openThanksModal();

        // Open Privacy modal
        if(this.props.navProps.location.hash === "#privacy") App.openPrivacyModal();

        
        
    }

    UNSAFE_componentWillUpdate(newProps:Props){
        // log("HomeScreen.UNSAFE_componentWillUpdate()")
        // log(" - newProps.audienceId = " + newProps.audienceId)
        
        // Audience has switched
        // We need to unreveal the page before it updates with new audience
        if(newProps.audienceId !== this.props.audienceId){
            // log(" - App.state.audienceId = " + App.state.audienceId)
            // log(" - newProps.audienceId = " + newProps.audienceId)
            // log(" - this.props.audienceId = " + this.props.audienceId)
            this.switchAudience();
            
        }
    }
    
    

    componentDidUpdate(prevProps:Props){
        log("  ")
        log("----------")
        log("HomeScreen.componentDidUpdate()")
        
        // log(" - this.props = ", this.props)
        // log(" - this.props.topicId = ", this.props.topicId)
        // log(" - this.props.itemId = " + this.props.itemId)
        // log(" - this.props.sectionId = " + this.props.sectionId)
        // log("  ")
        // log(" - prevProps.topicId = " + prevProps.topicId)
        // log(" - prevProps.itemId = " +  prevProps.itemId)
        // log(" - prevProps.sectionId = " + prevProps.sectionId)
        // log("  ")
        // log(" - this.topicsData = ", this.topicsData);
        // log("----------")
        // log(" - App.state.audienceId = " + App.state.audienceId)
        // log(" - this.props.audienceId = " + this.props.audienceId)
        
       
        Router.detectAndNavigateToError(this.props)


        // Is user coming back from kids/learners section?
        const hasComeBackFromKids:boolean = 
            (prevProps.navProps.location.pathname.indexOf("kids") > -1 && this.props.navProps.location.pathname.indexOf("kids") === -1)
            || (prevProps.navProps.location.pathname.indexOf("toddlers") > -1 && this.props.navProps.location.pathname.indexOf("toddlers") === -1)
        if(hasComeBackFromKids){
            log(" - COME BACK FROM KIDS")
            this.refreshRellax();
        }


        // Thanks modal
        const isOpeningThanksModal:boolean = prevProps.navProps.location.hash !== "#credits" && this.props.navProps.location.hash === "#credits" ;
        const isClosingThanksModal:boolean = prevProps.navProps.location.hash === "#credits" && this.props.navProps.location.hash !== "#credits" ;
        if(isOpeningThanksModal) App.openThanksModal();
        else if(isClosingThanksModal) App.closeThanksModal();

        // Privacy modal
        const isOpeningPrivacyModal:boolean = prevProps.navProps.location.hash !== "#privacy" && this.props.navProps.location.hash === "#privacy" ;
        const isClosingPrivacyModal:boolean = prevProps.navProps.location.hash === "#privacy" && this.props.navProps.location.hash !== "#privacy" ;
        if(isOpeningPrivacyModal) App.openPrivacyModal();
        else if(isClosingPrivacyModal) App.closePrivacyModal();

        // User has opened or closed a media modal 
        if(prevProps.topicId !== this.props.topicId){
            
            log(" - prevProps.topicId !== this.props.topicId")

            // Modal has been closed (no topicId)
            if(!this.props.topicId){
                log("User has closed a media modal ");    
                log(" - no this.props.topicId")
                this.setState({
                    mediaItemId:undefined
                })
                //console.log("HomeScreen.componentDidUpdate() no this.props.topicId")
                this.setTitle();
            }

            // There's a new modal requested (new topicId)
            else{
                log(" - new modal requested (new topicId)")
                log(" - new topic id")
                log(" - this.props.topicId = " + this.props.topicId)
                this.loadTopicFromProps();
            }
            
        }

        // Same topicId as before, or brand new url with topicId
        else {

            // There's a new media item requested from the same topic as before (same topicId, but different itemId)
            if(prevProps.itemId !== this.props.itemId){
                log(" - new media item requested")
                this.setState({
                    mediaItemId: this.props.itemId
                })
            }
            
            // User has navigated to topicId, but no itemId
            else if(!this.props.itemId && this.props.topicId){
                
                if(this.loadingTopicDataState !== LoadingTopicState.loading){
                    log(" - topicId but no itemId")
                    log(" - topicData = ", this.topicData)
                    const story = Content.getStory(this.props.topicId)
                    if(story) this.handleSectionItemClick(story)
                }
            }
        }

        
    }

    componentWillUnmount(){
        log("HomeScreen.componentWillUnmount()")
        this.isUnmounted = true;
        window.removeEventListener('scroll', this.throttledScrollHandler);
        App.setState({
            homeScrollY: this.state.scrollY
        })
    }



    setTitle(){
        log("HomeScreen.setTitle()")
        
        let title:LangString = {
            en: Lang.t("title", LangId.en),
            ga: Lang.t("title", LangId.ga)
        }
        if(Audience.isAudienceAnyKids){
            const termId:string = "nav." + Audience.id;
            title.en += " | " + Lang.t(termId, LangId.en)
            title.ga += " | " + Lang.t(termId, LangId.ga)
        }

        const audienceId = Audience.isAudienceAnyKids ? AudienceId.kids : AudienceId.learners;
        const imageUrl = Config.getImagePath() + "share/home2." + audienceId + "." + Lang.langId + ".jpg";
        
        Lang.setTitle(title, "home.description", undefined, Tools.OPENGRAPH_TYPE_WEBSITE, imageUrl);
    }

    /**
     * The audience has switched so we need to:
     * - unreveal
     * - wipe carousels
     * - set new Audience.id
     * - rebuild carousels
     * - reveal 
     * Complicated!
     */
    switchAudience = async () => {
        // log("HomeScreen.switchAudience()");

        // Unreveal
        this.unreveal();

        // Destroy carousels
        await Tools.later(0.3);
        this.setState({
            showCarousels: false
        })

        // Set Audience.id
        Router.setAudienceUsingRoute() 

        // Set the title
        // console.log("HomeScreen.switchAudience()");
        this.setTitle();
        
        // Rebuild carousels
        if(this.isUnmounted) return;
        this.setState({
            showCarousels: true,
            scrollY: 0
        })

        //  Scroll to top
        App.setState({
            homeScrollY:0
        })

        // Reveal
        this.reveal();
    }

    init = async () => {
        // log("HomeScreen.init()")
        // log(" - setting lang and audience using route...")
        


        // Detect when lang is wrong
        const isInvalidRoute:boolean = 
            (this.props.langId !== LangId.en && this.props.langId != null) &&
            !this.props.topicId;
        if(isInvalidRoute){
            // console.log(" - error: isInvalidRoute, langId = " + this.props.langId);
          
        }
        // Set lang
        if(!isInvalidRoute) Router.setLangUsingRoute(true);
        
        
        // Set audience
        Router.setAudienceUsingRoute();

        // Reveal page
        await Tools.later(0.3);

        // console.log("HomeScreen.init()");
        this.setTitle();
        

        // log(" - Lang.langId = " + Lang.langId);
        // log(" - Audience.id = " + Audience.id);

        this.loadTopicFromProps();

        // Scroll listener
        this.throttledScrollHandler = _.throttle(this.handleScroll, 100, {leading:true})
        window.addEventListener('scroll', this.throttledScrollHandler);

        const rellaxEls = document.getElementsByClassName("rellax");
        if(rellaxEls && rellaxEls.length > 0){
            //@ts-ignore
            window.rellax = new Rellax('.rellax'); 
        }

        
        

        this.reveal();
        
        
    }
    

    reveal(){
        log("HomeScreen.reveal()")
        if(this.isUnmounted) return;

        log(" - scrolling to: " + App.state.homeScrollY)
        if(App.state.homeScrollY !== undefined){
            window.scrollTo(0, App.state.homeScrollY)
        }

        this.setState({
            isRevealed:true,
            scrollY: App.state.homeScrollY
        });
    }

    unreveal(){
        // log("HomeScreen.unreveal()")
        if(this.isUnmounted) return;
        this.setState({isRevealed:false});
    }

    /**
     * Rellax is the js library that does parallax in the main Hero area.
     * We need to refresh it when coming back from the kids section
     */
    async refreshRellax(){
        
        await Tools.later(3)
    
        //@ts-ignore
        if(window.rellax){
            if(this.isUnmounted) return;
            
            // log("HomeScreen.refreshRellax()")
            
            //@ts-ignore
            if(window.rellax && window.rellax.destroy) window.rellax.destroy()
    
            //@ts-ignore
            window.rellax = new Rellax('.rellax'); 

        }
    }


    async loadTopicFromProps(){
        // console.log("HomeScreen.loadTopicFromProps()")
        // console.log(" - this.props.topicId = ", this.props.topicId)
        // console.log(" - this.props.itemId = ", this.props.itemId)
        if(!this.props.topicId) return;

        // Load in the topicData only if needed
        const shouldLoadTopicData = !this.topicsData[this.props.topicId]
            // ( !this.topicData || (this.topicData && this.topicData.topicId !== this.props.topicId));
        // log(" - this.topicsData[this.props.topicId] = " + this.topicsData[this.props.topicId])
        // log(" - shouldLoadTopicData = " + shouldLoadTopicData)
        if(shouldLoadTopicData){
            await this.loadTopicData(this.props.topicId);
        }else{
            this.topicData = this.topicsData[this.props.topicId];
        }
        
        if(this.isUnmounted) return;
        
        // console.log(" - this.topicsData = ", this.topicsData)
        // Open modal with new media items
        if(this.topicData && this.props.itemId){

            // Is media item in topic?
            let isMediaItemInTopic:boolean = false;
            if(this.topicData.mediaItems){
                for(let mediaItem of this.topicData.mediaItems){
                    if(mediaItem.id === this.props.itemId){
                        isMediaItemInTopic = true;
                        break;
                    }
                }
            }

            if(isMediaItemInTopic){
                this.setState({
                    mediaItemId: this.props.itemId
                });
            }else{
                // Show error snackbar
                let str:string = Lang.t("error.loadingFile");
                str += " (\"" + this.props.itemId + "\")"
                Snacks.add({
                    message: str,
                    secs:5,
                })
            }
        }
    
    }


    

    loadTopicData = async (topicId:string):Promise<void> => {
        
        log(" ")
        log("--------------------------");
        log("HomeScreen.loadTopicData()");
        
        if(this.topicsData[topicId] !== undefined){
            this.topicData = this.topicsData[topicId];
            log(" - Topic loaded, returning now")
            return;
        }

        if(this.loadingTopicDataState === LoadingTopicState.loading){
            log(" - Topic loading, returning now")
            return;
        }

        let res:any = {};
        try{
            
            
            this.loadingTopicDataState = LoadingTopicState.loading
            

            // Clear existing data
            this.topicData = null;

            // Get _id
            let _id:string|null = Content.getStory_id(topicId);
            // log("Content.instance.rawStoryMap = ",  Content.getRawStoryMap())
            if(!_id){
                throw Error("Story '" + topicId + "' not found in Content.rawStoryMap")
            }


            let topicData:TopicData|null = await DataProvider.getTopic(_id);
            topicData = Content.cleanTopicData(topicData || undefined) || null;
            this.topicData = topicData;
			// console.log(`LOADED TOPIC:`, this.topicData)
            if(topicData) {
				this.topicsData[topicId] = topicData;
			}
            
            
            
            this.loadingTopicDataState = LoadingTopicState.loaded

            if(this.topicData){
                
                const audienceId = Audience.isAudienceAnyKids ? AudienceId.kids : AudienceId.learners;
                const imageUrl = Config.getImagePath() + "thumbs/" + audienceId + "/" + this.topicData.topicId + ".jpg";
                //console.log("HomeScreen.loadTopicData()");
                Lang.setTitle(this.topicData.title, undefined, undefined, Tools.OPENGRAPH_TYPE_ARTICLE, imageUrl)
            }

            // log("- data loaded...", json)
        }
        catch(err){
            console.log("err = ", err);
            res.error = err;
            this.loadingError = String(err);

            // Show error snackbar
            let str:string = Lang.t("error.loadingFile");
            str += " (\"" + topicId + "\")"
            Snacks.add({
                message: str,
                secs:5,
            })

            
            this.loadingTopicDataState = LoadingTopicState.error
        }
        // log("--------------------------");
        // log(" ")

        return;

    }

    /**
     * The user a tapped on an item. Route accordingly.
     * @param itemData
     * @param item
     * @param rowData
     */
     handleSectionItemClick = async (itemData:ContentStory, item?:SectionItem, rowData?:ContentSectionRow, event?:any) =>{
        // console.log("HomeScreen.handleSectionItemClick()");
        // console.log(" - itemData = ", itemData)

        // KIDS OR TODDLERS
        if(Audience.isAudienceAnyKids) {
            // log(" - row.id = " + row.id)
            // log(" - row.path = " + row.path)
            // log(" - row.items = ",  row.items)
            // log(" - item.id = " + item.id)
            // log(" - item = ", item);
            let shouldLoadTopicData:boolean = !this.topicsData[itemData.id];
            //this.topicData === null || (this.topicData !== null && this.topicData.topicId !== itemData.id);
            
            // Load topic data
            if(shouldLoadTopicData){
                if(item) item.disable()
                await this.loadTopicData(itemData.id);
                if(item) item.enable()
            }

            // Set the topicData now we're sure it's loaded
            this.topicData = this.topicsData[itemData.id];

            // Topic data is loaded
            if(this.topicData){
                // log(" - this.topicData.topicId = " + this.topicData.topicId);
                // log(" - item.sectionId = " + item.sectionId)
                // log(" - topic mediaItems = ", this.topicData.mediaItems)
                

                // -------------------------------------
                // Open the supplied media item

                if(itemData.mediaItemId){
                    Router.navigateToMediaItem(itemData.mediaItemId)
                }

                // -------------------------------------
                // OR open a media item based on available media or the topic

                else{

                    
                    // Default media type
                    let mediaType:string = MediaType.video;
                    
                    // KIDS
                    if(Audience.isAudienceKids){
                        let types:MediaType[] = Topic.getMediaTypesFromTopic(this.topicData, true)
                        for(let type of Topic.mediaGridSortOrder){
                            if(types.includes(type)){
                                mediaType = type;
                                break;
                            }
                        }
                    }

                    // TODDLERS
                    else if(Audience.isAudienceToddlers){
                        // log(" - toddlers, decide which media type now...");
                        // log(" - item.sectionId = " + item?.sectionId)
                        // log(" - this.props.sectionId = " + this.props.sectionId)
                        let sectionId = "";
                        
                        // Item sectionId
                        if(item){ 
                            sectionId = item.sectionId;
                        }
                        // URL sectionId
                        else{
                            sectionId = this.props.sectionId? this.props.sectionId : "";
                        }

                        if(sectionId){
                            switch (sectionId){
                                case Section.ID_TODDLERS_FILMS:
                                    mediaType = MediaType.video;
                                    break;

                                case Section.ID_TODDLERS_MUSIC:
                                    mediaType = MediaType.audio;
                                    break;
                                case Section.ID_TODDLERS_COMICS:
                                    mediaType = MediaType.comic;
                                    break;
                                default:
                                    mediaType = MediaType.video;
                                    break;
                            }
                        }else{
                            mediaType = MediaType.video;
                        }
                    }
                    // log(" - mediaType = " + mediaType)
                    const mediaItem:MediaItemData|null = Topic.getMediaItemFromTopic(this.topicData, mediaType);
                    if(mediaItem){
                        // log(" - mediaItem.id = ", mediaItem.id);
                        // let id = mediaItem.id + "_" + mediaItem._id

						// @audience-kludge
						// We're  having trouble with many media items not being tagged with an audience.
						// So if an item thumbnail is in Kids or Toddlers, we force it to stay in there...
						const forceAudienceId =Audience.id
						
                        Router.navigateToMediaItem(mediaItem.id, forceAudienceId)
                    }
                }
            }
            
        }
        
        // LEARNERS
        else{
            
            // Go to topic page.
            // Passing the event allows the user to open in new tab by pressing the metaKey
            Router.navigateToTopic(itemData.id, undefined, event)
        }
        
    }

    get scrollY():number{
        if( window.pageYOffset ) { return window.pageYOffset; }
        return Math.max(document.documentElement.scrollTop, document.body.scrollTop);
    }

    handleScroll = (event:any)=>{
        // log("HomeScreen.handleScroll()")
        if(this.isUnmounted) return;
        this.setState({
            scrollY: this.scrollY
        })
        
        // log(" - event = ", event)
    }

    

    renderSections():any{
        // log("HomeScreen.renderSections()");
        // log(" - App.state.audienceId = " + App.state.audienceId)

        if(!this.state.showCarousels) return null;


        let sections:any[] = [];

        let data:ContentHome = this.content.getAudienceData(App.state.audienceId).home;
        // log(" - data = ", data)
        for(let sectionData of data.sections){
            let section = (
                <Section
                    data={sectionData}
                    key={"section__" + sectionData.id}
                    onItemClick={this.handleSectionItemClick}
                    scrollY={this.state.scrollY}
                    langId={Lang.langId}
                    audienceId={Audience.id}
                />
            )
            sections.push(section);
        }

        return  (
            <div id="sections">
               {sections}
            </div>
        );

    }
    
    renderMediaModal():any{
        // log("HomeScreen.renderMediaModal()")
        // log(" - this.topicData = ", this.topicData)
        // log(" - this.state.mediaItemId = " + this.state.mediaItemId)
        if(this.topicData && this.state.mediaItemId){
            // log(" - this.topicData.topicId = " + this.topicData.topicId)
            // log(" - this.state.mediaItemId = " + this.state.mediaItemId)
            // log(" - showing modal...")
            return(
                <ModalMediaController
                    topicData={this.topicData}
                    itemId={this.state.mediaItemId}
                />    
            )
        }
        // log(" - NOT showing modal...")
        return null;  
    }

  
    render(){
       
        
        // log(" - App.state.audienceId: " + App.state.audienceId)
        let containerClass = "hidden";
        if(this.state.isRevealed) containerClass = ""
        return (
            <div id="home-screen" className={containerClass}>
               
                {/* HERO */}
                <Hero
                    scrollY={this.state.scrollY}
                />
        
                {/* LANG */}
                <LangBar/>
            
                {/* HOME PROMO: KIDS */}
                {/* {(Audience.isAudienceLearners && Config.settings.home.promo.id === this.HOME_PROMO_KIDS) &&
                        <HomePromoKids/>
                } */}

                {/* HOME PROMO: KIDS AND ORAL HISTORY */}
                {(Audience.isAudienceLearners && Config.settings.home.promo.id === this.HOME_PROMO_KIDS_AND_ORAL) &&
                        <HomePromoKidsAndOral/>
                }

                {/* SHORTCUTS */}
                {/* {Audience.isAudienceAnyKids && */}
                    <Shortcuts
                        scrollY={this.state.scrollY}    
                    />    
                {/* } */}

                {/* SECTIONS */}
                {this.renderSections()}
                

                {/* FOOTER */}
                <Footer/>

                {/* NAV */}
                <Nav
                    screenId="home"
                    // audienceId={App.state.audienceId}
                />


                {/* MEDIA MODAL */}
                {this.renderMediaModal()}
                
            </div>
        );
    }
  }

