import './_styles.scss';
import React from 'react';

import App from '../../App';
import Nav, {NavTheme} from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer';
// import Cookies from '../../tools/Cookies';
import Tools from '../../tools/Tools';
import Content, {ContentStory} from '../../data/Content';
import Router from '../../router/Router';
import Lang from '../../tools/Lang';
import SectionItem from '../../components/SectionItem/SectionItem';
// import ErrorScreen from '../ErrorScreen/ErrorScreen';
import Tween, {TweenObject} from '../../tools/Tween';
// import Tools from '../../tools/Tools';
import type {ContentSectionRow} from '../../data/Content';
import Config from '../../config/Config';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

enum LoadingState {
    
    // Loading data or banner
    loading = "loading",
    
    // Everything is ready to reveal
    loaded = "loaded",
    
    // Error loading data (data loading error only, banner loading error does NOT set this to true)
    error = "error"
}

interface Props{
    content:Content,
    langId?:string,
    id:string,
    uiSectionId:string,
    navProps:any,
}

interface State{
    // Loading, loaded, or error
    state:LoadingState,

    bannerImageLoaded:boolean,
    rowData:ContentSectionRow|null
}

export default class GroupScreen  extends React.Component<Props, State>{

    data:ContentSectionRow|null = null;

    bannerTween:TweenObject|null = null;

    imagePath:string = Config.getImagePath();

    isUnmounted:boolean = false;

    constructor(props:Props){
        super(props);
    
        log("GroupScreen()")
        log("- props = ", props);
   
        this.state = {
            rowData:null,
            bannerImageLoaded:false,
            state:LoadingState.loading,
        }

        Router.detectAndNavigateToError(props);
    }

    componentDidMount(){
        Router.setLangUsingRoute(true)

        // Open Thanks modal
        if(this.props.navProps.location.hash === "#credits") App.openThanksModal();

        // Open Privacy modal
        if(this.props.navProps.location.hash === "#privacy") App.openPrivacyModal();

        this.init();


    }

    componentDidUpdate(prevProps:Props){
        log("GroupScreen.componentDidUpdate()");
        if(prevProps.id !== this.props.id){
            this.init();
        }

        Router.detectAndNavigateToError(this.props);

        if(this.state.state === LoadingState.error){
            Router.navigateToError();
        }

        

        // Thanks modal
        const isOpeningThanksModal:boolean = prevProps.navProps.location.hash !== "#credits" && this.props.navProps.location.hash === "#credits" ;
        const isClosingThanksModal:boolean = prevProps.navProps.location.hash === "#credits" && this.props.navProps.location.hash !== "#credits" ;
        if(isOpeningThanksModal) App.openThanksModal();
        else if(isClosingThanksModal) App.closeThanksModal();

        // Privacy modal
        const isOpeningPrivacyModal:boolean = prevProps.navProps.location.hash !== "#privacy" && this.props.navProps.location.hash === "#privacy" ;
        const isClosingPrivacyModal:boolean = prevProps.navProps.location.hash === "#privacy" && this.props.navProps.location.hash !== "#privacy" ;
        if(isOpeningPrivacyModal) App.openPrivacyModal();
        else if(isClosingPrivacyModal) App.closePrivacyModal();

    }

    componentWillUnmount(){
        this.isUnmounted = true;
        Tween.kill(this.bannerTween);

    }
   
    init(){
        log("GroupScreen.init()");
        if(this.isUnmounted) return;
        const rowData:ContentSectionRow|null = this.getData();
        if(!rowData){
            this.setState({
                state: LoadingState.error
            })
        }
        else{
            log(" - rowData = ", rowData)
            this.setState({
                rowData: rowData,
                state: LoadingState.loaded
            }, ()=>{
                if(this.state.rowData){
                    Lang.setTitle(this.state.rowData.title, undefined, true, Tools.OPENGRAPH_TYPE_WEBSITE)
                }
            })
        }
        
    }

    getData():ContentSectionRow|null{
        
        return this.props.content.getGroupData(this.props.id, this.props.uiSectionId)
    }

  


    handleItemClick = (itemData:ContentStory, item:SectionItem, event:any) => {
        log("GroupScreen.handleItemClick() itemId = " + itemData.id)
        // event.preventDefault();

        // Go to topic page.
        // Passing the event allows the user to open in new tab by pressing the metaKey
        Router.navigateToTopic(itemData.id, undefined, undefined, undefined, undefined, undefined, undefined, event)
    }

    renderItems():any{
        // log("GroupScreen.renderItems()");
        if(!this.state.rowData) return null;
        let items:any[] = [];
        let idParts = this.props.id.split(".");
        const sectionId:string = idParts[0];
        // const groupId:string = idParts.pop() || "";
        const groupId = idParts && idParts[1] ? idParts[1] : "";
        // log(" - groupId = " + groupId)

        // Should switch title and subtitle?
        const switchTitle:boolean = this.state.rowData && this.state.rowData.switchTitle === true ? true : false;
        
		for (let stripData of this.state.rowData.strips || []) {
			for(let itemData of stripData.items){
				const item = (
					<div className="group-item"
						key={"group_item__" + this.state.rowData.id + "__" + itemData.id + "__" + items.length}
					>
						<SectionItem
							data={itemData}
							groupId={groupId}
							sectionId={sectionId}
							onClick={this.handleItemClick}
							rowTitle={this.state.rowData.title}
							sectionTitle={this.props.content.getSectionTitle(this.props.uiSectionId)}
							switchTitle={switchTitle}
						/>
					</div>
				)
				items.push(item);
			}
		}
        return items;
    }

    handleBannerImageLoad = (evt:any) => {
        log("GroupScreen.handleBannerImageLoad()")
        
        
        
        this.setState({bannerImageLoaded:true}, ()=>{
            //@ts-ignore
            if(window.playApng) window.playApng(".banner-image-png");
        })
    }

  

    render(){

        
        
      
        if(!this.state.rowData) return null;
    
        let bannerClass = "banner";
        if(this.state.bannerImageLoaded) bannerClass += " image-loaded";

        const bannerImageUrl = this.imagePath + "group_banners/" + this.props.id + ".png";


        return (
            <div id="group-screen">
                <div className={bannerClass}>
                    <div className="main-column">

                        <div className="banner--imagewrapper">
                        
                            <img src={bannerImageUrl} className="banner--image preloader"  onLoad={this.handleBannerImageLoad} alt=""/>
                            {this.state.bannerImageLoaded && 
                                <img src={bannerImageUrl} className="banner--image banner-image-png" alt=""/>
                            }
                        </div>


                        <div className="main-column-content">
                            <div className="banner--text">
                                <h3>{Lang.tc(this.props.content.getSectionTitle(this.props.uiSectionId))}</h3>
                                <h1>{Lang.tc(this.state.rowData.title)}</h1>
                            </div>
                        </div>
                        
                    </div>
                </div>
                    

                <div className="main-column">
                    <div className="main-column-content">
                    
                        <div className="group-items">
                            {this.renderItems()}
                        </div>

                    </div>
                </div>
            

                <Footer/>

                <Nav
                    theme={NavTheme.dark}
                    showLogo={true}
                />
            </div>
        );
    }
  }