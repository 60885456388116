import './_styles.scss';
// import React, {Component} from 'react';

import {AudienceId} from '../../tools/Audience';
import Router from '../../router/Router';
import Link from '../Link/Link';
import Lang from '../../tools/Lang';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}



export default function HomePromoKidsAndOral (){
    

    log("HomePromoKidsAndOral()")
    
     /* -------------------------------------------------
    * HANDLERS
    */
     const handleKidsPress = () => {
        Router.navigateToPath(Router.getAudiencePath(AudienceId.kids));
     }

     const handleOralPress = () => {
        Router.navigateToPath(Router.getOralPath());
     }
   


   
    

    return(
        <div id="home-promo" className="home-promo">

            {/* KIDS */}
            <div className="block block1">
                <div className="home-promo-bg bg1">
                    <img src="/images/ui/promos/home/kidsAndOral/bg1.png" alt=""/>
                </div>
                <div className="home-promo-image image1">
                    <img src="/images/ui/promos/home/kidsAndOral/image1.png" alt=""/>
                </div>
                <div className="home-promo-text">
                    <h2 className="title">{Lang.t('promo.home.kids.title')}</h2>
                    <p className="subtitle">{Lang.t('promo.home.kids.text')}</p>
                    <Link 
                        to={Router.getAudiencePath(AudienceId.kids)}  
                        onClick={handleKidsPress} 
                        className="but"
                    ><span>{Lang.t('promo.home.kids.but')}</span></Link>
                </div>
            </div>

            {/* ORAL TRADITIONS */}
            <div className="block block2">
                <div className="home-promo-bg bg2">
                    <img src="/images/ui/promos/home/kidsAndOral/bg2.png" alt=""/>
                </div>
                <div className="home-promo-image image2">
                    <img src="/images/ui/promos/home/kidsAndOral/image2.png" alt=""/>
                </div>
                <div className="home-promo-text">
                    <h2 className="title">{Lang.t('promo.home.oral.title')}</h2>
                    <p className="subtitle">{Lang.t('promo.home.oral.text')}</p>
                    <Link to={Router.getOralPath()} onClick={handleOralPress} className="but"><span>{Lang.t('promo.home.oral.but')}</span></Link>
                </div>
            </div>
        </div>
        
    )
}
