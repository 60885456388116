import React from 'react';


// import App from '../../App';
import ModalScreen from '../ModalScreen/ModalScreen';
import Audience, {AudienceId} from '../../tools/Audience';
import Lang, {LangId} from '../../tools/Lang';
import Tools from '../../tools/Tools';
import {TopicData,  MediaItemData, MediaType} from '../../data/Topic';
import {Close} from '@material-ui/icons';
import ModalMediaVideo from '../ModalMediaVideo/ModalMediaVideo';
import ModalMediaComic from '../ModalMediaComic/ModalMediaComic';
// import Expander from '../Expander/Expander';
import Waveform from '../Waveform/Waveform';
import Config from '../../config/Config'
// import {LangString} from '../../data/Content';
import {Message} from '../Message/Message';
import MediaGrid from '../MediaGrid/MediaGrid';
import Credits from '../Credits/Credits';
// import Credits from '../Credits/Credits';
import Content, {LangString} from '../../data/Content';
import type {ContentManualMediaItem} from '../../data/Content';
import Topic from '../../data/Topic';
import Flash, {LangMode, PreloaderId} from '../Flash/Flash';
import Router from '../../router/Router';
import {BootstrapTooltip} from '../BootstrapTooltip/BootstrapTooltip';

import './_styles.scss';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

type Props = {
    isOpen:boolean,
    onClose:any,
    topicData:TopicData,
    mediaItemData:MediaItemData,
}

type State = {
    topicData:TopicData,
    mediaItemData:MediaItemData,
    hidden:boolean,
    isMax:boolean
}



export default class ModalMedia extends React.Component<Props, State>{
    
    isUnmounted:boolean = false;

    imagePath:string = Config.getImagePath();

    CREDITS_MAX_VISIBLE_LEARNERS:number = 5;
    CREDITS_MAX_VISIBLE_KIDS:number = 2;

    constructor(props:Props){
        
        super(props);
        log("ModalMedia()")
        // this.mediaItemData = this.cleanData(props.mediaItemData)
        // log(" - this.mediaItemData = ", this.state.mediaItemData)
        // log(" - this.props.topicData = ", this.props.topicData);
        this.state = {
            topicData:{...props.topicData},
            mediaItemData: this.cleanData(props.mediaItemData),
            hidden:false,
            isMax:false
        }
    }


    // -------------------------------------------------------------------------------------
    // LIFECYCLE

    componentDidMount(){
        log("ModalMedia.componentDidMount()");

        this.setTitle();
    }

    componentDidUpdate(oldProps:Props){
        log("ModalMedia.componentDidUpdate()");
        

        if(oldProps.mediaItemData.id !== this.props.mediaItemData.id){

            log(" - oldProps.mediaItemData.id = " + oldProps.mediaItemData.id)
            log(" - this.props.mediaItemData.id = " + this.props.mediaItemData.id)
            this.reload();
            
        }
    }

    componentWillUnmount(){
        this.isUnmounted = true;
    }



    // -------------------------------------------------------------------------------------
    // METHODS


    /**
     *
     */
     setTitle = () =>{
        log("ModalMedia.setTitle()")
        // console.log(" - this.state.mediaItemData = ", this.state.mediaItemData)
        if(this.props.topicData){
            
            // --------------------
            // TITLE

            let title:LangString|undefined = {en:"", ga:""};
            
            // Media item title
            if(this.state.mediaItemData.title){
                title = {...this.props.mediaItemData.title as LangString};
            }

            // Parent topic title
            if(this.props.topicData.title){

                // Media item title and topic title are different
                const shouldAddTopicTitleEn:boolean = !Lang.areLangStringsSame(this.props.topicData.title, title, true, LangId.en);
                const shouldAddTopicTitleGa:boolean = !Lang.areLangStringsSame(this.props.topicData.title, title, true, LangId.ga);

                // Add topic title
                if(shouldAddTopicTitleEn){
                    if(title.en) title.en += " | ";
                    title.en += this.props.topicData.title.en;    
                }
                if(shouldAddTopicTitleGa){
                    if(title.ga) title.ga += " | ";
                    title.ga += this.props.topicData.title.ga;    
                }
        
                
                // Trunc title
                // title = Tools.truncLangString(description);
            }

            // --------------------
            // DESCRIPTION
            
            
            // Default to generic topicData description
            let description:LangString | undefined;
            
            // Media item description
            const isDescriptionValid:boolean = 
                this.state.mediaItemData !== undefined
                && this.state.mediaItemData.intro !== undefined
                && this.state.mediaItemData.intro.en !== undefined
                && this.state.mediaItemData.intro.en !== ""
                && this.state.mediaItemData.intro.ga !== undefined
                && this.state.mediaItemData.intro.ga !== ""

            if(isDescriptionValid){
                description = {...this.state.mediaItemData.intro as LangString};
            }
            
            else{
                description = this.props.topicData.description;
            }
            

            // Trunc the description
            if(description){
                description = Tools.truncLangString(description);
                log(" - description.en = " + description.en)
                log(" - description.ga = " + description.ga)
            }

            // --------------------
            // IMAGE
            const audienceId = Audience.isAudienceAnyKids ? AudienceId.kids : AudienceId.learners;
            const imageUrl = Config.getImagePath() + "thumbs/" + audienceId + "/" + this.props.topicData.topicId + ".jpg";
            

            // Set the title and description
            log("ModalMedia.setTitle()")
            log(" - imageUrl = " + imageUrl)
            Lang.setTitle(title, description, true, Tools.OPENGRAPH_TYPE_ARTICLE, imageUrl)
        }
    }

    reload = async () => {
        if(this.isUnmounted) return;
        
        // Unreveal
        this.setState({
            hidden:true
        });


        // Repopulate with new media item
        await Tools.later(0.2);
        if(this.isUnmounted) return;
        
        this.setState({
            mediaItemData: this.cleanData(this.props.mediaItemData),
            topicData: this.props.topicData
        })

        // Wait till render done
        await Tools.later(0.2);
        if(this.isUnmounted) return;

        // Reveal new media
        this.setState({
            hidden:false,
        })

        // Scroll to top
        await Tools.later(0.3);
        if(this.isUnmounted) return;

        let els = document.getElementsByClassName("modal-container");
        if(els){
            const el = els[0]
            el.scrollTo({top:0, behavior: 'smooth'})
        }

        this.setTitle();

    }

    cleanData(propsItemData:MediaItemData):MediaItemData{
        // console.log(" - propsItemData = ", propsItemData)
        let itemData = {...propsItemData}
        itemData.intro = Tools.langStringClean(itemData.intro)
        itemData.text = Tools.langStringClean(itemData.text)
        return itemData;
        
    }

    close = () => {
        log("ModalMedia.close()")
        this.props.onClose();
    }

   

    handleLangClick = async () => {
        log("ModalMedia.handleLangClick()");
        
        await Lang.toggleLangId();

        Router.navigateToAppStateLang();

        // Snacks.add({
        //     message: Lang.t("snack.langSwitched"),
        //     // type:SnackType.success,
        //     secs:4,
        // })

       

    }
   

    handleCloseClick = () => {
        this.close();
    }
   
    handleMaxClick = () => {
        if(this.isUnmounted) return;

        this.setState({
            isMax:true
        })
    }

    handleMinClick = () => {
        if(this.isUnmounted) return;

        this.setState({
            isMax:false
        })
    }

    get isAudio():boolean{return this.state.mediaItemData && this.state.mediaItemData.type === MediaType.audio}
    get isVideo():boolean{return this.state.mediaItemData && this.state.mediaItemData.type === MediaType.video}
    get isScript():boolean{return this.state.mediaItemData && this.state.mediaItemData.type === MediaType.script}
    get isComic():boolean{return this.state.mediaItemData && this.state.mediaItemData.type === MediaType.comic}
    get isGame():boolean{return this.state.mediaItemData && this.state.mediaItemData.type === MediaType.game}
    

    renderMedia():any|null{
        log("ModalMedia.renderMedia()");

        switch (this.state.mediaItemData.type){
            case  MediaType.video:

                if(this.state.mediaItemData.videoSource === undefined) return null;

                return (
                    <ModalMediaVideo
                        videoSource={this.state.mediaItemData.videoSource}
                    />
                    
                )
                
            case  MediaType.audio:
                
                // const url = Tools.fillTags(Config.settings.soundsPath, {cdn: Config.settings.cdn, _id: this.itemData._id});
                const url = Config.getSoundPath(this.state.mediaItemData._id);
                log(" - url = " + url)

                
                return (
                    <div className="media">
                        <Waveform
                            // url="/audio/the_arrogant_man.mp3"
                            url={url}
                        />
                    </div>
                )
            case  MediaType.script:
                
                // Script banner
                let showBanner:boolean = false;
                
                let imagePath:string = "";
                if(this.state.mediaItemData.type === MediaType.script){
                    
                    const story = Content.getStory(this.state.topicData.topicId)
                    if(story && story.manualMediaItems){
                        const manualMediaItem:any = story.manualMediaItems[this.state.mediaItemData.id]
                        if(manualMediaItem && manualMediaItem.hasScriptBanner && manualMediaItem.hasScriptBanner[Audience.id] === true){
                            showBanner = true;

                            imagePath = this.imagePath + "script_banners/" + Audience.id + "/" + this.state.topicData.topicId + "." + this.state.mediaItemData.id;
                            imagePath += "." + Lang.langId; 
                            imagePath += ".jpg";
                        }
                    }
                    
                }
                return (
                    <div className="media">

                        {showBanner &&
                            <div className="img-container">
                                <img src={imagePath} alt=""/>
                            </div>
                        }
                    </div>
                )
                
            case  MediaType.comic:

                // Get any manual data or this media item
                const story:any = Content.getStory(this.props.topicData.topicId);
                const mediaItemId:string = this.state.mediaItemData.id;
                const manualMediaItem:ContentManualMediaItem|undefined = story?.manualMediaItems?.[mediaItemId];
                const isFormatImage:boolean = manualMediaItem?.comic?.fileFormat === "jpg";

                let flashUrl = "";
                if(!isFormatImage){
                    const _id:string = this.state.mediaItemData._id || "";
                    flashUrl = Config.getFlashComicPath(_id);
                }
                return (
                    <div className="media">
                        {isFormatImage ?
                            
                                <ModalMediaComic
                                    mediaItem={this.props.mediaItemData}
                                    manualMediaItem={manualMediaItem}
                                    isMax={this.state.isMax}
                                    onMaximizePress={this.handleMaxClick}
                                    onMinimizePress={this.handleMinClick}
                                />
                            :
                            <div>
                                <Flash
                                    id={"comic_" + mediaItemId}
                                    url={flashUrl}
                                    flashWidth={700}
                                    flashHeight={380}
                                    backgroundColor={"#F5F4F3"}
                                    langId={Lang.langId}
                                    langMode={LangMode.reload}
                                    onReady={undefined}
                                    preloader={PreloaderId.ruffle}
                                />
                            </div>
                        }
                    </div>
                )
            
        
            case  MediaType.game:
                
                const _id:string = this.state.mediaItemData._id || "";
                const flashGameUrl = Config.getFlashGamePath(_id);
                
                return (
                    <div className="media">
                        
                            
                            <Flash
                                id={"game_" + this.state.mediaItemData.id}
                                url={flashGameUrl}
                                flashWidth={700}
                                flashHeight={380}
                                backgroundColor={"#F5F4"}
                                // backgroundColor={"#F5F4F3"}
                                langId={Lang.langId}
                                langMode={LangMode.reload}
                                onReady={undefined}
                                preloader={PreloaderId.ruffle}
                                
                            />
                        
                    
                    </div>
                )
                
            default:
                return <div></div>;
        }

        
    }

    handleFlashReady = () => {

    }

    renderAudioIntro():any{
        if(!this.state.mediaItemData.description) return null

        return (
            <div className="modal-media-description">
                <p>{Lang.tc(this.state.mediaItemData.intro)}</p>
            </div>
        )
    }

    renderDescription():any{
        if(!this.state.mediaItemData.description) return null

        return (
            <div className="modal-media-description">
                <p>{Lang.tc(this.state.mediaItemData.description)}</p>
            </div>
        )
    }

    renderIntro():any{
        if(!this.state.mediaItemData.intro) return null;
        log("ModalMedia.renderIntro()")
        const str = Lang.tc(this.state.mediaItemData.intro);
        if(!str) return null;
        return (
            <div className="modal-media-summary text-column">
                <h3><i>{str}</i></h3>
            </div>
        )

    }

    renderText():any{
        if(!this.state.mediaItemData.text) return null;

        let paras:string[] = Tools.stringToArray(Lang.tc(this.state.mediaItemData.text))
        let warning:any = null;
        if(paras.length === 0){
            paras = Tools.stringToArray(Lang.tc(this.state.mediaItemData.text, true))
            warning = 
                <Message 
                    title={Lang.t("error." + Lang.langId + "NotAvailable.ga")} 
                    text={Lang.t("error." + Lang.langId + "NotAvailable.en")}
                    key="message_no_lang"
                />
        }
        let items:any[] = [];
        let i = 0;
        for(let para of paras){
            let item =  <p key={"story_para__" + i + "__" + this.state.mediaItemData.id} dangerouslySetInnerHTML={{__html:para}}></p>
            items.push(item);
            i++;
        }
        
        

        return (
            <div className="modal-media-story text-column">
                {warning}
                {items}
                {!this.isAudio && <span className="modal-media-story-underline"></span>}
            </div>
        )

    }
    

    /** Does this topic have any mediaGrid items, eg. video, music, script, comic, games? */
    _hasMediaGridItems?:boolean = undefined;
    get hasMediaGridItems():boolean{
        log("ModalMedia.hasMediaGridItems()")
        if(this._hasMediaGridItems === undefined){
            let types:MediaType[] = Topic.getMediaTypesFromTopic(this.props.topicData, true);
            this._hasMediaGridItems = types.length > 1;
            
        }
        log(" - this._hasMediaGridItems = " + this._hasMediaGridItems)
        return this._hasMediaGridItems;
    }


    get locationString():string{
        // log("TopicScreen.locationString()");
        let str = "";
        if(this.props.topicData.topicId){
            str = Content.getStoryLocation(this.props.topicData.topicId)
            // log(" - this.story.id = " + this.story.id)
        }
        
        // log(" - str = " + str)
        return str;
    }

    render(){
        log("ModalMedia.render()")
        log(" - _id = " + this.state.mediaItemData._id)
        let bodyClass = "modal-media-body";
        if(this.state.hidden) bodyClass += " hidden";

        let showOnlyTypes:MediaType[]|string[]|undefined = undefined;
        if(Audience.isAudienceToddlers){
            // showOnlyTypes = [this.props.mediaItemData.type]
        }

        // Styles
        let contentMainClass = "modal-media-content-main";
        if(Audience.isAudienceLearners || !this.hasMediaGridItems) contentMainClass += " no-side-col";

        let modalClassName:string = "modal modal-media-" + this.state.mediaItemData.type;
        if(this.state.isMax) modalClassName += " max";

        // Don't show text area if in max mode
        const showTextArea:boolean = !this.state.isMax;

        // Min / max buttons in header bar
        // let showMinMaxButs:boolean = false;
        // if(this.isComic){
        //     if(Topic.isComicFormatJpg(this.state.mediaItemData, this.props.topicData.topicId)) showMinMaxButs = true;
        // }

        return(

            <ModalScreen
                open={this.props.isOpen}
                onClose={this.props.onClose}
            >
                <div className={modalClassName} id="modal-media">
                    <div className="modal-header">
                        <div className="modal-header-text">
                            <h3>{Lang.tc(this.props.topicData.title)}</h3>
                        </div>
                        <div className='buts'>
                           

                            <BootstrapTooltip
                                title={Lang.t("nav.menu.lang.tip")}
                            >
                                <button 
                                    aria-label={Lang.t("nav.menu.lang.tip")} 
                                    className="but lang-but" onClick={this.handleLangClick}
                                >
                                    
                                    <div className="but-icon lang-icon"></div>
                                    
                                </button>
                            </BootstrapTooltip>

                            <BootstrapTooltip
                                title={Lang.t("but.close")}
                            >
                            <button 
                                aria-label={Lang.t("but.close")} 
                                className="but close-but" onClick={this.handleCloseClick}
                            >
                                <Close className="but-icon" />
                            </button>
                            </BootstrapTooltip>
                        </div>

                    </div>
                    <div className={bodyClass}>
                        
                        
                        {/* ACTUAL MEDIA */}
                        {this.renderMedia()}
                        

                        {/* TEXT AREA */}
                        {showTextArea &&
                        <div className="modal-content modal-text modal-media-content">

                            <div className={contentMainClass}>
                            
                                {/* TITLE */}
                                <div className="modal-media-title">
                                    <h1>{Lang.tc(this.state.mediaItemData.title)}</h1>

                                    {this.locationString  &&
                                        <div className="location">
                                            <span className="location-from">{Lang.t("locationFrom")}</span>
                                            <span className="location-place">{this.locationString}</span>
                                        </div>
                                    }
                                </div>

                                {/* CREDITS */}
                                {/* {this.renderCredits(this.state.mediaItemData.contributors)} */}
                                <Credits
                                    contributors={this.state.mediaItemData.contributors}
                                    id={this.state.mediaItemData.id}
                                />


                                {/* -----------------------------------
                                * VIDEO 
                                */}
                                {this.isVideo && this.renderDescription()}

                                {/* -----------------------------------
                                * COMIC 
                                */}
                                {this.isComic ?
                                    <div>
                                        {/* {this.renderDescription()} */}
                                        {this.renderIntro()}
                                    </div>
                                    :
                                    null
                                }

                                

                                
                                
                                {/* -----------------------------------
                                * AUDIO 
                                */}
                                {this.isAudio ?
                                    <div>
                                        {this.renderAudioIntro()}
                                        {this.state.mediaItemData.text && (
                                            <div className="modal-media-lyrics">
                                                <h3>{Lang.t("media.lyrics")}</h3>
                                                <div className="title-underline"><span/></div>
                                                {this.renderText()}
                                            </div>
                                        )}
                                    </div>
                                    :
                                    null
                                
                                }

                                {/* -----------------------------------
                                * SCRIPT 
                                */}
                                {this.isScript ?
                                    <div>
                                        {this.renderIntro()}
                                        {this.renderText()}
                                        
                                    </div>
                                    :
                                    null
                                }
                            </div>

                            {Audience.isAudienceAnyKids && this.hasMediaGridItems &&
                                <div className="modal-media-content-side">
                                    <div className="modal-media-grid">
                                        <MediaGrid
                                            topicData={this.props.topicData}
                                            mediaItemId={this.props.mediaItemData.id}
                                            showOnlyTypes={showOnlyTypes}
                                            hideIfSingleItem={true}

                                        />
                                    </div>
                                
                                </div>
                            }

                            
                            
                        </div>
                        }
                        
                    </div>
                    
                    
                </div>
            </ModalScreen>
        )
    }
}