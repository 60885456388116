//@ts-ignore
import _ from 'lodash'


import Audience, {AudienceId} from '../tools/Audience'
import Lang from '../tools/Lang';
import Searcher from './Searcher';
import Tools from '../tools/Tools';
import Config from '../config/Config';
import type {MediaItemData, MediaType} from './Topic';
import {TopicData} from './Topic';
import App from '../App';

const DEBUG__SKIP_LOADING_FRESH_CONTENT = false


// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

export type GroupData = {
    id:string,
    sectionId:string,
    title:Object,
    items:GroupItemData[]
}

export type GroupItemData = {
    id:string,
    title:Object,
    description:Object
}



type RawSubgroup = { _id:string,	title: LangString }
type RawGroup = {
	_id:string,	
	title: LangString,
	items:{[key:string] : RawSubgroup}
}
type RawGroups = {[key:string]: RawGroup}

type RawStoryManualData = {
	id:string,
	sort:ContentSortSections,
    hasTopicBanner?:boolean,
    topicBannerCredit?:LangString,
    manualMediaItems?:{[key:string]:RawManualMediaItem},
    descriptions:ContentDescriptions,
    titles:ContentTitles,
}

type ContentDescriptions = {
    [key:string]: LangString
}

type ContentTitles = {
    [key:string]: LangString
}

export type RawManualMediaItem = {
    
    // Script
    hasScriptBanner?:RawManualMediaItemScriptBanner,

    hiddenOnTouchDevice?:boolean,

    difficulty?:DifficultyLevel,
    
    // Comic
    comic?:RawManualMediaItemComic,
}

export enum DifficultyLevel {
    level1 = "1",
    level2 = "2",
    level3 = "3",
    level4 = "4",
    level5 = "5",
    level6 = "6",
}

type RawManualMediaItemComic = {
    fileFormat?:string,
    audiences?:AudienceId[],
    langs:RawManualMediaItemLangs
    
}
type RawManualMediaItemLangs = {
    [key:string]:RawManualMediaItemLangItem,
}
type RawManualMediaItemLangItem = {
    totalPages?:number,
    width?:number,
    height?:number,
}


type RawManualMediaItemScriptBanner = {
    kids?:boolean,
    learners?:boolean
}



// type RawStory = {
    
//     // E.g. "28"
//     _id:string,

//     // E.g. "the_neishes_and_the_macnabs"
//     id:string,
    
//     // E.g. { toddlers: 0, kids: 1, learners: 1 }
//     audiences?:any,

//     // This story goes into which characters rows?
//     characters?:RawStoryCharacter[],
    
//     // This story goes into which places rows?
//     places?:RawStoryPlace[],
    
//     // This story goes into which storyTypes rows?
//     storyTypes?:RawStoryStoryType[],
    
//     // This story goes into which superStories rows?
//     superStories?:RawStorySuperStory[],

//     title?:LangString,

//     description?:LangString,

//     // This is set currently in the stories manual data
//     hasTopicBanner?:boolean,

//     // Is the entire topic hidden on touch devices? Usually this is because the topic contains only one thing – a game that is incompatible with touch devices
//     hiddenOnTouchDevice?:boolean,

//     // When a story is used multiple times in a row, this member will be populated with e.g. "clan_neish", or "clan_macnab"
//     duplicateId?:string,

//     // When a story should open a specific media item wen tapped then this is populated
//     mediaItemId?:string,

//     // Alternate descriptions when story is disaplyed in specific places
//     descriptions?:ContentDescriptions,

//     // Alternate titles when story is disaplyed in specific places
//     titles?:ContentTitles,

// 	// Each story usually has some media. We keep a list of the media types and their tags, e.g.
// 	//	{
// 	//		game: [],
// 	//		"archive.sounds": ["song", "storytelling"]
// 	//	}
// 	mediaTags?:Record<string, string[]>,
// }

export type RawContent = {
	audiences:			RawAudiences,
	places: 			RawGroups,
	characters: 		RawGroups,
	storyTypes: 		RawGroups,
	specialCollections:	RawGroups,
	superStories: 		{[key:string]: RawSubgroup},
	stories:			Array<any>,
	storiesManualData:	Array<RawStoryManualData>,
	materials:			Array<any>,
}

export type ContentMaterial = {
	/** E.g. "318" */
	_id:string,
	/** E.g. "black_dog" */
	id:string,
	
	story:string,
	tags:string[],
	type:string,
	title:LangString,
	description:LangString,
	keywords?:LangString,

	hiddenOnTouchDevice?:boolean,
	audiences?:{[key:string]: number},
}

type RawAudiences = Array<RawAudience>;
type RawAudience = {
    id:string,
    home:RawAudienceHome
}
type RawAudienceHome = {
    sections:RawAudienceHomeSection[],
    shortcuts?:RawAudienceHomeShortcuts
}
type RawAudienceHomeShortcuts = {
    id:string,
    items:RawAudienceHomeShortcutsItem[]
}

type RawAudienceHomeShortcutsItem = {
    id:string,
    targetId:string,
    title:LangString,
    subtitle:LangString,
    targetPadding?:number,
}

type RawAudienceHomeSection = {
    id:string,
    title:LangString,
    subtitle?:LangString,
    rows:RawAudienceHomeSectionRow[],
    items?:RawAudienceHomeSectionItem[],
    numRowsVisibleAtStart: number,
    aspect?:ContentSectionRowAspect
}

type RawAudienceHomeSectionRow = {
    id:string,
    switchTitle?:boolean,
    images:RawAudienceHomeSectionRowImages,
	strips?:RawAudienceHomeSectionRowStrip[],
    title?:LangString,
    aspect?:ContentSectionRowAspect
}
type RawAudienceHomeSectionRowStrip = {
	id?:string,
	title?:string,
	items:RawAudienceHomeSectionItem[],
}

type RawAudienceHomeSectionItem = {
    topicId:string,
    itemId?:string,
    mediaType?:MediaType|string,
}

type RawAudienceHomeSectionRowImages = {
    left?:boolean,
    right?:boolean
}

type RawStoryPlaceLocations = {
	title:LangString
}
type RawStoryPlace = {
	id:string, 
	parent:string,
	locations?:RawStoryPlaceLocations,
}
type RawStoryCharacter = {
	id:string, 
	parent:string,
}

// type RawStoryStoryType = {
// 	id:string, 
// 	parent:string,
// }

// type RawStorySuperStory = {
// 	id:string, 
// 	parent:string,
// }




export type LangString = {[key:string]:string}

// Ids of the various sections appearing in the UI
// These are not necessarily the same as the section ids that appear in the data
export enum UISectionIds {
    places 		= "places",
	storyTypes 	= "storyTypes",
	characters 	= "characters",
    specialCollections = "specialCollections"
}

// Ids of the various sections appearing in the data
export enum ContentSectionId {
	places 		= "places",
	storyTypes 	= "storyTypes",
	characters 	= "characters",
    superStories = "superStories",
}



type ContentSort = {
	
    // Number between 0 and 1
	toddlers:number,

    // Number between 0 and 1
	kids:number,
    
	// Number between 0 and 1
	learners:number
}
type ContentSortRows = {
	[key:string]:ContentSort
}

type ContentSortSections = {
	[key:string]: ContentSortRows
}



export type ContentStory = {
	_id:string,
	id:string,
	title:LangString,
	description:LangString,
	sort?:ContentSortSections,
    places?:ContentStorySection[],
    characters?:RawStoryCharacter[],
    audiences?:Record<AudienceId, number>,
    hasTopicBanner?:boolean,
    hiddenOnTouchDevice?:boolean,
    topicBannerCredit?:LangString,
    duplicateId?:string,
    manualMediaItems?:{[key:string]:ContentManualMediaItem},
    mediaItemId?:string,
    mediaType?:MediaType|string,
    descriptions?:ContentDescriptions,
    titles?:ContentTitles,
	mediaTags?:ContentMediaTags,
}
export type AnyContentItem = RawGroup|RawSubgroup|ContentStory|ContentMaterial|undefined;

export type ContentMediaTags = Record<string, string[]>;



export type ContentManualMediaItem = {
    
    // Script
    hasScriptBanner?:boolean,

    // Some games require a keyboard etc, so will be disabled on touch devices
    hiddenOnTouchDevice?:boolean,

    // All games have a difficulty value
    difficulty?:DifficultyLevel,

    // Comic
    comic?:ContentManualMediaItemComic,
    
	audiences?:{[key:string]: number},
}

export type ContentManualMediaItemComic = {
    fileFormat?:string,
    langs:ContentManualMediaItemLangs
    
}
export type ContentManualMediaItemLangs = {
    [key:string]:ContentManualMediaItemLangItem,
}
export type ContentManualMediaItemLangItem = {
    totalPages?:number,
    width?:number,
    height?:number,
}

type ContentStorySection = {
    id:string,
    parent:string,
    locations?:ContentStorySectionLocations
}
type ContentStorySectionLocations = {
    title:LangString
}
export type ContentShortcuts= {
    id:string,
    items:ContentShortcutsItem[]
}
export type ContentShortcutsItem = {
    id:string,

    title:LangString,
    subtitle:LangString,
    
    // Id of target DOM element that we scroll to on click
    targetId:string,

    // 
    targetPadding?:number
}

export enum ContentSectionItemLayout {
    row = "row",
    column = "column"
}


export type ContentSectionRow = {
	path:string,
	id:string,
    groupId:string,
    sectionId:string,
	title:LangString,
	// allItems:Array<ContentStory>,
	strips?:Array<ContentSectionRowStrip>,
    images?:ContentSectionRowImages,
    uiSectionId:string,
    switchTitle?:boolean,
    aspect?:ContentSectionRowAspect

}

export type ContentSectionRowStrip = {
	id?:string,
	title?:string,
	allItems:Array<ContentStory>,
	items:Array<ContentStory>,
}

// Thumbnail aspect
export enum ContentSectionRowAspect{
    portrait = "portrait",
    landscape = "landscape",
    panavision = "panavision",

}

export type ContentSectionRowImages = {
    left?:boolean,
    right?:boolean
}

export type ContentSection = {
	id:string,
	title:LangString,
    subtitle?:LangString,
	rows:Array<ContentSectionRow>,
    items?:Array<ContentStory>,
	numRowsVisibleAtStart:number,
    aspect?:ContentSectionRowAspect,
}
export type ContentHome = {
	sections:Array<ContentSection>,
    shortcuts?:ContentShortcuts
}
export type ContentAudience = {
	id:AudienceId,
	home:ContentHome,
}
export type ContentData = {
	audiences:Array<ContentAudience>,
	stories:Array<ContentStory>,
	materials:Array<ContentMaterial>,
}

let instance:Content;

export default class Content {
	// Only stories which have these media types will be included in the Oral Traditions section
	// Note: I suspect we may need to whittle this down some more. Like, just use the `formTypeStoryFilters` in OralScreen?
	static VALID_ORAL_MEDIA_TYPES:string[] = [
        // "audio",
        "archive.sound"
    ]

	STORIES_URL:string = Config.getStoriesDataPath();

	raw:RawContent|null = null

	data:ContentData|null = null;

	searcher?:Searcher;

	rawStoryMap:{[key:string]:ContentStory} = {}
	rawStoryMapByRawId:{[key:string]:ContentStory} = {}
	
	rawMaterialMapByRawId:{[key:string]:ContentMaterial}|undefined

	constructor(rawContent:RawContent) {
        this.raw = rawContent
        instance = this;
        
	}


	/**
	 * Load some json and slam it into a data object
	 * @param options.fieldpath 		You can pick a field in the object ot assign the loaded json result to.
	 * 									By default we just assign the loaded result into the data.
	 */
	async _loadJson(url:string, data:object, options:{fieldpath?:string} = {}):Promise<void> {
        log(" ");
        log("----------------------------------------");
        log("Content._loadJson()");
		try {
			// const headers = new Headers({"Origin": "localhost"});
			const result = await (await fetch(url)).json()
			if (options.fieldpath!=null) {
				_.set(data, options.fieldpath, result)
			}
			else {
				Object.assign(data, result)
			}
		}	
		catch(err) {
			console.log(`Error loading json from url "${url}"`)
			console.log(err)
			throw err;
		}
	}
	

    static get data():ContentData|null{
        if(!instance) return null;
        return instance.data || null;
    }



	static getNiceId(id?:string, _id?:string):string {
		if (id==null || !id || _id==null || !_id) {
			return "";
		}
		return `${id}_${_id}`;
	}

	static getRawId(niceId?:string):string {
		if (Tools.string.isEmpty(niceId)) return "";
		const _id = niceId?.split("_").pop()
		return _id || "";
	}

	static isLangStringEmpty(langString?:LangString):boolean {
		if (!langString) return true;
		if (Tools.string.isEmpty(langString.en) && Tools.string.isEmpty(langString.ga)) return true;
		return false;
	}	
	static isLangStringPopulated(langString?:LangString):boolean {
		if (!langString) return false;
		if (Tools.string.isEmpty(langString.en) && Tools.string.isEmpty(langString.ga)) return false;
		return true;
	}

	static cleanTopicData(topicData?:TopicData):TopicData|undefined{
        if(!topicData) return;

        let data:TopicData = {...topicData}

        if(data.ref){
            data.ref.en = Tools.stringClean(data.ref.en)
            data.ref.ga = Tools.stringClean(data.ref.ga)
        }

        if(data.mediaItems){
            for(let itemData of data.mediaItems){

                // We add the _id to the end because in the data there are items with non-unique id values
                // _id is always unique within the context of media items
                if(itemData.id) {
					itemData.id = Content.getNiceId(itemData.id, itemData._id)
				}
            }
        }
        return data;
    }

    async init():Promise<Error|null>{
        log("Content.init()");
        
        if(!this.raw) return null;
        
		if (!DEBUG__SKIP_LOADING_FRESH_CONTENT) {
            // let res:any = {};
            try{
			    // res = await this._loadJson(this.STORIES_URL, this.raw)
                await this._loadJson(this.STORIES_URL, this.raw)
            }catch(err){
                console.log("Error loading content: ", err)
                return new Error("Error loading content")
            }
		}	
        
		this.data = this._hydrate(this.raw)
		this.searcher = new Searcher(this, this.data)

		this._populateSections(this.data)
		this._hydrateMaterialAudiencesUsingSections(this.data)
		
		console.log(`CONTENT DATA:`, this.data)
        return null;
        
    }


	/** Look through the data to find an item and its parent (if it has one) */
	getContentById({type, id, parent}:{type:string, id:string, parent?:string}):{item:AnyContentItem, parent:AnyContentItem} {

		switch(type) {
			case 'topic':
				var topic:ContentStory|undefined = this.data?.stories.find((story)=>story.id===id)
				return {item: topic, parent: undefined};

			// Places have an extra level of hierarchy
			case UISectionIds.places:
				const parentPlace:RawGroup|undefined  = this.raw?.[type]?.[parent || ""]
				const place = parentPlace?.items[id]
				return {item: place, parent: parentPlace};

			case UISectionIds.characters:
			case UISectionIds.storyTypes:
			case UISectionIds.specialCollections:
				const sectionId = parent as UISectionIds
				const raw = this.raw
				const item:RawSubgroup|undefined  = this.raw?.[type]?.[sectionId]?.items?.[id]
				return {item, parent: undefined};

			case 'material':
				const material = this.getMaterialByRawId(id)
				if (material) {
					var parentTopic:ContentStory|undefined = this.getStoryByRawId(material.story)
				}
				return {item: material, parent: parentTopic};

			default:
				console.log(`type, id, parent:`, {type, id, parent})
				throw Error(`Content.getContentById(${type}, ${id}): Not yet implemented type ${type}`)
		}	
	}
	

	getStoryFullPlaceNames(storyPlaces?:RawStoryPlace[]):LangString|null {
		if (!storyPlaces) return null;

		let lines:{en:string[], ga:string[]} = {en: [], ga: []}
		storyPlaces.map((storyPlace:RawStoryPlace):null => {
			let parts:{en: string[], ga:string[]} = {en:[], ga: []}
			const {item, parent}:{item:AnyContentItem, parent:AnyContentItem} = this.getContentById({type:UISectionIds.places, id: storyPlace.id, parent: storyPlace.parent})
			if (parent?.title) {
				parts.en.push(parent.title.en || "")
				parts.ga.push(parent.title.ga || "")
			}

			if (item?.title) {
				parts.en.push(item.title.en || "")
				parts.ga.push(item.title.ga || "")
			}

			if (storyPlace.locations?.title) {
				parts.en.push(storyPlace.locations.title.en || "")
				parts.ga.push(storyPlace.locations.title.ga || "")
			}
			lines.en.push(parts.en.join(", "))
			lines.ga.push(parts.ga.join(", "))
            return null;
		})
		const langString = {
			en: lines.en.join("\n").trim(),
			ga: lines.ga.join("\n").trim(),
		}
		return langString;
	}


	getStoryCharacterNames(storyCharacters?:RawStoryCharacter[]):LangString|null {
		if (!storyCharacters) return null;

		let lines:{en:string[], ga:string[]} = {en: [], ga: []}
		storyCharacters.map((storyChar:RawStoryCharacter):null => {
			let parts:{en: string[], ga:string[]} = {en:[], ga: []}
			const {item, parent}:{item:AnyContentItem, parent:AnyContentItem} = this.getContentById({type:UISectionIds.characters, id: storyChar.id, parent: storyChar.parent})
			if (parent?.title) {
				parts.en.push(parent.title.en || "")
				parts.ga.push(parent.title.ga || "")
			}

			if (item?.title) {
				parts.en.push(item.title.en || "")
				parts.ga.push(item.title.ga || "")
			}

			lines.en.push(parts.en.join(", "))
			lines.ga.push(parts.ga.join(", "))
            return null;
		})
		const langString = {
			en: lines.en.join("\n").trim(),
			ga: lines.ga.join("\n").trim(),
		}
		return langString;
	}


    static isStoryAudienceId(story:ContentStory, audienceId:AudienceId):boolean{
        let isId = false;
        if(story.audiences && story.audiences[audienceId] > 0) isId = true;
        return isId;
    }
    static isStoryAudienceLearners(story:ContentStory):boolean{
        return this.isStoryAudienceId(story, AudienceId.learners);
    }
    static isStoryAudienceKids(story:ContentStory):boolean{
        return this.isStoryAudienceId(story, AudienceId.kids);
    }
    static isStoryAudienceToddlers(story:ContentStory):boolean{
        return this.isStoryAudienceId(story, AudienceId.toddlers);
    }

	/**
	 *
	 * @param story 
	 * @param audienceId 
	 * @returns 
	 */
    static isMediaItemAudienceId(mediaItem:MediaItemData, story:ContentStory, audienceId:AudienceId):boolean{
		// console.log(`mediaItem, story:`, mediaItem, story)
        let isId = false;
        if(!mediaItem || !story) return false;
		// console.log(`TESTING isMediaItemAudienceId mediaItemId:${mediaItem.id}`)
        if(story.manualMediaItems && story.manualMediaItems[mediaItem.id]){
            const manualMediaItem = story.manualMediaItems[mediaItem.id];
            if(manualMediaItem?.audiences?.[audienceId]) {
				isId = true;
			}
        }

        return isId;
    }
    static isMediaItemAudienceLearners(mediaItem:MediaItemData, story:ContentStory):boolean{
        return this.isMediaItemAudienceId(mediaItem, story, AudienceId.learners);
    }   


	static isMaterialForAudience(audience?:string, material?:ContentMaterial):boolean|undefined {
		if (!audience) return;
		const materialAudienceValue:number|undefined = material?.audiences?.[audience]
		if (materialAudienceValue!=undefined) {
			return materialAudienceValue >= 1;
		}
	}

	static isStoryForAudience(audience:AudienceId, story?:ContentStory):boolean|undefined {
		if (!audience) return;
		const storyAudienceValue:number | undefined = story?.audiences?.[audience]
		if (storyAudienceValue!=undefined) {
			return storyAudienceValue >= 1;
		}
	}

	static isMaterialBlacklistedForAudience(audience?:string, type?:string):boolean {
		if (!audience) return false;
		const blacklist:string[]|undefined = Config.settings.search.audiences[audience]?.resultItemTypeBlacklist
		if (!type || !blacklist) return false;
		return blacklist.includes(type);
	}



    /**
     * Convenience function to get the audience data for a particular audience
     * @returns ContentAudience
     */
    static getAudienceData():ContentAudience|undefined{
        let audienceData:ContentAudience|undefined;
        this.data?.audiences.forEach((data:ContentAudience)=>{
            if(data.id === App.state.audienceId){
                audienceData = data;
            }
        })
        return audienceData;
    }



	//--------------------------------------------- HYDRATION ----------------------------------------------------


	_hydrate(raw:RawContent):ContentData {
        log(" ")
        log("-------------------------------")
        log("Content._hydrate()");
		this._hydrateRawStories(raw.stories, raw.storiesManualData, raw.materials)



		let data:ContentData = {
			audiences: 	raw.audiences.map(this._hydrateAudience),
			stories: 	raw.stories,
			materials:	raw.materials,
		}

        // log("Content._hydrate")
		// log(" - data = ", data)
		return data;
	}


	/**
	 * We're struggling to assign audience metadata to materials, since the CMS 
	 * only allows us to assign audience metadata to stories.
	 * However, in content.flat.js `audiences[audienceId].home.sections[].items` 
	 * tells us if a material item is in a particular audience.
	 * As a stopgap, we populate materials with audience metadata using this insight.
	 */
	_hydrateMaterialAudiencesUsingSections(data:ContentData) {
		const materialsMap = Tools.array.toObject(data.materials, "_id")
		
		// Only kids and toddlers have items directly in the flat data json.
		const audienceIds = [AudienceId.kids, AudienceId.toddlers]
		audienceIds.forEach(audienceId  => {
			// console.log(`_hydrateMaterialAudiencesUsingSections(audience ${audienceId})`)
			const audience = data.audiences.find(audience=>audience.id===audienceId)
			const sections:ContentSection[]|undefined = audience?.home.sections
			sections?.forEach((section:ContentSection)=> {
				// if (audienceId===AudienceId.kids && section.id==="characters") console.log(`Content section:`, section);
				// Some sections just have items, e.g. in the toddlers side Music section
				section.items?.forEach((item:ContentStory) => {
					this._tagMaterialWithAudience(materialsMap, item, audienceId)
				})
				
				// Other sections have `rows.strips.items`, e.g. the kids side Games section.
				section.rows?.forEach((row:ContentSectionRow)=> {
					const shouldLog = false && audienceId===AudienceId.kids && row.id=="musicAndSongs.songs"
					if (shouldLog) {
						console.log(`Content row:`, row);
					}

					row.strips?.forEach((strip:ContentSectionRowStrip)=> {
						if (shouldLog) console.log(`STRIP ${strip.items?.length}`, strip.items);

						strip.items?.forEach((item:ContentStory) => this._tagMaterialWithAudience(materialsMap, item, audienceId, row, shouldLog))
					})
					// Some sections have simple rows, no strips. 
					// Each row will have an `id` like "characters.pets". Ignore these for now.
				})
			})
		})
	}

	/** 
	 * Used by this._hydrateMaterialAudiencesUsingSections() 
	 * @param story		Important: 
	 * 					For certain types of story items there is no mediaItemId. This seems to be deliberate.
	 * 					E.g. all Kids > Places items have not mediaItemId. You can see when you 
	 * 					hover over the item, it's a link to the story. When you tap the item it 
	 * 					figures out which media item to take you to.
	*/
	_tagMaterialWithAudience(materialsMap:{[key:string]: any}, story:ContentStory, audienceId:string, row:any=undefined, shouldLog=false) {
		if(story._id==="29") {
			console.log(`STOP!`)
		}

		// We weren't given enough information to pick out a specific material.
		// The item is just a story, and the link is calculated when it's tapped.
		// Why this way? I suppose because there are a few items within the story that fit that audience?
		// For now we just leave it alone, we're not going to do all that link logic at hydration.
		if (!story.mediaItemId) {
			// console.log(`Content._tagMaterialWithAudience(story ${story._id} (${story.id}, ${audienceId}, row ${row.id}) has no mediaItemId)`);
			return;
		}
		const materialRawId = Content.getRawId(story.mediaItemId)
		const material = materialsMap[materialRawId]

		// Weird, there isn't a material with this id
		if (!material) {
			console.log(`Content._tagMaterialWithAudience(story ${story._id} ${story.id}, ${audienceId}, row ${row.id}) could not find material ${story.mediaItemId} (${materialRawId})`)//, item:`, item)
			return;
		}

		// if (materialRawId==="35") {
			// console.log(`STOP!`, material)
		// }
		if (shouldLog) console.log(`Content material: `, material)

		// A material inherits its parent story's audiences, and can override with its own.
		// This merge will do that.
		// Also: This material is definitely for "this" specific audience, so set it to 1.
		// Why? Because we're going through all the items on the audienceId's home screen (e.g. Kids).
		// IMPORTANT: We've decided, for now a material does not inherit its parent's audiences.
		// 			  It causes games to open in learners section sometimes, e.g. "Spring Cleaning"
		// material.audiences = _.merge({}, story.audiences, material.audiences, {[audienceId]: 1})
		material.audiences = _.merge({}, material.audiences, {[audienceId]: 1})

	}



	_hydrateRawStories(rawStories:Array<any>, manualStories:Array<any>, rawMaterials:Array<any>) {
        // console.log("Content._hydrateRawStories()");
        // console.log(" rawStories = ", rawStories)
        // console.log(" manualStories = ", manualStories)
		this.rawStoryMap = {}
		rawStories.forEach((story:any) => this.rawStoryMap[story.id] = story)


		
        // Merge in any manual stories data
		const materialsMap = Tools.array.toObject(rawMaterials, "_id")
		let numManualItemsMerged = 0
		manualStories.forEach((manualStory:any) => {
			let story = this.rawStoryMap[manualStory.id]
			if (story) {
				_.assign(story, manualStory)
			}
			if (manualStory.manualMediaItems) {
				for (let niceId in manualStory.manualMediaItems) {
					const manualMaterial = manualStory.manualMediaItems[niceId]
					const rawMaterialId:string = Content.getRawId(niceId)
					const rawMaterial:Object|undefined = materialsMap[rawMaterialId]
					if (rawMaterial) {
						_.merge(rawMaterial, manualMaterial)
						numManualItemsMerged++;
					}
				}
			}
		});
		if (numManualItemsMerged>0) {
			// console.log(`Merged ${numManualItemsMerged} manualMediaItems into rawMaterials.`)
		}
	}

	getMaterials():ContentMaterial[] {
		return this.data?.materials || [];
	}


    /**
     * Get a list of ContentStory items for the Oral Traditions page.
	 * Note: So far this isn't filtered by audience.
	 * 
     * @returns ContentStory[]
     */
    getOralStories():ContentStory[]{
        if(!this.data?.stories) return [];

		const validOralTypesMap = Tools.array.toMap(Content.VALID_ORAL_MEDIA_TYPES)

		// Filter the stories looking for 
		const oralStoryFilter = ((story:ContentStory) => {
            if(!story?.mediaTags) return;
			const storyMediaTypes:string[] = Object.keys(story.mediaTags)
			const hasOralMedia:boolean = storyMediaTypes.reduce(
				(resultSoFar:boolean, el:string) => resultSoFar || (validOralTypesMap[el]===true)
				, false
			)
			return hasOralMedia;
		})

		const oralStories:ContentStory[] = Object.values(this.data.stories)
										.filter(oralStoryFilter)
										.map(_.cloneDeep)

        return oralStories;
    }

	_hydrateAudience = (raw:RawAudience):ContentAudience => {
        log(" ")
        log("-------------------------------")
        log("Content._hydrateAudience()");
        log(" - raw.id = " + raw.id);
        let contentAudience:ContentAudience = {
			id: raw.id as AudienceId,
			home: {
				sections: raw.home.sections.map(this._hydrateSection),
                shortcuts: raw.home.shortcuts
			}
		}

		return contentAudience;
	}

	_hydrateSection = (rawSection:RawAudienceHomeSection):ContentSection => {
        log(" ")
        log("-------------------------------")
        log("Content._hydrateSection()");
        log(" - rawSection.id = " + rawSection.id)
		let section:ContentSection = {
			id: 	rawSection.id,
			title: 	rawSection.title,
            subtitle: 	rawSection.subtitle,
            rows:[],
			numRowsVisibleAtStart: rawSection.numRowsVisibleAtStart,
            aspect: rawSection.aspect
		}

        // Logging
        let shouldLog:boolean = false;//rawSection.id === "specialCollections";

        // Rows (used by kids and learners audiences)
        if(rawSection.rows){
            let rows:ContentSectionRow[]|any = rawSection.rows.map((rawRow:RawAudienceHomeSectionRow) => {
                    shouldLog = false; //rawRow.id === "media.comics"//"superStories.scottish_urisk"
                    return this._hydrateSectionRow(rawRow, rawSection.id, shouldLog) 
                })
                .filter((row:ContentSectionRow|undefined) => row!==undefined);
            if(rows) section.rows = rows;
        }

        // Items (used by toddlers audience)
        if(rawSection.items){
            let items:ContentStory[]|any = this._getStoriesByHomeSectionItems(rawSection.items, "", false);
            items = items.filter((item:ContentStory|undefined) => item!==undefined);
            if(items) section.items = items;
        }
        return section;
	}

	_hydrateSectionRow = (rawRow:RawAudienceHomeSectionRow, sectionId:string, shouldLog:boolean = false):ContentSectionRow|undefined => {
		

        if(!this.raw) return;

        if(!rawRow) return;
        
        let rowId = rawRow.id;

        
        if(shouldLog){
            console.log(" ")
            console.log("----------------------------------------")
            console.log("Content._hydrateSectionRow()");
            console.log(" - rawRow = ", rawRow)
            console.log(" - sectionId = " + sectionId)
            console.log(" - rowId = ", rowId)
            // console.log(" - itemIds = ", itemIds)
        }

        const uiSectionId:string = sectionId;
        
        let parts:Array<string> = rowId.split(".")
        sectionId = parts[0];
		const groupId:string = parts[1]
		if(shouldLog) console.log(" - parts = " + parts);
        if(shouldLog) console.log(" - groupId = " + groupId);
		const subgroupId:string|undefined = parts.length > 2 ? parts[2] : undefined
        if(shouldLog) console.log(" - subgroupId = " + subgroupId);
		
		// Get the group title from the raw data
		let rawGroups:RawGroups|any = this.raw[sectionId as keyof RawContent]
		let title:LangString | null = null

        if(rawRow.strips){
            let row:ContentSectionRow = {
                id: rowId,
                switchTitle:rawRow.switchTitle,
                path: `${sectionId}.${rowId}`,
                sectionId: sectionId,
                uiSectionId: uiSectionId,
                groupId: groupId,
                title:rawRow.title || {en:'', ga:''},
                // allItems: this._getStoriesByHomeSectionItems(rawRowItems, rowId, shouldLog),
                strips: this._getStripsByHomeSectionRowStrips(rawRow.strips, rowId, shouldLog),
                aspect:rawRow.aspect
            }
            if(rawRow.images) row.images = rawRow.images;
            if(shouldLog){
                console.log(" - row = ", row)
            }
            return row;
        }else{
            if (rawGroups) {
                const group:RawGroup = rawGroups[groupId]
                if (group) {
                    //@ts-ignore
                    const subgroup:RawSubgroup = group.items && group.items[subgroupId]
                    title = subgroup ? subgroup.title : group.title
                }

                if (title!=null) {
                    let row:ContentSectionRow = {
                        id: rowId,
                        switchTitle:rawRow.switchTitle,
                        path: `${sectionId}.${rowId}`,
                        sectionId: sectionId,
                        uiSectionId: uiSectionId,
                        groupId: groupId,
                        title,
						strips: [
							{
		                        allItems: this._getStories(sectionId, groupId, subgroupId, shouldLog),
								items: [],
							}
						],
                    }
                    if(rawRow.images) row.images = rawRow.images
                    if(shouldLog) console.log(" - row = ", row)
                    if(shouldLog){
                        console.log(" ")
                        console.log("-------------------------")
                    }
                    return row;
                }
            }
        }
       
	}


	_getStripsByHomeSectionRowStrips = (strips:RawAudienceHomeSectionRowStrip[], rowId:string, shouldLog:boolean = false):ContentSectionRowStrip[] => {
		return strips.map(strip => 
			({
				id: 		strip.id,
				title: 		strip.title,
				allItems: 	this._getStoriesByHomeSectionItems(strip.items, rowId, shouldLog),
				items: 		[],
			})
		);
	}


    /**
     * Get an array of stories from a given list of RawAudienceHomeSectionItem objects.
     * We use this when hydarating to populate rows where we have specific items hard-coded into content.flat.js
     * @param sectionItems List of RawAudienceHomeSectionItem objects
     * @param shouldLog 
     * @returns Array of ContentStory objects
     */
    _getStoriesByHomeSectionItems = (sectionItems:RawAudienceHomeSectionItem[], rowId:string, shouldLog:boolean = false):ContentStory[] => {
        
        if(shouldLog){
            console.log("Content._getStoriesById()")
            console.log(" - ids = ", sectionItems)
        }
        if(!this.rawStoryMap) return [];

        let stories:Array<ContentStory> = [];
        let topicIdsCount:{[key:string]:any} = {}
        for(let sectionItem of sectionItems) {
            let story = this.rawStoryMap[sectionItem.topicId];
            if(story){
                
                // If the item requires a keyboard but we're on a touch device, then ignore
                let isHiddenOnTouchDevice = this.isMediaItemHiddenOnTouchDevice(story, sectionItem.itemId) && Tools.isTouchDevice;
                if(isHiddenOnTouchDevice) continue;

                // Create a new story object, with mediaItemId
                story = {...story, mediaItemId: sectionItem.itemId, mediaType:sectionItem.mediaType};

                // Increment count
                if(topicIdsCount[sectionItem.topicId] === undefined){
                    topicIdsCount[sectionItem.topicId] = {num:0, sectionItem:sectionItem};
                }
                topicIdsCount[sectionItem.topicId].num ++;
                
                
                
                // Add the story, making sure it has a mediaItemId
                stories.push(story)
            }
        }
        
        for(let sectionItem of sectionItems) {
            if(topicIdsCount[sectionItem.topicId]?.num > 1){
                for (let story of stories){
                    
                    if(story.id === sectionItem.topicId && story.mediaItemId === sectionItem.itemId){
                        story.duplicateId = sectionItem.itemId
                    }
                }
            }
        }
        

        return stories;
    }

    static isMediaItemHiddenOnTouchDevice = (story:ContentStory, niceId:string = ''):boolean => {
        return instance.isMediaItemHiddenOnTouchDevice(story, niceId);
    }

    /** Check if a media item is hidden on mobile (usually because it requires keyboard and Ruffle doesn't show one)
     * @param {ContentStory} story
     * @param {string} niceId - media item id, e.g. "black_toad_38"
     * @returns {boolean}
    */
    isMediaItemHiddenOnTouchDevice = (story:ContentStory|undefined, niceId:string = ''):boolean => {
		let isHidden = false;
		if (story?.hiddenOnTouchDevice) {
			isHidden = true;
		}
        if(story?.manualMediaItems && niceId){
            if(story.manualMediaItems[niceId]?.hiddenOnTouchDevice) isHidden = true;
        }
        return isHidden;
    }

    /**
     * Get a list of ContentStory objects to populate a given section row
     * @param sectionId 
     * @param groupId 
     * @param subgroupId 
     * @param shouldLog 
     * @returns {ContentStory[]}
     */
	_getStories = (sectionId:string, groupId?:string, subgroupId?:string, shouldLog:boolean = false):Array<ContentStory> => {
        if(!this.raw) return [];
        if(shouldLog){
            console.log("Content._getStories()")
            console.log(" - sectionId = " + sectionId)
            console.log(" - groupId = " + groupId)
            console.log(" - subgroupId = " + subgroupId)
            // console.log(" - this.raw.stories = ", this.raw.stories)
        }
    

        // let logNow:boolean = false;
        // let index = -1;
		let stories:Array<ContentStory> = []
        for(let rawStory of this.raw.stories) {
            // index ++;
            // console.log(index + ": " + rawStory.id)
            let logNow = false;
            // if(shouldLog && rawStory.id === "demon"){
            //     logNow = true
            // }
           

            // shouldLog = true;
            
            // Loop through the "characters", "places", "storyTypes", or "superStories" array of the rawStory
			let rawStorySection:Array<any> = rawStory[sectionId]
			if (rawStorySection) {
                if(logNow){
                    console.log(" ")
                    
                    console.log(" - rawStory.id = " + rawStory.id)
                    console.log(" - sectionId = " + sectionId)
                    console.log(" - groupId = " + groupId);
                    console.log(" - subgroupId = " + subgroupId);
                    console.log(" - rawStorySection = ", rawStorySection)    
                }

                // Get a list of any duplicate parent ids in the rawStorySection
                let parents:string[] = [];
                let duplicateParents:string[] = [];
                for (let row of rawStorySection) {
                    if(row.parent !== undefined){
                        if(parents.indexOf(row.parent) === -1) parents.push(row.parent);
                        else duplicateParents.push(row.parent);
                    }
                }

				for (let row of rawStorySection) {
                    if(logNow){
                        console.log("   - row.id = " + row.id)
                    }
                    // Is this item appearing multiple times in the content row?
                    // E.g. in Clansmen and Clan Chiefs, the Battle of Carinish item appears twice, once with Clan MacLeod, and again with Macleod of Dunvegan
                    let isDuplicate:boolean = row.parent ? duplicateParents.indexOf(row.parent) !== -1 : false;

                    // if(logNow){console.log(" - row = ", row)}
                    // SUPER STORIES
                    if(sectionId === ContentSectionId.superStories){
                        const groupIsGood = groupId===row.id;
                        if(groupIsGood){
                            // if(logNow) console.log(" - " + index + " adding rawStory.id: " + rawStory.id)
                            rawStory = {...rawStory}
                            if(isDuplicate){
                                rawStory.duplicateId = row.id;
                            }
                            stories.push(rawStory)
                        }
                    }

                    // NORMAL STORIES
                    else{
                        
                        const groupIsGood = groupId===undefined || groupId===row.parent
                        const subgroupIsGood = subgroupId===undefined || subgroupId===row.id
                        if(groupIsGood && subgroupIsGood){
                            // if(logNow) console.log(" - adding rawStory.id: " + rawStory.id)
                            rawStory = {...rawStory}
                            if(isDuplicate){
                                rawStory.duplicateId = row.id;
                            }
                            stories.push(rawStory)
                        };
                    }
				}
			}
			
		}

		return stories;
	}

    _getStories_LEGACY = (sectionId:string, groupId?:string, subgroupId?:string, shouldLog:boolean = false):Array<ContentStory> => {
        if(!this.raw) return [];
        if(shouldLog){
            console.log("Content._getStories()")
            console.log(" - sectionId = " + sectionId)
            console.log(" - groupId = " + groupId)
            console.log(" - subgroupId = " + subgroupId)
            // console.log(" - this.raw.stories = ", this.raw.stories)
        }
    

        // let logNow:boolean = false;
        // let index = -1;
		let stories:Array<ContentStory> = this.raw.stories.filter((rawStory:any) => {
            // index ++;
            // console.log(index + ": " + rawStory.id)
            let logNow = false;
            // if(shouldLog && rawStory.id === "demon"){
            //     logNow = true
            // }
           

            // shouldLog = true;
            
			let rawStorySection:Array<any> = rawStory[sectionId]
			if (rawStorySection) {
                if(logNow){
                    console.log(" ")
                    console.log(" - rawStory.id = " + rawStory.id)
                    console.log(" - sectionId = " + sectionId)
                    console.log(" - groupId = " + groupId);
                    console.log(" - subgroupId = " + subgroupId);
                    console.log(" - rawStorySection = ", rawStorySection)    
                }

                
				for (let row of rawStorySection) {
                    // if(logNow){console.log(" - row = ", row)}
                    // SUPER STORIES
                    if(sectionId === ContentSectionId.superStories){
                        const groupIsGood = groupId===row.id;
                        if(groupIsGood){
                            // if(logNow) console.log(" - " + index + " adding rawStory.id: " + rawStory.id)
                            return true;
                        }
                    }

                    // NORMAL STORIES
                    else{
                        
                        const groupIsGood = groupId===undefined || groupId===row.parent
                        const subgroupIsGood = subgroupId===undefined || subgroupId===row.id
                        if(groupIsGood && subgroupIsGood){
                            // if(logNow) console.log(" - adding rawStory.id: " + rawStory.id)
                            return true;
                        };
                    }
				}
			}
			return false;
		})

		return stories;
	}

    static getShortcuts():ContentShortcuts|null{
        return instance._getShortcuts();
    }
    _getShortcuts = ():ContentShortcuts|null => {
        return this.getAudienceData(Audience.id).home.shortcuts || null;
    }

    static getStory = (topicId:string):ContentStory|undefined =>{
        return instance.getStory(topicId);
    }

    getStory = (topicId:string):ContentStory|undefined =>{
        if(!this.rawStoryMap) return;
        let story:ContentStory|undefined = this.rawStoryMap[topicId];
        return story;
    }

	static getStoryByRawId = (rawId:string) => instance.getStoryByRawId(rawId)
	
	getStoryByRawId = (rawId:string|undefined):ContentStory|undefined =>{
		if(rawId==null) return;
		if(!this.rawStoryMap) return;
		if (this.rawStoryMapByRawId) {
			this.rawStoryMapByRawId = Tools.object.createMap(this.rawStoryMap, "_id")
		}		
        let story:ContentStory|undefined = this.rawStoryMapByRawId[rawId];
        return story;
    }

	getMaterialByRawId = (rawId:string|undefined):ContentMaterial|undefined =>{
		if(rawId==null) return;
		if(this.data==null) return;
		if (!this.rawMaterialMapByRawId) {
			this.rawMaterialMapByRawId = Tools.array.toObject(this.data.materials, "_id")
		}		
        let material:ContentMaterial|undefined = this.rawMaterialMapByRawId?.[rawId];
        return material;
    }


	static getMaterialByRawId = (rawId:string|undefined):ContentMaterial|undefined => instance.getMaterialByRawId(rawId)

	getMaterialByNiceId = (niceId:string|undefined):ContentMaterial|undefined => {
		const _id = Content.getRawId(niceId)
		const material = Content.getMaterialByRawId(_id)
		return material;
	}
	static getMaterialByNiceId = (niceId:string|undefined):ContentMaterial|undefined => instance.getMaterialByNiceId(niceId)


    static getStoryLocation(topicId:string):string{
        if(!instance) return "";
        return instance.getStoryLocation(topicId);
    }

    getStoryLocation(topicId:string):string{
        let str:string = "";
        const story:ContentStory|undefined = this.getStory(topicId);
        if(story){
            if(story.places && story.places[0] && story.places[0].locations && story.places[0].locations.title){
                str = Lang.tc(story.places[0].locations.title);
            }
        }
        return str;
    }

	//--------------------------------------------- POPULATING SECTIONS ----------------------------------------------------


	_populateSections(data:ContentData) {
		data.audiences.forEach((audience:ContentAudience) => {
			audience.home.sections.forEach( (section:ContentSection)=> {
				section.rows.forEach((row:ContentSectionRow) => {
					row.strips?.forEach((strip:ContentSectionRowStrip) => {
						this._sortRow(row, strip, section.id, audience.id)
					})
				})
			})
		})
	}

    /**
     * New way of populating the items array for a row. This uses the audiences checkboxes from the CMS story page.
     * The checkboxes can be overiden by the content.flat.json storiesManualData if necessary
     * @param row 
     * @param sectionId 
     * @param audienceId 
     */
    _sortRow = (row:ContentSectionRow, strip:ContentSectionRowStrip, sectionId:string, audienceId:AudienceId) => {

		const sortKey:string = "sort_" + sectionId + "_ " + row.id + "_ " + audienceId;
		// console.log(row)

		strip.items = strip.allItems.filter((topic:ContentStory) => {
			let shouldShow = true;

			// The default: show or hide an item based on audiences checkboxes in the CMS
			if(topic.audiences && topic.audiences[audienceId] !== undefined){
				const sortVal = topic.audiences[audienceId];
				shouldShow = sortVal === 0 ? false : true;
				//@ts-ignore
				topic[sortKey] = sortVal;
			}
			

			// Override using content.flat.json storiesManualData.
			// This is so that we can include or exclude an item from particular rows
			if(topic.sort
				&& topic.sort[sectionId]
				&& topic.sort[sectionId][row.id]
				&& topic.sort[sectionId][row.id][audienceId] !== undefined
			){  
				const sortVal = topic.sort[sectionId][row.id][audienceId];
				shouldShow = sortVal === 0 ? false : true;
				
				//@ts-ignore
				// Create a shortcut to the sort value so that we can sort the resulting array
				topic[sortKey] = sortVal;
			}

			return shouldShow;
		})

		// if(row.id === "characters.wizards_witches"){
			// console.log(" ")
			// console.log("------------------------------")
			// console.log("Content._sortRow()")
			// console.log(" - before arr = ", row.items)
		//}

		//@ts-ignore
		Tools.array.sortByKey(strip.items, sortKey, true)

		// if(row.id === "characters.wizards_witches"){
		// console.log(" - after arr = ", row.items)
		//}

    }

    /**
     * This is the old way of filtering the allItems array to create an items array for each row.
     * It does it using the hard coded storiesManualData object found in content.flat.json (specifcally the topic.sort object in storiesManualData)
     * @param row 
     * @param sectionId 
     * @param audienceId 
     */
	// _sortRow_LEGACY = (row:ContentSectionRow, sectionId:string, audienceId:AudienceId) => {
	// 	row.items = row.allItems.filter((topic:ContentStory) => {
	// 		let shouldShow = !topic.sort 
	// 				|| !topic.sort[sectionId] 
	// 				|| !topic.sort[sectionId][row.id]
	// 				|| topic.sort[sectionId][row.id][audienceId]===undefined
	// 				|| topic.sort[sectionId][row.id][audienceId] > 0;
                    
	// 		return shouldShow;
	// 	})
	// 	row.items = _.orderBy(row.items, (topic:ContentStory) => {
	// 		const sortValue = topic.sort 
	// 				&& topic.sort[sectionId] 
	// 				&& topic.sort[sectionId][row.id]
	// 				&& topic.sort[sectionId][row.id][audienceId]!==undefined ? topic.sort[sectionId][row.id][audienceId] : 0.01
			
	// 		// if (row.id==="5") console.log(`Row ${row.id} item ${topic.id} (${topic.title.en}) sortValue ${sortValue}`)
			
	// 		return sortValue;
	// 	}, "desc")
	// }




	getAudienceData(audienceId:AudienceId):ContentAudience {
        if(!this.data){
            throw Error(`No data for audience ${audienceId}`)
        }
		let data:ContentAudience|undefined = this.data.audiences.find((audience:any) => audienceId===audience.id);
		if (!data) throw Error(`No data for audience ${audienceId}`)
		return data;
	}

    /**
     * 
     * @param groupId - E.g. "places.west_highlands"
     * @param sectionId - E.g. "places"
     * @returns 
     */
    getGroupData(groupId:string, sectionId:string):ContentSectionRow|null{
        
        log("Content.getGroupData()");
        log(" - groupId = " + groupId)
        log(" - sectionId = " + sectionId)
        // let data:GroupData|null = null;

        let audienceData:ContentAudience = this.getAudienceData(AudienceId.learners);
        // console.log(" - audienceData = ", audienceData)
        let groupData:ContentSectionRow|null = null;
        
        for(let section of audienceData.home.sections){
            if(section.id === sectionId){
                for(let row of section.rows){
                    if(row.id === groupId){
                        
                        groupData = row;
                    }
                }
            }
        }
        // console.log(data)
        // console.log(" - groupData = ", groupData)
        return groupData;
    }

    getSectionTitle(sectionId:string):LangString|undefined{
        log("Content.getSectionTitle()");
        let data:ContentAudience = this.getAudienceData(AudienceId.learners);
        for(let section of data.home.sections){
            if(section.id === sectionId && section.title) return section.title;
        }
        return;
    }

    static getStory_id(topicId:string):string|null{
        if(!instance) return null;
        return instance.getStory_id(topicId);
    }
    
    getStory_id(topicId:string):string|null{
        let story = this.rawStoryMap[topicId];
        if(story) return story._id;
        return null;
    }

    static getRawStoryMap():{[key:string]:any}{
        return instance.rawStoryMap;
    }
    static getSubgroupTitle(sectionId:ContentSectionId, groupId:string, subgroupId:string):string{
        if(!instance) return "";
        return instance.getSubgroupTitle(sectionId, groupId, subgroupId);

    }

    /**
     * Get the title of a subgroup from the raw data.
     * @param sectionId - E.g. "storyTypes", "places", "superStories"
     * @param groupId - E.g. "animals"
     * @param subgroupId - E.g. "wild_animals"
     * @returns {string} - E.g. "Anecdotes & jokes", "Wild Animals", "Argyll & the Highlands".
     */
    getSubgroupTitle(sectionId:ContentSectionId, groupId:string, subgroupId:string):string{
        if(!this.raw) return "";
        let raw:RawContent = this.raw;
        
        // Get the raw groups data for a particular section
        // @ts-ignore
        let rawGroups:RawGroups | undefined = raw[sectionId];
        if(!rawGroups) return "";
                
        // Look inside the rawGroups for the groupId
        for(let groupKey in rawGroups){
            if(groupKey === groupId){
                
                let rawGroup:RawGroup = rawGroups[groupId];
                if(rawGroup.items){
                    for(let subgroupKey in rawGroup.items){
                        if(subgroupKey === subgroupId){
                            const title:LangString|undefined = rawGroup.items[subgroupId].title;
                            if(title) return title[Lang.langId];
                        }
                    }
                }

            }
        }
    
        return "";

    }

    


		
	//---------------------------------------------------------------------------------
	// Little helpers

	_normaliseIdPattern = /[^a-z0-9]/gi

	_generateReadableId(title:string) {
		return title.toLowerCase().replace(this._normaliseIdPattern, '_');
	}
}
 