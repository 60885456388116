import './_styles.scss';
import React from 'react';
import App from '../../App';
import Router from '../../router/Router';
import Lang, {LangId} from '../../tools/Lang';
import Snacks from '../Snacks/Snacks';
import Link from '../Link/Link';

interface LangBarProps{
    location?:string
    
}

export default function LangBar (props:LangBarProps){
    
    
    const setLang = async (langId:LangId) => {
        await Lang.setLangId(langId)

        Router.navigateToAppStateLang();

        Snacks.add({
            message: Lang.t("snack.langSwitched"),
            // type:SnackType.success,
            secs:4,
        })
    }

    const handleLangGaClick = async () => {
        setLang(LangId.ga);
    }

    const handleLangEnClick = async () => {
        setLang(LangId.en);
    }

    

    const langId = App.state.langId;
    
    return(
        
        <div id="lang">
            <div className="lang--inner main-column">
                <div className="lang--title">
                    <div className={"lang--title--icon lang--title--icon-" + langId}></div>
                    <h3 className="smallcaps">{Lang.t("home.lang.title")}</h3>
                </div>
                
                <div className="lang--buttons">
                    <Link 
                        to={Router.getLangPath(LangId.ga)}
                        onClick={handleLangGaClick} 
                        className={"button " + (langId === LangId.ga ? "active" : "")}
                    >
                        {Lang.t("lang.ga")}
                    </Link>
            
                    <Link 
                        to={Router.getLangPath(LangId.en)}
                        onClick={handleLangEnClick} 
                        className={"button " + (langId === LangId.en ? "active" : "")}
                    >
                        {Lang.t("lang.en")}
                    </Link>

                </div>
        
            </div>    
        </div>
    
    )
}




