import './_styles.scss';

import React from 'react';

import Tween, {TweenObject} from '../../tools/Tween';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

interface Props  {
    children?:any
}

interface State {
    opacity:number
}



export default class TabPage extends React.Component<Props, State>{
    
  
    isUnmounted:boolean = false;

    tween:TweenObject|null = null;

    constructor(props:Props){
        
        super(props);
        this.state = {
            opacity: 0
        }

        log("TabPage()")


        
        
        
    }

    componentDidMount(){
        this.reveal()
    }
    componentWillUnmount(){
        this.isUnmounted = false;
        Tween.kill(this.tween);
    }
    
    reveal(){
        this.tween = Tween.to(this, {opacity:1, duration:0.2, delay:0.1})
    }

    
  

    

    render(){
        return(
            <div id="tab-page"
                style ={{
                    opacity:this.state.opacity
                }}
            >
                {this.props.children}
            </div>
        )
    }
}