import React from 'react';

interface Props{

    onIntersect?:Function,
    numSteps?:number,
    
    root?:any,
    rootMargin?:string,

    // Add initial class denoting that the intersection is happening or not.
    // If undefined no initial 'view' class is added
    initView?:InitView,

    // Add initial class denoting that the intersection is happening at this position
    // If undefined no initial 'pos' class is added
    initPosition?:InitPosition,

    // Will add reveal classes 
    addClasses?:boolean,
    
    inViewAt?:number,
    outOfViewAt?:number,
    
    onceOnly?:boolean,

    children:any,
    
}

export enum InitView{
    in = "in",
    out = "out"
}
export enum InitPosition{
    top = "top",
    bottom = "bottom"
}

export enum RatioDirection {
    increasing = "increasing",
    decreasing = "decreasing",
    none = ""
}

type ElementData = {
    element:any,
    ratio:number|null,
    
}

export default class Intersector extends React.Component<Props>{
    
    elementsData:{[key:string]:ElementData} = {};

    inViewAt:number;
    outOfViewAt:number;
    observer:IntersectionObserver|null = null;
    
    IN_VIEW_AT:number = 0.5;
    OUT_OF_VIEW_AT:number = 1;
    NUM_STEPS = 20;
    CLASS_IN_VIEW = "in-view";
    CLASS_OUT_OF_VIEW = "out-of-view";
    CLASS_TOP = "pos-top";
    CLASS_BOTTOM = "pos-bottom";

    constructor(props:Props){
        super(props);

        
        this.inViewAt = props.inViewAt ||  this.IN_VIEW_AT;
        this.outOfViewAt = props.outOfViewAt ||  this.OUT_OF_VIEW_AT;

        // console.log(" - this.props.children = ", this.props.children)
    }

    componentDidMount(): void {
        this.createObserver();
        this.addInitClasses()
    }



    // -----------------------------------
    // CREATION

    createObserver() {
        
        let options = {
            root: this.props.root || null,
            rootMargin: this.props.rootMargin || "0px",
            threshold: this.buildThresholdList()
        };
      
        
        this.observer = new IntersectionObserver(this.handleIntersect, options);

        // @ts-ignore
        const children = Array.isArray(this.props.children) ?  this.props.children : [this.props.children];
        for(let child of children){
            const id =  child.props.id;
            const element = document.querySelector("#" + id);
            if(element){
                this.elementsData[id] = {
                    element:element, 
                    ratio:null,
                };
                if(this.observer.observe) this.observer.observe(element);
            }
            
        }
        
    }

    buildThresholdList():number[] {
        let thresholds = [];
        let numSteps = this.props.numSteps || this.NUM_STEPS;
      
        for (let i=1.0; i<=numSteps; i++) {
          let ratio = i/numSteps;
          thresholds.push(ratio);
        }
      
        thresholds.push(0);
        return thresholds;
    }



    // -----------------------------------
    // REMOVAL

    remove(){
        if(!this.observer) return;
        try{
            for(let id in this.elementsData){
                const elementData = this.elementsData[id]
                this.observer.unobserve(elementData.element);
            }
            this.observer = null;
            this.elementsData = {};
        }
        catch(err){
            console.log(err);
        }
    }

    removeById(id:string){
        if(!id || !this.observer) return;
        const element = document.querySelector("#" + id);
        this.removeByElement(element);
    }

    removeByElement(element:any){
        try{
            if(!this.observer){ throw new Error("No observer") }
            if(!element){ throw new Error("No element to remove") }
            const id = element.id;
            // console.log(" - removing id: " + id)
            this.observer.unobserve(element);
            delete this.elementsData[id];
        }
        catch(err){
            console.log(err);
        }
    }

    

    // -----------------------------------
    // CLASSES

    

    addInitClasses(){
        if(this.props.initView){
            const isInView = this.props.initView === InitView.in;
            for(let id in this.elementsData){
                const elementData = this.elementsData[id];
                if(isInView) this.addClassInView(elementData.element)
                else this.addClassOutOfView(elementData.element)
            }
        }

        if(this.props.initPosition){
            const isTop = this.props.initPosition === InitPosition.top;
            for(let id in this.elementsData){
                const elementData = this.elementsData[id];
                if(isTop) this.addClassTop(elementData.element)
                else this.addClassBottom(elementData.element)
            }
        }
    }

    addClassView(element:any, isInView:boolean){
        if(isInView){
            this.addClassInView(element);
        }else{
            this.addClassOutOfView(element);
        }
    }

    addClassPosition(element:any, isInTopHalf:boolean){
        if(isInTopHalf){
            this.addClassTop(element);
        }else{
            this.addClassBottom(element);
        }
    }

    addClassInView(element:any){
        element.classList.remove(this.CLASS_OUT_OF_VIEW);
        element.classList.add(this.CLASS_IN_VIEW);   
    }
    addClassOutOfView(element:any){
        element.classList.remove(this.CLASS_IN_VIEW);
        element.classList.add(this.CLASS_OUT_OF_VIEW);
    }

    addClassTop(element:any){
        element.classList.remove(this.CLASS_BOTTOM);
        element.classList.add(this.CLASS_TOP);   
    }
    addClassBottom(element:any){
        element.classList.remove(this.CLASS_TOP);
        element.classList.add(this.CLASS_BOTTOM); 
    }

    // -----------------------------------
    // HANDLERS

    handleIntersect = (entries:any, observer:any) => {
        
        for(let entry of entries){
            
            // Element
            const element = entry.target;
            const id = element.id;
            if(!id){
                console.log("Error handling intersection: element has no 'id' property")
                continue;
            }

            
            // Element data
            const elementData = this.elementsData[id];
            if(!elementData){
                // console.log("Error handling intersection: no elementData for id " + element.id)
                continue;
            }
            
            // Ratio
            const ratio = entry.intersectionRatio;
            const ratioPrev = elementData.ratio;
            elementData.ratio = ratio;
            // console.log(" - ratio = " + ratio)

            
            let shouldFireCallback:boolean = false;

            // Ratio direction
            let ratioDirection = RatioDirection.none;
            if(ratioPrev !== undefined && ratioPrev !== null){
                if (ratio > ratioPrev) ratioDirection = RatioDirection.increasing;
                else if (ratio < ratioPrev) ratioDirection = RatioDirection.decreasing;
            }
            
            // Bounding rectangle
            const rect:any = element.getBoundingClientRect();

            // Add classes
            if(ratioDirection){
                const y = rect.top;
                const midY = y + (rect.height/2);
                const rootHeight = this.props.root ? this.props.root.getBoundingClientRect().height : document.documentElement.clientHeight;
                const isInTopHalf = midY < (rootHeight / 2);
                
                // Increasing
                if(ratioDirection === RatioDirection.increasing){
                    if(ratio > this.inViewAt){
                        
                        // console.log(" - increasing")
                        shouldFireCallback = true;
                        if(this.props.addClasses){
                            this.addClassView(element, true);
                            this.addClassPosition(element, isInTopHalf);
                        }
                    }
                }

                // Decreasing
                else {
                    if(ratio < this.outOfViewAt){
                        
                        // console.log(" - decreasing")
                        shouldFireCallback = true;
                        if(this.props.addClasses){
                            this.addClassView(element, false);
                            this.addClassPosition(element, isInTopHalf);
                        }
                    }
                }
            }

            
            if(this.props.onIntersect && shouldFireCallback) this.props.onIntersect(element, ratio, ratioDirection, rect )

            // Once only. Remove now.
            if(this.props.onceOnly && shouldFireCallback){
                this.removeByElement(element)
            }
        }

    }

   
    // -----------------------------------
    // RENDER

    render():any {

        //@ts-ignore
        return this.props.children;
    }
}