import './_styles.scss';

//@ts-ignore
import _ from 'lodash'

import React from 'react';
import Lang from '../../tools/Lang';
import Tools from '../../tools/Tools'
import {TopicData, MediaItemData, MediaType} from '../../data/Topic';
import Router from '../../router/Router';
import TopicLibraryItemPage from '../TopicLibraryItemPage/TopicLibraryItemPage';
import TabPage from '../TabPage/TabPage';
import Link from '../Link/Link';
import {TopicTabId} from '../TopicTabs/TopicTabs';

// import { gsap } from "gsap";

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

interface Props  {
    topicData:TopicData,
    childId?:string,
    setTitle:Function,
}


export default class TopicLibrary extends React.Component<Props>{
    
    topicData:TopicData;

    itemsData:MediaItemData[] = [];

    constructor(props:Props){
        
        super(props);
        log("TopicLibrary()")
        log(" - props.topicData.mediaItems = ", props.topicData.mediaItems)
       
        this.itemsData = [];
        this.topicData = _.cloneDeep(props.topicData);
        
        if(this.topicData.mediaItems){
            for(let itemData of this.topicData.mediaItems){
                if(itemData.type === MediaType.archiveText){
                    itemData = this.cleanItemData(itemData);
                    this.itemsData.push(itemData);
                }
            }
        }
    }

    componentDidMount(){

        log("TopicLibrary.componentDidMount()")
        log(" - this.props.childId = " + this.props.childId)

        if(!this.props.childId)
            this.props.setTitle();
    }

    componentDidUpdate(prevProps:Props){
        
        // Refresh title if coming back from child page
        const isComingBackFromChild:boolean = prevProps.childId !== undefined && !this.props.childId;
        if(isComingBackFromChild){
            this.props.setTitle();
        }

    }

    cleanItemData(propsItemData:MediaItemData):MediaItemData{
		log("TopicLibrary.cleanData()")
        
        let itemData = propsItemData;

        // log(" - itemData = ", itemData)
        log(" - propsItemData.id = " + propsItemData.id);
        log(" - itemData.id = " + itemData.id);
        log(" - itemData._id = " + itemData._id);

        // Append unique _id to the non-iunique id
        if(itemData._id) itemData.id += "_" + itemData._id;

        log(" - itemData.id = " + itemData.id);

        if(itemData.collectionObject){
            
            let source = itemData.collectionObject.name;
            let str:string|undefined;
            
            

            if(source){
                // log(" - source = " + source);
                source.trim();

                
            
                // Use <em> or <i>
                let tag:string = "em";
                if(source.indexOf("<i>") > -1) tag = "i";
                const tagOpen = "<" + tag + ">";
                const tagClose = "</" + tag + ">";

                // ---------------
                // Get the publication
                const tagOpenIndex = source.indexOf(tagOpen);
                const tagCloseIndex = source.indexOf(tagClose);
                
                

                // Tag present
                if(tagOpenIndex !== -1 && tagCloseIndex !== -1){
                    str = source.substr(tagOpenIndex + tagOpen.length,  tagCloseIndex - tagOpen.length);
                    
                }
                // No tag present
                else{
                    const dotIndex = source.indexOf(".");
                    str = source.substr(0, dotIndex);
                    
                }
                if(str){
                    str = Tools.stringClean(str)
                    itemData.publication = Tools.stringTrimTrailingJunk(str);
                    
                }
                
                // ---------------
                // Get the publisher
                str = "";
                if(tagCloseIndex !== -1){
                    str = source.substr(tagCloseIndex + tagClose.length,  source.length);
                    // str = Tools.stringTrimTrailingJunk(str);
                    str = Tools.stringCapitalizeFirstLetter(str);
                }else{
                    const dotIndex = source.indexOf(".");
                    str = source.substr(dotIndex);

                }
                
                if(str){
                    if(str.length < 3) str = "";

                    // Fix any pp lacking a full stop
                    str = str.replace(/ pp /gi, " pp. ")
                    
                    str = Tools.stringClean(str)
                    itemData.publisher = Tools.stringTrimTrailingJunk(str);
                }
                
                // log(" - publisher = " + str)

                
            }

            // ---------------
            // Page fix

            // Publisher string contains page, wipe the page data
            if(this.doesPublisherContainPage(itemData.publisher)){
                itemData.collectionObject.page = "";

            }

            // Publisher string doesn't contain page, clean the page data
            else{
                str = itemData.collectionObject.page;
                // log(" - page = " + str)
                if(str){
                    
                    str = str.replace(/p\./gi, "");
                    str = str.replace(/pp\./gi, "");
                    str = str.replace(/pp/gi, "");
                    str = Tools.stringTrimTrailingJunk(str);
                    itemData.collectionObject.page = str;
                    // log(" - page (after) = " + str)
                }
            }
        }
        return itemData;
    }

    
  
    

    getItemData(id?:string):MediaItemData|null{
        if(!id || !this.itemsData) return null;
        for(let itemData of this.itemsData){
            if(itemData.id === id) return itemData
        }
        return null;
    }

    getUrl(childId:string):string{
        const url = Router.getTopicTabChildPath(this.props.topicData.topicId, TopicTabId.library, childId)
        return url;
    }


    handleItemClick = (id:string) => {
        if(!id) return;
        Router.navigateToTopicTabChild(id);
    }

    doesPublisherContainPage(name?:string):boolean{
        if(!name) return false;
        return name.indexOf(" pp") >= 0;
    }
    
    renderItems():any{
        if(!this.itemsData) return null;

        let items:any[] = [];
        let i = 0;
        
        for(let itemData of this.itemsData){
            
            let item = (
                <Link
                    to={this.getUrl(itemData.id)}
                    onClick={()=>{this.handleItemClick(itemData.id)}} 
                    className="topic-library-list-item" 
                    key={"library_item__" + i}
                >
                    <div className="item-content">
                        <h3 className="list-title">{Lang.tc(itemData.title)}</h3>
                        <h4 className="publication"><i>{itemData.publication}</i></h4>
                        <p className="smallcaps author">{itemData.author}</p>
                        <p className="faint publisher small">
                            {itemData.publisher}
                            {itemData.publication && itemData.collectionObject && itemData.collectionObject.page && (
                                " pp. " + itemData.collectionObject.page
                            )}
                        </p>
                    </div>

                    {/* <p className="faint">{itemData.text}</p> */}
                </Link>
            )
            items.push(item);
            i++;
        }
        
        return (
            <div className="library-items">
                {items}

            </div>
        )

    }
    

    render(){
        
        let shouldRenderItemPage:boolean = false;
        let itemData:MediaItemData|null = null;
        if(this.props.childId){
            itemData = this.getItemData(this.props.childId);
            if(itemData && (itemData.intro || itemData.text)) shouldRenderItemPage = true;
        }

        return(
            <TabPage>
                <div id="topic-library" className="topic-panel">
                    <div className="main-column main-column--narrow">
                        <div className="main-column-content">


                            {/* SHOW THE LIST */}
                            {!shouldRenderItemPage && 
                                <div className="text-column">
                                    <div className="topic-subheader"/>
                                    {this.renderItems()}

                                </div>
                            }

                            
                            {/* SHOW THE ITEM PAGE */}
                            {(itemData && shouldRenderItemPage) && 
                                <TopicLibraryItemPage
                                    itemData={itemData}
                                    setTitle={this.props.setTitle}
                                />
                            }
                        
                        </div>
                    </div>
                    
                </div>
            </TabPage>
        )
    }
}