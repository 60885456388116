//@ts-ignore
import lunr from 'elasticlunr';

export default {
	
	separators: {
		/**
		 * How do we separate words in a sentence?
		 */
		default: /[\s\-\/]+/,
	},

	extendFunctionality: {

		/**
		 * Gets a document from the index by doc ref.
		 *
		 * A 'remove' event is emitted with the document that has been removed and the index
		 * the document has been removed from. This event can be silenced by passing false
		 * as the second argument to remove.
		 *
		 * If user setting DocumentStore not storing the documents, then remove doc by docRef is not allowed.
		 *
		 * @param {String|Integer} docRef The document ref to remove from the index.
		 * @param {Boolean} emitEvent Whether to emit remove events, defaults to true
		 * @memberOf Index
		 */
		getDoc: () => {
			lunr.Index.prototype.getDoc = function (docRef:string, emitEvent:any):object|undefined {
				if (!docRef) return;
				if (this.documentStore.isDocStored() === false) {
					return;
				}
			
				if (!this.documentStore.hasDoc(docRef)) return;
				var doc = this.documentStore.getDoc(docRef);
				return doc;
			}
		}
	},

	pipeline: {
		removeAccents: (word:string):string => 	{
			let cleanWord = word.normalize("NFD").replace(/\p{Diacritic}/gu, "")

			// Console log any words that had accents removed
			// if (text!==cleanText) console.log(`SEARCH removeAccents(${text}) = ${cleanText}`)
			
			return cleanWord;
		},

	}
}