import './_styles.scss';

//@ts-ignore
import _ from 'lodash'

import React from 'react';
import Lang from '../../tools/Lang';
import Expander from '../../components/Expander/Expander';
import MediaGrid from '../MediaGrid/MediaGrid';
import Tools from '../../tools/Tools'
import {TopicData} from '../../data/Topic';
// import Tween from '../../tools/Tween';
import TabPage from '../TabPage/TabPage';
import {LangString} from '../../data/Content';
import Topic from '../../data/Topic';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

interface Props  {
    topicData:TopicData,
    mediaItemId?:string
    setTitle:Function,
}




export default class TopicOverview extends React.Component<Props>{
    
    topicData:TopicData|null;

    constructor(props:Props){
        
        super(props);
        log("TopicOverview()")
        
        
        this.topicData = this.cleanData(_.cloneDeep(props.topicData));
        
        
    }


    // ----------------------------------------------------------------------
    // LIFECYCLE
    
    componentDidMount(){
        log("TopicOverview.componentDidMount()")
        this.props.setTitle();
    }


    
    componentDidUpdate(prevProps:Props){
        // log("TopicOverview.componentDidUpdate()")
        // log(" - prevProps = ", prevProps);
        // log(" - props = ", this.props);
        const isClosingMediaModal:boolean = prevProps.mediaItemId !== undefined && !this.props.mediaItemId;
        if(isClosingMediaModal){
            this.props.setTitle();
        }
        
        
    }
    
    // ----------------------------------------------------------------------
    // METHODS

    /** Does this topic have any mediaGrid items, eg. video, music, script, comic, games? */
    _hasMediaGridItems?:boolean = undefined;
    get hasMediaGridItems():boolean{
        if(this._hasMediaGridItems === undefined) this._hasMediaGridItems = Topic.hasMediaGridItems(this.topicData);
        return this._hasMediaGridItems;
    }

    cleanData(propData:TopicData):TopicData|null{
        if(!propData) return null;

        let data:TopicData = propData

        if(data.ref){
            data.intro = Tools.langStringClean(data.intro);
            data.ref = Tools.langStringClean(data.ref);
            
        }

        if(data.mediaItems){

            for(let itemData of data.mediaItems){

                // We add the _id to the end because in the data there are items with non-unique id values
                // _id is always unique within the context of media items
                if(itemData.id) itemData.id += "_" + itemData._id
            }
        }
        // console.log(" - hasMedia = ", data)
        return data;
    }



    // ----------------------------------------------------------------------
    // RENDER


    renderIntro(data?:LangString):any{
        if(!data) return null;
     

        let paras:string[] = Tools.stringToArray(Lang.tc(data))
        let items:any[] = [];
        let i = 0;
        for(let para of paras){
            // let item =  <p key={"story_para__" + i}>{para}</p>
            let item = <h3 key={"overview__intro_para__" + i} className="subtitle"><i>{para}</i></h3>    
            items.push(item);
            i++;
        }
        
        return items;

    }

    /**
     * Get the visible bit of the description
     * @returns
     */
    renderRefVisible():any|null{
        // log("TopicOverview.getDescriptionVisible()")
        if(!this.topicData) return null;
        let arr:string[] = Tools.stringToArray(Lang.tc(this.topicData.ref))
        if(arr.length === 0) return null;

        let para = arr[0];
        return (
            <div className="visible">
                <p dangerouslySetInnerHTML={{__html: para}}></p>
            </div>
        );
    }

    /**
     * Get the hidden bit of the description
     * @returns
     */
    renderRefHidden():any|null{
        if(!this.topicData) return null;
        let arr:string[] = Tools.stringToArray(Lang.tc(this.topicData.ref))
        if(arr.length < 2) return null;
        arr.shift();
        let paras:any[] = [];
        let i = 0;
        for(let str of arr){
            let frag = (
                <p key={"overview__ref__" + this.topicData.topicId + "__para" + i} dangerouslySetInnerHTML={{__html: str}}></p>
            )
            paras.push(frag);
            i++;
        }
        return (
            <div>
                {paras}
            </div>
        )

    }


    
    

    render(){
        if(!this.topicData) return null;

        const expanderId = "topic__" + this.topicData.topicId + "__overview__description__";
        // console.log("expander id = " + expanderId)

        
        // Styles
        let contentMainClass = "main-column-content-main";
        if(!this.hasMediaGridItems) contentMainClass += " no-side-col";

        return(

            <TabPage>
                <div id="topic-overview" className="topic-panel">
                    <div className="main-column main-column--narrow">
                        <div className="main-column-content">
                            <div className={contentMainClass}>
                                <div className="text-column">
                                    
                                    {/* <div className="topic-subheader"/> */}
                                    {/* <div className="topic-header">
                                        <h1>{Lang.tc(this.topicData.title)}</h1>
                                        <h3 className="subtitle"><i>{Lang.tc(this.topicData.description)}</i></h3>    
                                    </div> */}


                                    <div className="topic-subheader">
                                        {this.renderIntro(this.topicData.intro)}
                                    </div>

                                    <div className="topic-body">
                                        <div className="topic-description">
                                            <Expander
                                                save={true}
                                                id={expanderId}
                                                childrenHidden={this.renderRefHidden()}
                                            >
                                            
                                                {this.renderRefVisible()}

                                            </Expander>
                                        </div>
                                        
                                    </div>

                                    
                                        
                                </div>
                            </div>
                            {this.hasMediaGridItems &&
                                <div className="main-column-content-side">
                                    <div className="topic-media">
                                        <MediaGrid
                                            topicData={this.topicData}
                                            mediaItemId={this.props.mediaItemId}
                                            
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    
                </div>
            </TabPage>
        )
    }
}