import './_styles.scss';
import React from 'react';

import Lang from '../../tools/Lang';
import Router from '../../router/Router';
import App from '../../App';
import {BootstrapTooltip} from '../BootstrapTooltip/BootstrapTooltip';
import Snacks from '../../components/Snacks/Snacks';
import Audience, {AudienceId} from '../../tools/Audience';
// import Tween, {TweenObject} from '../../tools/Tween';
// import Tools from '../../tools/Tools'
import Link from '../Link/Link';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

export enum NavTheme {
    light = "light",
    dark = "dark"
}


interface Props{
    theme?:NavTheme;
    showLogo?:boolean;
    screenId?:string;
}


interface State {
    bgOpacity:number,
    logoOpacity:number,
    // top:number;
    theme:string;
}
export default class Nav extends React.Component<Props, State>{


    isBgRevealed:boolean = false;

    // Y pos when the nav goes into dark mode
    themeGoDarkValues:any = {
        home: 500,
        default: 40
    }

    audienceId:AudienceId;

    BG_OPACITY_MAX:number = 0.93;


    constructor(props:Props){
        super(props);
        this.state = {
            bgOpacity:Audience.isAudienceAnyKids ? this.BG_OPACITY_MAX: 0,
            logoOpacity: props.screenId === "home" ? 0 : 1,
            theme: this.props.theme ? this.props.theme : ""
        }

        window.addEventListener('scroll', this.handleScroll)

        this.audienceId = App.state.audienceId;

    }


    // --------------------------------------------------------------------
    // LIFECYCLE
    

    componentDidMount(){
        // log("Nav.componentDidMount()")
        // log(" - App.state.audienceId = " + App.state.audienceId);
        this.handleScroll(null);

        
    }

    componentDidUpdate(){
        // log("Nav.componentDidUpdate()")
        // log(" - App.state.audienceId = " + App.state.audienceId)

        // If audience has changed then update the nav
        if(this.audienceId !== App.state.audienceId){
            this.audienceId = App.state.audienceId;
            this.handleScroll(null);
        }
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll)
    }

    

    // --------------------------------------------------------------------
    // METHODS

    get goDarkY():number {
        let screenId:string = this.props.screenId || "default";
        let val:number = this.themeGoDarkValues[screenId] || this.themeGoDarkValues.default;
        return val;
    }

    

    // --------------------------------------------------------------------
    // EVENTS

    handleScroll = (event:any) => {
        const top  = window.pageYOffset || document.documentElement.scrollTop;
        const goDarkY = this.goDarkY;

        // Update 'theme' in state, based on 'top' value
        let theme:string = this.props.theme ? this.props.theme : "";
        const shouldGoDark:boolean = !theme && top > goDarkY;
        if(shouldGoDark) theme = "dark";
        if(theme !== this.state.theme){
            this.setState({theme});
        }
        
        // If user is on Home screen and audience is kids, then just reveal the bg immediately and get out of here
        if(Audience.isAudienceAnyKids && this.props.screenId === "home"){
            if(this.state.bgOpacity < 1 && !this.isBgRevealed){
                this.setState({bgOpacity:this.BG_OPACITY_MAX});
                this.isBgRevealed = true;
            }   
            return;
            // ----------
        }

    

        // Reveal bg
        if(top > goDarkY && !this.isBgRevealed){
            this.setState({
                bgOpacity:this.BG_OPACITY_MAX,
                logoOpacity:1
            });
            this.isBgRevealed = true;
        }

        // Unreveal bg
        if(top < goDarkY && this.isBgRevealed){
            let logoOpacity = this.props.screenId === "home" ?  0 : 1;
            this.setState({
                bgOpacity:0,
                logoOpacity:logoOpacity
            });
            this.isBgRevealed = false;
        }
    }

    

    /**
     * User has tapped one of the audience buttons: kids, toddlers, or exit-kids
     * @param id 
     */
     handleAudienceClick = async (id:AudienceId) => {
        log("Nav.handleAudienceClick()");
        Router.navigateToAudience(id);
    }
    

   

    handleLangClick = async () => {
        log("Nav.handleLangClick()");
        
        await Lang.toggleLangId();

        Router.navigateToAppStateLang();

        Snacks.add({
            message: Lang.t("snack.langSwitched"),
            // type:SnackType.success,
            secs:4,
        })

       

    }


    handleOralClick = (event:any) => {
        if(event) event.preventDefault();
        log("Nav.handleOralClick()");
        Router.navigateToPath(Router.getOralPath())
    }

    handleSearchClick = (event:any) => {
        event.preventDefault();
        log("Nav.handleSearchClick()");
        App.openSearch();
    }

    handleHomeClick = (event:any) => {
        log("Nav.handleHomeClick()");
        event.preventDefault();
        Router.navigateToPath(Lang.getUrlLangFrag(), event);

    }

    // --------------------------------------------------------------------
    // RENDER

    render(){

        log("Nav.render()");

        // -----------------
        // NAV CLASS

        // Lang
        let navClass:string = App.state.langId;
        
        // Theme
        if(this.state.theme) navClass += " " + this.state.theme;

        // Audience
        navClass += " audience-" + App.state.audienceId;
    
        // Screen
        if(this.props.screenId) navClass += " screen-" + this.props.screenId;

        // -----------------

        // log("App.state = ", JSON.stringify(App.state, null, 1));

        return(
            <div id="nav" className={navClass}>

                {/* SHADOW */}
                <div className="nav--shadow"/>

                {/* BG */}
                <div className="nav--bg" style={{
                    opacity:this.state.bgOpacity}}
                />        
                
                
                {/* <div className="topline"></div> */}

                <div className="main-column">
                    <div className="main-column-content">
                        <div id="nav--inner">
                            
                            {/* LOGO */}
                            <div id="nav--logo"
                                style={{
                                    opacity:this.state.logoOpacity
                                }}
                            >
                                <a href={Lang.getUrlLangFrag()} onClick={this.handleHomeClick}>
                                    <img src="/images/ui/logo-l-tv.png" alt="Bocan TV"/>
                                </a>
                            </div>
                        


                            {/* KIDS NAV */}
                            {(Audience.isAudienceAnyKids && this.props.screenId === "home") &&
                                <div id="nav--kids">
                                    <div className="nav--kids--item audience-kids">
                                       
                                            <Link 
                                                to={Router.getAudiencePath(AudienceId.kids)}
                                                onClick={(event:any)=>this.handleAudienceClick(AudienceId.kids)}
                                                className="button"
                                            >
                                                <BootstrapTooltip
                                                    title={Lang.t("nav.kids.tip")}
                                                >   
                                                    <div className="button-content">
                                                        <span>{Lang.t("nav.kids")}</span>
                                                        <img src="/images/ui/nav-kids.png" alt=""/>
                                                    </div>
                                                </BootstrapTooltip>
                                            </Link>   
                                        
                                    </div>

                                    <div className="nav--kids--item audience-toddlers">
                                        
                                             <Link 
                                                to={Router.getAudiencePath(AudienceId.kids)}
                                                onClick={(event:any)=>this.handleAudienceClick(AudienceId.toddlers)}
                                                className="button"
                                            >
                                                <BootstrapTooltip
                                                    title={Lang.t("nav.toddlers.tip")}
                                                >
                                                    <div className="button-content">
                                                        <span>{Lang.t("nav.toddlers")}</span>
                                                        <img src="/images/ui/nav-toddlers.png" alt=""/>
                                                    </div>
                                                </BootstrapTooltip>
                                            </Link>
                                        
                                    </div>
                                </div>
                            }
                        
                        
                            {/* MENU */}
                            <nav id="menu">
                                <ul>

                                    {/* EXIT KIDS BUTTON */}
                                    {Audience.isAudienceAnyKids &&
                                        <li id="menu-exit-kids">
                                           
                                            <Link 
                                                to={Router.getAudiencePath(AudienceId.learners)}
                                                onClick={(event:any)=>this.handleAudienceClick(AudienceId.learners)}
                                                className="button"
                                            >
                                                <BootstrapTooltip
                                                    title={Lang.t("nav.menu.exit-kids.tip")}
                                                >
                                                    <div className='button-content'>
                                                        <div className="menu-icon"></div>
                                                        <span>{Lang.t("nav.menu.exit-kids")}</span>
                                                    </div>
                                                </BootstrapTooltip>
                                            </Link>
                                            
                                        </li>
                                    }

                                    {/* KIDS BUTTON */}
                                    {Audience.isAudienceLearners &&
                                        <li id="menu-kids">
                                            
                                            <Link 
                                                to={Router.getAudiencePath(AudienceId.kids)}
                                                onClick={(event:any)=>this.handleAudienceClick(AudienceId.kids)}
                                                className="button"
                                            >
                                                <BootstrapTooltip
                                                    title={Lang.t("nav.menu.kids.tip")}
                                                >
                                                    <div className='button-content'>
                                                        <div className="menu-icon"></div>
                                                        <span>{Lang.t("nav.menu.kids")}</span>
                                                    </div>
                                                </BootstrapTooltip>
                                            </Link>
                                            
                                        </li>
                                    }

                                    

                                    {/* ORAL BUTTON */}
                                    {Audience.isAudienceLearners &&
                                        <li id="menu-oral">
                                            
                                            <Link
                                                to={Router.getOralPath()} 
                                                onClick={this.handleOralClick}
                                                className="button"
                                            >
                                                <BootstrapTooltip
                                                    title={Lang.t("nav.menu.oral.tip")}
                                                >
                                                    <div className='button-content'>
                                                        <div className="menu-icon"></div>
                                                        <span>{Lang.t("nav.menu.oral")}</span>
                                                    </div>
                                                </BootstrapTooltip>
                                            </Link>
                                        
                                        </li>
                                    }

                                    {/* LANG BUTTON */}
                                    
                                    <li id="menu-lang">
                                        
                                            <Link
                                                to={Router.getLangPath(Lang.langIdInactive)} 
                                                onClick={this.handleLangClick}
                                                className="button"
                                            >
                                                <BootstrapTooltip
                                                    title={Lang.t("nav.menu.lang.tip")}
                                                >
                                                    <div className='button-content'>
                                                        <div className="menu-icon"></div>
                                                        <span className="text text-ga">{Lang.t("nav.menu.lang.ga")}</span>
                                                        <span className="text text-separator"> | </span>
                                                        <span className="text text-en">{Lang.t("nav.menu.lang.en")}</span>
                                                    </div>
                                                </BootstrapTooltip>
                                            </Link>
                                        
                                    </li>

                                    
                                    {/* SEARCH BUTTON */}
                                    <li id="menu-search">
                                        <BootstrapTooltip
                                            title={Lang.t("nav.menu.search.tip")}
                                        >
                                            <button 
                                                onClick={this.handleSearchClick}
                                                className="button"
                                            >
                                                <div className="menu-icon"></div>
                                            </button>
                                        </BootstrapTooltip>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



