import UniCookies from 'universal-cookie'
import Tools from '../tools/Tools';
let instance:Cookies;


export default class Cookies {
    
    cookies:any|UniCookies;
 
    constructor() {
        // console.log("Lang()");
        
        instance = this;
        
        this.cookies = new UniCookies();
        
        
    }

    static getCookie(name:string, options?:object){
        return instance.cookies.get(name, options);
    }

    static getAllCookies(options?:object){
        return instance.cookies.getAll(options);
    }

    static setCookie(name:string, value:any, options?:object){
        // return;
        const defaultOptions = {
            
			sameSite: "strict",

            secure: Tools.isDeployedProduction,

			// All pages on the domain will have access to this cookie
			path: '/',

			// Expires in 20 years
			maxAge: 20*365*24*60*60,
            
			// These options will overwrite the ones mentioned in the previous lines above
			...options
        }

        instance.cookies.set(name, value, defaultOptions);
    }

    static removeCookie(name:string, options?:object){
        instance.cookies.remove(name, options);
    }

    static addChangeListener(callback:Function){
        instance.cookies.addChangeListener(callback);
    }

    static removeChangeListener(callback:Function){
        instance.cookies.removeChangeListener(callback);
    }
   

}