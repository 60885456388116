import './_styles.scss';

// import React, {useEffect, useRef, useState} from 'react';
// import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      overflowY:'scroll',
    //   border:'0 !important',
    },
    paper: {
    //   backgroundColor: theme.palette.background.paper,
    //   border: '0px solid #000 !important',
    //   boxShadow: theme.shadows[5],
      borderRadius:'0.3rem',
    //   border:'0 !important',
      outlineWidth:0,
    //   padding: theme.spacing(2, 4, 3),
    },
  }),
);


type Props = {
    open:boolean;
    onClose:any,
    children?:any,
    classes?:string,
}

export default function ModalScreen(props:Props) {
    log("ModalScreen()")
    let classes = useStyles();
    let paperClass:string = classes.paper;
    if(props.classes){
        paperClass = classes.paper + " " + props.classes
    }
  
    return (
    
      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal + " modal-container"}
        open={props.open}
        onClose={props.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={paperClass}>
            
            
            {/* <div className="modal-container">
                <div className="modal-container-scrim" onClick={props.onClose}/>
                {props.children}
            </div> */}
         
            {props.children}
         
            
          </div>
        </Fade>
      </Modal>
    
  );
}
