import './_styles.scss';

//@ts-ignore
import _ from 'lodash'

import React from 'react';
import Lang from '../../tools/Lang';
import Tools from '../../tools/Tools'
import {TopicData} from '../../data/Topic';
import TabPage from '../TabPage/TabPage';
import {LangString} from '../../data/Content';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

interface Props  {
    topicData:TopicData,
    setTitle:Function,
}



export default class TopicResearch extends React.Component<Props>{
    
    topicData:TopicData|null;

    constructor(props:Props){
        
        super(props);
        log("TopicResearch()")
        
        this.topicData = this.cleanData(_.cloneDeep(props.topicData));
    }

    componentDidMount(){
        log("TopicResearch.componentDidMount()")
        const intro:LangString = Tools.truncLangString(this.props.topicData.intro, 100);
        // console.log(" - intro = ", intro)
        this.props.setTitle(undefined, intro);
    }

    

    // ----------------------------------------------------------------------
    // METHODS

    cleanData(propData:TopicData):TopicData|null{
        if(!propData) return null;

        let data:TopicData = {...propData}

        data.intro = Tools.langStringClean(data.intro);
        data.longref = Tools.langStringClean(data.longref);

        return data;
    }

    renderIntro(data?:LangString):any{
        if(!data) return null;
     

        let paras:string[] = Tools.stringToArray(Lang.tc(data))
        let items:any[] = [];
        let i = 0;
        for(let para of paras){
            // let item =  <p key={"story_para__" + i}>{para}</p>
            let item = <h3 key={"research_intro_para__" + i} className="subtitle"><i>{para}</i></h3>    
            items.push(item);
            i++;
        }
        
        return items;

    }
    
    renderLongref(data:LangString):any{
        if(!data) return null;

        let paras:string[] = Tools.stringToArray(Lang.tc(data))
        let items:any[] = [];
        let i = 0;
        for(let para of paras){
            let item =  <p key={"research_text_para__" + i} dangerouslySetInnerHTML={{__html: para}}></p>
            items.push(item);
            i++;
        }
        
        return (
            <div className="research-text">
                {items}
                <span className="research-underline"></span>
            </div>
        )

    }
    

    render(){
        if(!this.topicData || !this.topicData.longref ) return null;
        


        return(
            <TabPage>
                <div id="topic-research" className="topic-panel">
                    <div className="main-column main-column--narrow">
                        <div className="main-column-content">
                            <div className="text-column">
                                {/* <div className="topic-subheader">
                                    {this.renderIntro(this.topicData.intro)}
                                </div> */}
                                {this.renderLongref(this.topicData.longref)}

                            </div>
                        </div>
                    </div>
                    
                </div>
            </TabPage>
        )
    }
}