// CSS
import './_styles.scss';
import React from 'react';
import App from '../../App';
// import Tools from '../../tools/Tools';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}


type Props = {

}

type State = {
    items:any[],
    show:boolean,
}

var instance:Debug;

export default class Debug extends React.Component<Props,State>{
    
    but:any
    butParent:any;
    shiftX:number = 0;
    shiftY:number = 0;

    // SHOW_DEBUG_WINOW:boolean = false;
    

    constructor(props:Props){
        super(props)
        log("Debug()");

        this.state = {
            items:[],
            show: App.SHOW_DEBUG_WINOW
        }

        instance = this;
    }

    componentDidMount(){
        log("Debug.componentDidMount()");
        this.addDragAndDropListeners();
    }

    

    addDragAndDropListeners = async () =>{
        // await Tools.later(1)
        this.but = document.getElementById("debug-drag-but");
        this.butParent = document.getElementById("debug-drag-but")?.parentElement;
        if(!this.but || !this.butParent) return

        this.but.onmousedown = this.handleDragMouseDown; 
        this.but.ondragstart = function() {
            return false;
        };
    }

    updatePosition = (clientX:number, clientY:number) => {
        if(!this.but || !this.butParent) return;
        this.butParent.style.left = clientX - this.shiftX + 'px';
        this.butParent.style.top = clientY - this.shiftY +  'px';
    }

    
    // -------------------------------------------------------------------------------
    // METHODS

    static log(obj:any){
        if(!instance) return;
        instance.log(obj);

    }

    log(obj:any){
        let items = [...this.state.items];
        items.push(obj);
        this.setState({items:items});

    }
    static clear(){
        if(!instance) return;
        instance.clear();
    }

    clear(){
        this.setState({items:[]});
    }


    // -------------------------------------------------------------------------------
    // EVENT HANDLERS
    handleDragMouseDown = (event:any) => {
        if(!this.but || !this.butParent) return;
        let butParentLeft = this.butParent.getBoundingClientRect().left;
        let butParentTop = this.butParent.getBoundingClientRect().top;
        this.shiftX = event.clientX - butParentLeft;
        this.shiftY = event.clientY - butParentTop
        this.updatePosition(event.clientX, event.clientY);
        document.addEventListener('mousemove', this.handleDragMouseMove);
      
        // drop the ball, remove unneeded handlers
        this.but.onmouseup = this.handleDragMouseUp;
    }

   

    handleDragMouseMove = (event:any) => {
        this.updatePosition(event.clientX, event.clientY);
    }
   
    handleDragMouseUp = (event:any) => {
        log("Debug.handleDragMouseUp");
        document.removeEventListener('mousemove', this.handleDragMouseMove);
        this.but.onmouseup = null;
    }


    handleClearClick = (evt:any) => {
        this.clear();
    }


    // -------------------------------------------------------------------------------
    // RENDER

    renderItems(){
        let els = [];
        let index=  0;
        for(let item of this.state.items){
            const el = (
                <li key={"debug_row" + index}>{JSON.stringify(item)}</li>
            )
            els.push(el);
            index++;
        }
        return els;
    }

    render(){
        // if(this.state.items.length === 0) return null;
        if(!this.state.show) return null;

        return(
            <div id="debug" className="debug">

                {/* DRAGGER */}
                <div id="debug-drag-but">
                    <div className='drag-line'/>
                </div>

                {/* BUTS */}
                <div className='debug-buts'>
                    
                    {/* CLEAR BUT */}
                    <button className='but' aria-label="Clear" onClick={this.handleClearClick}>×</button>
                </div>

                {/* ITEMS */}
                <ul>
                    {this.renderItems()}
                </ul>
            </div>
        )
    }


}