// CSS
import './_App.scss';


// IMPORTS
import React from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom";
//@ts-ignore
import {Helmet} from "react-helmet";
import _ from 'lodash';

//@ts-ignore
import $ from 'jquery';

import Content from './data/Content';
import Router from './router/Router';
import HomeScreen from './screens/HomeScreen/HomeScreen';
import OralScreen from './screens/OralScreen/OralScreen';
import ErrorScreen from './screens/ErrorScreen/ErrorScreen';
import SitemapScreen from './screens/SitemapScreen/SitemapScreen';
import GroupScreen from './screens/GroupScreen/GroupScreen';
import TopicScreen from './screens/TopicScreen/TopicScreen';
import Audience, {AudienceId} from './tools/Audience';
import Lang, {LangId} from './tools/Lang';
import Cookies from './tools/Cookies';
import Snacks from './components/Snacks/Snacks';
import Tools from './tools/Tools';
import Config from './config/Config';
import ModalSearch from './components/ModalSearch/ModalSearch'
import ModalThanks from './components/ModalThanks/ModalThanks';
import ModalPrivacy from './components/ModalPrivacy/ModalPrivacy';
import CookieConsent from './components/CookieConsent/CookieConsent'
import Debug from './components/Debug/Debug';
import Tracker from './tools/Tracker';
import Spinner from './components/Spinner/Spinner';
// import TestScreen from './screens/TestScreen/TestScreen';

export type HeadParams = {
    title?:string,
    titleOG?:string,
    description?:string,
    type?:string,
    url?:string,
    site_name?:string,
    locale?:string,
    localeAlternate?:string
    imageOG?:string,
    isSearchable?:boolean,
}

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

let instance:App;

enum LoadingState {
    unset = "unset",
    loading = "loading",
    loaded = "loaded",
    error = "error"
}

type AppProps = {

}

type AppState = {
    loadingState:LoadingState,
    langId:LangId,
	audienceId:AudienceId,
    numTimesKidsHeroShown:number,
    flashHistory:FlashHistory,
    homeScrollY:number,
    carousels:any,
    isSearchOpen:boolean,
    isPrivacyModalOpen:boolean,
    isThanksModalOpen:boolean,
    head:HeadParams,
}

export type FlashHistory = {
    [sectionId:string]:string[]
}


export default class App extends React.Component<AppProps,AppState> {

    lang:Lang|null;

    audience:Audience|null;

    cookies:Cookies;
    
    router:Router;

	content:Content;

    tracker:Tracker;

    static debug:Debug|null = null;
    
    // Enables the Flash elements in specific areas: HeroKids, Section headers
    // When set to false these elements have placeholder graphics
    static ENABLE_FLASH:boolean = true;

    // Overrides ENABLE_FLASH when in dev mode
    // Useful in dev when not wanting the overheat caused by Ruffle processing requirements
    static ENABLE_FLASH_IN_DEV:boolean = false;
    // static ENABLE_FLASH_IN_DEV:boolean = true;

    static SHOW_DEBUG_WINOW:boolean = false;
    

    constructor(props:AppProps){
        super(props);
        log(" ")
        log("----------------------------------------------------------------------")
        log("App()")
        

        this.state = {
            loadingState:LoadingState.loading,
            langId:		Config.settings.defaultLangId,
			audienceId: Config.settings.defaultAudienceId,
            numTimesKidsHeroShown:0,
            flashHistory:{
                places: ["places-kids"],
                characters: ["characters-kids"],
                specialCollections:["specialCollections"]
            },
            homeScrollY: 0,
            carousels:{},
            isSearchOpen:false,
            isPrivacyModalOpen:false,
            isThanksModalOpen:false,
            head:{}
        }
        instance = this;
        
        this.lang = null;

        this.audience = null;
        
        this.cookies = new Cookies();

        this.router = new Router();

        this.tracker = new Tracker();
        
        //@ts-ignore
        this.content = new Content(window.bocan.content)

        // Turn off Flash when in dev
        if(Tools.isDev){
            App.ENABLE_FLASH = App.ENABLE_FLASH_IN_DEV === true;
        }
        
    }

    componentDidMount(){
        this.boot();
    }

        
   
    static get state():AppState{   
        return instance.state;
    }

    static setState(state:object, callback?:Function){
        instance.setState(state, ()=>{
            if(callback) callback();
        });
    }

   
   
    get isLoading():boolean{
        return this.state.loadingState === LoadingState.loading;
    }
    
    get isLoadingError():boolean{
        return this.state.loadingState === LoadingState.error;
    }

    get isLoaded():boolean{
        return this.state.loadingState === LoadingState.loaded;
    }
   
    

    boot = async () => {
        // console.log("App.boot()");

        // HTML Class
        let htmlClass = '';
        if(Tools.isHoverDevice) htmlClass += 'hover';
        else htmlClass += 'no-hover';
        $('html').addClass(htmlClass)

        // Load in content data

        const err:Error|null = await this.content.init();
        if(err){
            this.setState({
                loadingState: LoadingState.error
            })
        }
        
        

        // ----------------------
        // Set up Lang
        this.lang = new Lang(Config.settings.defaultLangId);
        await this.lang.init(); 
        
		this.audience = new Audience(Config.settings.defaultAudienceId);
        await this.audience.init(); 

        // ----------------------
        // Set state to begin

        if(!err){
            this.setState({
                loadingState: LoadingState.loaded
            })
        }

        if(Tools.isDev){
            //App.openSearch();
        }



        log("App.boot() complete");
        
    }   
    
    /**
     * Logs to console and debug window if there is one.
     * @param obj Log a string or object etc
     * @returns 
     */
    static log(obj:any){
        if(!instance) return;
        instance.log(obj);
    }

    log(obj:any){
        if(!App.debug) return;
        App.debug.log(obj);
        log(obj);
    }

    

    // --------------
    // REACT HELMET HEAD

    static setHead(head:HeadParams){
        // console.log("App.setHead()");
        // console.log(" - head = ", head)
        App.setState({head:head})

        //@ts-ignore
        if(!window.prerenderReady) App.handleSetHeadComplete()
    }
    
    /**
     * Sets a flag so that Prerender.io knows the page has been fully rendered.
     */
    static setPageRenderComplete = () => {
        //@ts-ignore
        window.prerenderReady = true;
    }
    
    static DELAY_BEFORE_PAGE_COMPLETE_MILLIS:number = 7000;

    /**
     * This is debounced as we have several setHead calls happening on init sometimes,
     * and we want to be sure we're on the last one when we call setPageRenderComplete()
     */
    static handleSetHeadCompleteDebounced = _.debounce(App.setPageRenderComplete, App.DELAY_BEFORE_PAGE_COMPLETE_MILLIS)
    static handleSetHeadComplete(){
        this.handleSetHeadCompleteDebounced();
    }



    // --------------
    // SEARCH MODAL
    
    static openSearch = () =>{
        App.setState({
            isSearchOpen:true
        })
    }

    static closeSearch = () =>{
        App.setState({
            isSearchOpen:false
        })
    }
    
    
    handleSearchClose = () => {
        App.closeSearch()
    }

    // --------------
    // PRIVACY MODAL

    static openPrivacyModal = () => {
        if(App.state.isPrivacyModalOpen) return;
        App.setState({
            isPrivacyModalOpen: true
        })
    }
    static closePrivacyModal = () => {
        if(!App.state.isPrivacyModalOpen) return;
        App.setState({
            isPrivacyModalOpen: false
        })
    }
    handlePrivacyModalClose = () => {
        if(Router.isOnFirstScreen) Router.removeHash();
        else{Router.navigateBack()}
        App.closePrivacyModal()
    }


    // --------------
    // THANKS MODAL
    static openThanksModal = () => {
        if(App.state.isThanksModalOpen) return;
        // Router.addHash("credits");
        App.setState({
            isThanksModalOpen: true
        })
    }
    static closeThanksModal = () => {
        if(!App.state.isThanksModalOpen) return;
        
        App.setState({
            isThanksModalOpen: false
        });
    }

    /** User has tapped the close button on the open modal  */
    handleThanksModalClose = () => {
        if(Router.isOnFirstScreen) Router.removeHash();
        else{Router.navigateBack()}
        App.closeThanksModal();
    }
    

    handleCookieConsentPress = () => {
        log("App.handleCookieConsentPress()");
    }
    

    render(){
        
        if(Tools.isTouchDevice){
            Tools.addClassToHTML("touch")
        }
        
       
        
        const langPropsEn = {rel:"alternate", hreflang:"en", href:Router.getLangPath(LangId.en, true)}
        const langPropsGa = {rel:"alternate", hreflang:"gd-gb", href:Router.getLangPath(LangId.ga, true)}

        return (
            
                <div id="app">

                    {/* LOADING ERROR */} 
                    {this.isLoadingError &&
                        <div className="app-wrapper loading-error">
                            <div className='message'>
                                <h4 className='title'>Error loading content data</h4>
                                {Config.needsCorsAnywhere &&
                                    <p>
                                        Possible CORS error. Temporarily enable CORS on <a href="https://cors-anywhere.herokuapp.com">https://cors-anywhere.herokuapp.com</a>
                                    </p>
                                }

                                <p>
                                    That's all we know.
                                </p>
                            </div>
                        </div>
                    }

                    {/* LOADING */}
                    {this.isLoading &&
                        <div className="app-wrapper loading">
                            <Spinner
                                className="spinner"
                            />
                        </div>
                    }


                    {/* LOADED */}
                    {this.isLoaded &&
                        
                        <div className="app-wrapper">
                            
                            <BrowserRouter
                                //  basename="/"
                            >

                                {/* 
                                * More info here on opengraph:
                                * https://developers.facebook.com/docs/sharing/webmasters#markup 
                                */}
                                <Helmet>
                                    <title>{App.state.head.title}</title>
                                    <meta name="description" content={App.state.head.description}/>
                                    <meta property="og:title" content={App.state.head.titleOG}/>
                                    <meta property="og:description" content={App.state.head.description}/>
                                    <meta property="og:type" content={App.state.head.type}/>
                                    <meta property="og:url" content={App.state.head.url}/>
                                    <meta property="og:site_name" content={App.state.head.site_name}/>
                                    <meta property="og:locale" content={App.state.head.locale}/>
                                    <meta property="og:locale:alternate" content={App.state.head.localeAlternate}/>
                                    <meta property="og:image" content={App.state.head.imageOG}/>
                                    {!this.state.head.isSearchable &&
                                        <meta name="robots" content="noindex" />
                                    }
                                    
                                    <link {...langPropsEn}/>
                                    <link {...langPropsGa}/>

                                </Helmet>

                                <Switch>

                                    {/* <Route
                                        path={["/test"]}
                                        render={(props:any)=>{
                                            Router.setProps(props);
                                            return(
                                                <TestScreen/>
                                            )
                                        }}
                                    /> */}

                                    {/* SITEMAP SCREEN  */}
                                    <Route 
                                        path={[
                                            "/sitemap",
                                        ]}
                                        render={(props:any)=>{
                                            Router.setProps(props);
                                            return(
                                                <SitemapScreen 
                                                    navProps={props}
                                                />    
                                            );
                                        }}
                                    />

                                    {/* ERROR SCREEN  */}
                                    <Route 
                                        path={[
                                            "/error",
                                            "/:langId?/error",
                                        ]}
                                        render={(props:any)=>{
                                            Router.setProps(props);
                                            return(
                                                <ErrorScreen 
                                                    langId={props.match.params.langId}
                                                    navProps={props}
                                                />    
                                            );
                                        }}
                                    />

                                    
                                    {/* GROUP SCREEN */}
                                    <Route 
                                        path={[
                                            "/:langId?/a/:audienceId?/group/:uiSectionId/:id",
                                            "/:langId?/group/:uiSectionId/:id"
                                        ]}
                                        render={(props:any)=>{
                                            Router.setProps(props);
                                            return(
                                                <GroupScreen 
                                                    langId={props.match.params.langId}
                                                    uiSectionId={props.match.params.uiSectionId}
                                                    id={props.match.params.id}
                                                    content={this.content}
                                                    navProps={props}
                                                />    
                                                
                                            );
                                        }}
                                    />

                                    {/* TOPIC SCREEN */}
                                    <Route 
                                        path={[
                                            "/:langId?/topic/:topicId/item/:itemId?",
                                            "/:langId?/topic/:topicId#:hash?",
                                            "/:langId?/topic/:topicId/overview/item/:itemId?",
                                            "/:langId?/topic/:topicId/:tabId?/:tabChildId?",
                                            "/:langId?/topic/:topicId/:tabId?/:tabChildId?#:hash?",
                                            
                                            
                                        ]}
                                        render={(props:any)=>{
                                            Router.setProps(props);
                                            return(
                                                <TopicScreen 
                                                    content={this.content}
                                                    topicId={props.match.params.topicId}
                                                    tabId={props.match.params.tabId}
                                                    tabChildId={props.match.params.tabChildId}
                                                    itemId={props.match.params.itemId}
                                                    navProps={props}
                                                />    
                                            );
                                        }}
                                    />

                                    {/* ORAL TRADITIONS  */}
                                    <Route 
                                        path={[
                                            "/:langId?/oral-traditions/filter/:filter?",
                                            "/:langId?/oral-traditions",
                                            
                                        ]}
                                        render={(props:any)=>{
                                            Router.setProps(props);
                                            return(
                                                <OralScreen 
                                                    langId={props.match.params.langId}
                                                    content={this.content}
                                                    navProps={props}
                                                    filter={props.match.params.filter}
                                                />    
                                            );
                                        }}
                                    />

                                    {/* HOME SCREEN  */}
                                    <Route 
                                        path={[
                                            "/:langId?/a/:audienceId?/topic/:topicId/section/:sectionId",
                                            "/:langId?/a/:audienceId?/topic/:topicId/item/:itemId",
                                            "/:langId?/a/:audienceId?/topic/:topicId",
                                            "/:langId?/a/:audienceId?",
                                            "/:langId?",
                                        ]}
                                        render={(props:any)=>{
                                            Router.setProps(props);
                                            return(
                                                <HomeScreen 
                                                    langId={props.match.params.langId}
                                                    audienceId={props.match.params.audienceId}
                                                    content={this.content}
                                                    topicId={props.match.params.topicId}
                                                    itemId={props.match.params.itemId}
                                                    sectionId={props.match.params.sectionId}
                                                    navProps={props}
                                                />    
                                            );
                                        }}
                                    />
                                    

                                    
                                   
                                        
                                </Switch>
                                
                            </BrowserRouter>

                            <CookieConsent/>

                            <ModalSearch
                                isOpen={App.state.isSearchOpen}
                                onClose={this.handleSearchClose}
								content={this.content}

                            />

                            <ModalThanks
                                isOpen={App.state.isThanksModalOpen}
                                onClose={this.handleThanksModalClose}
                            />

                            <ModalPrivacy
                                isOpen={App.state.isPrivacyModalOpen}
                                onClose={this.handlePrivacyModalClose}
                            />
                            
                            
                        </div>
                    }

                    
                    <Snacks
                    />

                    <Debug
                        ref={(ref)=>{if(ref) App.debug = ref}}
                    />
                            
                </div>
            
            
        );
    }
}


