import './_styles.scss';
import React from 'react';
import App from '../../App';
import Lang from '../../tools/Lang';
// import Tween, {TweenObject} from '../../tools/Tween';
import Tools from '../../tools/Tools';
import Config from '../../config/Config';
import Flash, {LangMode, PreloaderId} from '../Flash/Flash';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

interface Props{
    scrollY?:number
    
}

interface State {
    
}

export default class HeroKids extends React.Component<Props, State>{
    

    
    constructor(props:Props){
        super(props)

        this.state = {
           
        }

       
    }


    // ---------------------------------------------------------------------------------
    // LIFECYCLE

  

    // ---------------------------------------------------------------------------------
    // METHODS


    // ---------------------------------------------------------------------------------
    // EVENTS


    // ---------------------------------------------------------------------------------
    // RENDER

    handleFlashReady = (player:any)=>{
        log("HeroKids.handleFlashReady()")
        if(!player || !player.SetVariable){
            return;
        }

        // Increment num times shown
        let numTimesKidsHeroShown = App.state.numTimesKidsHeroShown + 1;
        if(numTimesKidsHeroShown > 1){
            player.SetVariable("isForceMuted", true)
        }
        else{
            App.setState({
                numTimesKidsHeroShown: numTimesKidsHeroShown
            });
        }
        
    }
    

    render(){

        let params:any = {};
        if(App.state.numTimesKidsHeroShown){
            params.isForceMuted = true;
        }
     
        return(
            
            <div id="hero" className="audience-kids">
                <div 
                    className="main-column" 
                >
                    <div className="flash-wedge"/>
                    {(Tools.isHoverDevice && App.ENABLE_FLASH) ?
                            <Flash
                                id="hero-kids"
                                flashWidth={1280}
                                flashHeight={500}
                                backgroundColor={"#F5F4F3"}
                                langId={Lang.langId}
                                langMode={LangMode.live}
                                scrollY={this.props.scrollY}
                                onReady={this.handleFlashReady}
                                params={params}
                                preloader={PreloaderId.bocan}
                                
                        />
                        :
                        <img src={Config.getImagePath() + "l/hero_kids.png"} className="hero-image" alt=""/>
                    }

                    

                </div>
            </div>
        
        )
    }
}



