import './_styles.scss';
// import React, {useState} from 'react';
import { Facebook, Twitter } from '@material-ui/icons';
import {BootstrapTooltip} from '../BootstrapTooltip/BootstrapTooltip';

import App from '../../App';
import Router from '../../router/Router';
import Lang, {LangId} from '../../tools/Lang';
import Snacks from '../Snacks/Snacks';
import Tools from '../../tools/Tools';
import Tracker, {ShareOptions, ShareButtonLocation, ShareNetwork} from '../../tools/Tracker';
import Link from '../Link/Link';

// @ts-ignore
import $ from 'jquery';



// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}



export default function Footer(){
    
   

    const setLang = async (langId:LangId) => {
        await Lang.setLangId(langId)

        Router.navigateToAppStateLang();

        Snacks.add({
            message: Lang.t("snack.langSwitched"),
            // type: SnackType.success,
            secs:4,
        })
    }

    
    const handleLangGaClick = async () => {
        setLang(LangId.ga);
    }

    const handleLangEnClick = async () => {
        setLang(LangId.en);
    }
    
    const handleThanksClick = () => {
        log("Footer.handleThanksClick()");
        Router.navigateToThanksModal();
    }

    const handlePrivacyClick = () => {
        log("Footer.handlePrivacyClick()");
        Router.navigateToPrivacyModal();
    }

    const handleShareFacebookPress = async (url:string) => {
        const options:ShareOptions = {network:ShareNetwork.facebook, button_location: ShareButtonLocation.footer}
        Tracker.track({id:Tracker.events.share, options:options})
        
        await Tools.later(0.2);

        // Touch devices open new tab
        if(Tools.isTouchDevice){
            window.open(url, "_blank")
        }
        
        // Desktop devices open popup window
        else{
            const screenW = $(window).width();
            const screenH = $(window).height();
            const ratio = screenW / screenH;
            const defaultW = 540;
            const defaultH = defaultW / ratio;

            // Try default size. If too big, then make smaller
            const w = defaultW > screenW ? screenW * 0.8 : defaultW;
            const h = defaultH > screenH ? screenH * 0.8 : defaultH;
            console.log(" w = " + w)
            console.log(" h = " + h)
            window.open(url, Lang.t("footer.social.share.facebook.tip"), 'resizable, height=' + h +', width=' + w);
        }
        
        
    }

    const handleShareTwitterPress = async(url:string) => {
        const options:ShareOptions = {network:ShareNetwork.twitter, button_location: ShareButtonLocation.footer}
        Tracker.track({id:Tracker.events.share, options:options})
        await Tools.later(0.2);
        window.open(url, "_blank")
        
    }
    

    
        const langId = Lang.langId === LangId.en ? LangId.en : "";
        const facebookUrl = "https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&u=https%3A%2F%2Fbocan.tv%2F" + langId + "&display=popup&ref=plugin&src=share_button";
        const twitterUrl = "https://twitter.com/intent/tweet?hashtags=bocan%2C&original_referer=https%3A%2F%2Fpublish.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Ehashtag%7Ctwgr%5Ebocan";
     
        return(
            
            <div id="footer">
                <div className="footer-top"/>
                <div className="main-column">
                    <div className="main-column-content">
                        <div className="footer-row footer-row-top">
                            <div className="footer-col footer-col-logo">
                                <img src="/images/ui/logo-light-l-tv.png" id='footer-logo' alt="Bocan TV"/>
                                <p>{Lang.t("home.subtitle")}</p>
                            </div>
                            <div className="footer-col footer-col-char">
                                <img src="/images/ui/footer-top-char1.png" alt=""/>
                            </div>
                        </div>

                        <div className="footer-row">
                            <div className="footer-col">
                                <h3>{Lang.t("footer.lang")}</h3>
                                <div className="lang--buttons">
                                    <Link 
                                        to={Router.getLangPath(LangId.ga)}
                                        onClick={handleLangGaClick} 
                                        className={"button " + (App.state.langId === LangId.ga ? "active" : "")}
                                    >
                                        {Lang.t("lang.ga")}
                                    </Link>
                            
                                    <Link 
                                        to={Router.getLangPath(LangId.en)}
                                        onClick={handleLangEnClick} 
                                        className={"button " + (App.state.langId === LangId.en ? "active" : "")}
                                    >
                                        {Lang.t("lang.en")}
                                    </Link>

                                </div>
                            </div>

                            <div className="footer-col">
                                <h3>{Lang.t("footer.social.share")}</h3>
                                <p>{Lang.t("footer.social.share.text")}</p>
                                <div className="footer-hello">
                                    
                                    <Link 
                                        to={"#share_facebook"} 
                                        onClick={()=>{
                                            handleShareFacebookPress(facebookUrl)
                                            return false;
                                        }}
                                        ariaLabel="Facebook"
                                    >
                                        <BootstrapTooltip title={Lang.t("footer.social.share.facebook.tip")}>
                                            <Facebook className="footer-hello-icon" target='_blank'/>
                                        </BootstrapTooltip>
                                    </Link>
                                    

                                    
                                    <Link
                                        to="#share_twitter" 
                                        // target='_blank'
                                        onClick={()=>{
                                            handleShareTwitterPress(twitterUrl)
                                            return false;
                                        }}
                                        ariaLabel="Twitter"
                                        
                                    >
                                        <BootstrapTooltip title={Lang.t("footer.social.share.twitter.tip")}>
                                            <Twitter className="footer-hello-icon"/>
                                        </BootstrapTooltip>
                                    </Link>

                                </div>
                            </div>


                            <div className="footer-col">
                                <h3>{Lang.t("footer.thanks")}</h3>
                                <p>{Lang.t("footer.thanks.text")}</p>
                                <Link to="#thanks" onClick={handleThanksClick} >{Lang.t("footer.thanks.link")}</Link >
                            </div>


                        </div>

                        <div className="footer-row footer-row-bottom">
                            <div className="footer-col footer-col-copyright">
                                <p>{Lang.t("footer.copyright")}<span className="separator">|</span>
                                    <Link to="#privacy" onClick={handlePrivacyClick}>
                                        {Lang.t("footer.privacy.link")}
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

               
                
            </div>
        
        )
    }




