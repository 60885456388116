import App from '../App';
import Cookies from './Cookies';
import Tools from '../tools/Tools';

export enum AudienceId {
    kids = "kids",
    learners = "learners",
    toddlers = "toddlers",
}


// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}



let instance:Audience;

export default class Audience {

	defaultAudienceId:AudienceId;

	static get defaultAudienceId():AudienceId { return instance.defaultAudienceId }

    audienceClassNames:any = {
        learners: "audience-" + AudienceId.learners, 
        kids: "audience-" + AudienceId.kids, 
        toddlers: "audience-" + AudienceId.toddlers
    };





	constructor(defaultAudienceId:AudienceId) {
        log("Audience()");
        log("- App.state.audienceId = " + App.state.audienceId)

		this.defaultAudienceId = defaultAudienceId
        instance = this;
        
    }

    // -------------------------------------
    // INIT

    init = async () => {
        log("Audience.init()")

		this.setAudienceId( Cookies.getCookie("audienceId") || this.defaultAudienceId);
	}


    // -------------------------------------
    // GETTERS / SETTERS

	static get id():AudienceId{
        return instance.id;
    }
	
	get id():AudienceId{
        return App.state.audienceId;
    }

    static get isAudienceAnyKids():boolean{
		// console.log(`Audience.isAudienceAnyKids: App.state.audienceId = ${App.state.audienceId},  result = ${ App.state.audienceId !== AudienceId.learners}`)
        return App.state.audienceId !== AudienceId.learners;
    }
    
    static get isAudienceKids():boolean{
        return App.state.audienceId === AudienceId.kids;
    }

    static get isAudienceToddlers():boolean{
        return App.state.audienceId === AudienceId.toddlers;
    }

    static get isAudienceLearners():boolean{
        return App.state.audienceId === AudienceId.learners;
    }
    
    // -------------------------------------
    // SET AUDIENCE


    static setAudienceId = async (audienceId:AudienceId, storeCookie:boolean = false) =>{
        if(!Tools.misc.isValidEnum(audienceId, AudienceId)) {
			audienceId = AudienceId.learners;
		}
        if(audienceId === App.state.audienceId) return;

        await instance.setAudienceId(audienceId, storeCookie);
    }
    
    setAudienceId = async (audienceId:AudienceId, storeCookie:boolean = false) => {
        
        log("Audience.setAudienceId('" + audienceId + "', " + storeCookie + ")")

        // Set the css class
        this.setAudienceClass(audienceId);

        App.setState({audienceId});
        
        if(storeCookie) Cookies.setCookie("audienceId", audienceId);

        log(`- App.state.audienceId = ${App.state.audienceId}`);
        log(`- Cookie audienceId = ${Cookies.getCookie("audienceId")}`);

    }



    
    // -------------------------------------
    // CSS CLASS

    setAudienceClass(id:AudienceId){
        
        for(let key in this.audienceClassNames){
            Tools.removeClassFromHTML(this.audienceClassNames[key]);
        }
        Tools.addClassToHTML(this.audienceClassNames[id]);
    }


  
}
    

