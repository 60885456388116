import './_styles.scss';

import React from 'react';
import {ArrowBackIos} from '@material-ui/icons';

import Lang from '../../tools/Lang';
import Tools from '../../tools/Tools'
import {MediaItemData} from '../../data/Topic';
import Tween from '../../tools/Tween';
import Router from '../../router/Router';
import {TopicTabId} from '../TopicTabs/TopicTabs';
import {Message} from '../Message/Message';
import {LangString} from '../../data/Content';


// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

type Props = {
    itemData:MediaItemData,
    setTitle:Function,

}

type State = {
    opacity:number,
    y:number,
}

export default class TopicLibraryItemPage extends React.Component<Props, State>{


    itemData:MediaItemData;

    isUnmounted:boolean = false;

    tween:any;

    constructor(props:Props){
        super(props);

        log("TopicLibraryItemPage()")

        this.state={
            opacity:0,
            y:0
        }   
        
        this.itemData = this.cleanData(props.itemData);


    }


    componentDidMount(){
       
        Tween.to(this, {
            opacity:1, 
            y:-5, 
            duration:0.8, 
            ease:"power4.out",
            // onUpdate: ()=> {log("onUpdate()")},
            // onComplete: ()=> {log("onComplete()")}
        })

        this.setTitle();
        
    }

    componentWillUnmount(){
        this.isUnmounted = true;
        Tween.kill(this.tween)
    }

    setTitle(){
        let description:LangString|undefined = undefined;
        let descriptionStr = "";
        
        if(this.itemData.publication){ 
            descriptionStr = this.itemData.publication
        }
        if(this.itemData.author){
            descriptionStr += " | " + this.itemData.author
        }

        if(this.itemData.publisher){
            descriptionStr += " | " + this.itemData.publisher
        }

        let descriptionStrEn = descriptionStr;
        let descriptionStrGa = descriptionStr;
        if(this.itemData.intro){
            descriptionStrEn += " | " + this.itemData.intro.en
            descriptionStrGa += " | " + this.itemData.intro.ga
        }

        if(descriptionStrEn || descriptionStrGa){
            description = {
                en: descriptionStrEn,
                ga: descriptionStrGa
            }
        }
        this.props.setTitle(this.itemData.title, description)
    }

    cleanData(propsItemData:MediaItemData):MediaItemData{
        let itemData:MediaItemData = {...propsItemData};
        Tools.langStringClean(itemData.intro);
        Tools.langStringClean(itemData.text);
        return itemData;
    }

    handleBackClick = () => {
        Router.navigateToTopicTab(TopicTabId.library)
    }

    renderText(itemData:MediaItemData):any{
        // log("TopicLibraryItemPage.renderText()")
        let textString = Lang.tc(itemData.text);
        
        let paras:string[] = Tools.stringToArray(textString)
        
        let warning:any = null;
        if(paras.length === 0){
            textString = Lang.tc(itemData.text, true);
            paras = Tools.stringToArray(textString)
    
            warning = 
                <Message 
                    title={Lang.t("error." + Lang.langId + "NotAvailable.ga")} 
                    text={Lang.t("error." + Lang.langId + "NotAvailable.en")}
                />
        }


        let items:any[] = [];
        let i = 0;
        for(let para of paras){
            let item =  <p key={"linrary_text_para__" + i} dangerouslySetInnerHTML={{__html: para}}></p>
            items.push(item);
            i++;
        }
        
        const textItems = 
            <div className="text">
                {items}
                <span className="topic-library-text-underline"></span>
            </div>
        

        return (
            <div>
                {warning}
                {textItems}
            </div>
        )
    }

    render(){
        const itemData = this.itemData;
        
        if(!itemData) return null;

        return (
            <div className="topic-library-item text-column"
                style={{
                    opacity:this.state.opacity,
                    transform:"translateY(" + this.state.y + "px)"
                }}
            >
                <div className="topic-subheader">

                    {/* BACK BUTTON */}
                    <button className="but type2" onClick={this.handleBackClick}><ArrowBackIos className="icon"/>{Lang.t("topic.back-to-list")}</button>

                    {/* TITLE */}
                    {itemData.title && 
                        <h2>{Lang.tc(itemData.title)}</h2>
                    }
                    
                    {/* PUBLICATION */}
                    {itemData.publication && 
                        <h3 className="publication"><i>{itemData.publication}</i></h3>
                    }

                    {/* AUTHOR */}
                    {itemData.author && 
                        <p className="author smallcaps">{itemData.author}</p>
                    }

                    {/* PUBLISHER */}
                    {itemData.publisher && 
                        <p className="publisher faint small">
                            
                            {itemData.publisher}
                        
                            {itemData.collectionObject && itemData.collectionObject.page && (
                                " pp. " + itemData.collectionObject.page
                            )}
                        </p>
                    }

                </div>

                {/* SUMMARY */}
                {itemData.intro && 
                    <h4 className="summary"><i dangerouslySetInnerHTML={{__html: Lang.tc(itemData.intro)}}></i></h4>
                }

                {/* TEXT */}
                {itemData.text && this.renderText(itemData)}
                
            </div>
        )
    }

    


}

