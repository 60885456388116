import './_styles.scss';
import {memo, useEffect, useRef} from 'react';

import Audience from '../../tools/Audience';
import Lang from '../../tools/Lang';
import Expander from '../Expander/Expander';
import Tools, {useAsyncState} from '../../tools/Tools';



// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

interface Props {
    contributors?:{[key:string]:any}|null;
    id?:string;
}

// export default memo(Credits);
export default memo (function Credits(props:Props){
    
    const CREDITS_MAX_VISIBLE_LEARNERS:number = 5;
    const CREDITS_MAX_VISIBLE_KIDS:number = 2;

    const isUnmounted = useRef<boolean>(false);
    const prevId = useRef<string>('');

    log(" ")
    log("-------------")
    log("Credits()")
    // log(" - props.contributors = ", props.contributors)

    // log("Credits()")
    // log(" - contributors = ", props.contributors)
    const [contributors, setContributors] = useAsyncState(null)

    
  
    // Update credits when new set of credits comes in via props
    useEffect(()=> {

        const setNewContributors = async()=>{

            // Clear the contributors from state asynchronously
            // (If we don't do this, then Expander fails to update properly)
            await setContributors(null);

            if(isUnmounted.current) return;

            // Clean the props.contributors data
            let data:{[key:string]:any} = {...props.contributors};
            for(let key in data){
                let contributor:any = data[key];
                if(!contributor || contributor.length === 0){
                    delete data[key];
                }
            }

            setContributors(data);
        }

     

        if(prevId.current !== props.id){
            // Update the prevId value now that we have a new one
            prevId.current = props.id || '';

            setNewContributors();
        }

    }, [props.id, props.contributors, contributors, setContributors])


    // Handle unmounting
    useEffect(()=>{
        // log("useEffect() []")
        // log(" - on mount")
      
        // Function called on unmount
        return ()=>{
            isUnmounted.current = true
        }
    }, [])


    

    const renderCredits = ():any[]|null => {
        // log("Credits.renderCredits")
        if(!contributors) return null;

        let credits:any[] = [];
        let maxVisible:number = Audience.isAudienceAnyKids ? CREDITS_MAX_VISIBLE_KIDS : CREDITS_MAX_VISIBLE_LEARNERS;
        let i = 0;
        const numContributors:number = Tools.object.numKeys(contributors)
        // log(" - maxVisible = " + maxVisible)
        // log(" - numContributors = " + numContributors)
    
        for(let key in contributors){
            let contributor:any[] = contributors[key]
            
            let isVisible:boolean = 
                i < maxVisible
                || numContributors === maxVisible + 1;


            let credit = renderCredit(key, contributor, isVisible)
            credits.push(credit);
            i++;
        }
        return credits;
    
    
    }

    const renderCreditNames = (job:any[]):any => {
        
        let personFrags:any[] = [];

        // Loop thru people
        let i = 0;
        for(let person of job){
            
            // Build string for person
            let str = "";
            if(i > 0) str +=", ";

            str += Lang.tc(person.name, true);
            if(person.instrument){
                let instrument = Lang.tc(person.instrument);
                if(instrument){
                    instrument = instrument.toLowerCase();
                    str += " (" + instrument + ")";
                }
                
            }

            const personFrag = <span key={job + "_person_" + i}>{str}</span>
            
            personFrags.push(personFrag);

            i++;
        }
        
        return (
            <p className="small">
                {personFrags}
            </p>
        )
        
        
    }

    const renderCredit = (key:string, job:any[], isVisible:boolean):any => {
        // log("Credits.renderCredit")
        let frag = null;

        let isJobPopulated:boolean = false;
        for(let person of job){
            if(person.name){
                isJobPopulated = true;
                break;
            }
        }
        if(!isJobPopulated){
            return frag;
        }

        
        let jobId:string = "credits." + key;
        if(job.length > 1) jobId += ".pl";

        // Class for cell content, used by Expander to hide / reveal
        let cellContentClass = isVisible ? "cell-content" : "cell-content hidden revealed";

        frag = (
            <tr className="credit-row" key={"credit_row_" + key}>
                <td>
                    <div className={cellContentClass}>
                        <div className="credit-column job"><p className="small">{Lang.t(jobId)}</p></div>
                    </div>
                </td>
                <td>
                    <div className={cellContentClass}>
                        <div className="credit-column name">
                            {renderCreditNames(job)}
                        </div>
                    </div>
                </td>
            </tr>
        )
    
        return frag;
    }
    

    if(!contributors || !props.id) return null;
    
    let credits:any[]|null = renderCredits();
    

    return (
        <div className="modal-media-credits">
            <Expander
                butAlignLeft={true}
                id={props.id}
            >
                <div>
                    <table>
                        <tbody>
                        {credits}
                        </tbody>
                    </table>
                </div>
            </Expander>
        </div>
    )
});

