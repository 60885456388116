// import './_styles.scss';
import React from 'react';
// import Lang from '../../tools/Lang';
// import Tween, {TweenObject} from '../../tools/Tween';
// import Tools from '../../tools/Tools';
// import Config from '../../config/Config';
import Audience from '../../tools/Audience';
import HeroKids from '../HeroKids/HeroKids';
// import HeroToddlers from '../HeroToddlers/HeroToddlers';
import HeroLearners from '../HeroLearners/HeroLearners';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}


interface Props{
    scrollY?:number,
    
}

interface State {
    
}

export default class Hero extends React.Component<Props, State>{
    
   
    constructor(props:Props){
        super(props)
        log("Hero()")
        this.state = {
           
        }

       
    }




    // ---------------------------------------------------------------------------------
    // METHODS

    


    // ---------------------------------------------------------------------------------
    // EVENTS

   
    


    // ---------------------------------------------------------------------------------
    // RENDER

    
    

    render(){


        if(Audience.isAudienceKids){
            return(
                <HeroKids
                    scrollY={this.props.scrollY}
                />
            )
        }

        if(Audience.isAudienceToddlers){
            return null
            // (
            //     <HeroKids
            //         scrollY={this.props.scrollY}
            //     />
            // )
        }

        return(
            <HeroLearners
                scrollY={this.props.scrollY}
            />
        )   


    }
}



