import gsap from 'gsap';

// Map of all the props objects that the tweens are tweening
// Keys are 
let tweenProps:any = {}

// Map of all the TweenObject objects
let tweenObjects:any = {}

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}


export type TweenObject = {
    tween:gsap.core.Tween,
    id:string,
}

/**
 * Tweener that uses GSAP.
 * https://greensock.com/
 * For easing options see: https://greensock.com/ease-visualizer
 * 
 * USAGE
 * Make sure that your component has value(s) in state to tween.
 * Then call:
 * Tween.to(this, {
 *      opacity:1, 
 *      y: -100,
 *      duration:0.3, 
 *      delay:1,
 *      onUpdate:()=>{},
 *      onComplete:()=>{},
 * })
 * 
 * UNMOUNTING
 * Always clean up tweens when componentWillUnmount is called or you'll get a no-op warning. 
 * Either:
 * - set isUnmounted = true (deals with all tweens in the component)
 * - call Tween.kill(tweenObject) for any tweens (deals with specific tween)
 */
export default class Tween {

    static to = (comp:any, params:any):TweenObject|null  => {
        if(!comp || !comp.state) return null;
        const onUpdate = params.onUpdate;
        const onComplete = params.onComplete;

        // Make props object
        let id:string = "tween_" + Date.now().toString();
        let props:any = {}
        for(let key in params){
            if(key !== "onUpdate" && key !== "onComplete" && key !== "duration" && key !== "delay" && key !== "ease"){
                props[key] = comp.state[key]
            }
        }
        tweenProps[id] = props;

        // Make params object
        params.onUpdate = ()=>{
            // log("onUpdate() comp.isUmounted = " + comp.isUnmounted)
            // The component has unmounted
            if(!comp || !comp.state || comp.isUnmounted){
                log("Tween.to onUpdate UNMOUNTED");
                Tween._killById(id);
                return null;
            }else{
                comp.setState({...props})
                if(onUpdate) onUpdate();
            }
            
        };
        params.onComplete = () => {
            Tween._killById(id);
            if(onComplete) onComplete();
        }
    
        // Start tween
        let tween:gsap.core.Tween = gsap.to(props, params);
        
        // Store tween object
        let tweenObject:TweenObject = {
            tween: tween,
            id: id
        }
        tweenObjects[id] = tweenObject;

        // Send TweenObject back
        return tweenObject;

    }

    /**
     * Kill all the tween elements by TweenObject id
     * @param id 
     */
    static _killById(id:string){
        log("_killById() tweenId = ", id)
        let tweenObj:TweenObject = tweenObjects[id];
        Tween.kill(tweenObj);

    }

    /**
     * Kill all the tween elements (removes object from tweenObjects, props from tweenProps, kills gsap tween)
     * @param id 
     */
    static kill(obj:TweenObject|null){
        log("kill() obj = ", obj)
        if(!obj) return;
        if(obj.tween){
            obj.tween.kill();
            log("killed gsap tween")
        }
        delete tweenProps[obj.id];
        delete tweenObjects[obj.id];
        log("tweenProps = ", tweenProps)
        log("tweenObjects = ", tweenObjects)
    }

    
}