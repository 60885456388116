import {LangString} from '../data/Content';
// import MediaGrid from '../components/MediaGrid/MediaGrid';
import Audience, {AudienceId} from '../tools/Audience';
import Content from '../data/Content';
import Tools from '../tools/Tools';

import type {ExternalLinkData} from '../tools/ExternalLink'


export type TopicData = {
    
    topicId:string,
    title:LangString,
    description?:LangString,
    intro?:LangString,
    ref?:LangString,
    longref?:LangString,
    bibliography?:TopicDataBibliography,
    links?:TopicDataLinks,
    mediaItems?:MediaItemsData,
}

export type TopicDataLinks = TopicDataLink[];


export type TopicDataLink = {
    url:string,
    name?:string,
    description?:LangString,
}

export type TopicDataBibliography = {
    source:string
}


export type MediaItemsData = MediaItemData[]
export type MediaItemCredits = {[key:string]:MediaItemCredit}

export type MediaItemCredit = {
    job:Object,
    name:Object,
}

export enum VideoLang {
    en = "en",
    et = "et",
    ga = "ga",
    hi = "hi",
    en_gaSubs = "en_gaSubs",
    et_enSubs = "et_enSubs",
    ga_enSubs = "ga_enSubs",
    hi_enSubs = "hi_enSubs",
    
    
    
}

export type VideoSource = {
    service:VideoService,
    en?:VideoLang,
    et?:VideoLang,
    ga?:VideoLang,
    hi?:VideoLang,
    en_gaSubs:VideoLang,
    et_enSubs?:VideoLang,
    ga_enSubs?:VideoLang,
    hi_enSubs?:VideoLang,
}

export enum VideoService {
    vimeo = "vimeo"
    
}




export type MediaItemData = {
	/** E.g. "318" */
    _id?:string,

	/**
	 * E.g. "black_dog_318"
	 * Note: This is badly named. Should be `niceId`. 
	 * @see Content.cleanTopicData()
	 */
    id:string,

    // Some media require a filename, e.g audio
    filename?:string,
    
    title:Object,
    type:MediaType|string,
    credits?:MediaItemCredits,

    // Story suummary
    summary?:Object,

    // Longer text
    text?:LangString,
    description?:LangString,

    fieldworker?:string,
    informant?:string,
    catname?:string,
    catnumber?:string,
    date?:string,
    intro?:LangString,
    copyright?:string,
    collection?:string,
    collectionObject:MediaItemDataCollectionObject,
    author?:string,
    publication?:string,
    publisher?:string,
    isBoganach?:boolean,
    contributors?:any[],
	tags?:string[],
    keywords?:LangString,
    externalLinks?:ExternalLinkData[],

    videoSource?:VideoSource,


}

export type MediaItemDataCollectionObject = {
    name?:string,
    author?:string,
    page?:string
}


export enum MediaType  {
    video = "video",
    audio = "audio",
    script = "script",
    comic = "comic",
    game = "game",
    archiveSound = "archive.sound",
    archiveText = "archive.text",
    archive = "archive"
}

export const MediaTypes:MediaType[] = [
    MediaType.video,
    MediaType.audio,
    MediaType.script,
    MediaType.comic,
    MediaType.game,
    MediaType.archiveSound,
    MediaType.archiveText
]



// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

export default class Topic{

    static FILE_FORMAT_JPG:string = "jpg";

    static MEDIA_GRID_SORT_ORDER_BY_AUDIENCE:{[key:string]:MediaType[]} = {
        kids: [MediaType.video, MediaType.comic, MediaType.game, MediaType.script, MediaType.audio],
        toddlers: [MediaType.video, MediaType.audio, MediaType.comic],
        learners: [MediaType.video, MediaType.comic, MediaType.script, MediaType.audio ]
    }

    static get mediaGridSortOrder():MediaType[]{
        return this.MEDIA_GRID_SORT_ORDER_BY_AUDIENCE[Audience.id] || [];
    }



    static getMediaItemFromTopic(topic:TopicData, type:string):MediaItemData|null{
        log("Topic.getMediaItemFromTopic()")
        log(" - type = " + type)
        if(!topic || !topic.mediaItems) return null;

        for(let item of topic.mediaItems){
            if(item.type === type){
                return item;
            }
        }
        return null;
    }

    /**
     * Get a list of MediaType types for the given topic
     * @param {TopicData} topic 
     * @param {boolean} [onlyMediaGridTypes]
     * @returns Always returns an array
     */
    static getMediaTypesFromTopic(topic:TopicData, onlyMediaGridTypes:boolean = false):MediaType[]{
        log("Topic.getMediaTypesFromTopic()")
        let arr:MediaType[] = [];
        if(!topic || !topic.mediaItems) return arr;

        for(let mediaItem of topic.mediaItems){
            
            const type:MediaType = mediaItem.type as MediaType;
            
            if(!arr.includes(type)){

                // Just mediaGrid types
                if(onlyMediaGridTypes){
                    
                    if(this.isValidMediaItem(mediaItem, topic.topicId)){
                        // if(MEDIA_GRID_SORT_ORDER.includes(type)){
                            arr.push(type)
                        // }
                    }
                    
                } 

                // Any media types
                else{
                    arr.push(type)
                }
            }
        }
        return arr;
    }

    /**
     * Does a given topic have any MediaGrid items?
     * @param {TopicData} [topic]
     * @returns 
     */
    static hasMediaGridItems(topic:TopicData|null):boolean{
        if(!topic) return false;
        const types:MediaType[] = Topic.getMediaTypesFromTopic(topic, true);
        return types.length > 0;
    }

    
    

    static isValidMediaItem(data:MediaItemData, topicId:string = ""):boolean{

        // No data
        if(!data) return false;
    
        // Media not valid for current audience
        const validArr:MediaType[] = this.mediaGridSortOrder;
        const includesType:boolean = validArr.includes(data.type as MediaType);
        if(!includesType) return false;

        // If media item is a comic and audience is learners then check audiences in the manual media item data
        if(data.type === MediaType.comic && Audience.isAudienceLearners){
            if(!this.isComicEnabledForLearners(data, topicId)) return false;
        }

        // If media item is a game then don't show it if disabled on touch device
        if(data.type === MediaType.game && Audience.isAudienceKids){
            const story = Content.getStory(topicId);
            
            if(story){
				const isTouchDevice = Tools.isTouchDevice
				const storyHidden = Content.isMediaItemHiddenOnTouchDevice(story, data.id)
				// console.log(`TOPIC isValidMediaItem storyHidden=${storyHidden} isTouchDevice=${isTouchDevice}:`, data)
                if (storyHidden && isTouchDevice){
                    return false;
                }
            }
            
        }


        return true;

    }


    

     /**
     * Check to see if the comic is enabled in the manual data for learners.
     * Comics are unique in that we only want to show specific comics to learner audiences, i.e. the Arctic comics.
     * @param data - media item data object
     * @param topicId 
     * @returns True if manual data has override in place, otherwise false
     */
    static isComicEnabledForLearners(data:MediaItemData, topicId:string = ""):boolean{
        if(!data || !topicId) return false;
        
        const story = Content.getStory(topicId);
		// console.log(`TOPIC isComicEnabledForLearners()`, story, data)
        const audiences = story?.manualMediaItems?.[data.id]?.audiences;
        if (audiences?.[AudienceId.learners]) return true
        
        return false;
    }


    static isComicFormatJpg(data:MediaItemData, topicId:string = ""):boolean{
        return this.getComicFileFormat(data, topicId) === this.FILE_FORMAT_JPG;
    }

    

    static getComicFileFormat(data:MediaItemData, topicId:string = ""):string{
        if(!data || !topicId) return "";
        
        const story = Content.getStory(topicId);
		// console.log(`TOPIC getComicFileFormat()`, story, data)
        const fileFormat:string|undefined = story?.manualMediaItems?.[data.id]?.comic?.fileFormat;
        return fileFormat || "";
    }

    static topicHasArchiveSounds(topicData:TopicData|null):boolean{
        if(!topicData || !topicData.mediaItems) return false
        for(let itemData of topicData.mediaItems){
            if(itemData.type === MediaType.archiveSound) return true;
        }
        return false;
    }

    static topicHasResearch(topicData:TopicData|null):boolean{
        if(!topicData || !topicData.longref) return false
        if(topicData.longref.en || topicData.longref.ga) return true;
        return false;
    }

    static topicHasBibliography(topicData:TopicData|null):boolean{
        if(!topicData || !topicData.bibliography) return false
        if(topicData.bibliography.source) return true;
        return false;
    }

    static topicHasLibrary(topicData:TopicData|null):boolean{
        if(!topicData || !topicData.mediaItems) return false
        for(let itemData of topicData.mediaItems){
            if(itemData.type === MediaType.archiveText) return true;
        }
        return false;
    }

    static topicHasLinks(topicData:TopicData|null):boolean{
        let data = topicData;
        if(!data || !data.links) return false
        if(data.links && data.links.length > 0) return true;
        return false;
    }


    


   
}

