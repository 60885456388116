import './_styles.scss';

import React, {createRef} from 'react';

// @ts-ignore
import ScriptTag from 'react-script-tag'

import Tools from '../../tools/Tools';
import Tween, {TweenObject} from '../../tools/Tween';
import Lang, {LangId} from '../../tools/Lang';
import Config from '../../config/Config';
import Spinner from '../Spinner/Spinner';
import App from '../../App';


export enum LangMode{
    
    // The default. Will reload the swf if lang changes
    reload = "reload",
    
    // Use if SWF has ExternalInterface.SetVariable set up to toggle language live
    live = "live"
    
    
}

export enum PreloaderId{
    bocan = "bocan",
    ruffle = "ruffle"
}

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

interface Props  {

    params?:any,

    // The original Flash dimensions of the swf (not the desired width and height)
    flashWidth:number;
    flashHeight:number;

    // Unique id
    id:string;

    // We can load a remote url
    url?:string;

    // E.g. "#112233"
    backgroundColor?:string;
    
    // Use these if you want fixed dimensions, otherwise it'll be responsive to container size
    width?:string;
    height?:string;

    langId:LangId,

    // Defauts to 'reload'
    langMode?:LangMode,

    scrollY?:number|undefined,

    onReady?:Function,

    disabled?:boolean,

    preloader?:PreloaderId,
}


interface State {
    opacity:number,
    containerHeight:string,
    state:StateId,
    
}

enum StateId {
    loading = "loading",
    error = "error",
    loaded = "loaded"
}

/**
 * Uses Ruffle to show Flash swf files.
 * For latest builds of ruffle check https://ruffle.rs/#releases
 * Current build is from 2021-03-19
 * 
 * NOTES
 * 
 * 1) Responsive scaling
 * Provide the original width and height of the flash asset and the <Flash> component will
 * scale to its container dimensions, whatever that is
 */
export default class Flash extends React.Component<Props, State> {

	container:any = null
	
    containerRef:any = createRef()

    player:any;

    opacityTween:TweenObject|null= null;

    langId:LangId|null = null;

    checkFlashReadyInterval:any = null;

	constructor(props:Props) {
        
        super(props)

        log("Flash()");
        
        this.langId = props.langId;


        this.state = {
            opacity:0,
            containerHeight:this.containerHeight,
            state:StateId.loading
        }

        // if(this.props.disabled) returzn this;

        //@ts-ignore
        window.react_handleBackPress = this.handleFlashBackPress;
        
        
	}

    handleFlashBackPress = (sectionId:string) => {
        log("Flash.handleFlashBackPress()")
        log(" - sectionId = " + sectionId)
        let flashHistory = {...App.state.flashHistory}
        flashHistory[sectionId].pop()
        App.setState({
            flashHistory: flashHistory
        })


    }
    // ---------------------------------------------------------------------------
    // LIFECYCLE

	componentDidMount() {
        log("Flash.componentDidMount()");
        // if(this.props.disabled) return;
		this.container = this.containerRef.current;
		log(` - container = `, this.container);

        // Listen for resize if responsive
        const isResponsive = this.props.width === undefined && this.props.height === undefined;
        if(isResponsive) window.addEventListener("resize",this.handleResize);

        // Once mounted we can set the height of the container, since we now have the width
        if(this.containerRef){
            this.setState({
                containerHeight: this.containerHeight
            })
        }

        
	}

    componentDidUpdate(oldProps:Props){
        // log("Flash.componentDidUpdate()")
        // if(this.props.disabled) return;
        // log(" - this.langId = " + this.langId)
        // log(" - this.props.langId = " + this.props.langId)
        if(this.langId !== this.props.langId){
            // log(" - changing lang...")
            // log(" - this.player = " + this.player)
            this.setLangId();
        }

        if(oldProps.scrollY !== this.props.scrollY){
            // log(" - scrollY updated: " + this.props.scrollY)
            this.setIsOnscreen();
        }

        if(oldProps.id !== this.props.id){
            // log(" - id updated: " + this.props.id)
            this.reloadSwf();
        }
    }

    componentWillUnmount(){
        
        window.removeEventListener("resize", this.handleResize);

        this.stopCheckFlashReady();

        Tween.kill(this.opacityTween);
    }

    
	// ---------------------------------------------------------------------------
    // METHODS

    setIsOnscreen() {
        // log("Flash.setIsOnscreen()");
        // if(this.props.disabled) return;
        
        // Get dom element
        let element = this.container || document.querySelector("#" + this.containerId);

        // Return if no container element, scroll position or containerHeight
        if(!element || this.props.scrollY === undefined || !this.state.containerHeight) return false;
        
        // Get necessary params
        const viewportHeight = this.getViewportHeight();
        const elementY = this.getElementY(element);
        const elementHeight = parseInt(this.state.containerHeight);
        const viewportStartY:number = this.props.scrollY;
        const viewportEndY:number = viewportStartY + viewportHeight;
  
        // Onscreen
        const isOnscreen =((elementY + elementHeight > viewportStartY) && (elementY < viewportEndY))

        // The player has instantiated fully
        if(this.player && this.player.SetVariable){
            this.player.SetVariable("isOnscreen", isOnscreen);
        }
        
        
    }

    getElementY(element:any) {
        let testElement = element;
        let top = 0;
    
        while(!!testElement && testElement.tagName.toLowerCase() !== "body") {
            top += testElement.offsetTop;
            testElement = testElement.offsetParent;
        }
    
        return top;
    }
    
    getViewportHeight() {
        var de = document.documentElement;
    
        if(!!window.innerWidth)
        { return window.innerHeight; }
        else if( de && !isNaN(de.clientHeight) )
        { return de.clientHeight; }
        
        return 0;
    }

   

    setLangId = () => {
        log("Flash.setLangId()");
        log(" - langId = " + this.props.langId);
        log(" - this.props.id = " + this.props.id)
        // if(this.props.disabled) return;
        // log(" - langMode = " + this.props.langMode)
        // log(" - player = " + this.player)
        // log(" - player.SetVariable = " + this.player.SetVariable)
        this.langId = this.props.langId;

         // The player has instantiated fully
        if(this.props.langMode === LangMode.live && this.player && this.player.SetVariable){
            log(" - setLang() setting with this.player.SetVariable()")
            this.player.SetVariable("version", Lang.version);
        }
        
        // Either not instantiated or SWF doesn't have ExternalInterface.SetVariable set up. Just reload the swf
        else{
            log(" - setLang() reloading swf")
            this.reloadSwf();
        }
    }

    

    get containerId():string{
        return 'flash-container-' + this.props.id;
        // return 'container';
    }

    /**
     * Works out the height that the container should be, based on the current width and the dimensions supplied in the props.
     * @return string
     */
    get containerHeight():string{
        // let h = "100%";
        let h = "";

        try{
            let container = document.querySelector("#" + this.containerId);
            // let container = this.container;
            if(!container) return "0px";

            // @ts-ignore
            let w = container.offsetWidth;
            const scale = w/this.props.flashWidth;
            h = (this.props.flashHeight * scale) + "px";
            log(" - container width = " + w)
            log(" - container height = " + h)
        }
        catch(err){
            log("Error getting ruffle container height: ", err)
        }
        
        return h;
    }

    reveal(){
        this.opacityTween = Tween.to(this, {opacity:1, duration:0.3})
    }

    reloadSwf = async () => {
        log("Flash.reload()");
        // if(this.props.disabled) return;
        this.stopCheckFlashReady();
        
        await Tools.later(0.2)
        
        this.loadSwf();
    
    }

    loadSwf = async () => {
        log(" ");
        log("--------------------------------")
        log("Flash.loadSwf()")
        // if(this.props.disabled) return;

        //@ts-ignore
        if(!window.RufflePlayer) return;

        // Return 
        if(!this.containerRef) return;
        
        this.container = this.containerRef.current;

        if(!this.container) return;

        log(" - this.container = " + this.container)
        log(" ");

        this.stopCheckFlashReady();

        if(!this.player) {
            // @ts-ignore
            
            let ruffle = window.RufflePlayer.newest();
            this.player = ruffle.createPlayer();

            // console.log("(this.props.preloader === PreloaderId.ruffle) = " + (this.props.preloader === PreloaderId.ruffle))
            
            // Config
            this.player.config = {
                // Options affecting the whole page
                // "publicPath": undefined,
                // "polyfills": true,

                // Options affecting files only
                "autoplay": "on",
                // "unmuteOverlay": "visible",
                "unmuteOverlay": "hidden",
                "backgroundColor": this.props.backgroundColor,
                "letterbox": "fullscreen",
                "warnOnUnsupportedContent": true,
                "contextMenu": false,
                // "upgradeToHttps": window.location.protocol === "https:",
                "maxExecutionDuration": {"secs": 15, "nanos": 0},
                // "logLevel": "error",
                // "logLevel": "trace",

                "preloader":(this.props.preloader === PreloaderId.ruffle),
                // "preloader":false,
                // "preloader": true
            };

            // Style
            this.player.style.width="100%"
            this.player.style.height=this.containerHeight;
            this.player.style.backgroundColor=this.props.backgroundColor;

            // Add to DOM
            this.container.appendChild(this.player);
        }

        // Load
        // let path = process.env.PUBLIC_URL + "/flash/" + this.props.id + ".swf";
        let path = Config.getGenericFlashPath(this.props.id);
        if(this.props.url) path = this.props.url;

        // FlashVar params
        let params = "containerId=" + this.containerId + "&langId=" + this.props.langId + "&version=" + Lang.version;
        if(this.props.params){
            for(let key in this.props.params){
                params += "&" + key + "=" + this.props.params[key]
            }
            
        }
        // log(" - params = ", params)
        this.player.load({
            url: path,
            parameters: params,
            allowScriptAccess: true,
            // allowScriptAccess: "always",
            // allowScriptAccess: "sameDomain",
        }).then((event:any) => {
            log(" - swf loaded")

            this.setState({
                state: StateId.loaded
            })

            this.reveal();

            if(this.props.onReady){
                this.startCheckFlashReady();
            }
            
            

        }).catch((e:any) => {
            console.error(`Ruffle failed to load the file: ${e}`);
        });

    }

    

    startCheckFlashReady = () => {
        this.checkFlashReadyInterval = setInterval(this.handleCheckFlashReady, 200);
    }

    stopCheckFlashReady = () => {
        clearInterval(this.checkFlashReadyInterval);
    }

    // ---------------------------------------------------------------------------
    // EVENTS

    /**
     * The flash SWF has been loaded and rendered onto the screen. 
     * - this.player exists.
     * - SetVariable is defined.
     * @returns 
     */
    handleFlashReady = () => {
        log("Flash.handleFlashReady()");
        log(" - this.player = " + this.player);
        this.setState({
            state:StateId.loaded
        })
       
        this.stopCheckFlashReady()
        if(this.props.langMode === LangMode.live) this.setLangId();
        if(this.props.onReady){
            this.props.onReady(this.player);
        }

        
        
    }

    
    handleCheckFlashReady = () => {
        log("Flash.handleCheckFlashReady()")
        if(!this.player) return false;
        const isFlashReady = this.player.SetVariable !== undefined;
        if(isFlashReady) this.handleFlashReady();
    }

    handleResize = (event:any) => {
        log("Flash.handleResize()")
        if(!this.player) return false;
        const h = this.containerHeight;
        log(" - setting height to: " + h)
        
        // Set the player height
        this.player.style.height = h;

        // Set the container height
        this.setState({containerHeight:h});
    }

	/**
	 * Taken from similar code in cms.bocan.tv/media/comic.php
     * https://github.com/ruffle-rs/ruffle/wiki/Using-Ruffle#javascript-api
	 */
	handleRuffleLoaded = async () => {
        this.loadSwf();
        

	}

	handleRuffleLoadError(err:any) {
		console.log("RUFFLE ERROR: ", err)
        this.setState({
            state:StateId.error
        })
	}


	render() {
        
        if(this.props.disabled) return null;
        
        // -------------
        // Container style

        let style:any = {
            opacity:this.state.opacity,
            height: this.state.containerHeight
        }
        
        // Fixed dimensions
        if(this.props.width) style.width = this.props.width;
        if(this.props.height) style.height = this.props.height;

        // -------------
        

		return (
            <div className="flash-block">
                
                <ScriptTag 
                    type="text/javascript" 
                    onLoad={this.handleRuffleLoaded} 
                    onError={this.handleRuffleLoadError} 
                    src={process.env.PUBLIC_URL +"/js/ruffle/ruffle.js"} 
                />

                {/* LOADING */}
                {/* Always rendered, gets covered up */}
                {this.props.preloader === PreloaderId.bocan &&
                    <div className="flash-loading">
                        <Spinner 
                            className="spinner-icon"
                        />    
                    </div>
                }
            

                {/* <button id={this.containerId + "_anchor"} className="flash-anchor" /> */}
                <button 
                    id={this.containerId}
                    className="flash-container"
                    style={style}
                    ref={this.containerRef} 
                />

                
            </div>
        )
	}
}