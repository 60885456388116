import './_styles.scss';
import React from 'react';


import MediaItem from "../MediaItem/MediaItem";
// import ModalMedia from '../../components/ModalMedia/ModalMedia';
import {TopicData, MediaItemData, MediaType} from '../../data/Topic';
import Router from '../../router/Router';
import Audience from '../../tools/Audience';
// import Tools from '../../tools/Tools';
import ModalMediaController from '../ModalMedia/ModalMediaController';
// import Content from '../../data/Content';
// import type {ContentManualMediaItem} from '../../data/Content';
import Topic from '../../data/Topic';

import {AudienceId} from '../../tools/Audience';



// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

type Props = {
    topicData:TopicData,
    mediaItemId?:string,
    showOnlyTypes?:MediaType[]|string[],
    hideIfSingleItem?:boolean
}


export default class MediaGrid extends React.Component<Props>{

    // This is used to differentiate between two scenarios:
    // 1) When user opens an item manually
    // 2) When an item is opened automatically using the url
    // The router uses this information to choose to goBack (when manually opened) or not (when automatic) when the user closes the modal
    goBackOnModalClose:boolean= false;

    mediaItems:MediaItemData[];

    constructor(props:Props){
        super(props);

        log("MediaGrid()")

        this.mediaItems = this.sortItems(props.topicData.mediaItems);
        log(" - mediaITems = ", this.mediaItems)
        log(" - this.props.topicData = ", this.props.topicData)
        log(" - this.props.mediaItemId = " + this.props.mediaItemId)

    }


    // -------------------------------------------------------------------------------------------------------
    // LIFECYCLE

    componentDidUpdate(){
        // log("MediaGrid.componentDidUpdate()");
        // log(" - this.props.mediaItemId = " + this.props.mediaItemId);
    }


    // -------------------------------------------------------------------------------------------------------
    // METHODS

    sortItems(propItems?:MediaItemData[]):MediaItemData[]{
        log("MediaGrid.sortItems()")
        if(!propItems) return [];
        let items:MediaItemData[] = [];
        
        for(let type of Topic.mediaGridSortOrder){
            for(let item of propItems){
                if(item.type === type) items.push(item);
            }
        }
        return items;
    }

    getItemData(mediaItemId:string):MediaItemData|undefined{
        // log("MediaGrid.getItemData()");
        // log("this.itemsData = " + this.itemsData)
        if(!this.mediaItems) return undefined;
        for(let itemData of this.mediaItems){
            if(itemData.id === mediaItemId) return itemData
        }
        return undefined
    }

  
    
   
    
    handleItemClick = (mediaItemData:any) => {
        log("MediaGrid.handleItemClick()")
        this.goBackOnModalClose = true;

		// @audience-kludge
		// We're  having trouble with many media items not being tagged with an audience.
		// So if an item thumbnail is in Kids or Toddlers, we force it to stay in there...
		const shouldForceAudienceId = Audience.id===AudienceId.kids || Audience.id===AudienceId.toddlers
		const forceAudienceId = shouldForceAudienceId ? Audience.id : undefined

        Router.navigateToMediaItem(mediaItemData.id, forceAudienceId)
    }




    // -------------------------------------------------------------------------------------------------------
    // RENDER
   
    renderItems():any{
        log("MediaGrid.renderItems()")
        
        let items:any[] = [];
        
        let i=0;

        if(this.mediaItems === undefined){
            return null;
        }

        for(let mediaItemData of this.mediaItems){
            // console.log(" - mediaItemData = ", mediaItemData)
            if(!Topic.isValidMediaItem(mediaItemData, this.props.topicData.topicId)){
                continue;
            }

            if(this.props.showOnlyTypes){
                let type:MediaType = mediaItemData.type as MediaType;
                if(!this.props.showOnlyTypes.includes(type)){
                    continue
                }
            }

            let item = (
                <MediaItem
                    itemData={mediaItemData}
                    topicData={this.props.topicData}
                    key={"media__" + this.props.topicData.topicId + "__" + mediaItemData.type + "__" + i}
                    onClick={()=>{this.handleItemClick(mediaItemData)}}
                    isActive={this.props.mediaItemId === mediaItemData.id}
                />
            )
            items.push(item)
            i++;
        }

        if(this.props.hideIfSingleItem && items.length === 1) return null;

        // Only one item, do not show it if kids or toddlers
        // if(items.length === 1) return null

        return items;
    }
  
    renderModal (){
        log("MediaGrid.render()")
        // log(" - this.state.itemData = " + this.state.itemData)
        if(this.props.topicData && this.props.mediaItemId){

            return (
                <ModalMediaController
                    topicData={this.props.topicData}
                    itemId={this.props.mediaItemId}
                    goBackOnModalClose={this.goBackOnModalClose}
                />  
            )
        }
        return null;
    }

    render(){
        log("MediaGrid.render()")
     
       
        return (
            <div>
                <div className="media-grid">
                    {this.renderItems()}
                </div>
                
                {!Audience.isAudienceAnyKids && 
                    this.renderModal()
                }
            </div>
        )
    }
}