import Lang from "../../tools/Lang";
import './_styles.scss';
import { SyntheticEvent } from "react";

type Props = {
    onSelect:Function,
    langTerm:string,
    values:string[],
    defaultValue?:string,
    value?:string,
	className?:string,
    disabled?:boolean
}
export const FormSelect = (props:Props) => {
    const handleChange = (event:SyntheticEvent) => {
        //@ts-ignore
        const val = event.target.selectedOptions[0].value;
        props.onSelect(val);
    }

    // Class
    let className =  `${props.className || ""} control control-select`;
    if(props.disabled) className += " disabled";
    
    return (
        <select 
            className={className}
            onChange={handleChange} 
            defaultValue={props.defaultValue}
            value={props.value}
            disabled={props.disabled}
        >
            {props.values.map((value:string, index:number) => 
                    <option 
                        key={"form_select_option_" + props.langTerm + "." + value} 
                        value={value}
                    >
                            {Lang.t(props.langTerm + "." + value)}
                    </option>
                )
            }
        </select>
    )
}