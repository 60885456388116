import './_styles_snack.scss';
import React from 'react';
// import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
import {SnackType} from './Snacks';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

type Props = {
    // show?:boolean
    message:string,
    onClose?:Function,
    type?:SnackType,
    secs?:number,
    butLabel?:string,
    onButPress?:Function,
    
}

export default function Snack(props:Props) {

    log("Snack() props = ", props)
    
    const [open, setOpen] = React.useState(true);

//   const handleClick = () => {
//     setOpen(true);
//   };

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);

        if(props.onClose) props.onClose();
    }

    
    
    // const renderAction = () => {
    //     return (
    //         <React.Fragment>
    //             <Button color="secondary" size="small" onClick={handleClose}>
    //                 UNDO
    //             </Button>
    //             <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
    //                 <CloseIcon fontSize="small" />
    //             </IconButton>
    //         </React.Fragment>
    //     )
    // }

    // Style the snackbar
    let className = "snack";
    if(props.type) className += " snack--" + props.type;

    // Time onscreen
    let millis:number|undefined = 5000;
    if(props.secs != null) millis = props.secs * 1000;

    if(props.butLabel){
        millis = undefined;
    }

    const handleButPress = () => {
        let evt:any = undefined;
        handleClose(evt);
        if(props.onButPress) props.onButPress();
    }


    return (
        
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={open}
            autoHideDuration={millis}
            onClose={handleClose}
            // message={props.message}
            
            // action={renderAction()}
        >
            <div className={className}>
                <p>{props.message}</p>
                {props.butLabel && props.onButPress &&
                    <button onClick={handleButPress} className="snack-but">{props.butLabel}</button>
                }
            </div>
        </Snackbar>
        
    );
}

