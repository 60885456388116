import {useEffect, useRef, useState } from 'react';

import App from '../../App';
import Cookies from '../../tools/Cookies';
import Lang from '../../tools/Lang';
import Tools from '../../tools/Tools';
// import Config from '../../config/Config';
import Router from '../../router/Router';

import './_styles.scss';



// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}


type CookieData = {
    isConsentGranted:boolean
}

export default function CookieConsent (){
    // log("CookieConsent()")
    

    const COOKIE_NAME:string = "bocanCookieConsent";
    const SHOW_IN_DEV:boolean = false;

    const [show, setShow] = useState<boolean>(false);
        
    // Mounted / unmounted
    const isUnmounted = useRef<boolean>(false)

    useEffect(() => {
        // On mount
        log("useEffect() [SHOW_IN_DEV]")
        log(" - on mount")

        const  init = async() => {
            await Tools.later(3);
            log("CookieConsent.init()")
            const isGranted:boolean = isConsentGranted()
            const shouldShow = 
                !isGranted
                || (Tools.isDev && SHOW_IN_DEV);
    
                log(" - isGranted = " + isGranted)
                log(" - shouldShow = " + shouldShow)
            if(shouldShow){
                if(isUnmounted.current) return;
                setShow(true);
            }
        }
        init();

        // On unmount
        return () => {
            log(" - on unmount")
            isUnmounted.current = true
        }
    }, [SHOW_IN_DEV]) 

  
    const isConsentGranted = ():boolean => {
        App.log("CookieConsent.isConsentGranted()")
        let isGranted:boolean = false;
        let cookie:CookieData = Cookies.getCookie(COOKIE_NAME);
        if(cookie) isGranted = cookie.isConsentGranted === true;
        return isGranted;
    }

    const setCookie = (val:boolean) => {
        log("CookieConsent.setCookie()")
        const cookie:CookieData = {isConsentGranted: val}
        Cookies.setCookie(COOKIE_NAME, cookie);
    }

    const grantConsent = () => {
        App.log("CookieConsent.grantConsent()")
        setCookie(true)
        hidePanel();
    }

    // const revokeConsent = () => {
    //     setCookie(false)
    // }

    // const showPanel = () => {
    //     if(isUnmounted) return;
    //     setShow(true)
    // }

    const hidePanel = () => {
        if(isUnmounted.current) return;
        setShow(false)
    }

    const handleInfoButPress = () => {
        log("CookieConsent.handleInfoButPress()")
        Router.navigateToPrivacyModal();
    }

    const handleConsentButPress = () => {
        log("CookieConsent.handleButPress()")
        grantConsent();
    }

    
    log("CookieConsent.render()")
    
    if(show){
        return (
            <div className="cookie-consent">
                <p>{Lang.t("cookieConsent")}</p>
                <div className="cookie-consent-buts">
                    <button onClick={handleInfoButPress} className="cookie-consent-but cookie-consent-but-info">{Lang.t("but.moreInfo").toUpperCase()}</button>
                    <button onClick={handleConsentButPress} className="cookie-consent-but cookie-consent-but-ok">{Lang.t("but.ok").toUpperCase()}</button>
                </div>
            </div>
        )
    }
    return null;
}
