import './_styles.scss';
import React from 'react';

import Nav, {NavTheme} from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer';
import Lang, {LangId} from '../../tools/Lang';
import Router from '../../router/Router';
// import Tools from '../../tools/Tools';
// import {Message} from '../../components/Message/Message';
import Content from '../../data/Content';
import SectionItem from '../../components/SectionItem/SectionItem';
import OralItems from '../../components/OralItems/OralItems';
import type {ContentStory, ContentMaterial, LangString} from '../../data/Content';
import {TopicTabId} from '../../components/TopicTabs/TopicTabs';
import {Form} from '../../components/Form/Form';
import {FormSelect} from '../../components/FormSelect/FormSelect';
import {FormButton} from '../../components/FormButton/FormButton';
import Tools from '../../tools/Tools';
import Spinner from '../../components/Spinner/Spinner';
import {Message} from '../../components/Message/Message';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}


enum LoadingState  {
    loading = "loading",
    loaded = "loaded",
    error = "error",
}


type Props = {
    langId?:LangId,
    navProps?:any,
    content?:Content,
    filter?:string,
}
type State = {
    items:ContentStory[],
    loadingState:LoadingState,
}

type StoryFilters = {
	ignoreFilterLang?:boolean,
	filters:StoryFilter[],
}
type StoryFilter = {
	type?:string,
	tags?:string[],
}


export type PropsFilter = {
	type:string,
	lang:string,
}


// Ids of all filter tags used in the db
enum filterTags {
    english = "english",
    "eyewitness account" = "eyewitness account",
    factual = "factual",
    gaelic = "gaelic",
    "historical account" = "historical account",
    music = "music",
    songs = "songs",
    storytelling = "storytelling",
    verse = "verse"
}

export default class OralScreen extends React.Component<Props, State>{
    
    // Sort the stories (can be hard coded to sort by single language, e.g. English)
    // If null then list is sorted by current language
    SORT_BY_TITLE_IN_LANGUAGE:LangId|null = null;//LangId.en;

    FORM_TYPE_ALL:string = "all";
    FORM_LANG_ALL:string = "all";

    MIN_DELAY_WHEN_UPDATING:number = 0.1;
    MAX_DELAY_WHEN_UPDATING:number = 0.3;

    /**
	 * All items that have oral media items.
     * We filter this to show specific ones
	 */
    oralStories:ContentStory[];

    // All the options in the 'types' select box
    formTypes:string[] = [
        this.FORM_TYPE_ALL,
        
        // Storytelling
        filterTags.storytelling,
        filterTags['eyewitness account'], 
        filterTags['historical account'],  
        // filterTags.factual, 
        
        // Music and song
        filterTags.music, 
        filterTags.songs, 
        filterTags.verse, 
    ];

	formLangs:string[] = [this.FORM_LANG_ALL, 'english', 'gaelic']

	/**
	 * Each form type (aka media item genre tag, aka material tag), e.g. storytelling, can have an array of little filters.
	 * For a filter to work it must have an entry here.
	 * If no entry is found we show everything.
	 */
	formTypeStoryFilters:Record< string, StoryFilters > = {

		"english": {
			filters:[
				/**
				 * This is a filter.
				 * If your filter has multiple tags, to pass the filter the story must have ALL of the tags
				 */
				{type: "archive.sound", tags: ["english"]}
			]
		},

        "eyewitness account": {
			filters:[{type: "archive.sound", tags: ["eyewitness account"]}]
		},

        "factual": {
			filters:[{type: "archive.sound", tags: ["factual"]}]
		},

        "gaelic": {
			filters:[{type: "archive.sound", tags: ["gaelic"]}]
		},

        "historical account": {
			filters:[{type: "archive.sound", tags: ["historical account"]}]
		},

        "music": {
			ignoreFilterLang: true,
			filters:[
				{type: "archive.sound", tags: ["music"]},
			]
		}, 

		// Multiple filters! We use an OR for multiple filters.
		"songs": {
			filters:[
				{type: "archive.sound",	tags: ["song"]}, 
				// OR...
				// {type: "audio",	tags: ["song"]}
			]
		}, 

        // Storytelling stories must have `archive.sound` media items tagged with `stortytelling`.
        "storytelling": {
			filters:[   

				// This is a filter. A story must everything in this filter to be included.
				{type: "archive.sound", tags: ["storytelling"]}
			]
		},
				
        "verse": {
			filters:[{type: "archive.sound", tags: ["verse"]}]
		}, 
	}

   
    
    isUnmounted:boolean = false

    constructor(props:Props){
        super(props);
        log("OralScreen()")
        log(" - props.filter = " + props.filter);

        this.oralStories = props.content?.getOralStories() || []
        
        this.state = {
            loadingState:LoadingState.loading,
            items:[]
        }
        
        //TODO: Set the title
        this.setTitle();
        
    }

    // ----------------------------------------------------------
    // LIFECYCLE

    componentDidMount(): void {
        this.updateItems();
    }

    componentWillUnmount(): void {
        this.isUnmounted = true;
    }

    componentDidUpdate(prevProps: Props, prevState: State): void {
        // log("OralScreen.componentDidUpdate()")
        // log(" - prevProps.type = " + prevProps.type)
        // log(" - this.props.type = " + this.props.type)

        // Filter has changed, update items
        if(prevProps.filter !== this.props.filter){
            this.updateItems()
        }

        const hasLangChanged = prevProps.langId !== this.props.langId;
        const haveFiltersOrLangChanged:boolean = 
            hasLangChanged
            || prevProps.filter !== this.props.filter;

        if(haveFiltersOrLangChanged){
            this.setTitle();
        }

        if(hasLangChanged){
            this.updateItems()
        }

        
    }

    // ----------------------------------------------------------
    // METHODS
    setTitle(){
        let title:LangString|undefined = {en:"",ga:""};
        let filterText = "";
        if(this.props.filter){
            let propsFilter:PropsFilter = this.parsePropsFilter(this.props.filter)
            if(propsFilter.type){
                // Type
                if(propsFilter.type !== this.FORM_TYPE_ALL) filterText += Lang.t("oral.controls.filter.type." + propsFilter.type);
                
                // Lang
                if(propsFilter.lang && propsFilter.lang !== this.FORM_LANG_ALL){
                    if(filterText) filterText += ", ";
                    filterText += Lang.t("oral.controls.filter.lang." + propsFilter.lang);
                }
                filterText += " | "
            }
            
        }
        title[LangId.en] = filterText + Lang.t("oral.header.title", LangId.en);
        title[LangId.ga] = filterText + Lang.t("oral.header.title", LangId.ga);

        Lang.setTitle(title, "promo.home.oral.text", true, Tools.OPENGRAPH_TYPE_ARTICLE)
    }

	/** Always returns a valid filter */
	parsePropsFilter = (fragment:string=""):PropsFilter => {
		let parts = fragment.split(',')
		let filter = {
			type: parts[0] || this.FORM_TYPE_ALL,
			lang: parts[1] || this.FORM_LANG_ALL,
		}
		return filter;
	}


	isPropsFilterEmpty = (filter:PropsFilter):boolean => {
		if (!filter) return true;
		if (filter.type===this.FORM_TYPE_ALL && filter.lang===this.FORM_LANG_ALL) return true;
		return false;
	}


    /**
     * Get a list of items from the Content data, filtered by whatever filters are coming in through the props, cookies, or state
     */
    getItems = async ():Promise<ContentStory[]|Error> => {
        
        let filteredStories:ContentStory[]|undefined = [];

        try{
            // throw Error("bob");
            // const delay = this.MIN_DELAY_WHEN_UPDATING + (Math.random() * (this.MAX_DELAY_WHEN_UPDATING - this.MIN_DELAY_WHEN_UPDATING))
            // await Tools.later(delay);

            // Specific type or lang
			let propsFilter:PropsFilter = this.parsePropsFilter(this.props.filter)

			let storyFilters:StoryFilters|undefined = this.formTypeStoryFilters[propsFilter.type]
			if (!storyFilters) {
				if (propsFilter.type!==this.FORM_TYPE_ALL) {
					console.log(`Could not find filter for "${propsFilter.type}", returning stopries of all types`)
				}
				propsFilter.type = this.FORM_TYPE_ALL
			}

			// All oral stories wanted, just do it quick, no filtering.
            const propsFilterIsEmpty = this.isPropsFilterEmpty(propsFilter);
			if (propsFilterIsEmpty) {
				filteredStories = this.oralStories;
			}




			const doesMaterialPassAFilter = (material:ContentMaterial, storyFilters:StoryFilter[]):boolean => {
				if (!storyFilters) return true;

				for (const typeFilter of storyFilters) {

					const badFilterType = typeFilter.type && material.type !== typeFilter.type
					const goodFilterType = !badFilterType

					let hasAllTags = true
					const worthChecking = goodFilterType
					if (worthChecking) {
						hasAllTags = typeFilter.tags!=null 
									 &&
									 Tools.array.hasAllValues(material.tags || [], typeFilter.tags)
					}  
					const passedFilter = goodFilterType && hasAllTags
					if (passedFilter) {
						return true;
					}
				}
				return false;
			}

			const filterStory = (story:ContentStory, allMaterials:ContentMaterial[], storyFilters?:StoryFilters, filterLang?:string) => {
				
				// console.log(`Searching ${allMaterials.length} materials for story ID ${story.id}:`, allMaterials.map(material=>material.story))

				const storyMaterials = allMaterials.filter((material:ContentMaterial) => {
					return material.story === story._id
				})
				// console.log(`Found ${storyMaterials.length} materials for this story`)
			

				// The story must have at least one material with...
				// 		*  the right language
				//		AND
				// 		* pass at least one of the type-filters

				for (let material of storyMaterials) {
					let goodLang = true
					
					const shouldCheckLang = !storyFilters?.ignoreFilterLang && filterLang && filterLang !== this.FORM_LANG_ALL
					if (shouldCheckLang) {
						//@ts-ignore
						goodLang = material.tags?.includes(filterLang)
					}
					const worthCheckingMore = goodLang

					let passedAFilter = true
					if (worthCheckingMore && storyFilters?.filters) {
						passedAFilter = doesMaterialPassAFilter(material, storyFilters.filters)
					}
					const materialMatchesAllRequirements = goodLang && passedAFilter
					if (materialMatchesAllRequirements) {
						return true;
					}
				}
				return false;
			}
            if(!propsFilterIsEmpty){
                const allMaterials = this.props.content?.getMaterials() || []
                filteredStories = this.oralStories.filter(story=>filterStory(story, allMaterials, storyFilters, propsFilter.lang))
            }
            
        }
		catch(error){
			console.log(error)
            return new Error("Error loading data");    
        }

        // Sort the stories (can be hard coded to sort by single language, e.g. English)
        const sortLang:LangId = this.SORT_BY_TITLE_IN_LANGUAGE ? this.SORT_BY_TITLE_IN_LANGUAGE : Lang.langId;
        
        filteredStories.sort((a:ContentStory,b:ContentStory)=>{
            if(a.title[sortLang] > b.title[sortLang]) return 1
            if(a.title[sortLang] < b.title[sortLang]) return -1
            return 0;

        })

        return filteredStories || [];
    }







    
    /**
     * Update the items list in the state
     * @returns 
     */
    updateItems = async () => {
        
        log("OralScreen.updateItems()");
        if(this.isUnmounted) return;

        this.setState({
            items:[],
            loadingState:LoadingState.loading,
        }, async ()=> {

            const items:ContentStory[]|Error = await this.getItems();
            if(this.isUnmounted) return;
            
            // Loaded items
            if(Array.isArray(items)){
                
                this.setState({
                    items:items,
                    loadingState:LoadingState.loaded
                })
            }
            // Error loading items
            else{
                this.setState({
                    items:[],
                    loadingState:LoadingState.error
                })
            }
            
        });
    }

    // ----------------------------------------------------------
    // HANDLERS

    /**
     * 
     * @param itemData User has tapped an item in the list
     * @param item 
     * @param rowData 
     */
    handleItemClick = (itemData:ContentStory, item?:SectionItem, event?:any) => {
        
        // Go to topic page.
        // Passing the event allows the user to open in new tab by pressing the metaKey
        Router.navigateToTopic(itemData.id, TopicTabId.sounds, undefined, undefined, undefined, undefined, undefined, event)
        
    }

        
    /**
     * User has selected a 'type' to view
     * @param type 
     * @returns 
     */
    handleFormTypeSelected = (type?:string) => {
        if(this.isUnmounted) return;
        // console.log(" - type = " + type)
        // this.setState({type});

        // User selected "all"
        if(!type) type = this.FORM_TYPE_ALL;
        
		let filter:PropsFilter|undefined = this.parsePropsFilter(this.props.filter)
		filter.type = type 
		// If the filters are at their defaults, then it's not actually a filter
		if (this.isPropsFilterEmpty(filter)) {
			filter = undefined
		}
        Router.navigateToOralWithFilters(filter);
    }


	handleFormLangSelected = (lang?:string) => {
        if(this.isUnmounted) return;
        // console.log(" - type = " + type)
        // this.setState({type});

        // User selected "all"
        if(!lang) lang = this.FORM_LANG_ALL;

		let filter:PropsFilter|undefined = this.parsePropsFilter(this.props.filter)
		filter.lang = lang 
		// If the filters are at their defaults, then it's not actually a filter
		if (this.isPropsFilterEmpty(filter)) {
			filter = undefined
		}
        Router.navigateToOralWithFilters(filter);
    }


    /**
     * 
     * @param event User has tapped the clear filters button
     */
    handleClearButClick = (event:any) => {
        Router.navigateToOralWithFilters();
    }

    // ----------------------------------------------------------
    // RENDER


    render(){
        log("OralScreen.render()")

		let {type, lang} = this.parsePropsFilter(this.props.filter)
		const isFiltering = this.props.filter!=null

		if (isFiltering) {
			log(" - this.props.filter.type = " + type);
			log(" - this.props.filter.lang = " + lang);
		}

        
        return(

            <div id="oral-screen">
                <Nav
                    theme={NavTheme.dark}
                    showLogo={true}
                    screenId="oral"
                />
                <div className="header-bg">
                    <img src="/images/ui/oral/header-bg.png" alt=""/>
                </div>
                
                <div className="main-column">

                    {/* HEADER */}
                    <header>
                        
                        {/* TEXT BLOCK */}
                        <div className="text">
                            
                            {/* TITLE */}
                            <div className="title-wrapper">
                                <h1>{Lang.t("oral.header.title")}</h1>
                                <h3>{Lang.t("oral.header.subtitle")}</h3>
                            </div>

                            {/* PARTNERS */}
                            <div className="partners-wrapper">
                                <a className="partners" href="https://www.tobarandualchais.co.uk/" target="_blank" rel="noreferrer">
                                    <img src="/images/ui/logo-tobar.png" alt=''/>
                                    <div className="partners-text">
                                        <div className="block1">{Lang.t("oral.credit")}</div>
                                        <div className="block2">Tobar an Dualchais <span>|</span> Kist o' Riches</div>
                                    </div>
                                </a>
                            </div>
                        </div>

                        {/* BADGE BLOCK */}
                        <img src="/images/ui/oral/badge-mono.png" className="badge" alt=""/>
                            
                        
                    </header>

                    {/* CONTENT BLOCK */}
                    <div className="main-column-content content">
                       
                        {/* GRID HEADER */}
                        <div className="grid-header">
                            
                            <Form id="oral-form" className="controls">
                                <FormSelect
									className="type"
                                    langTerm="oral.controls.filter.type"
                                    values={this.formTypes}
                                    onSelect={this.handleFormTypeSelected}
                                    // defaultValue={type}
                                    value={type}
                                    disabled={this.state.loadingState !== LoadingState.loaded}
                                />
                                <FormSelect
									className="lang"
                                    langTerm="oral.controls.filter.lang"
                                    values={this.formLangs}
                                    onSelect={this.handleFormLangSelected}
                                    // defaultValue={type}
                                    value={lang}
                                    disabled={this.state.loadingState !== LoadingState.loaded}
                                />								
                                {isFiltering &&
                                    <FormButton
                                        onClick={this.handleClearButClick}
                                        labelId={"oral.controls.clear"}
                                        className="clear-but"
                                        labelClassName="clear-but-label"
                                        icon="close"
                                        disabled={this.state.loadingState !== LoadingState.loaded}
                                    />
                                }
                            </Form>
                            
                        </div>
                        
                        {/* GRID ITEMS */}
                        {this.state.loadingState === LoadingState.loaded &&
                            <OralItems
                                id="oral"
                                items={this.state.items}
                                onItemClick={this.handleItemClick}
                                
                            />
                        }  

                        {/* LOADING */}
                        {this.state.loadingState === LoadingState.loading && 
                            <div className="loading">
                                <Spinner
                                    className='spinner'
                                />
                            </div>
                        }

                        {/* ERROR */}
                        {this.state.loadingState === LoadingState.error && 
                            <div className="error">
                                <Message 
                                    title="Error loading page"
                                    text="Please try again later or contact the site directly"
                                />
                            </div>
                        }
                        
                    </div>
                </div>

                <Footer/>
            </div>
        )
    }


}