import './_styles.scss';
import React, { Component } from 'react';

import WaveSurfer from 'wavesurfer.js';
import {PlayArrowRounded, Pause} from '@material-ui/icons';
import Spinner from '../Spinner/Spinner';
import Tools from '../../tools/Tools';
import Tween, {TweenObject} from '../../tools/Tween';
import Snacks from '../Snacks/Snacks';
import Lang from '../../tools/Lang';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}


type Props = {
    url:string,
}

type State = {
    playing:boolean,
    state:StateId,
    opacity:number,
    butOpacity:number,
    autoplay:boolean,
}

enum StateId {
    loading = "loading",
    error = "error",
    loaded = "loaded"
}

export default class Waveform extends Component<Props,State> {  
    
    waveform:any;
    
    isUnmounted:boolean = false;

    tweenOpacity:TweenObject|null = null;
    tweenButOpacity:TweenObject|null = null;

    STATE_LOADING:string = "loading";

    constructor(props:Props){
        super(props);
        
        this.state = {
            playing: false,
            opacity:0,
            butOpacity:0,
            state:StateId.loading,
            autoplay:true,
        };
    }

    

    componentDidMount() {
        log("Waveform.componentDidMount()");

        

        this.load();
        
    };

    componentDidUpdate(oldProps:Props){
        log("Waveform.componentDidUpdate()");
        if(oldProps.url !== this.props.url){
            log(" - oldProps.url = " + oldProps.url)
            log(" - this.props.url = " + this.props.url)
            this.reload();
        }
    }

    componentWillUnmount(){
        this.isUnmounted = true;
        if(this.waveform) this.waveform.destroy();
        Tween.kill(this.tweenOpacity);
        Tween.kill(this.tweenButOpacity);
    }

    reload = () => {
        log("Waveform.reload()");

        // Destroy old waveform
        if(this.waveform){
            if(this.state.playing){
                this.waveform.playPause();
                this.setState({
                    playing:false
                })
            }
            Tween.kill(this.tweenOpacity);
            Tween.kill(this.tweenButOpacity);

            this.waveform.destroy();
        }
        
        
        this.load();
    }
    
   

    load = async () => {
        log("Waveform.load()");
        // await Tools.later(2);
        if(this.isUnmounted) return;

        this.setState({
            state: StateId.loading
        })

        this.waveform = WaveSurfer.create({
            container: '#waveform',
            waveColor: '#1111111A',
            progressColor: '#4E3874',
            cursorColor: '#4E387400',
            barWidth: 3,
            barRadius: 3,
            cursorWidth: 1,
            height: 200,
            barGap: 3,
            responsive: true
        });

        this.waveform.on('ready', this.handleReady);
        this.waveform.on('error', this.handleError);
        this.waveform.on('finish', this.handleFinish);
        
        this.waveform.load(this.props.url);
    }

    reveal(){
        this.tweenOpacity = Tween.to(this, {opacity:1, duration: 0.6});
        this.tweenButOpacity = Tween.to(this, {butOpacity:1, duration: 0.3});
    }

    handleFinish = async () => {
        log("Waveform.handleFinish()");
        if(this.isUnmounted) return;
        this.setState({
            playing: false
        })
    }

    handleReady = async () => {
        log("Waveform.handleReady()");
        if(this.isUnmounted) return;
        
        this.setState({
            state: StateId.loaded
            
        })
        log(" - state set complete")
        this.reveal();
        log(" - reveal complete")
        await Tools.later(1);
        log(" - delay complete")
        log(" - state.autoplay = " + this.state.autoplay)
        log(" - this.waveform.isReady = " + this.waveform.isReady)
        log(" - this.waveform = ", this.waveform)
        if(this.state.autoplay){
            this.handlePlay();
        }
        
    }

    handleError = async (err:any) => {
        log("Waveform.handleError()");
        if(this.isUnmounted) return;
    
        await Tools.later(1);
        if(this.isUnmounted) return;
        this.setState({
            state: StateId.error
        })

        Snacks.add({
            message: Lang.t("error.loadingFile"),
            secs:5,
        })

        console.log("Waveform.handleError() Error loading audio file '" + this.props.url +"': ", err)
        
    }

    handlePlay = () => {
        log("Waveform.handlePlay()");
        if(this.isUnmounted) return;
        // log(" - this.state.state = " + this.state.state)
        if(this.state.state !== StateId.loaded) return;
        // log(" - this.state.playing = " + this.state.playing)
        const playing = !this.state.playing;
        this.setState({playing});
        // log(" - state.playing set to " + playing)
        this.waveform?.playPause();
        
    };
    
    render() {
        return (
            <div className="waveform-container">
            
                <div id="waveform" 
                    className="waveform"
                    style={{
                        opacity: this.state.opacity
                    }}
                />
                

                <div className="controls">


                    {/* LOADING */}
                    {(this.state.state === StateId.loading || this.state.state === StateId.error)  && (
                        <div className="waveform-loading">
                           
                            {this.state.state !== StateId.error &&
                                <div className="waveform-loading-controls">
                                    <div className="spinner controls-item">
                                        <Spinner className="spinner-icon"/>
                                    </div>
                                </div>
                            }
                        </div>
                    )}

                    {/* ERROR */}
                    {/* {this.state.state === StateId.error  && (
                        <div className="waveform-loading">
                            <div className="waveform-loading-bg">
                                <div className="waveform-loading-bg-line"></div>
                            </div>
                            <div className="waveform-loading-controls">
                                <div className="spinner controls-item">
                                    X
                                </div>
                            </div>
                        </div>
                    )}
                         */}
                    {/* LOADED */}
                    {this.state.state === StateId.loaded && (   
                        <button onClick={this.handlePlay} 
                            className="play-but controls-item"
                            style={{
                                opacity:this.state.butOpacity
                            }}
                            aria-label={this.state.playing ? Lang.t("but.pause") : Lang.t("but.play")} 
                        >
                            
                            {!this.state.playing ? 
                                <PlayArrowRounded className="but-icon"/> : 
                                <Pause className="but-icon"/>
                            }
                        
                        </button>          
                    )}

                </div>
                
            </div>
        );
    }
};

