import './_styles.scss';

import React from 'react';
import Lang from '../../tools/Lang';
// import Tools from '../../tools/Tools'
import {TopicData, TopicDataLink, TopicDataLinks} from '../../data/Topic';
import TabPage from '../TabPage/TabPage';
// import Router from "../../router/Router";
import Link from '../Link/Link';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

interface Props  {
    topicData:TopicData,
    setTitle:Function,
}


export default class TopicLinks extends React.Component<Props>{

    componentDidMount(){
        log("TopicBibliography.componentDidMount")
        this.props.setTitle();
    }
    
    handleItemClick = (event:any, url:string) =>{
        event.preventDefault();
        //@ts-ignore
        window.location = url;
        // Router.navigateToPath(url, event, false, false, false, true)
        return false;
    }

    getUrl(itemData:TopicDataLink):string{
        if(!itemData) return "";
        let url = itemData.url;
        if(url.indexOf("http") === -1){
            url = "http://" + url
        }
        return url;
    }
    renderItems():any{
        const data:TopicDataLinks|undefined = this.props.topicData.links;
        if(!data) return null;

        let items:any[] = [];
        let i = 0;
        for(let itemData of data){
            let url = itemData.url.replace("http://", "");
            url = url.replace("https://", "");

            let name = "";
            if(itemData.name){
                name = itemData.name.replace("http://", "");
                name = name.replace("https://", "");
            }
            
            let item = (
                
                <Link 
                    to={this.getUrl(itemData)}  
                    className="topic-links-item" 
                    key={"topic_links_item__" + i}
                >
                    <div className="item-content">
                        <h4 className="list-title">{name}</h4>
                        
                        
                        <p className="url">{url}</p>
                        
                        {itemData.description && (
                            <p className="faint description">{Lang.tc(itemData.description)}</p>
                        )}
                    </div>

                </Link>
            )
            items.push(item);
            i++;
        }
        
        return (
            <div className="topic-links-items">
                {items}
            </div>
        )

    }
    

    render(){
       

        return(
            <TabPage>
                <div id="topic-research" className="topic-panel">
                    <div className="main-column main-column--narrow">
                        <div className="main-column-content">
                            <div className="text-column">
                                <div className="topic-subheader"/>
                                {this.renderItems()}

                            </div>
                        </div>
                    </div>
                    
                </div>
            </TabPage>
        )
    }
}