import './_styles.scss';

import React from 'react';

import Spinner from '../../components/Spinner/Spinner';

import App from '../../App';
import Nav, {NavTheme} from '../../components/Nav/Nav';
import Tools from '../../tools/Tools';
import Lang, {LangId} from '../../tools/Lang';
import Content, {LangString} from '../../data/Content';
import type {ContentStory} from '../../data/Content';
import Router from '../../router/Router';
import TopicTabs, {TopicTabId} from '../../components/TopicTabs/TopicTabs';
import Footer from '../../components/Footer/Footer';
import Config from '../../config/Config';
import Topic, {MediaType, TopicData} from '../../data/Topic';
import DataProvider from '../../data/DataProvider'

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}



enum LoadingState {
    
    // Loading data or banner
    loading = "loading",
    
    // Everything is ready to reveal
    loaded = "loaded",
    
    // Error loading data (data loading error only, banner loading error does NOT set this to true)
    error = "error"
}

interface Props{
    content:Content,
    topicId:string;
    tabId?:string;
    tabChildId?:string;
    itemId?:string,
    navProps:any,
}


interface State {

    // Loading, loaded, or error
    state:LoadingState,

    // Topic json data loading has completed successfully
    dataLoaded:boolean,

    // Banner image loading has completed successfully
    bannerLoaded:boolean,
}

export default class TopicScreen  extends React.Component<Props, State>{
    content:Content;

    topicData:TopicData | null  = null;
    story:ContentStory | null | undefined  = null;

    loadingError:string = "";

    imagePath:string = Config.getImagePath();

    isUnmounted:boolean = false;

    constructor(props:Props){
        super(props);

        log("TopicScreen()")
        // console.log(" Content.data = ", this.props.content.data)
        

        this.state = {
            state:LoadingState.loading,
            dataLoaded:false,
            bannerLoaded:false,
        }
        

        Router.detectAndNavigateToError(props);

        this.content = props.content;
        
        // log("- props = ", props);
    }

    // ----------------------------------------------------------------------------------------
    // LIFECYCLE

    componentDidMount(){
        // log("TopicScreen.componentDidMount()")
        
        Router.setLangUsingRoute(true)

        this.loadTopicData();

        // Open Thanks modal
        if(this.props.navProps.location.hash === "#credits") App.openThanksModal();

        // Open Thanks modal
        if(this.props.navProps.location.hash === "#privacy") App.openPrivacyModal();
            
        

    }
    


    componentDidUpdate(prevProps:Props){
        // log("TopicScreen.componentDidUpdate()")
        let isUrlChanged = false;
        const prevTabId = prevProps.tabId || TopicTabId.overview;
        const tabId = this.props.tabId || TopicTabId.overview;
        const prevTabChildId = prevProps.tabChildId;
        const tabChildId = this.props.tabChildId;
        // log(" - prevProps = ", prevProps)
        // log(" - nextProps = ", this.props)

        Router.detectAndNavigateToError(this.props);

        if(this.state.state === LoadingState.error){
            Router.navigateToError();
        }

        // Thanks modal
        const isOpeningThanksModal:boolean = prevProps.navProps.location.hash !== "#credits" && this.props.navProps.location.hash === "#credits" ;
        const isClosingThanksModal:boolean = prevProps.navProps.location.hash === "#credits" && this.props.navProps.location.hash !== "#credits" ;
        if(isOpeningThanksModal) App.openThanksModal();
        else if(isClosingThanksModal) App.closeThanksModal();

        // Privacy modal
        const isOpeningPrivacyModal:boolean = prevProps.navProps.location.hash !== "#privacy" && this.props.navProps.location.hash === "#privacy" ;
        const isClosingPrivacyModal:boolean = prevProps.navProps.location.hash === "#privacy" && this.props.navProps.location.hash !== "#privacy" ;
        if(isOpeningPrivacyModal) App.openPrivacyModal();
        else if(isClosingPrivacyModal) App.closePrivacyModal();
        
        

        if(prevTabId !== tabId){
            log(" - changed tabId from: " + prevTabId + " to " + tabId)
            isUrlChanged = true;
        }

        if(prevTabChildId !== tabChildId){
            log(" - changed tabChildId from: " + prevTabChildId + " to " + tabChildId)
            isUrlChanged = true;
        }

        const isTopicChanged:boolean = prevProps.topicId !== this.props.topicId;
        if(isTopicChanged){
            log(" - topic has changed!")
            this.loadTopicData();
        }

        else if(isUrlChanged){
            log(" - url is changed")
            // When user goes back they may have used the nav to change the langId on the previous page.
            // If they have done so, then we need to override the route langId with cookies langId
            Router.setLangUsingRoute(true)
        }
       
    }

    componentWillUnmount(){
        this.isUnmounted = true;
    }



    // ----------------------------------------------------------------------------------------
    // METHODS

    /**
     * 
     * @param subtitle - additional title to append to topic title
     * @param description 
     */
    setTitle = (subtitle?:LangString, description?:LangString) =>{
        log("TopicScreen.setTitle()")
        
        
        if(this.topicData){
            
            // --------------------
            // TITLE

            let title:LangString|undefined = {en:"",ga:""};

            // Add subtitle
            if(subtitle){
                log(" subtitle.en = " + subtitle.en)
                // Media item title and topic title are different
                const shouldAddSubtitleEn:boolean = !Lang.areLangStringsSame(this.topicData.title, subtitle, true, LangId.en);
                const shouldAddSubtitleGa:boolean = !Lang.areLangStringsSame(this.topicData.title, subtitle, true, LangId.ga);
                if(shouldAddSubtitleEn) title[LangId.en] += subtitle[LangId.en]
                if(shouldAddSubtitleGa) title[LangId.ga] += subtitle[LangId.ga]
                
            }
            
            // Add topic title
            if(this.topicData.title){
                // title = {...this.topicData.title};
                log(" topic title.en = " + this.topicData.title.en)
                if(title.en.length > 0) title[LangId.en] += " | ";
                if(title.ga.length > 0) title[LangId.ga] += " | ";
                
                title[LangId.en] += this.topicData.title[LangId.en]
                title[LangId.ga] += this.topicData.title[LangId.ga]
            
                // Trunc title
                // title = Tools.truncLangString(description);

                // log(" - title.en = " + title.en)
                // log(" - title.ga = " + title.ga)
            }

            // Add tab name, e.g. "Archive sounds"
            if(title.en.length > 0){
                
                const tabId:string = this.props.tabId !== undefined && this.props.tabId !== TopicTabId.overview ? this.props.tabId : "";
                if(tabId){
                    title[LangId.en] += " | " + Lang.t("topic." + tabId, LangId.en);
                    title[LangId.ga] += " | " + Lang.t("topic." + tabId, LangId.ga);
                }
            }
            log(" title string = " + title.en)



            // --------------------
            // DESCRIPTION
            
            // Validate description argument
            const isDescriptionInvalid = 
                !description 
                || 
                (
                    description 
                    && (!description.en || !description.ga)
                );
                
            // Default to generic topicData description
            if(isDescriptionInvalid && this.topicData.description){
                description = this.topicData.description;
            }

            // Trunc the description
            if(description){
                description = Tools.truncLangString(description);
                // log(" - description.en = " + description.en)
                // log(" - description.ga = " + description.ga)
            }

            // --------------------
            // IMAGE
            const imageUrl = Config.getImagePath() + "thumbs/learners/" + this.topicData.topicId + ".jpg";
            // console.log(" - imageUrl = " + imageUrl)

            // Set the title and description
            Lang.setTitle(title, description, true, Tools.OPENGRAPH_TYPE_ARTICLE, imageUrl)
        }
    }

    loadTopicData = async ():Promise<void> => {

       
        // console.log("TopicScreen.loadTopicData()");
        if(this.isUnmounted) return;

        this.topicData = null;
        this.story = null;

        // Clear the page
        this.setState({
            state:LoadingState.loading
        });

        // log(" - topic data = ", Content.data);
        
        try{
           
            // Get _id
            let _id:string|null = Content.getStory_id(this.props.topicId);
            if(!_id){
                throw Error("Story '" + this.props.topicId + "' not found in Content.rawStoryMap")
            }

			try {
	            this.topicData = await DataProvider.getTopic(_id)
			}
			catch(err) {
				this.topicData = null
				throw err
			}
			
            // console.log(" - this.topicData = ", this.topicData)
            if(this.topicData){
                this.story = this.props.content.getStory(this.topicData.topicId)
                // console.log(" - this.story = ", this.story)
                // console.log(" - this.state.state = " + this.state.state)
            }

            // Set new state
            if(!this.isUnmounted){
                
                // If no banner image then loading is complete
                const hasBannerLoaded:boolean = this.topicHasBannerImage ? false : true
                const newState = hasBannerLoaded ? LoadingState.loaded : LoadingState.loading;
                this.setState({
                    state:newState,
                    dataLoaded:true,
                    bannerLoaded: hasBannerLoaded
                });
            }
            

           
            // this.setTitle();
            // log("- data loaded...", json)
        }
        catch(err){
            console.log("err = ", err);
            this.loadingError = String(err);

            
            this.setState({
                state:LoadingState.error
            })
        }

        return;

    }


    // ----------------------------------------------------------------------------------------
    // GETTERS

    get topicHasArchiveSounds():boolean{
        if(!this.topicData || !this.topicData.mediaItems) return false
        for(let itemData of this.topicData.mediaItems){
            if(itemData.type === MediaType.archiveSound) return true;
        }
        return false;
    }

    get topicHasResearch():boolean{
        if(!this.topicData || !this.topicData.longref) return false
        if(this.topicData.longref.en || this.topicData.longref.ga) return true;
        return false;
    }

    get topicHasBibliography():boolean{
        if(!this.topicData || !this.topicData.bibliography) return false
        if(this.topicData.bibliography.source) return true;
        return false;
    }

    get topicHasLibrary():boolean{
        if(!this.topicData || !this.topicData.mediaItems) return false
        for(let itemData of this.topicData.mediaItems){
            if(itemData.type === MediaType.archiveText) return true;
        }
        return false;
    }

    get topicHasLinks():boolean{
        let data = this.topicData;
        if(!data || !data.links) return false
        if(data.links && data.links.length > 0) return true;
        return false;
    }

    get locationString():string{
        // log("TopicScreen.locationString()");
        let str = "No story";
        if(this.story){
            str = Content.getStoryLocation(this.story.id)
            // log(" - this.story.id = " + this.story.id)
        }
        
        // log(" - str = " + str)
        return str;
    }

    get topicHasBannerImage():boolean{
        if(this.story) return this.story.hasTopicBanner === true ? true : false;
        return false;
    }

    get topicBannerCredit():string{
        let str:string = "";
        if(!this.story || !Lang.tc(this.story.topicBannerCredit)) return str;
        if(Lang.tc(this.story.topicBannerCredit, false, LangId.en) === Lang.tc(this.story.topicBannerCredit, false, LangId.ga)){
            // str = Lang.t("topic.bannerCredit") + Lang.tc(this.story.topicBannerCredit, false, LangId.en);
            str = Lang.tc(this.story.topicBannerCredit, false, LangId.en);
        }else{
            str = Lang.tc(this.story.topicBannerCredit);
        }

        return str;
    }


    // ----------------------------------------------------------------------------------------
    // HANDLERS

    handleBannerLoad = async(event:any) => {
        if(this.isUnmounted) return;
        this.setState({
            state: LoadingState.loaded,
            bannerLoaded:true,
        })
    }

    handleBannerError = async(event:any) => {
        if(this.isUnmounted) return;
        this.setState({
            state: LoadingState.loaded
        })
    }


    // ----------------------------------------------------------------------------------------
    // RENDER

    render(){
        

        let tabIds:TopicTabId[] = [];
        if(this.state.state === LoadingState.loaded){
            tabIds = [TopicTabId.overview];
            if(Topic.topicHasArchiveSounds(this.topicData)) tabIds.push(TopicTabId.sounds);
            if(Topic.topicHasResearch(this.topicData)) tabIds.push(TopicTabId.research);
            if(Topic.topicHasLibrary(this.topicData)) tabIds.push(TopicTabId.library);
            
            if(Topic.topicHasBibliography(this.topicData)) tabIds.push(TopicTabId.bibliography);
            if(Topic.topicHasLinks(this.topicData)) tabIds.push(TopicTabId.links);
        }

       
        // ----------
        // LOADING / LOADED
        
        // Header style
        let headerClass:string = "topic-header main-column main-column--narrow"
        if(!this.topicHasBannerImage) headerClass += " no-banner";

        // Style for when everything has loaded
        const topicScreenClass = this.state.state === LoadingState.loaded ? "topic-loaded" : "";
        
        return (
            
            <div id="topic-screen" className={topicScreenClass}>
                
                <div id="topic-screen-content">
                    
                    {/* LOADING */} 
                    {this.state.state === LoadingState.loading && 
                        <div className="loader-container">
                            <Spinner className="loader"/>
                        </div>
                    }

                   
                    {/* DATA LOADED */}
                    {this.state.dataLoaded && this.topicData !== null &&

                        
                        <div>
                            
                            {/* BANNER IMAGE */}
                            {this.topicHasBannerImage && this.story &&
                                <div className="topic-banner-container">
                                    <div className="topic-banner">
                                        <img 
                                            onLoad={this.handleBannerLoad} 
                                            onError={this.handleBannerError} 
                                            src={this.imagePath + "topic_banners/" + this.story.id + ".jpg"} 
                                            alt=""
                                        />
                                    </div>
                                    {this.story && this.story.topicBannerCredit &&
                                        <div className="main-column main-column--narrow">
                                            <div className="main-column-content">
                                                <span className="topic-banner--credit">{this.topicBannerCredit}</span>
                                            </div>
                                        </div>
                                    }   
                                </div>
                            }

                            
                                <div className="topic-content">

                                    {/* HEADER (TITLE ETC) */}
                                    <div className={headerClass}>
                                        <div className="main-column-content text-column">
                                                
                                            <h1>{Lang.tc(this.topicData.title)}</h1>
                                            {this.locationString && 
                                                <div className="location">
                                                    <span className="location-from">{Lang.t("locationFrom")}</span>
                                                    <span className="location-place">{this.locationString}</span>
                                                </div>
                                            }
                                            <h2 className="subtitle"><i>{Lang.tc(this.topicData.description)}</i></h2>    
                                        </div>
                                    </div>

                                    {/* TABS CONTENT */}
                                    <div className="tabs">
                                        <TopicTabs
                                            activeId={this.props.tabId}
                                            childId={this.props.tabChildId}
                                            itemId={this.props.itemId}
                                            tabIds={tabIds}
                                            topicData={this.topicData}
                                            setTitle={this.setTitle}
                                        />
                                    </div>
                                </div>
                            
                        </div>
                    
                    }

                </div>


                <Footer/>

                <Nav
                    theme={NavTheme.dark}
                    showLogo={true}
                    
                />

                
            </div>
        );

    }
  }