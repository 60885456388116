import './_styles.scss';
import React from 'react';
// import App from '../../App';
// import Config from '../../config/Config'
import Lang from '../../tools/Lang';
import SectionHeaderToddlers from '../../components/SectionHeaderToddlers/SectionHeaderToddlers';
import SectionRow from '../../components/SectionRow/SectionRow';
import SectionItems from '../../components/SectionItems/SectionItems';
// import {ExpandMore } from '@material-ui/icons';
import Expander from '../Expander/Expander';
import type {ContentSection} from '../../data/Content';
import Audience from '../../tools/Audience';
import Flash, {LangMode, PreloaderId} from '../Flash/Flash';
import Router from '../../router/Router';
import Tools from '../../tools/Tools';
import App from '../../App';
import type {FlashHistory} from '../../App';
// import {ContentSectionId} from '../../data/Content';
import {LangId} from '../../tools/Lang'
import {AudienceId} from '../../tools/Audience';
import Snacks from '../Snacks/Snacks';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}


type Props = {
    data:ContentSection,
    onItemClick:Function,
    scrollY:number,
    langId?:LangId,
    audienceId?:AudienceId,
}



export default class Section extends React.Component<Props>{
    
   
    // Main sections 
    static ID_PLACES:string = "places";
    static ID_CHARACTERS:string = "characters";
    static ID_SPECIAL_COLLECTIONS:string = "specialCollections";
    static ID_STORY_TYPES:string = "storyTypes";
    static ID_MEDIA:string = "media";
    static ID_MUSIC_AND_SONGS:string = "musicAndSongs";
    static ID_TODDLERS_MUSIC:string = "toddlersMusic";
    static ID_TODDLERS_FILMS:string = "toddlersFilms";
    static ID_TODDLERS_COMICS:string = "toddlersComics";


    constructor(props:Props){
        super(props);

        


        log("Section()");
        log(" - data = ", this.props.data);

       
        

        //@ts-ignore
        window.react_handleGroupPress = this.handleGroupPress;
        
        //@ts-ignore
        window.react_handleTopicPress = this.handleTopicPress;


    }
    
  
    handleMoreClick = () => {
        log("Section.handleMoreClick()");
        
    }

    shouldComponentUpdate(nextProps:Props){
        // console.log("Section.shouldComponentUpdate()");
        // console.log(" - Audience.id = " + Audience.id)
        
        // Always update if audience is kids (the Section flash needs to update based on the current scrollY)
        let should:boolean = Audience.isAudienceKids;

        // Lang has changed
        if(!should) if(this.props.langId !== nextProps.langId) should = true;
        
        // Audience has changed
        if(!should) if(this.props.audienceId !== nextProps.audienceId) should = true;
        
        return should;
    }
    
  

   renderRows():any{

        let rowsVisible:any[] = [];
        let rowsHidden:any[] = [];

        let i = 0;
        for(let rowData of this.props.data.rows){
            // rowData.sectionId=this.props.data.id;
            const row = (
                <SectionRow
                    key={"section_row__" + this.props.data.id + "__" + rowData.id + "__" + i}
                    data={rowData}
                    onItemClick={this.props.onItemClick}
                    index={i}
                />
            )
            if(i<this.props.data.numRowsVisibleAtStart) rowsVisible.push(row);
            else rowsHidden.push(row);
            i++;
        }
        const sectionId = this.props.data.id;
        if(rowsHidden.length === 0){
            return (
                <div>
                    {rowsVisible}
                </div>
            )
        }
        return (
            <Expander
                id={"home__section__" + sectionId}
                butType={1}
                butTextMore={Lang.t("section." + sectionId + ".more")}
                butTextLess={Lang.t("section.generic.less")}
                butStyle={{marginTop:'3rem'}}
                shouldLog={false}//sectionId === Section.ID_SPECIAL_COLLECTIONS
                childrenHidden={rowsHidden}
            >
                <div className="visible">
                    {rowsVisible}
                </div>

                {/* <div className="hidden">
                    {rowsHidden}
                </div> */}
            </Expander>
            
        )
    }


    handleGroupPress = (sectionId:string, groupId:string) => {
        log("Section.handleGroupPress()");
        log(" - sectionId = " + sectionId)
        log(" - groupId = " + groupId)
        // Router.navigateToGroup(groupId, sectionId);
        // log(" - this = " + this)
        // log(" - this.setState = " + this.setState)
        groupId = groupId.indexOf(".") === -1 ? groupId : groupId.split(".")[1];
        let flashHistory:FlashHistory = {...App.state.flashHistory}
        flashHistory[sectionId].push(groupId);
        App.setState({
            flashHistory: flashHistory
        })
    }

    /**
     * User has tapped a topic
	 * 
	 * Note: We don't need the topicId anymore, everything is inferred from the itemId.
     * @param topicId - The topic id e.g. "battle_of_gruineart_beach"
     * @param itemId - The particular media item id that the user should see, e.g. "black_fairy_372"
     * @param disabled - sometimes the topic is disabled e.g. many of the specialCollections flash arctic stories
     */
    handleTopicPress = (topicId:string, itemId:string, disabled:boolean = false) => {
        log("Section.handleTopicPress()");
        log(" - topicId = " + topicId)
        log(" - itemId = " + itemId)
        log(" - disabled = " + disabled)
        
        // Load topic
        if(!disabled) Router.navigateToMediaItem(itemId);
        
        // Show error message if topic disabled
        else{
            Snacks.add({
                message: Lang.t("snack.topicDisabled"),
                // type: SnackType.success,
                secs:5,
            })
        }
    }


    handleFlashReady = async () => {
        log("Section.handleFlashReady()");
    }

    render(){
        // log("Section.render()");
        const sectionId = this.props.data.id;
        
        // log(" - sectionId = " + sectionId)

        // const enableFlash = App.ENABLE_FLASH;
        
        // TODDLERS
        if(Audience.isAudienceToddlers){
            return(
            
                <section className={"section section-" + sectionId}>
                    <SectionHeaderToddlers
                        sectionId={this.props.data.id}
                        title={this.props.data.title}
                        subtitle={this.props.data.subtitle}
                    />
                        
                    <SectionItems
                        data={this.props.data}
                        onItemClick={this.props.onItemClick}
                    />
                        
                </section>
            )
        }   

        // KIDS
        else if(Audience.isAudienceKids){
            return(
            
                <section className={"section section-" + sectionId}>
                    
                        <div className="section-header">
                            <div className="main-column">
                                
                                {/* MUSIC AND SONGS */}
                                {this.props.data.id === Section.ID_MUSIC_AND_SONGS &&
                                    <div className="main-column-content">
                                        <div className="section-image-container">
                                            <img src="/images/ui/section-banners/section-banner-musicAndSongs.png" className="section-image" alt=""/>
                                            
                                            <h1 id={"section-title-" + this.props.data.id} className="section-title">
                                                <span>{Lang.tc(this.props.data.title)}</span>
                                            </h1>
                                        </div>
                                        
                                    </div>
                                }

                                {/* MEDIA */}
                                {this.props.data.id === Section.ID_MEDIA &&
                                    <div className="main-column-content">
                                        <div className="section-image-container">
                                            <img src="/images/ui/section-banners/section-banner-media.png" className="section-image" alt=""/>
                                            
                                            <h1 className="section-title">
                                                <span>{Lang.tc(this.props.data.title)}</span>
                                            </h1>
                                        </div>
                                        
                                    </div>
                                }

                                {/* FLASH */}
                                {this.props.data.id === Section.ID_PLACES && 
                                    <div className="main-column-content">
                                        <div className="section-image-container">
                                        
                                            {Tools.isHoverDevice && App.ENABLE_FLASH ? 

                                                <Flash
                                                    id={App.state.flashHistory.places[App.state.flashHistory.places.length-1]}
                                                    flashWidth={600}
                                                    flashHeight={400}
                                                    backgroundColor={"#F5F4F3"}
                                                    langId={Lang.langId}
                                                    langMode={LangMode.live}
                                                    params={{
                                                        sectionId:"places",
                                                    }}
                                                    scrollY={this.props.scrollY}
                                                    onReady={this.handleFlashReady}
                                                    preloader={PreloaderId.bocan}
                                                />

                                                :

                                                <img src="/images/ui/section-banners/section-banner-places.png" className="section-image" alt=""/>
                                                
                                            }
                                        

                                        </div>
                                        <h1 className="section-title">
                                            <span>{Lang.tc(this.props.data.title)}</span>
                                        </h1>
                                    </div>
                                }

                                {this.props.data.id === Section.ID_CHARACTERS && 
                                    <div className="main-column-content">
                                        <div className="section-image-container">
                                            {Tools.isHoverDevice && App.ENABLE_FLASH ? 
                                                <Flash
                                                    id={App.state.flashHistory.characters[App.state.flashHistory.characters.length-1]}
                                                    flashWidth={600}
                                                    flashHeight={400}
                                                    backgroundColor={"#F5F4F3"}
                                                    langId={Lang.langId}
                                                    langMode={LangMode.live}
                                                    params={{
                                                        sectionId:"characters",
                                                    }}
                                                    scrollY={this.props.scrollY}
                                                    onReady={this.handleFlashReady}
                                                    preloader={PreloaderId.bocan}
                                                    
                                                />
                                                :
                                                <img src="/images/ui/section-banners/section-banner-characters.png" className="section-image" alt=""/>
                                            }
                                        
                                           
                                        </div>
                                        <h1 className="section-title">
                                            <span>{Lang.tc(this.props.data.title)}</span>
                                        </h1>
                                    </div>
                                }

                                    {this.props.data.id === Section.ID_SPECIAL_COLLECTIONS && 
                                    <div className="main-column-content">
                                        <div className="section-image-container">
                                            {Tools.isHoverDevice && App.ENABLE_FLASH? 
                                                <Flash
                                                    id={App.state.flashHistory.specialCollections[App.state.flashHistory.specialCollections.length-1]}
                                                    flashWidth={600}
                                                    flashHeight={400}
                                                    backgroundColor={"#F5F4F3"}
                                                    langId={Lang.langId}
                                                    langMode={LangMode.live}
                                                    params={{
                                                        sectionId:"superStories",
                                                    }}
                                                    scrollY={this.props.scrollY}
                                                    onReady={this.handleFlashReady}
                                                    preloader={PreloaderId.bocan}
                                                    
                                                />
                                                :
                                                <img src="/images/ui/section-banners/section-banner-specialCollections.png" className="section-image" alt=""/>
                                            }
                                        
                                           
                                        </div>
                                        <h1 className="section-title">
                                            <span>{Lang.tc(this.props.data.title)}</span>
                                        </h1>
                                    </div>
                                }

                                {/* STORY THEMES */}
                                {this.props.data.id === Section.ID_STORY_TYPES &&
                                    <div className="main-column-content">
                                        <div className="section-image-container">
                                            <img src="/images/ui/section-banners/section-banner-storyTypes.png" className="section-image" alt=""/>
                                            
                                            <h1 className="section-title">
                                                <span>{Lang.tc(this.props.data.title)}</span>
                                            </h1>
                                        </div>
                                        
                                    </div>
                                }
                                
                            </div>
                        
                    </div>
                    <div className="rows">
                        
                        {this.renderRows()}
                        
    
                        
                    </div>
                    
                </section>
            
            )
        }

        // LEARNERS
        return(
            
            <section id={"section-" + sectionId} className={"section section-" + sectionId}>
                <h1 id={"section-title-" + sectionId} className="section-title ">
                    <span>{Lang.tc(this.props.data.title)}</span>
                    <span className="section-title-underline"></span>
                </h1>
                <div className="rows">
                    
                    {this.renderRows()}
                    

                    
                </div>
            </section>
        
        )
    }
}





