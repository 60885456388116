import App from '../App';
import Lang, {LangId} from '../tools/Lang';
import Tools from '../tools/Tools';
import Audience, {AudienceId} from '../tools/Audience';
import Snacks from '../components/Snacks/Snacks';
import {PropsFilter} from '../screens/OralScreen/OralScreen'
import Content from '../data/Content'
import {MediaType} from '../data/Topic'
import type {ContentMaterial} from '../data/Content'

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

let instance:Router;

export default class Router{

    // These router props are passed in as the argument when the Route render method is called (see App.render)
    props:any;

    // This gets set to false after the user navigates to a new route (used when navigating to topic tab)
    isOnFirstScreen:boolean = true;

    constructor(){
        log("Router()")
        instance = this;
    }

    static log(){
        if(!instance) return;
        log("Router.log()")
        log(" - props = ", instance.props)

    }

    static logPath(){
        if(!instance) return;
        console.log("Router.logPath()");
        console.log(" - instance.props.location.pathname = " + instance.props.location.pathname)
    }
    static get isOnFirstScreen():boolean{
        if(!instance) return true;
        return instance.isOnFirstScreen;
    }

    static get pathname():string{
        if(!instance || !instance.props || !instance.props.location) return "";
        // console.log("Router.pathname() instance.props = ", instance.props)
        return instance.props.location.pathname
    }
    static get isOnHomeScreen():boolean{
        if(!instance) return false;
        return instance.isOnHomeScreen;
    }

    get isOnHomeScreen():boolean{
        if(!this.props || !this.props.location) return false;

        return this.props.location.pathname === "" || this.props.location.pathname === "/en/";
    }

    static get url():string{
        return window.location.href;
    }

    // --------------------
    // ROUTE TO ERROR SCREEN

    static detectAndNavigateToError(props:any){
        log("Router.detectAndNavigateToError()")
        const isError = 
            
            // Lang is not right
            (props.langId !== LangId.en && props.langId != null)

            // Audience is not right
            || (props.audienceId && props.audienceId !== AudienceId.kids && props.audienceId !== AudienceId.toddlers && props.audienceId !== AudienceId.learners)

            // Not exact match
            || !props.navProps?.match.isExact;
        
        
        log(" - isError = " + isError)
        if(isError){
            log(" - props.langId = " + props.langId)
            log(" - props.audienceId = " + props.audienceId)
            log(" - props.navProps?.match.isExact = " + props.navProps?.match.isExact)
            
            // Router.navigateToError();
            return;
        }

    }

    static navigateToError(){
        let path = "";
        path += Lang.getUrlLangFrag();
        path += "error";
        Router.navigateToPath(path, undefined, true, true)
    }


    // --------------------
    // ROUTE TO / FROM MEDIA ITEM

    /**
     * 
     * @param itemId - the media item id, e.g. "plaidie_603" (an amalgam of the mediaItem's id and _id values)
     * @returns 
     */
    static navigateToMediaItem = (itemId:string, forceAudienceId?:AudienceId) => instance?.navigateToMediaItem(itemId, forceAudienceId)
    
    /**
     * Navigate to a new media item route
     * @param niceId E.g. "plaidie_603"
     */
    navigateToMediaItem(niceId:string, forceAudienceId?:AudienceId){
        log("Router.navigateToMediaItem()")
        log(" - itemId = " + niceId)
        let path = Router.getMediaItemPath(niceId, forceAudienceId)
        if(!path){
            let str:string = Lang.t("error.loadingFile") + `("${niceId}")`;
            
            Snacks.add({
                message: str,
                secs:5,
            })
            return;
        }
        
        this.navigateToPath(path, null, false);
    }

    static getMediaItemPath(niceId:string, forceAudienceId?:AudienceId):string{
        if(!instance) return "";
		const material = Content.getMaterialByNiceId(niceId)
		if (!material) return "";
		const story = Content.getStoryByRawId(material.story)
		if (!story) return "";
		const topicId = story.id

		let path = ""

		switch (material.type) {
			default:
				path = `{pathStart}/topic/{topicId}/item/{niceId}`
				break
			case "archive.text":
				path = `{pathStart}/topic/{topicId}/library/{niceId}`
				break
			case "archive.sound":
				path = `{pathStart}/topic/{topicId}/sounds/{niceId}`
				break
		}
		let audienceId = this.getAudienceFromPath()

		const langId = this.getLangFromPath()
		const pathStart = this.getPathStartForMaterial(material, audienceId, langId, forceAudienceId)
		// console.log(material)
		path = Tools.fillTags(path, {pathStart, topicId, niceId})

		if (material._id==="707") {
			// console.log(`MATERIAL ${material?.title.en} ${material._id} ${material.type}`)
			// console.log(path)
			/*
			GOOD LINKS
				EN http://localhost:3000/en/a/kids/topic/the_mull_witch/item/the_mull_wind_witch_130
				GA http://localhost:3000/a/kids/topic/the_mull_witch/item/the_mull_wind_witch_130
				/a/kids/topic/ossian/item/wild_love_253
			 	/en/a/kids/topic/stop_the_snowstorm/item/stop_the_snowstorm_707
			 BAD LINKS
			 	GA /a/kids/topic/the_mull_witch (this is what the sectionitem renderer creates)
			 	GA /topic/the_mull_witch/item/the_mull_wind_witch_130 (this is where the link finally ends up)
				
			*/
		}
        return path;
    }

	static getAudienceFromPath(path:string=""):AudienceId {
		path = path || instance.props.location.pathname
		// if (!path) return "";
		let crumbs = path.split('/')
		crumbs.shift()
		if (crumbs[0]===LangId.en) {
			crumbs.shift()
		};
		if (crumbs[0]==="a") {
			const audienceId = crumbs[1]
			const isValidAudience = Tools.misc.isValidEnum(audienceId, AudienceId)
			if (isValidAudience) {
				return audienceId as AudienceId;
			}
			else {
				console.log(`Invalid audienceId "${audienceId}" in URL path`)
			}
		}
		return Audience.defaultAudienceId;
	}


	static getLangFromPath(path:string=""):LangId {
		path = path || instance.props.location.pathname
		// if (!path) return "";
		let crumbs = path.split('/')
		crumbs.shift()
		if (crumbs[0]===LangId.en) return LangId.en;
		return Lang.defaultLangId;
	}

	static getPathStartForMaterial(material:ContentMaterial, userAudience:AudienceId, userLang:LangId, forceAudienceId?:AudienceId):string {
		let crumbs:string[] = [""]
		if (userLang != Lang.defaultLangId) {
			crumbs.push(userLang)
		}
		let audience = userAudience
		if (material.audiences) {
			const materialLivesInOtherSide = ! material.audiences[userAudience]
			if (materialLivesInOtherSide) {
				switch(userAudience) {
					case AudienceId.learners: 
						if (material.audiences.kids) 	 audience = AudienceId.kids
						if (material.audiences.toddlers) audience = AudienceId.toddlers
						break
					case AudienceId.kids: 
						if (material.audiences.toddlers) audience = AudienceId.toddlers
						if (material.audiences.learners) audience = AudienceId.learners
						break
					case AudienceId.toddlers: 
						if (material.audiences.kids) audience = AudienceId.kids
						if (material.audiences.learners) audience = AudienceId.learners
						break
				}
			}
		}
		else {
			audience = Audience.defaultAudienceId
		}
		if (forceAudienceId) {
			audience = forceAudienceId
		}

		if (audience!==Audience.defaultAudienceId) {
			crumbs.push("a")
			crumbs.push(audience)
		}
		const path = crumbs.join("/")
		return path;
	}

	/** We could be at any page in the site, and we want just the first few contextual crumbs, e.g. 
	 * 		/en
	 * 		/en/a/kids
	 * 		/en/a/learners
	 * 		/
	 * 		/a/kids
	 * 		/a/learners
	 */
	static getPathStart():string {
		let crumbs = instance.props.location.pathname.split('/')
		// console.log(`path crumbs:`, crumbs)
		crumbs.shift()
		let newCrumbs:string[] = []
		if (crumbs[0] === LangId.en || crumbs[0] === LangId.ga) {
			newCrumbs.push(crumbs[0])
		}
		if (crumbs[0]==="a") {
			newCrumbs.push(crumbs[0])
			newCrumbs.push(crumbs[1])
		}
		else if (crumbs[1]==="a") {
			newCrumbs.push(crumbs[1])
			newCrumbs.push(crumbs[2])
		}
		let path = "/" + newCrumbs.join('/')
		path = Tools.string.removeTrailingSlash(path)
		return path;
	}


    static navigateBackFromMediaItem(goBack:boolean = false){
        if(!instance) return;
        instance.navigateBackFromMediaItem(goBack);
    }
    
    /**
     * Navigate back from a media item route to its parent page
     * @param goBack 
     */
    navigateBackFromMediaItem = async (goBack:boolean = false) => {
        log("Router.navigateBackFromMediaItem()");
        
        // Go back if modal opened manually on the topic page.
        // We need this because when you replace the path the Router
        // then has two identical consecuive topic pages in the history.
        if(goBack){
            log(" - going back")
            this.props.history.goBack();
        }
        
        // Replace path ()
        else{  
            log(" - replacing current path")
            let path = ""  ;

            // Kids
            if(Audience.isAudienceAnyKids){
                path = this.props.location.pathname.split("/topic/")[0];
            }
            
            // Learners
            else{
                path = this.props.location.pathname.split("/item/")[0];
            }
            this.navigateToPath(path, null, false, true);
            
        }
    }



    // --------------------
    static navigateToTopic(topicId:string, tabId?:string, jumpToTop:boolean = true, replacePath:boolean = false, tabChildId?:string, goBackBeforeNavigating:boolean = false, sectionId?:string, event?:any){
        if(!instance) return;
        instance.navigateToTopic(topicId, tabId, jumpToTop, replacePath, tabChildId, goBackBeforeNavigating, sectionId, event);
    }

    /**
     * Navigate to a topic page
     * @param topicId 
     * @param tabId Optional tabId. Needs to be a TopicTabId string
     * @param jumpToTop When navigating using the tabs we do not jump to top
     * @param replacePath When navigating using the tabs we need to replace rather than push
     * @param tabChildId E.g. a topic > library > page id
     * @param goBackBeforeNavigating Navigate back in the history before going to topic
     * @param sectionId Sometimes we need this (e.g. in order to navigate to toddler media item)
     */
    navigateToTopic(topicId:string, tabId?:string, jumpToTop:boolean = true, replacePath:boolean = false, tabChildId?:string, goBackBeforeNavigating:boolean = false, sectionId?:string, event?:any){  
        let path = Router.getTopicPath(topicId, tabId, tabChildId, sectionId)
        this.navigateToPath(path, event, jumpToTop, replacePath, goBackBeforeNavigating);
    }

    static getTopicPath(topicId:string, tabId?:string, tabChildId?:string, sectionId?:string):string{
        if(!instance) return "";
        let path = "";
        path += Lang.getUrlLangFrag();
        if(Audience.isAudienceAnyKids){
            path += "a/" + Audience.id + "/";
        }
        path += "topic/" + topicId;
        if(tabId) path += "/" + tabId;
        if(tabChildId) path += "/" + tabChildId;
        if(sectionId) path += "/section/" + sectionId;
        return path;
    }
    
    static navigateToOralWithFilters(filter?:PropsFilter){
        let path = this.getOralPath();
		if (filter) {
        	path += `/filter/${filter.type},${filter.lang}`;
		}
        this.navigateToPath(path, null, false, true)
    }

    static getOralPath():string{
        if(!instance) return "";
        let path = "";
        path += Lang.getUrlLangFrag();
        path += "oral-traditions"
        return path;

    }
    // --------------------

    static navigateToTopicTabChild(childId:string){
        if(!instance) return;   
        instance.navigateToTopicTabChild(childId);
    }
    /**
     * Navigate to a specific child on the current topic page tab
     * @param childId 
     */
    navigateToTopicTabChild(childId:string){
        log("Router.navigateToTopicChild() tabId = " + childId)
        let path = this.props.location.pathname;
        if(path.indexOf("/topic/") === -1) return;
        let pathArr:string[] = path.split("/topic/")
        let topicArr:string[] = pathArr[1].split("/");
        const topicId:string = topicArr[0];
        const tabId:string = topicArr[1];
        log(" - topicId = " + topicId);
        log(" - tabId = " + topicId);
        this.navigateToTopic(topicId, tabId, true, false, childId)
    }

    static getTopicTabChildPath(topicId:string, tabId:string, childId:string):string{
        let path = Router.getTopicPath(topicId, tabId, childId)
        return path;
    }
    // --------------------

    
    static navigateToTopicTab(tabId:string){
        if(!instance) return;   
        instance.navigateToTopicTab(tabId);
    }
    

    /**
     * Navigate to a specific tab on the current topic page
     * @param tabId Needs to be a TopicTabId string in order to successfully display the tab
     */
    navigateToTopicTab = async (tabId:string) => {
        log("Router.navigateToTopicTab() tabId = " + tabId)
        log(" - this.props.location.pathname = " + this.props.location.pathname);
        let path = this.props.location.pathname;
        if(path.indexOf("/topic/") === -1) return;
        let pathArr:string[] = path.split("/topic/")
        const topicId:string = pathArr[1].split("/")[0];
        log(" - topicId = " + topicId);

        
        // ------------------
        // If user is navigating to tab after visiting a tabChild, then we need to go back first.
        const topicTabChildId:string = this._getTopicTabChildIdFromPath(topicId);
        log(" - topicTabChildId = " + topicTabChildId)
        let goBackBeforeNavigating:boolean = topicTabChildId && !this.isOnFirstScreen? true : false;
        log(" - this.isOnFirstScreen = " + this.isOnFirstScreen)
        log(" - goBackBeforeNavigating = " + goBackBeforeNavigating)
        this.navigateToTopic(topicId, tabId, false, true, undefined, goBackBeforeNavigating)
    }


    

    /**
     * Get the id of the topic tab child
     * @param topicId Current topic id
     */
    _getTopicTabChildIdFromPath(topicId:string):string{
        log("Router._getTopicTabChildIdFromPath()");
        let tabChildId:string = "";
        const path = this.props.location.pathname;
        const isTopicInPath:boolean = path.indexOf("/" + topicId + "/") !== -1;
        if(isTopicInPath){
            let pathArr:string[] = path.split("/" + topicId + "/");
            if(pathArr){
                const topicPathArr:string[]|null = pathArr[1] === undefined ? [] : pathArr[1].split("/");
                
                log(" - topicPathArr = ", topicPathArr)

                // Media item urls have the form ".../item/:itemId". We need to ignore them.
                if(topicPathArr[0] !== "item"){
                    tabChildId = topicPathArr[1] === undefined ? "" : topicPathArr[1];
                }
                
            }
        }

        return tabChildId;
    }
    
   
    

    // --------------------
    // NAVIGATE TO HASH

    static navigateToPrivacyModal(){
        Router.addHash("privacy");
    }

    static navigateToThanksModal(){
        Router.addHash("credits");
    }

    static addHash(hash:string){
        if(!instance) return;
        instance.addHash(hash);
    }

    /**
     * Add a hash to the url. Adds to the history.
     * @param hash 
     */
    addHash(hash:string){
        let path = window.location.pathname + "#" + hash;
        this.props.history.push(path);
    }

    static removeHash(){
        if(!instance) return;
        instance.removeHash();
    }

    /**
     * Remove the hash from the url, replacing the current history item with the hashless url, though this can be overridden.
     */
    removeHash(replace:boolean = true){
        window.location.hash = "";
        let path = window.location.pathname;
        this.navigateToPath(path, undefined, false, replace);
    }

    // --------------------
    // NAVIGATE TO GROUP

    static navigateToGroup(sectionId:string, groupId:string){
        if(!instance) return;
        instance.navigateToGroup(sectionId, groupId);
    }

    navigateToGroup(sectionId:string, groupId:string){
        
        let path = Lang.getUrlLangFrag() + "group/" + sectionId + "/" + groupId;
        this.navigateToPath(path);
    }

    // --------------------
    // NAVIGATE TO AUDIENCE

    

    

    static navigateToAudience(id:AudienceId){
        let path = Router.getAudiencePath(id);
        this.navigateToPath(path, undefined, false);
    }

    static getAudiencePath(id:AudienceId):string{
        let path = Lang.getUrlLangFrag();
        if(id !== AudienceId.learners) path += "a/" + id;
        return path;
    }

    // static navigateToAppStateAudience(){
    //     let path = Lang.getUrlLangFrag();
    //     if(App.state.audienceId !== AudienceId.learners) path += "a/" + App.state.audienceId;
    //     Router.navigateToPath(path);
    // }

    
   
    // --------------------
    // NAVIGATE TO PATH

    static navigateBack(){
        if(!instance) return;
        instance.navigateBack();
    }

    navigateBack(){
        this.props.history.goBack();
    }

    static navigateToPath(path:string, event?:any, jumpToTop:boolean = true, replacePath:boolean = false, goBackBeforeNavigating:boolean = false, isExternal:boolean = false, openInNewTab:boolean = false){
        if(!instance) return;
        instance.navigateToPath(path, event, jumpToTop, replacePath, goBackBeforeNavigating, isExternal, openInNewTab);
    }

    

    /**
     * Pushes a path into the router props.history OR opens new tab
     * @param path 
     * @param event click event (used to detect cmd-click etc) 
     * @param jumpToTop
     * @param replacePath
     * @param goBackBeforeNavigating
     * @param isExternal
     */
    navigateToPath = async (path:string, event?:any, jumpToTop:boolean = true, replacePath:boolean = false, goBackBeforeNavigating:boolean = false, isExternal:boolean = false, openInNewTab:boolean = false) => {
        log(" ")
        log("-------------")
        log("Router.navigateToPath() path = " + path);
        
        if(event){
            if (event.ctrlKey || event.metaKey) {
                openInNewTab = true;
            }
        }
        
        // New tab
        if(openInNewTab){
            log(" - opening in new tab");
            if(isExternal && path.indexOf("http") === -1) path = "https://" + path;
            window.open(path, '_blank');
            return;
        }

        // Same tab
        try{
            
            
            

            if(this.props){

                // When on a screen with tabs and deep in hierarchy we sometimes need to go back
                // before navigating to the desired route
                if(goBackBeforeNavigating) await this._goBackAsync();

                // When using tabs we often want to replace, not push a path in the history
                if(replacePath){
                    this.props.history.replace(path);
                    log(" - replacing path with: " + path)
                }

                // External website
                else if(isExternal){
                    if(path.indexOf("http") === -1) path = "https://" + path;
                    window.open(path, "_self");
                }

                // Regular navigation to internal path
                else{
                    log(" - pushing path: " + path)
                    this.props.history.push(path);
					// Make sure everybody knows which audience we're in.
					// It doesn't seem to be set anywhere else for an internal path change.
					Audience.setAudienceId(Router.getAudienceFromPath(path))
                }
                
                // No longer on first screen (unless we're replacing path or linking to external site)
                if(!replacePath && !isExternal) this.isOnFirstScreen = false;
                
            }
            if(jumpToTop) window.scrollTo(0, 0)
        }catch(err){
            console.log(err);
            Snacks.add({
                message: Lang.t("error.generic"),
                // type:SnackType.success,
                secs:5,
            })
        }
    }
    // --------------------


    /**
     * Navigate to the correct url based on what the App.state.langId is
     */
    static navigateToAppStateLang(){
        log("Router.navigateToAppStateLang()");
        let props = instance.props;
        let path = props.location.pathname;
        const hash = props.location.hash;
        
        // Remove langId from path
        const langId = props.match.params.langId;
        log(" - path = " + path);
        if(langId){
            log(" - removing '/" + langId + " from path" )
            path = path.replace("/" + langId , '');
            log(" - stripped path = " + path)
        }
        
        // Add "/en" to path if English has been selected
        const langFrag = App.state.langId === LangId.en ? "/" + LangId.en : "";
        path = langFrag + path + hash;
        log(" - new path = " + path)
        try{
            this.navigateToPath(path, null, false, true)
        }catch(err){
            console.log("Error routing: ", err)
        }
    }

    static getLangPath(langId:LangId, fullUrl:boolean = false):string{

        let props = instance.props;
        if(!props) return "";
        let path = props.location.pathname;
        const hash = props.location.hash;
        
        // Remove langId from path
        log(" - path = " + path);
        if(path.indexOf("/en") === 0){
            path = path.replace("/en" , '');
        }
        
        // Add "/en" to path if English has been selected
        const langFrag = langId === LangId.en ? "/" + LangId.en : "";
        path = langFrag + path + hash;

        if(fullUrl){
            path = window.location.protocol + "//" + window.location.host + path;
            // console.log("  - instance.props = ", window.location);

        }

        return path;
    }
    
    static setProps(props:any){
        if(!instance) return;
        instance.setProps(props);
    }
    setProps(props:any){
        // log("Router.setProps() props = ", props);
        this.props = props;
    }

    _goBackAsync = async() => {
        log("Router._goBackAsync()")
        this.props.history.goBack();
        await Tools.later(0.05)
    }


    

    static setAudienceUsingRoute(){
        if(!instance) return;
        instance.setAudienceUsingRoute();
    }

    static get audienceIdFromRoute():AudienceId{
        
        if(!instance || !instance.props) return AudienceId.learners
        const audienceId = instance.props.match.params.audienceId || AudienceId.learners;
        return audienceId;
    }


    
    setAudienceUsingRoute(){
        
        log("Router.setAudienceUsingRoute()")
        if(!this.props) return;

        const audienceId = this.props.match.params.audienceId || AudienceId.learners;
        log(" - this.props.match.params.audienceId = " + audienceId);
    
        const changed:boolean = audienceId !== App.state.audienceId;
        if(changed){
            log (" - changing audience to = " + audienceId);
            Audience.setAudienceId(audienceId);
        }
    }
    /**
     * Whenever a new screen is mounted we use the router props to set the lang.
     */
    static async setLangUsingRoute (allowCookieToOverride?:boolean){
        if(!instance) return;
        instance.setLangUsingRoute(allowCookieToOverride);
    }

    static get routeLangId():LangId|null{
        // log("Router.routeLangId")
        if(!instance || !instance.props) return null;
        const langId = instance.props.match.params.langId || null;
        return langId;
    }
    /**
     * Sets the language based on what's in the router pathname.
     * Should be called on mount of every screen so that the lang displayed always reflects what's in the url.
     * @param allowCookieOverride - Allow the cookie langId to take precednece over the langId in the route
     * @returns
     */
    async setLangUsingRoute(allowCookieOverride?:boolean){
        log(" ");
        log("-----------------------------------------------")
        log("Router.setLangUsingRoute()")
        log(" - allowCookieToOverride = " + allowCookieOverride)
        if(!this.props) return;

        let changed = false;
        let langId = this.props.match.params.langId;

        
        log(" - Lang.cookieLangId = " + Lang.cookieLangId)
        log(" - App.state.langId = " + App.state.langId)
        log(" - route langId = " + langId)
        
        
        // langId NOT in route
        if(!langId){
            log(" - route langId is undefined")
            // Change langId to Gaelic if on English
            if(App.state.langId !== LangId.ga){
                langId = LangId.ga;
                changed = true;
            }
            // Change langId to Gaelic if undefined
            else{
                langId = LangId.ga;
                changed = true;
            }
        }

        // LangId IS in route
        else{

            // Change lang to English if not already on it
            if(langId === LangId.en && langId !== App.state.langId){
                changed = true;
            }
        } 

        log(" - langId = " + langId)
        
        // --------------------
        // COOKIE OVERRIDE
        // Override langId derived from the route with the one stored in the cookies
        if(allowCookieOverride){
            log(" - allowCookieOverride...")
            let cookieLangId:LangId|null = Lang.cookieLangId;
            log(" - cookieLangId = " + cookieLangId)
            if(cookieLangId && cookieLangId !== langId){
                log(" - OVERRIDE langId from: " + langId + " to: " + cookieLangId)
                langId = cookieLangId;

                log(" - changed to cookie langId")
            
                // Set the langId in App.state
                await Lang.setLangId(langId, true);

                // Wait a bit
                await Tools.later(0.5);

                // NAvigate to the cookie verrsion of the page
                Router.navigateToAppStateLang();
                return;

            }
        }

        if(changed){
            log(" - changed, calling Lang.setLangId with langId: " + langId);
            Lang.setLangId(langId, true); 
        }
        
    }




}