// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics, logEvent } from "firebase/analytics";

import Tools from './Tools';


// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {

  apiKey: "AIzaSyAWNcFluyL2fbrugJkN6YejhLdmb2EF6co",

  authDomain: "bocan-site.firebaseapp.com",

  projectId: "bocan-site",

  storageBucket: "bocan-site.appspot.com",

  messagingSenderId: "1073382880633",

  appId: "1:1073382880633:web:038dbf6c7642c984192ba8",

  measurementId: "G-ZC211LK5N0"

};



// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);


export type TrackEvent = {
    id:string,
    options?:any,
}

// -----------------
// SHARING EVENT
export type ShareOptions = {
    network:ShareNetwork,
    button_location:ShareButtonLocation
}

export enum ShareNetwork  {
    facebook = "facebook",
    twitter = "twitter",
}
export enum ShareButtonLocation  {
    footer = "footer",
}

// -----------------
export default class Tracker {

    

    static events:{[key:string]:string} = {
        share:"share"
    }

    static TRACKING_DISABLED_ON_LOCAL_SERVER = true;
    
    constructor(){
        log("Tracker()")
    }
    

    static track(e:TrackEvent){
        // log("Tracker.track()")
        // log(" - e = ", e)
        if(!e) return;

        if(Tools.isLocal && Tracker.TRACKING_DISABLED_ON_LOCAL_SERVER){
            // log(" - event not tracked, we're on local server.")
            return;
        }
        logEvent(analytics, e.id, e.options);
    }
}