import _ from 'lodash'
import {Component} from 'react';

import ModalScreen from '../ModalScreen/ModalScreen';
import TextInput from '../TextInput/TextInput'
// import Audience from '../../tools/Audience';
import Lang from '../../tools/Lang';
import Config from '../../config/Config';
import {Close, Search } from '@material-ui/icons';
// import Expander from '../Expander/Expander';
import Tools from '../../tools/Tools';
import Spinner from '../Spinner/Spinner'
import SearchResultsItem from "./SearchResultsItem";
import {SearchResultsItemData} from './SearchResultsItem';
import App from '../../App';
import {MediaType} from '../../data/Topic';
import Content from '../../data/Content';
import Router from '../../router/Router';
import Audience, {AudienceId} from '../../tools/Audience';
import Cookies from '../../tools/Cookies';

import './_styles.scss';

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}


type Props = {
    isOpen:boolean,
    onClose:any,
    content:Content
}

type State = {
    hidden:boolean,
    isFieldFocussed:boolean,

	/** 
	 * The search string that has been searched for (using the search() function).
	 * Note: If a search is underway, then the currently visible search results will not match this.
	 */
    searchValue:string, 

    isSearching:boolean,
	
	/** The result we got back from the search */
    items:SearchResultsItemData[]|null,

	/** The error we may have hit during a search */
    error?:Error,

    audienceId:AudienceId,

    isRevealed:boolean,

	tabs:string[],

	selectedTab:string,

	audienceFilter:string,
	dropDownAudienceFilterOpen:boolean,
}

// url:"/topic/the_green_man/library/the_green_man_447",
// url:"/topic/the_green_man/item/the_green_man_607",


export default class ModalSearch extends Component<Props, State>{
    
    isUnmounted:boolean = false;


	/** The search is debounced for a tiny bit, so that every single keypress doesn't start a search immediately. */
    FIELD_TIMER_DURATION_MS:number = 250;

	/** 
	 * none | radioButtons | textual | dropdown | systemDropdown
	 */
	AUDIENCE_FILTER_MODE = "systemDropdown"

	AUDIENCE_FILTERS = {
		learners: 	["everybody", "learners", "children"],
		kids:		["everybody", "learners", "kids"],
		toddlers:	["everybody", "learners", "toddlers"],
	}
	handleSearchDebounced = _.debounce(_.bind(this.search, this), this.FIELD_TIMER_DURATION_MS);

    textInputRef:TextInput|null = null;


    constructor(props:Props){
        
        super(props);
        log("ModalSearch()")
        // this.mediaItemData = this.cleanData(props.mediaItemData)
        // log(" - this.mediaItemData = ", this.state.mediaItemData)

        this.state = {
            hidden:false,
            isFieldFocussed:true,
            searchValue:"",
            items:null,
            isSearching:false,
            error:undefined,
            audienceId:Audience.id,
            isRevealed:false,
			tabs: [],
			selectedTab: "all",
			audienceFilter: this.loadAudienceFilter(Audience.id),
			dropDownAudienceFilterOpen: false,
        }
    }




    // -------------------------------------------------------------------------------------
    // LIFECYCLE

    componentDidMount(){
        log("ModalSearch.componentDidMount()");

        
    }

    componentDidUpdate(prevProps:Props){
        log("ModalSearch.componentDidUpdate()");
        // log(" - prevProps = ", prevProps);
        const isAudienceChanged:boolean = Audience.id !== this.state.audienceId;
        
        const isOpening:boolean = !prevProps.isOpen && this.props.isOpen
        if(isOpening){
            
            this.reveal();

            if(isAudienceChanged){
				log(" - Audience changed to: " + Audience.id);
				const audienceFilter = this.loadAudienceFilter(Audience.id)
                this.setState({
                    audienceId: Audience.id,
					audienceFilter
                },()=>{
                    this.redoSearch();
                })
                
            }else{
                this.initField();
            }
            
            

        }
        const isClosing:boolean = prevProps.isOpen && !this.props.isOpen
        if(isClosing){
            this.unreveal();
        }

    }

    componentWillUnmount(){
        this.isUnmounted = true;
        this.unreveal();
    }



    // -------------------------------------------------------------------------------------
    // METHODS
    async reveal (){
        log("ModalSearch.reveal()")
        if(this.isUnmounted) return;
        const body = document.querySelector("body")
        if(body) body.classList.add("modal-open")
        await Tools.later(0.2);
        
        this.setState({
            isRevealed:true
        })
    }
    unreveal(){
        log("ModalSearch.unreveal()")
        // await Tools.later(0.2);
        this.setState({
            isRevealed:false
        })
    }

    initField(){
        log("ModalSearch.initField()");
        if(this.isUnmounted) return;
        this.setState({
            isFieldFocussed:true
        });
    }

    close = () => {
        log("ModalSearch.close()")
        const body = document.querySelector("body")
        if(body) body.classList.remove("modal-open")
        
        this.props.onClose();
        
    }

    redoSearch = async () => {
        const term:string = this.textInputRef ? this.textInputRef.value : '';
        
        // Do an empty search to clear results
        await this.search('');

        // Do a new search wih the exising term
        if(term && this.textInputRef){
            this.textInputRef.value = term;
            this.initField();
            this.search(term);
        }
    }


    async search(searchValue:string, evt?:any) {
        log(`ModalSearch.search(${searchValue}) audienceID=${App.state.audienceId} `);
        if(this.isUnmounted) return;


        // No value? Get one.
        if(searchValue === undefined){
            log(" - this.textInputRef = ", this.textInputRef)
            searchValue = this.textInputRef? this.textInputRef.value : ''
        }

		searchValue = searchValue.trim()
		// Has an existing search already happened for this searchValue? Don't do anything.
		if(this.state.searchValue === searchValue) return;

        


        // Empty field
        if(!searchValue){
            this.setState({
                searchValue,
                isSearching: false,
                items:null,
				tabs: this._getTabsForResultItems({items: null, audienceId: App.state.audienceId, selectedTab: this.state.selectedTab})
            })
            return;
        }

        // Prepare state
        this.setState({
            searchValue,
            isSearching: true,
            items:null,
            error:undefined,
        })

        // Do a search now
        let items:SearchResultsItemData[]|null = null;
        const useExampleResults:boolean = false;
        const simulateError:boolean = false;
        try{
			// Test results...
			if (simulateError) throw Error("Could not connect to server");
			if(useExampleResults){
				if(searchValue === "empty") return [];
				return require('./example_results.json');
			}
			
			// Real results...
			log(`SEARCHING for "${searchValue}" audience ${App.state.audienceId}...`)
			const audiences = this._getAudienceIdsByFilter(this.state.audienceFilter)
			items = await this.props.content.searcher?.search({lang: Lang.langId, searchValue, audiences}) || null
			if(this.isUnmounted) return;
			// Oh, another search has happened since this one began (this one must have taken a while).
			// Get out of here.
			if (this.state.searchValue !== searchValue) {
				return;
			}

			const tabs = this._getTabsForResultItems({items, audienceId: App.state.audienceId, selectedTab: this.state.selectedTab})
			this.setState({
				isSearching: false,
				items,
				error:undefined,
				tabs,
			})
        }
		catch(error:any){
			console.log(error)
            if(this.isUnmounted) return;
            this.setState({
                error,
                isSearching: false,
            })
        }
    }


	_getAudienceIdsByFilter(audienceFilter:string):AudienceId[] {
		switch (audienceFilter) {
			default:
			case "everybody": 	return [AudienceId.learners, AudienceId.kids, AudienceId.toddlers];
			case "learners":	return [AudienceId.learners];
			case "kids":		return [AudienceId.kids];
			case "toddlers":	return [AudienceId.toddlers];
			case "children":	return [AudienceId.kids, AudienceId.toddlers];
		}
	}
	


    // -------------------------------------------------------------
    // EVENTS

   
    handleCloseClick = () => {
        this.close();
    }

    handleItemClick = (data:SearchResultsItemData, url?:string) => {
        if(url){
            Router.navigateToPath(url);
            this.close();
        }
    }

    handleFormSubmit = (event:any) => {
		event?.preventDefault?.()        
    }

	handleAudienceInputChange = async (event:React.SyntheticEvent) => {
		const form:HTMLFormElement = (event.currentTarget as HTMLInputElement).form!
		const radio = form.querySelector<HTMLFormElement>(`input[name=audience]:checked`)!
		const audienceFilter = radio.value
		await Tools.misc.asyncSetState(this, {audienceFilter})
		this.saveAudienceFilter(audienceFilter, App.state.audienceId)
		this.redoSearch()
	}

	handleTextualAudienceFilterChange = async (audienceFilter:string) => {
		await Tools.misc.asyncSetState(this, {audienceFilter})
		this.saveAudienceFilter(audienceFilter, App.state.audienceId)
		this.redoSearch()
	}

	handleTabClick = (tab:string) => {
		const tabs = this._getTabsForResultItems({items: this.state.items, audienceId: App.state.audienceId, selectedTab: tab})
		this.setState({tabs, selectedTab: tab})
	}
   

    // -------------------------------------------------------------------------------------
    // AUDIENCE-FILTER COOKIES

	loadAudienceFilter(audienceId:string):string {
		const audienceFilter = Cookies.getCookie(this.getCookieName(audienceId)) || this.getDefaultAudienceFilter(audienceId)
		// console.log(`Loaded cookie ${this.getCookieName(audienceId)} = ${audienceFilter} (default = ${this.getDefaultAudienceFilter(audienceId)})`)
		return audienceFilter;
	}

	getDefaultAudienceFilter(audienceId:string) {
		if (this.isAudienceFilterGlobal) {
			return "everybody";
		}

		// Different default audience filter depending on which side of the site they're on
		switch(audienceId) {
			default:
			case AudienceId.learners: 	return "everybody";
			case AudienceId.kids: 		return AudienceId.kids;
			case AudienceId.toddlers: 	return AudienceId.toddlers;
		}
	}

	saveAudienceFilter(audienceFilter:string, audienceId:AudienceId) {
		Cookies.setCookie(this.getCookieName(audienceId), audienceFilter)
		// console.log(`Saved cookie ${this.getCookieName(audienceId)} = ${audienceFilter}`)
	}

	getCookieName(audienceId:string):string {
		return this.isAudienceFilterGlobal ? "audienceFilter" : `audienceFilter_${audienceId}`
	}

	/** Do we have one audience filter for all side of the site (learner | kids | toddlers)? */
	get isAudienceFilterGlobal():boolean {
		if (this.AUDIENCE_FILTER_MODE==="radioButtons") return false;
		return true;
	}


    // -------------------------------------------------------------
	// TABS

	_getTabsForResultItems(
		{items, audienceId, selectedTab}:{items:SearchResultsItemData[]|null, audienceId:string, selectedTab:string}
	):string[] {
		let tabs:string[] = []
		const showNoTabs = (items==null || items.length===0) && selectedTab=="all"
		if (showNoTabs) {
			return tabs;
		}

		tabs = Config.settings.search.audiences[audienceId].tabs.slice()

		const needsAllTab = selectedTab!=="all" && tabs.length > 0
		if (needsAllTab) {
			tabs.unshift("all")
		}
		return tabs;
	}


	_getSelectedTabItemTypes(selectedTab:string):string[] {

		if (selectedTab==="all") {
			return [];
		}
		switch (selectedTab) {
			default:
				// Most tabs are the same as the item type, e.g. "video"
				return [selectedTab];
		}
	}
	
	_getTabItems(tab:string, items:SearchResultsItemData[]|null):SearchResultsItemData[] {
		if (items==null || items.length===0) return [];
		const tabItemTypes = this._getSelectedTabItemTypes(tab)
		if (tabItemTypes.length===0) {
			return items;
		}
		return items.filter(item => tabItemTypes.includes(item.type));
	}


    // -------------------------------------------------------------
    // RENDER

	/**
	 * Well this is a funny one! To filter the audience we use a little contextual sentence & button, rather than 3 radio buttons.
	 * Much less intimidating I think.
	 * E.g. 
	 * 		Searching everything. <Hide kids results>
	 * 		Searching kids only. <Show everything>
	 * 
	 * It turns out there are quite a few combinations!
	 * 
	 */
	renderTextualAudienceFilter(audienceId:AudienceId, audienceFilter:string):JSX.Element|undefined{

		const showLearners  = () => this.handleTextualAudienceFilterChange("learners")
		const showChildren  = () => this.handleTextualAudienceFilterChange("children")
		const showToddlers  = () => this.handleTextualAudienceFilterChange("toddlers")
		const showEverybody = () => this.handleTextualAudienceFilterChange("everybody")

		const prefix = "search.audience.textual"

		switch(audienceId) {

			// LEARNERS SIDE
			case AudienceId.learners: 	
				switch(audienceFilter) {
					case "everybody": return <p>Searching everything. <button className="text-only-button"  onClick={showLearners}>{Lang.t(`${prefix}.${audienceId}.${audienceFilter}`)}</button>.</p>
					case "learners":  return <p>Hiding kids results. <button className="text-only-button" onClick={showEverybody}>{Lang.t(`${prefix}.${audienceId}.${audienceFilter}`)}</button>.</p>
					case "kids":  
					case "children":  
						return <p>Searching kids only. <button className="text-only-button" onClick={showEverybody}>{Lang.t(`${prefix}.${audienceId}.children`)}</button>.</p>
					case "toddlers":  
						return <p>Searching toddlers only. <button className="text-only-button" onClick={showEverybody}>{Lang.t(`${prefix}.${audienceId}.${audienceFilter}`)}</button>.</p>
				}
				break;

			// KIDS SIDE
			case AudienceId.kids: 	
				switch(audienceFilter) {
					case "everybody": return <p>Searching everything. <button className="text-only-button" onClick={showChildren}>{Lang.t(`${prefix}.${audienceId}.${audienceFilter}`)}</button>.</p>
					case "learners":  return <p>Searching adults only. <button className="text-only-button" onClick={showChildren}>{Lang.t(`${prefix}.${audienceId}.${audienceFilter}`)}</button>.</p>
					case "kids":
					case "children":  
						return <p>Searching kids only. <button className="text-only-button" onClick={showEverybody}>{Lang.t(`${prefix}.${audienceId}.children`)}</button>.</p>
					case "toddlers":
						return <p>Searching toddlers only. <button className="text-only-button" onClick={showChildren}>{Lang.t(`${prefix}.${audienceId}.${audienceFilter}`)}</button>.</p>
					}
				break;

			// TODDLERS SIDE
			case AudienceId.toddlers: 	
				switch(audienceFilter) {
					case "everybody": return <p>Searching everything. <button className="text-only-button" onClick={showToddlers}>{Lang.t(`${prefix}.${audienceId}.${audienceFilter}`)}</button>.</p>
					case "learners":  return <p>Searching adults only. <button className="text-only-button" onClick={showToddlers}>{Lang.t(`${prefix}.${audienceId}.${audienceFilter}`)}</button>.</p>
					case "kids":
					case "children":  
						return <p>Searching kids. <button className="text-only-button" onClick={showToddlers}>{Lang.t(`${prefix}.${audienceId}.children`)}</button>.</p>
					case "toddlers":
						return <p>Searching toddlers only. <button className="text-only-button" onClick={showEverybody}>{Lang.t(`${prefix}.${audienceId}.${audienceFilter}`)}</button>.</p>
				}			
				break;
		}

		// We should never get here, but just in case...
		console.log(`ModalSearch.renderTextualAudienceFilter() hit a problem: Audience filter is {audienceFilter}. We need to alter it in the {audienceId} side.`)
		if (audienceFilter==="everybody") return <p></p>;
		return <p><button className="text-only-button" onClick={showEverybody}>{Lang.t(`${prefix}.learners.everybody`)}</button>.</p>
	}



	renderCustomDropdownAudienceFilter(audienceId:AudienceId, audienceFilter:string):JSX.Element {
		const toggleOpen = () => {
			this.setState({dropDownAudienceFilterOpen: !this.state.dropDownAudienceFilterOpen})
		}
		const setFilter = (filterId:string) => {
			console.log(`FILTER ${filterId}`)
			toggleOpen()
			this.handleTextualAudienceFilterChange(filterId)
		}

		const audienceFilterOptions = ["learners", "children", "toddlers", "everybody"]
		const text:string = Lang.t(`search.audience.dropdown.${audienceFilter}`)
		const textStart = Tools.fillTagsWithValue(text, "")
		const audienceName:string = text.match(/.*{(.*)}/)?.[1] || "---"

		const openClass = this.state.dropDownAudienceFilterOpen ? "open" : "closed"
		return <div>
			<span>{textStart}</span>
			<span className={`select ${openClass}`}>
				<button className="scrim text-only-button" onClick={toggleOpen}></button>
				<ul>
				{audienceFilterOptions.map((filterId, i) => 
					<li key={`audienceFilterSelect_${i}`} className={filterId===audienceFilter ? "selected" : ""}>
						<button className={`text-only-button`}onClick={()=>setFilter(filterId)}>{Lang.t("search.audience.dropdown.item."+filterId)}</button>
					</li>
				)}
				</ul>
				<button className="name text-only-button" onClick={toggleOpen}>{audienceName} ▼</button>
			</span>
		</div>
	}


	renderSystemDropdownAudienceFilter(audienceId:AudienceId, audienceFilter:string):JSX.Element {

		const audienceFilterOptions = ["everybody", "learners", "children", "toddlers"]
		const text:string = Lang.t(`search.audience.dropdown.${audienceFilter}`)
		const textStart = Tools.fillTagsWithValue(text, "")
		const audienceName:string = text.match(/.*{(.*)}/)?.[1] || "---"

		const onChange = ({target}: {target:HTMLSelectElement}) => {
			this.handleTextualAudienceFilterChange(target.value)
			console.log(`TARGET VALUE ${target.value}`)
		}

		return <div>
			<span>{textStart}</span>
			<select onChange={onChange} value={audienceFilter}>
				{audienceFilterOptions.map(  (filterId, i) => 
					<option 
						key={`audienceFilterSelect_${i}`} 
						className={filterId===audienceFilter ? "selected" : ""}
						value={filterId}
					>
						{Lang.t("search.audience.dropdown.item."+filterId)}
					</option>
				)}
			</select>
		</div>
	}

    renderResults():JSX.Element[]|undefined{
        if(!this.state.items) return;
        let i = 0;
		return this._getTabItems(this.state.selectedTab, this.state.items)
				.map((item:SearchResultsItemData, index:number) =>  <SearchResultsItem
					index={index}
					data={item}
					onClick={this.handleItemClick}
					key={`search_results_item_container_${index}`}
				/>)
    }


    render(){
        log("ModalSearch.render()")
        

        const contentClass = "modal search" + (this.state.isRevealed ? "" : " unrevealed");

		const resultItems = this.renderResults()
		const numResults = resultItems?.length || 0

		const audienceFilters:string[] = this.AUDIENCE_FILTERS[this.state.audienceId]

        return(

            <ModalScreen
                open={this.props.isOpen}
                onClose={this.props.onClose}
                classes="modal-paper-search"
            >
                <div className={contentClass} id="search">
                    

                    {/* HEADER */}
                    <div className="modal-header">
                        <div className="buts">
                            <button 
                                aria-label={Lang.t("but.close")} 
                                className="but close-but" 
                                onClick={this.handleCloseClick}
                            >
                                <Close className="but-icon" />
                            </button>
                        </div>

                    </div>


                    {/* BODY */}
                    <div className="search-body">
                        <div className="main-column">

                            {/* SEARCH BOX */}
                            <div className="search-box-container">
                                <div className="search-box">
                                    
                                        <div className="search-form-container">
                                            <div 
                                                className="search-but" 
                                                // onClick={this.handleSearchClick}
                                            >
                                                <Search className="search-icon"/>
                                            </div>
                                            <form 
                                                className="search-form"
                                                onSubmit={this.handleFormSubmit}
                                            >
                                                <TextInput
                                                    name="search-field"
                                                    id="search-field"
                                                    // placeholder={Lang.t("search.placeholder")}
                                                    classes="search-field"
                                                    focussed={this.state.isFieldFocussed}
                                                    onChange={this.handleSearchDebounced}
                                                    initialValue={this.state.searchValue}
                                                    ref={(ref)=>{if(ref) this.textInputRef = ref}}
                                                    selectOnFocus={true}
                                                />
                                                
                                            </form>
                                            
                                        </div>
                                        <div className="search-help">
											{Tools.string.isEmpty(this.state.searchValue) && 
													<p className="ui">{Lang.t("search.help")}</p>
											}
	
											
											
											{!Tools.string.isEmpty(this.state.searchValue) && <>

												{this.AUDIENCE_FILTER_MODE==="radioButtons" && <>

													
														<form className="audiences">
															<span className="prefix">{Lang.t("search.selectAudience")}</span>
															{audienceFilters.map((audienceFilter:string) => 
																<label key={`audienceInput.${audienceFilter}`}>
																	<input
																		type="radio" 
																		name="audience" 
																		checked={this.state.audienceFilter===audienceFilter} 
																		value={audienceFilter}
																		onChange={this.handleAudienceInputChange} 
																	/>
																	{Lang.t(`search.audience.${audienceFilter}`)}
																</label>
															)}

														</form>
													
												</>}

												{this.AUDIENCE_FILTER_MODE==="textual" && 
													<div className="audiences textual">
														{this.renderTextualAudienceFilter(this.state.audienceId, this.state.audienceFilter)}
													</div>  
												}


												{this.AUDIENCE_FILTER_MODE==="dropdown" && 
													<div className="audiences dropdown">
														{this.renderCustomDropdownAudienceFilter(this.state.audienceId, this.state.audienceFilter)}
													</div>  
												}



												{this.AUDIENCE_FILTER_MODE==="systemDropdown" && 
													<div className="audiences systemDropdown">
														{this.renderSystemDropdownAudienceFilter(this.state.audienceId, this.state.audienceFilter)}
													</div>  
												}

											</>}
                                        </div>

										<ul className="search-tabs">
											{this.state.tabs.map((tab:string, tabIndex:number)=> 
												<li 
													className={`${tab===this.state.selectedTab ? "selected" : ""}  ${this._getTabItems(tab, this.state.items).length===0 ? "empty" : ""}`}
													key={`search-tab-${tabIndex}`}
												>
													<button type="button" onClick={()=>this.handleTabClick(tab)}>
														{Lang.t(`search.tabs.${tab}`)}
													</button>
												</li>
											)}
										</ul>
                                </div>
                                
                            </div>

                        </div>
                        



                        {/* SEARCH RESULTS */}
                        
                        <div className="search-results-container">
                            <div className="main-column">
                                {/* SEARCHING */}
                                {this.state.isSearching &&
                                    <div className="searching">
                                        <Spinner
                                            className="spinner-container spinner-container-search"
                                        />
                                    </div>
                                }
                                {/* ERROR */}
                                {this.state.error &&
                                    <div className="search-results-error">
                                        <p className="ui">There was an error searching for <strong><i>"{this.state.searchValue}"</i></strong></p>
                                        <p className="ui warning">{this.state.error?.message || String(this.state.error)}</p>
                                    </div>

                                }
                                {/* SEARCH COMPLETE */}
                                {this.state.items &&
                                    <div className="search-results">
                                        
                                      
                                        {/* NO RESULTS  */}
                                        {numResults === 0  &&
                                            <div className="search-results-empty">

													<p className="ui" 
													dangerouslySetInnerHTML={{__html: 
														Tools.fillTags(
															Lang.t(`search.noresults${this.state.selectedTab==="all" ? "" : ".filtered"}`), 
															{
																term: 	this.state.searchValue, 
																filter:	Lang.t(`search.tabs.${this.state.selectedTab}`)
															}  
														)
													}}
													></p>
												
                                            </div>

                                        }


                                        {/* RESULTS */}
										{numResults > 0 && 
											<div>
												<h5 className="search-results-header">{Lang.t("search.results")}: "{this.state.searchValue}"</h5>
											

												<div className="search-results-items">
													{resultItems}
												</div>
											</div>
										}
											
                                            
                                        
                                    </div>
                                }
                            </div>
                        </div>
                        
                    </div>
                    
                    
                </div>
            </ModalScreen>
        )
    }
}


