// CSS
import './_styles.scss';

// IMPORTS
import React from 'react';


// import App from '../../App';

// import Lang from '../../tools/Lang';
import SectionItem from '../SectionItem/SectionItem';
import type {ContentSectionRowAspect, ContentStory} from '../../data/Content';
// import Audience from '../../tools/Audience';
import {ContentSectionItemLayout} from '../../data/Content';



// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}


interface Props{
    id:string;
    items:ContentStory[];
    onItemClick:Function;
    aspect?:ContentSectionRowAspect
}

export default class OralItems extends React.Component<Props>{
    
  

    // constructor(props:Props){
        
    //     super(props);
        
    //     this.data = props.data;
    //     log("SectionItems() data = ", this.props.data)
    // }
    

    renderItems():any{
        log("SectionItems.renderItems()")
        // log(" - id = " + this.props.data.id)
        // log(" - rows = ", this.props.data.rows)
        // log(" - groupId = " + this.props.data.groupId)
        // log(" - uiSectionId = " + this.props.data.uiSectionId)
        // log(" - sectionId = " + this.props.data.sectionId)
        if(!this.props.items) return null;
        let items = [];
        let i=0;
        for(let itemData of this.props.items){
            log(" - itemData = ", itemData)
            const item = (
                <div 
                    className="item"
                    key={"section_item__" + this.props.id + "__" + itemData.id + "__" + i}
                >
                    <SectionItem
                        
                        data={itemData}
                        sectionId={this.props.id}
                        onClick={this.props.onItemClick}
                        aspect={this.props.aspect}
                        layout={ContentSectionItemLayout.row}
                        // mediaType={itemData.mediaType}
                        showFilterTags={true}
                    />
                </div>
            )
            items.push(item);
            i++;
        }
        return items
    }


   

   

    render(){
        log("SectionItems.render()");
        // log("langFrag = " + langFrag)
        // log(" - this.props.data = ", this.props.data)
    
         
        return(
            <div className="section-items">
                <div className="items-container">
                    {this.renderItems()}
                </div>
            </div>
        
        )
    }
}



