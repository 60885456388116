import './_styles.scss';
import React from 'react';

// import App from '../../App';
import Lang, {LangId} from '../../tools/Lang';
import {LangString} from '../../data/Content';

import Tools from '../../tools/Tools';
import Tween, {TweenObject} from '../../tools/Tween';
import Content, {ContentSectionId, ContentSectionItemLayout, ContentSectionRowAspect} from '../../data/Content';
import type {ContentStory} from '../../data/Content';
import Audience, {AudienceId} from '../../tools/Audience';
import Config from '../../config/Config';
import Link from '../Link/Link';
import Router from '../../router/Router';
import Section from '../Section/Section';
// import {MediaType} from '../../data/Topic';

// Logging
let isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}


interface Props{
    data:ContentStory;

    // Actual group id, e.g. "ireland", or "west_highlands"
    groupId?:string;

    // Section id that corresponds to a ContentSectionId, e.g. "places", "characters", "specialCollections", "superStories", or  "storyTypes"
    sectionId:string;

    onClick:Function;
    
    rowTitle?:LangString;
    
    sectionTitle?:LangString;

    switchTitle?:boolean,

    mediaItemId?:string,
    
    // mediaType?:MediaType|string,

    aspect?:ContentSectionRowAspect,

    layout?:ContentSectionItemLayout,

    // Show the filterTags (used on Oral Traditions page)
    showFilterTags?:boolean

}

interface State {
    isLoadingImage:boolean,
    isDisabled:boolean,

    
}

export default class SectionItem extends React.Component<Props, State>{
    
    // data:ContentStory;

    isUnmounted:boolean = false;

    TRUNCATE_DESCRIPTION:boolean = true;
    TRUNCATE_DESCRIPTION_CHARS_MAX:number = 100;

    SECTIONS_WHERE_ALTERNATE_THUMB_IS_USED:string[] = [Section.ID_CHARACTERS, Section.ID_MEDIA, Section.ID_MUSIC_AND_SONGS, Section.ID_TODDLERS_COMICS];

    opacityTween:TweenObject | null = null;

    imagePath:string = Config.getImagePath();

    filterTagsText:LangString|null = null;

    constructor(props:Props){
        
        super(props);
        // console.log("groupId = " + this.props.groupId)
        // console.log("this.props.data.id = " + this.props.data.id)
    
        // if((this.props.groupId === "highland_scotland" || this.props.groupId === "skye_and_raasay") && this.props.data.id === "cuchullain") isLoggingEnabled = true;
        // else isLoggingEnabled = false;
        log(" ")
        log("-----------------------")
        log("SectionItem()")
        
        this.state = {
            isLoadingImage:true,
            isDisabled:false,
            
        }

        this.setFilterTagsText();
        

        // log(" - data = ", this.props.data)
        // log(" - id = ", this.props.data.id)
        // log(" - groupId = ", this.props.groupId)
        // log(" - sectionId = ", this.props.sectionId)
    }
    

    // ---------------------------------------------------------------------------------
    // LIFECYCLE

    componentWillUnmount(){
        this.isUnmounted = true;
        Tween.kill(this.opacityTween)
    }



    // ---------------------------------------------------------------------------------
    // GETTERS


    get sectionId():string{
        return this.props.sectionId
    }

   
    
    // ---------------------------------------------------------------------------------
    // METHODS

    

    enable(){
        this.setState({
            isDisabled: false
        });

    }
    disable(){
        this.setState({
            isDisabled: true
        });

    }

   
    revealImage () {

        this.setState(
            {
                isLoadingImage:false,
            }
        );

    }

    getTitle ():string {
        let langString:LangString = this.props.data.title;

         // Is there a custom description for this particular item when in this row?
         if(this.props.data.titles){
            
            let titleKey:string = "";
            if(this.props.sectionId){
                titleKey =  this.props.sectionId;
                if(this.props.groupId) titleKey += ".";
            } 
            if(this.props.groupId) titleKey += this.props.groupId;

            // If this is a duplicate item (i.e. more than one item in the row for the same topic), then alter the key
            if(this.props.data.duplicateId) titleKey += "." + this.props.data.id + "." + this.props.data.duplicateId;
            
            const customDescription:LangString|undefined = this.props.data.titles[titleKey];
            if(customDescription) langString = customDescription;
        }

        let str = Lang.tc(langString);
        return str;
    }

    getDescription():string{
        
        // const shouldLog = this.props.data.id === "beltane"// && this.props.groupId === "characters.supernatural_beings";
        // if(shouldLog){
        //     console.log(" ")
        //     console.log("----------")
        //     console.log("SectionItem.getDescription");
        //     console.log(" - id = " + this.props.data.id)
        //     console.log(" - groupId = " + this.props.groupId)
        //     console.log(" - descriptions = ", this.props.data.descriptions)
        // }

        let langString:LangString = this.props.data.description;

        // Is there a custom description for this particular item when in this row?
        if(this.props.data.descriptions){
            
            let descriptionKey:string = this.props.sectionId;
            if(this.props.groupId) descriptionKey += "." + this.props.groupId;

            // If this is a duplicate item (i.e. more than one item in the row for the same topic), then alter the key
            if(this.props.data.duplicateId) descriptionKey += "." + this.props.data.id + "." + this.props.data.duplicateId;
            
            const customDescription:LangString|undefined = this.props.data.descriptions[descriptionKey];
            if(customDescription) langString = customDescription;
        }
        
        let str = Lang.tc(langString);

        // Truncate description
        if(this.TRUNCATE_DESCRIPTION) str = Tools.trunc(str, this.TRUNCATE_DESCRIPTION_CHARS_MAX);
        
        return  str;
    }

    getSubtitle():string{
        // console.log("SectionItem.getSubtitle()")
        const data:ContentStory|any = this.props.data
        // console.log(" - title = " + Lang.tc(this.props.data.title))
        // console.log(" - this.props.groupId = " + this.props.groupId)
        // console.log(" - this.props.sectionId = " + this.props.sectionId)
        // console.log(" - this.props.rowTitle = " + this.props.rowTitle)
        // console.log(" - this.props.duplicateId = " + this.props.data.duplicateId)

        const groupId:string = this.props.groupId || "";
        let sectionId:ContentSectionId = this.props.sectionId as ContentSectionId;//idArr[0] as ContentSectionId;
        let subgroupId = "";
        let subtitle:string = "";

        // ITEM IN PLACE
        
        let isPlace:boolean = this.props.sectionId === ContentSectionId.places && this.props.groupId !== "indian_tribes";
        if(isPlace){
            // console.log(" - props = :", this.props)
            subtitle = Content.getStoryLocation(this.props.data.id)
        }

        // ITEM IN OTHER SECTION
        else {
            if(data[sectionId] && data[sectionId].length > 0){
                for(let obj of data[sectionId]){
                    if(obj.parent === groupId){
                        if(data.duplicateId){
                            if(obj.id === data.duplicateId){
                                subgroupId = obj.id;
                            }
                        }else{
                            subgroupId = obj.id;
                        }
                        // subgroupId = obj.id;
                        
                        if(subgroupId){
                            subtitle = Content.getSubgroupTitle(sectionId, groupId, subgroupId)
                        }
                    }
                }
            }
        }
        // log(" - subtitle = " + subtitle)

        
    

        // Subtitle same as row title e.g. "Places"
        if(subtitle === Lang.tc(this.props.rowTitle)) return "";

        // Subtitle same as item title
        if(subtitle.toLowerCase() === Lang.tc(this.props.data.title).toLowerCase()) return "";

        return subtitle;
    }


    getUrl(){
		const shouldForceAudienceId = Audience.id===AudienceId.kids || Audience.id===AudienceId.toddlers
		const forceAudienceId = shouldForceAudienceId ? Audience.id : undefined
		const shouldLog = false // this.props.data._id==="9" // && this.props.data.mediaItemId==="dh___ithe_an_gruth_562"
		if (shouldLog) {
			// console.log(`EEEK this.props.data:`, this.props.data, `material:`, Content.getMaterialByNiceId(this.props.data.mediaItemId))
		}
        if(this.props.data.mediaItemId){
			const url = Router.getMediaItemPath(this.props.data.mediaItemId, forceAudienceId)
			if (shouldLog) console.log(`url: ${url}`)
         	return url;
        }

        const sectionId = Audience.isAudienceToddlers ? this.props.sectionId : undefined;
        const url = Router.getTopicPath(this.props.data.id, undefined, undefined, sectionId)
		if (shouldLog) console.log(`url: ${url}`)
		return url;
        // const langFrag = Lang.langId === LangId.en ? LangId.en + "/": "";
        // const audienceFrag = Audience.isAudienceAnyKids ? "a/" + Audience.id + "/": "";
        // let sectionFrag = Audience.isAudienceToddlers ?  "/section/" + this.props.sectionId : "";
        
        // return "/" + langFrag + audienceFrag + "topic/" + this.props.data.id + sectionFrag;
    }

    getDifficultyBadge(){
        // console.log("SectionItem.getDifficultyBadge()")
        // console.log(" - id = " + this.props.data.id)
        let mediaItem = this.props.data.manualMediaItems?.[this.props.data.mediaItemId || ""];
        if(!mediaItem || mediaItem.difficulty === undefined) return null;
        const cn = "difficulty-badge difficulty-badge-level" + mediaItem.difficulty;
        return (
            <div className={cn}>
                {Lang.t("difficulty" + mediaItem.difficulty)}
            </div>
        )

        
    }

    /**
     * Set the filter tags text LangString object.
     * Happens once on construction only.
     * @returns 
     */
    setFilterTagsText(){
        if(!this.props.data.mediaTags) return;
        
        let tagTermsEn:string[]=[];
        let tagTermsGa:string[]=[];

        // Loop through all media tags
        for(let key in this.props.data.mediaTags){
            
            // Ignore non oral media tag types
            if(!Content.VALID_ORAL_MEDIA_TYPES.includes(key)) continue;
            
            // Add tags to array for each language
            const tags = this.props.data.mediaTags[key];
            if(tags && tags.length > 0){
                for(let tag of tags){
                    const tagId = "oral.controls.filter.type." + tag;
                    if(Lang.hasTerm(tagId)){
                        tagTermsEn.push(Lang.t(tagId, LangId.en));
                        tagTermsGa.push(Lang.t(tagId, LangId.ga));
                    }   
                }
            }
        }
    
        // Sort tags
        Tools.array.sortAlphabetically(tagTermsEn);
        Tools.array.sortAlphabetically(tagTermsGa);
        

        // Convert tags to strings
        const strEn =  tagTermsEn.length>0 ? tagTermsEn.join(", ") : "";
        const strGa =  tagTermsGa.length>0 ? tagTermsGa.join(", ") : "";
        
        // Store in local member
        this.filterTagsText = {
            en:strEn,
            ga:strGa
        }
    }

    


    // ---------------------------------------------------------------------------------
    // EVENTS

    handleClick = (event:any) => {
        log("SectionItem.handleClick(): " + this.props.data.title.en);
        if(!this.state.isDisabled){
            this.props.onClick(this.props.data, this, event);
        }
        else{
            // console.log(" - isDisabled");
        }
    }


    
 

    handleImageLoad = () => {
        if(this.isUnmounted) return;
        this.revealImage();
    }

    // ---------------------------------------------------------------------------------
    // RENDER
    
    

    render(){
        // console.log("SectionItem.render(): " + this.props.sectionId + " > " + this.props.groupId + " > " + this.props.data.id)
        let title = this.getTitle();;//Lang.tc(this.props.data.title);
        let subtitle = this.getSubtitle();

        if(subtitle && title && (this.props.switchTitle)){
            const tempTitle:string = title;
            title = subtitle;
            subtitle = tempTitle;
        }


        let description = this.getDescription();

        // Image path
        const audienceFolder:string = Audience.id !== undefined ? Audience.id: AudienceId.learners;
        let imagePath = '';
        let folder = this.imagePath + "thumbs/" + audienceFolder + "/";
        let filename = this.props.data.id;
        
        // For characters and media, add sectionId and GroupId to the path
        if(this.SECTIONS_WHERE_ALTERNATE_THUMB_IS_USED.includes(this.props.sectionId)){
            folder += "_alternates/" + this.props.sectionId;
            if(this.props.groupId)  folder += "/" + this.props.groupId;
            folder += "/";
        }

        // Sometimes we want a specific aspect ratio
        if(this.props.aspect) folder += this.props.aspect + "/";

        // Items from the same topic will have duplicateId populated
        if(this.props.data.duplicateId){
            filename += "." + this.props.data.duplicateId;
        }

        filename += ".jpg";
        imagePath = folder + filename;

        let className = "row-item";
        if(this.state.isDisabled) className += " disabled";
        if(this.state.isLoadingImage) className += " loading-image";
        if(this.props.aspect) className += " aspect-" + this.props.aspect; 
        if(this.props.layout) className += " layout-" + this.props.layout; 
        

        // if (this.props.sectionId==="musicAndSongs" && this.props.data.id==="the_mull_witch") {
		// 	console.log(`SECTION ITEM ${this.props.data.id} (mediaItemId ${this.props.data.mediaItemId}) [${Router.getPathStart()}]`, this.getUrl())
		// }

        return(
            
            
            <Link 
                to={this.getUrl()} 
                className={className} 
                onClick={this.handleClick} 
            >
                
                
                

                {/* IMAGE */}
                <div className="row-item-image-wrapper">
                <div className="row-item-image-container">
                    <img 
                        onLoad={this.handleImageLoad} 
                        src={imagePath} 
                        className="row-item-image" 
                        alt=""
                        loading="lazy"
                    />

                    {/* BADGE */}
                    {this.props.data.mediaType &&
                        <div className={"row-item-icon row-item-icon--" + this.props.data.mediaType}/>
                    }
                    
                    {/* BORDER */}
                    <div className="row-item-image-border"/>
                </div>
                </div>
                
            
                {/* TEXT */}
                <div className="row-item-text">
                    <h3  className="row-item-title grid-item">{title}</h3>
                    {subtitle &&
                        <p className="row-item-subtitle grid-item">{subtitle}</p>
                    }

                       
                    {this.props.data.mediaItemId && 
                        this.getDifficultyBadge()
                    }

                    <p className="row-item-description">{description}</p>

                    {/* FILTER TAGS */}
                    {this.filterTagsText && this.props.showFilterTags &&
                        <p className="smallcaps faint row-item-filter-tags">{this.filterTagsText[Lang.langId]}</p>
                    }
                </div>
            </Link>
                
            
        
        )
    }
}



