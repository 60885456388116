import Lang from "../../tools/Lang";
import './_styles.scss';
import { SyntheticEvent } from "react";

type Props = {
    onClick:Function,
    labelId:string,
    className?:string,
    labelClassName?:string,
    icon?:string,
    disabled?:boolean

}
export const FormButton = (props:Props) => {
    const handleClick = (event:SyntheticEvent) => {
        if(props.onClick) event.preventDefault();

        //@ts-ignore
        // const val = event.target.selectedOptions[0].value;
        props.onClick();
    }

    // Class
    let className = "control control-button";
    if(props.className) className += " " + props.className;
    if(props.disabled) className += " disabled"

    // Icon
    let icon:any;
    if(props.icon){
        switch (props.icon){
            case "close":
                icon = (
                    <span className="icon text-icon">×</span>
                )
                break;
        }
        
    }

    // Label class
    let labelClassName = "label";
    if(props.labelClassName)  labelClassName += " " + props.labelClassName;


    ;
    return (
        <button 
            className={className}
            onClick={handleClick} 
            disabled={props.disabled}
        >
            <span className={labelClassName}>{Lang.t(props.labelId)}</span>

            {icon &&
                icon
            }
        </button>
    )
}