import './_styles.scss';
import React from 'react';
import {ExpandLess, ExpandMore } from '@material-ui/icons';

import Lang from '../../tools/Lang';
import Tools from '../../tools/Tools';
import Cookies from '../../tools/Cookies';

// Logging
var isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

enum ButType {
    type1 = 1,
    type2 = 2
}

type Props = {
    expanded?:boolean,
    
    // Save expanded state to a cookie?
    save?:boolean,

    // Id is required to save
    id?:string,

    butType?:ButType;

    butTextMore?:string;

    butTextLess?:string;

    butAlignLeft?:boolean;

    // SIMPLE METHOD (preferred)
    // Add any children you wish to be hidden using the childrenHidden prop. 
    // Takes any react Element or array of Elements.
    // This is the preferred method as it is more optimal in terms of rendering.
    // Needs no specific classes.
    // 
    // ADVANCED METHOD
    // In more complicated situations where hidden elements are interspersed with visible elements 
    // such as in the Credits component, leave this undefined, and include the element(s) you want hidden 
    // amongst the generic component children, and assign the className="hidden revealed" to the hidden elements.
    childrenHidden?:any;
    
    butTextStyle?:any;

    butStyle?:any;

    shouldLog?:boolean;

    children?:any;

    
}

type State = {
    
    // Set when the expand button is toggled. This triggers rendering of the hidden elements.
    expanded:boolean,
    
    // Used to determine whether or not to show the expand button
    isExpandable:boolean,

    // True when user taps the more button to expand. This triggers revealing of the hidden elements.
    isRevealed:boolean;
    
}

export default class Expander extends React.Component<Props,State>{

   
    isUnmounted:boolean = false;
    constructor(props:Props){
        super(props);

        log("Expander()")
        
        // Is expanded set in the cookie?
        let expanded:boolean = this.cookieValue;
        
        // Is expanded set in the props?
        if(this.props.expanded !== undefined) expanded = this.props.expanded;

        if(this.props.shouldLog) isLoggingEnabled = true;

        this.state = {
            expanded: expanded,
            isExpandable:this.containsHiddenElement(this.props.children),
            isRevealed: expanded
        }
    }

    
    componentDidUpdate(oldProps:Props, oldState:State){
        log("Expander.componentDidUpdate()")
        // log(" - oldProps.id/ = " + oldProps.id)
        // log(" - props.id = " + this.props.id)
        
        // A new bunch of content has arrived in the same Expander.
        // Update the state with new values for this new data.
        if(oldProps.id !== this.props.id){
            
            // log(" ");
            // log("--------------------------------------------")
            // log()

            // Is expanded set in the cookie?
            let expanded:boolean = this.cookieValue;
        
            // Is expanded set in the props?
            if(this.props.expanded !== undefined) expanded = this.props.expanded;
            
            // Set state
            this.setState({
                expanded: expanded,
                isExpandable: this.containsHiddenElement(this.props.children),
                
            })
        }

        // Reveal or unreveal when expand button toggled
        if(this.state.expanded !== oldState.expanded){
            if(this.state.expanded) this.reveal();
            else this.unreveal();
        }
    }

    reveal = async() =>{
        await Tools.later(0.1);
        if(this.isUnmounted) return;
        this.setState({isRevealed: true})
    }

    unreveal = async() =>{
        await Tools.later(0.1)
        if(this.isUnmounted) return;
        this.setState({isRevealed: false})
    }

    componentWillUnmount(){
        this.isUnmounted = true;
    }

    /**
     * Recurse through the elements to find if any are hidden (className = "hidden")
     * @param els - Elements, can be anything really
     * @returns boolean
     */
    containsHiddenElement = (els:any[] | any):boolean => {
        log("Expander.containsHiddenElement()")

        if(this.props.childrenHidden) return true;

        if(!els) return false;
        els = Array.isArray(els) ? els : [els];
        log(" els = ", els)

        let isHidden = false;
        for(let el of els){

            // Found a hidden one
            if(el && el.props && el.props.className && el.props.className.indexOf("hidden") !== -1){
                log(" - found hidden element: ", el)
                isHidden = true;
                break;
            }
            

            // Check children
            else if(el && el.props && el.props.children){
                log(" - checking children...")
                isHidden = this.containsHiddenElement(el.props.children)
            }
        }
        
        return isHidden;
        
    }

    


    get cookieKey():string{return "expander__" + this.props.id;}
    
    get cookieValue():boolean{
        if(!this.props.id) return false;
        const val:any = Cookies.getCookie(this.cookieKey)
        return val === 'true' || val === true;
    }
    

    save = (val:boolean) => {
        
        if(this.props.save === false) return;
        if(!this.props.id) return;
        log("Expander.save()")
        log(" - saving expanded = " + val)
        Cookies.setCookie(this.cookieKey, val);
    }

    handleMoreButClick = async() => {
        const expanded = !this.state.expanded;
        this.setState({expanded:expanded})
        this.save(expanded);
    }

    

    render(){
        log("Expander.render()")
        // log(" - this.state.expanded =  " + this.state.expanded);

        let className = "expander";
        if(this.state.expanded) className += " expanded";

        // But classes
        const butsClassName:string = this.props.butAlignLeft ? "expander-buttons expander-buttons-left" : "expander-buttons";
        const butType:string = this.props.butType === undefined ? "type" + ButType.type2 : "type" + this.props.butType;
        const butClassNameLess = "but " + butType + " less-but";
        const butClassNameMore = "but " + butType + " more-but";

        // Labels
        const butTextLess:string =  this.props.butTextLess === undefined ? Lang.t("showLess") : this.props.butTextLess;
        const butTextMore:string =  this.props.butTextMore === undefined ? Lang.t("showMore") : this.props.butTextMore;
        
        // Buttons
        const showButtons = this.state.isExpandable;
        log(" - showButtons = " + showButtons )

        let hiddenClass = "hidden";
        if(this.state.isRevealed) hiddenClass += " revealed"
        return (
            <div className={className}>
                
                {/* GENERIC CHILDREN 
                * Can contain hidden children when using the 'advanced' method described above
                */}
                {this.props.children}

                {/* HIDDEN CHILDREN 
                * Only contains hidden children
                */}
                {this.props.childrenHidden && this.state.expanded &&
                    <div className={hiddenClass}>
                        {this.props.childrenHidden}
                    </div>
                }

                {/* BUTTONS */}
                {showButtons &&
                    <div className={butsClassName}>
                        {this.state.expanded ?
                            <button onClick={this.handleMoreButClick} className={butClassNameLess} style={this.props.butStyle}><span style={this.props.butTextStyle}>{butTextLess}</span><ExpandLess className="icon"/></button>
                            :
                            <button onClick={this.handleMoreButClick} className={butClassNameMore} style={this.props.butStyle}><span style={this.props.butTextStyle}>{butTextMore}</span><ExpandMore className="icon"/>                    </button>
                        }
                    </div>
                }
                
            </div>
        )
    }
}