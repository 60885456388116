import App from '../App';
import type {HeadParams} from '../App';
import Cookies from './Cookies';
// import Router from '../router/Router';
import {LangString} from '../data/Content';
import Tools from './Tools';
import Router from '../router/Router';
 


// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}


type TermsMap = {
	[propName:string]: string
}

type Langs = {
    [propName:string]:TermsMap,
}

export enum LangId {
    en = "en",
    ga = "ga",
}

let instance:Lang;

export default class Lang {
    
    
    loaded:boolean = false;
    langs:Langs = {}

    defaultLangId:LangId

	static get defaultLangId():LangId { return instance.defaultLangId }

    constructor(defaultLangId:LangId) {
        log("Lang()");
        log("- App.state.langId = " + App.state.langId)
		this.defaultLangId = defaultLangId
        instance = this;
        
    }
    
    
    init = async () => {
        log("Lang.init()")
        await this.load();

        // this._logLangs();

        // Is there a lang cookie already set? Then apply it to App.state and Lang
        let langId:LangId = this.defaultLangId;
        const cookieLangId = Cookies.getCookie("langId");
        if(cookieLangId != null){
            log("- cookieLangId = " + cookieLangId)
            langId = cookieLangId;
        }
        
        this.setLangId(langId);
    }


    

    load = async () => {
		this.loaded = false
		try {
			
            this.langs.en = await require('../assets/langs/ui/en.json');
            this.langs.ga = await require(`../assets/langs/ui/ga.json`);
            // this.langs.en = await require(`./langs/ui/en.json`);
            // this.langs.ga = await require(`./langs/ui/ga.json`);
			this.loaded = true
        }
        
		catch(err) {
			throw err
		}
    }

    static get cookieLangId():LangId|null{
        const cookieLangId = Cookies.getCookie("langId");
        if(cookieLangId != null) return cookieLangId;
        return null;
    }
   
    /**
     * Get the url fragment if a language is set.
     * @returns "/en/" if English, or "/" if Gaelic
     */
    static getUrlLangFrag():string{
        let str = "/";

        // If EN
        if(App.state.langId === LangId.en) str += LangId.en + "/";
        
        return str;
    }

    static toggleLangId = async () => {
        const newLangId:LangId = App.state.langId === LangId.en ? LangId.ga : LangId.en;
        instance.setLangId(newLangId, true)
    }

    static get version():number{
        return Lang.langId === LangId.ga ? 0 : 1;
    }

    static get langId():LangId{
        return App.state.langId;
    }


	static get isoLangId():string{
		let lang:string = App.state.langId
		if (lang === "ga") {
			lang = "gd"
		}
        return lang;
    }

    static get langIdInactive():LangId{
        return App.state.langId === LangId.en ? LangId.ga : LangId.en;
    }
    
    /**
     * Set the langId in App.state and cookie
     * @param langId LangId string
     * @param storeCookie 
     */
    static setLangId = async (langId:LangId, storeCookie:boolean = false) =>{
        await instance.setLangId(langId, storeCookie);
    }


    /**
     * Set the window title in the current language
     * @param [titleOrId] - LangString or id of term in lang file
     * @param [descriptionOrId] - LangString or id of term in lang file
     * @param [addSuffix] - add the " – Bocan" text to the end
     * @param [string] type
     * @param [string] imageUrl
     * @param [boolean] isSearchable
     */
    static setTitle = async (titleOrId?:LangString|string, descriptionOrId?:LangString|string, addSuffix:boolean = false, type?:string, imageUrl?:string, isSearchable:boolean = true) => {
        log("Lang.setTitle()")
        // log(" - titleOrId = " + titleOrId)
        // log(" - descriptionOrId = " + descriptionOrId)
        // log(" - addSuffix = " + addSuffix)
        // log(" - type = " + type)
        // log(" - imageUrl = " + imageUrl)
        
        await Tools.later(0.1);
        
        // log(" - titleOrId = " + titleOrId)
        // log(" - descriptionOrId = " + descriptionOrId)
        // log(" - addSuffix = " + addSuffix)
        
    
        // TITLE ID
        // An id for title in the translation file has been suppplied
        if(typeof titleOrId === "string"){
            titleOrId = {
                en: Lang.t(titleOrId, LangId.en),
                ga: Lang.t(titleOrId, LangId.ga)
            }
        }

        // DESCRIPTION ID
        // An id for the description in the translation file has been suppplied
        if(typeof descriptionOrId === "string"){
            log(" - typeof = string")
            descriptionOrId = {
                en: Lang.t(descriptionOrId, LangId.en),
                ga: Lang.t(descriptionOrId, LangId.ga)
            }
            log(" - descriptionOrId = ",descriptionOrId)
        }

        // NO TITLE
        // No title or id supplied
        if(!titleOrId){
            if(Lang.title){
                titleOrId = Lang.title;
            }
            
            else{
                titleOrId = {
                    en: Lang.t("title", LangId.en),
                    ga: Lang.t("title", LangId.ga)
                }
                addSuffix = false;
            }
        }

        // NO DESCRIPTION
        // No description or id supplied
        if(!descriptionOrId){
            if(Lang.description){
                descriptionOrId = Lang.description;
            }
            
            else{
                descriptionOrId = {
                    en: Lang.t("home.description", LangId.en),
                    ga: Lang.t("home.description", LangId.ga)
                }
            }
        }

        // Store title for later use
        Lang.title = titleOrId;

        // Store description for later use
        Lang.description = descriptionOrId;
        
        // Make string
        let titleStr = Lang.tc(Lang.title);
        const titleOGStr = titleStr;
        if(addSuffix) titleStr += " | Bòcan";
         
        // Set description
        const descriptionStr:string = Lang.tc(Lang.description)
       
      
        const head:HeadParams = {

            // Title
            title: titleStr,
            titleOG: titleOGStr,
            
            // Description
            description: descriptionStr,
            
            // Locale
            locale: Lang.localeOG,
            localeAlternate: Lang.localeAlternateOG,

            // Type
            type: type,

            // Site
            site_name: "Bòcan",

            // URL
            url: Router.url,

            // Image
            imageOG: imageUrl || "",

            // Sets robots no-index if false
            isSearchable: isSearchable
        }
        // console.log(" - head = ", head)
        App.setHead(head);

        
        
        
    }
    
    static get localeOG():string {
        // Meta docs for locale:
        // https://developers.facebook.com/docs/javascript/internationalization#locales
        // 
        // Language codes
        // https://www.loc.gov/standards/iso639-2/php/code_list.php
        //
        // Country codes
        // https://en.wikipedia.org/wiki/ISO_3166-1
        return Lang.langId === LangId.en ? "en_GB" : "gd_GB";
    }

    static get localeAlternateOG():string {
        return Lang.langId === LangId.en ? "gd_GB" : "en_GB" ;
    }
    
    static title?:LangString;
    static description?:LangString;

    /**
     * Compare two LangString objects to see if they are equal
     * @param item1 
     * @param item2 
     * @param fuzzy - Do a fuzzy comparison that normalizes text (e.g. Ronan would equal Rònan)
     * @param langId - Only compare a specific lang. Defaults to both langs
     * @returns 
     */
    static areLangStringsSame(item1?:LangString, item2?:LangString, fuzzy:boolean = false, langId?:LangId):boolean{
        
        log("Tools.areLangStringsSame()");

        // Both strictly the same (they may both be undefined)
        if(item1 === item2) return true;
        
        // One exists, not the other
        if(item1 === undefined || item2 === undefined){
            if(item1 && item2 === undefined) return false;
            return false;
        }

        // Fuzzy comparison
        if(fuzzy){

            // Normalize item 1
            let item1En = Tools.stringNormalize(item1.en);
            let item1Ga = Tools.stringNormalize(item1.ga);

            // Normalize item2
            let item2En = Tools.stringNormalize(item2.en);
            let item2Ga = Tools.stringNormalize(item2.ga);


            // Only English
            if(langId === LangId.en){
                return item1En === item2En
            }
            // Only Gaelic
            else if(langId === LangId.ga){
                return item1Ga === item2Ga;
            }

            // Both English and Gaelic
            return item1En === item2En && item1Ga === item2Ga;
        }

        // Strict comparison of members

        // Only English
        if(langId === LangId.en){
            return item1.en === item2.en;
        }

        // Only Gaelic
        else if(langId === LangId.ga){
            return item1.ga === item2.ga;
        }

        // Both English and Gaelic
        return item1.en === item2.en && item1.ga === item2.ga;
    }

   

    setLangId = async (langId:LangId, storeCookie:boolean = false) => {
        
        log("Lang.setLangId('" + langId + "', " + storeCookie + ")")

        const langIdPrev = Lang.langId;
        
        // Set state
        App.setState({
            langId: langId
        }, ()=>{
            // Set cookie
            if(storeCookie) Cookies.setCookie("langId", langId);

            // Set html lang property
            document.documentElement.lang = langId;
            Lang.setTitle();

            Tools.removeClassFromHTML(langIdPrev)
            Tools.addClassToHTML(langId)

            log(" - App.state.langId = " + App.state.langId);
            log(" - Cookie langId = " + Cookies.getCookie("langId"));
            log(" - page title = " + Lang.tc(Lang.title))
            log(" - page description = " + Lang.tc(Lang.description))
        });

        
        

        

    }
  
    /**
	 * Giz a term from the ui-lang json please.
	 * 
	 * @param {string} termId - Which term do we want?
	 */
	static t(termId:string, langId?:LangId):string {
        
        // LangId not set
        if(!App.state.langId) return termId;
        
        // Get terms object
        const terms:TermsMap|null = instance._getTerms(langId);
        if(!terms) return termId;
        
        // Get term
        let term:string = terms[termId]
		if (term===undefined) return termId;

		return term;
    }

    /**
     * Get the translation from a content object which takes the form:
     * {en: "example English", ga: "example Gaelic"}
     * 
     * @param obj Object containing 'en' and 'ga' properties
     * @param useAlternativeAsBackup - If the preferred lang contains nothing, then return the string for other lang
     * @param langId - use a specific langId rather than the one stored in state
     * @returns string
     */
    static tc(obj:any, useAlternativeAsBackup:boolean = false, langId?:LangId):string{
        if(!langId) langId = App.state.langId;
     

        // Get trimmed text for this lang
        let text:string = "";
        if(obj && langId){
            text = obj[langId];
            if (typeof text === "string") text = text.trim();
        }

        // No string is available for this lang
        if(!langId || !obj || !text){

            // If an alternative exists then return that (when desired)
            if(useAlternativeAsBackup){
                const altLangId:LangId = langId === LangId.en ? LangId.ga : LangId.en;
                if(langId && obj && obj[altLangId]) return obj[altLangId];
            }
            return "";
        }

        return obj[langId];

    }

    

    static hasTerm(termId:string):boolean {

        const terms:TermsMap = instance.langs[App.state.langId];
		let term = terms[termId];
		
		// "?" is considered empty
		if(term && term === "?") return false;
		
		// Term exists or not
		return term != null;
    }
    
    private _getTerms(langId?:LangId):TermsMap|null{
        if(!langId) langId = App.state.langId;
        if(!langId) return null;
        if(!this.langs[langId]) return null;
        return this.langs[langId];
    }

    
    /**
     * Logs the term ids and terms of the lang files
     */
     _logLangs(){
        if(!isLoggingEnabled) return;
        this._logLang(LangId.en);
        this._logLang(LangId.ga);
    }

    _logLang(langId:LangId){
        log(" ");
        log("----------------------------")
        log(" LANG: " + langId )
        const lang:TermsMap = this.langs[langId];
        let ids = "";
        let terms = "";
        for(let termId in lang){
            ids += `${termId}\n`;
            terms += `${lang[termId]}\n`;
            
        }
        log(" ");
        log("----------")
        log(" ");
        log(" IDS");
        log(ids)

        log(" ");
        log("----------")
        log(" TERMS");
        log(" ");
        log(terms)
        log("----------------------------")
        log(" ");
    }
    

}