import './_styles.scss';

//@ts-ignore
import _ from 'lodash'

import {ReactNode} from 'react'

import React from 'react';
import Lang from '../../tools/Lang';
import Tools from '../../tools/Tools'
import ExternalLink from '../../tools/ExternalLink'
import {TopicData, MediaItemData, MediaType} from '../../data/Topic';
import Router from '../../router/Router';
import TopicSoundsItemPage from '../TopicSoundsItemPage/TopicSoundsItemPage';
import TabPage from '../TabPage/TabPage';
import Link from '../Link/Link';
import {TopicTabId} from '../TopicTabs/TopicTabs';

// import { gsap } from "gsap";

// Logging
const isLoggingEnabled:boolean = false;
function log(...args:any){if(isLoggingEnabled) console.log(...args);}

interface Props  {
    topicData:TopicData,
    childId?:string,
    setTitle:Function,
}


export default class TopicSounds extends React.Component<Props>{
    
    itemsData:MediaItemData[] = [];

    // Set to true to force every odd item in the list to be external
    DEV_FORCE_EXTERNAL:boolean = false;


    constructor(props:Props){
        
        super(props);
        log("TopicSounds()")
        
        let topicData = _.cloneDeep(props.topicData)

        if(topicData.mediaItems){
            const shouldInjectExternalData:boolean = this.DEV_FORCE_EXTERNAL && Tools.isDev;

            let index:number = 0
            topicData.mediaItems.forEach( (itemData:MediaItemData) =>  {
                if(itemData.type === MediaType.archiveSound){
                    itemData = this.cleanData(itemData);
                    
                    // Add temporary external links for testing
                    if(shouldInjectExternalData && (index % 2) > 0){
                        itemData.externalLinks = [
							{url: "https://www.tobarandualchais.co.uk/track/36067?l=en", label: "tobar", langs: ["en"]}
						]
                    }

                    this.itemsData.push(itemData);
                    index ++;
                }
            });
        }


        log(" - itemsData = ", this.itemsData);


        
    }

    componentDidMount(){
        if(!this.props.childId)
            this.props.setTitle();
    }

    componentDidUpdate(prevProps:Props){
        
        // Refresh title if coming back from child page
        const isComingBackFromChild:boolean = prevProps.childId !== undefined && !this.props.childId;
        if(isComingBackFromChild){
            this.props.setTitle();
        }

    }


    cleanData(itemData:MediaItemData):MediaItemData{
		
        if(itemData.catname) itemData.catname = Tools.stringTrimTrailingJunk(itemData.catname)
        if(itemData.catnumber) itemData.catnumber = Tools.stringTrimTrailingJunk(itemData.catnumber)
        
        // Append unique _id to the non-unique id
        if(itemData._id) itemData.id += "_" + itemData._id;
        
        return itemData;
    }

    

    getItemData(id?:string):MediaItemData|null{
        if(!id || !this.itemsData) return null;
        for(let itemData of this.itemsData){
            if(itemData.id === id) return itemData
        }
        return null;
    }

    getUrl(childId:string):string{
        const url = Router.getTopicTabChildPath(this.props.topicData.topicId, TopicTabId.sounds, childId)
        return url;
    }


    handleItemClick = (itemData:MediaItemData) => {
        if(!itemData?.id) return;
        
        // External url
        if(itemData.externalLinks){
			const link:ExternalLink|undefined = ExternalLink.pickLinkForLang(itemData.externalLinks, Lang.langId)
			if (link?.url) {
	            Router.navigateToPath(link.url, null, false, false, false, true, true)    ;
	            return;
			}
        }

        // Internal url
        Router.navigateToTopicTabChild(itemData.id);
    }


	renderTags(tags:string[]=[], glue:string=""):ReactNode[] {
		tags = tags.filter(tag => Lang.hasTerm(`oral.controls.filter.type.${tag}`) )

		return tags.map((tag:string, i:number) => 
		
            <span 
                className='tag'
                key={"tag_" + tag + "_" + i}
            >
                {Lang.t(`oral.controls.filter.type.${tag}`)}
                { 
                    (i < tags.length-1) && (glue || "")
                }
            </span>
				

		)
	}
    
    renderItems():any{
        if(!this.itemsData) return null;

        


        let items:any[] = [];
        let i = 0;
        for(let itemData of this.itemsData){

          
			const externalLink = ExternalLink.pickLinkForLang(itemData.externalLinks, Lang.langId)

            let item = (
                <Link 
                    to={this.getUrl(itemData.id)}
                    onClick={()=>{this.handleItemClick(itemData)}} 
                    className="topic-sounds-list-item" 
                    key={"sounds_item__" + i}
                >
                    <div className="item-content">

                        <div className="main-block">
                            <div className="item-header">
                                <div className="item-header-text">
                                    <h3 className="list-title">
                                        {Lang.tc(itemData.title)}
                                    </h3>

                                    
                                </div>
                                {externalLink?.label &&
                                    <div className="item-header-icon">
                                        <img src={`/images/ui/logo-${externalLink.label}.png`} alt={externalLink?.labelAlt}/>
                                    </div>
                                }
                            </div>

                            {/* AUTHOR */}
                            {itemData.informant || itemData.fieldworker ? 
                                <p className="smallcaps author">
                                    
                                        {itemData.informant ?
                                            <span>
                                                <span className="role">{Lang.t("topic.sounds.informant") + ": "}</span>
                                                {itemData.informant}
                                            </span>
                                            : null
                                        }

                                        {itemData.fieldworker && itemData.informant ?
                                            <span>
                                                <span className="divider">|</span>
                                                <span className="new-line"><br/></span>
                                            </span>
                                            :
                                            null
                                        }

                                        {itemData.fieldworker ?
                                            <span>
                                                <span className="role">{Lang.t("topic.sounds.fieldworker") + ": "}</span>
                                                {itemData.fieldworker}
                                            </span>
                                            : null
                                        }

                                    
                                </p>
                                : null
                            }
                            

                            {/* DESCRIPTION */}
                            {itemData.description && Lang.tc(itemData.description) &&
                                <p className="description">
                                    {Lang.tc(itemData.description)}
                                </p>
                            }


                            
                            
                            {/* PUBLISHER */}
                            <p className="faint publisher">
                                {itemData.catname}{itemData.catnumber && (", " + itemData.catnumber)}
                            </p>

                            {/* FILTER TAGS */}
                            {itemData && itemData.tags && itemData.tags.length>0 && 
								<p className='smallcaps faint tags'>{this.renderTags(itemData.tags, ", ")}</p>
							}

                            

							


                            {externalLink &&
                                <div className="external">
                                    <img src="/images/ui/icon-new-window.png" alt=""/>
                                    <span>{Lang.t("but.newWindow")}</span>
                                </div>
                            }
                        </div>
                        
                    </div>

                    {/* <p className="faint">{itemData.text}</p> */}
                </Link>
            )
            items.push(item);
            i++;
        }
        
        return (
            <div className="sounds-items">
                {items}

            </div>
        )

    }
    

    render(){
        log("TopicSounds.render()")
         
        let shouldRenderItemPage:boolean = false;
        let itemData:MediaItemData|null = null;
        if(this.props.childId){
            itemData = this.getItemData(this.props.childId);
            if(itemData && (itemData.intro || itemData.text)) shouldRenderItemPage = true;
            log(" - shouldRenderItemPage = " + shouldRenderItemPage)
        }
        
       
        return(
            <TabPage>
                <div id="topic-sounds" className="topic-panel">
                    <div className="main-column main-column--narrow">
                        <div className="main-column-content">


                            {/* SHOW THE LIST */}
                            {!shouldRenderItemPage && 
                                <div className="text-column">
                                    <div className="topic-subheader"/>
                                    {this.renderItems()}

                                </div>
                            }

                            
                            {/* SHOW THE ITEM PAGE */}
                            {(itemData && shouldRenderItemPage) && 
                                <TopicSoundsItemPage
                                    itemData={itemData}
                                    setTitle={this.props.setTitle}
                                />
                            }
                        
                        </div>
                    </div>
                    
                </div>
            </TabPage>
        )
    }
}